import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import {
  DJANGO_API_URL,
  SUPPORT_EMAIL,
  TOLL_FREE_NUMBER,
} from '../environment';

export function NotFound() {
  return (
    <div className="bg-light">
      <Row className="py-8 py-md-7 justify-content-center">
        <Col className="text-center" lg={8} md={10} xs={12}>
          <h1 className="display-2 font-weight-bold mt-2 text-primary">
            Page not found
          </h1>
          <p className="lead text-muted mb-6">
            The page you are looking for does not exist.
          </p>
          <div className="text-center">
            <Button href={`${DJANGO_API_URL}/company`} variant="primary">
              Liferaft Home
            </Button>
          </div>
        </Col>
      </Row>
      <div className="bg-light">
        <Container fluid="md">
          <Row className="py-8 py-md-7 justify-contents-center">
            <Col className="offset-md-3 text-center" md={6} xs={12}>
              <p>
                If you require assistance, please reach out to the Liferaft
                Customer Service Team at{' '}
                <a href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> or at{' '}
                {TOLL_FREE_NUMBER}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
