import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    fraudNotice: (
      <p>
        It is a crime to knowingly provide false, incomplete or misleading
        information to an insurance company for the purpose of defrauding the
        company. Penalties include imprisonment, fines and denial of insurance
        benefits.
      </p>
    ),
  },
};

export default stateModule;
