import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    fraudNotice: (
      <p>
        Any person who knowingly presents a false or fraudulent claim for
        payment of a loss or benefit or knowingly presents false information in
        an application for insurance is guilty of a crime and may be subject to
        fines and confinement in prison.
      </p>
    ),
  },
};

export default stateModule;
