import React from 'react';
import { Outlet } from 'react-router-dom';

type Props = {
  mainStyles?: string[];
  header?: React.ReactNode;
  banners?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
};

export function ColumnarLayout({
  mainStyles,
  header,
  banners,
  children,
  footer,
}: Props) {
  return (
    <main className={mainStyles?.join(' ') || ''} data-testid="main">
      {header}
      {banners}
      <div>
        {children}
        <Outlet />
      </div>
      {footer}
    </main>
  );
}
