import { DjangoRequestConfiguration } from '../utils/django-utils';
import type { NoBody } from '../utils/network';

export function getAuthToken(): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url('/user/auth/token/');
}

export function verifyAuthToken(): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>({
    headers: {
      'Content-Type': 'multipart/form',
    },
  })
    .get()
    .url('/user/auth/verify/');
}
