import React from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';

import {
  createDisclosureAcknowledgement,
  updateApplication,
} from '@liferaft/api/resources/application';
import type { Application, Egress } from '@liferaft/api/types';
import {
  CarrierKey,
  DisclosureAcknowledgementResponse,
  DisclosureType,
  DocumentDelivery,
} from '@liferaft/api/types';
import type { ParsedDjangoErrorResponse } from '@liferaft/api/utils/django-utils';
import { NetworkController } from '@liferaft/api/utils/network';

import { Card } from '@liferaft/core/components';
import type { FormErrorsSetter } from '@liferaft/core/components/forms';
import { Input, ValidatedForm } from '@liferaft/core/components/forms';
import { useUserContext } from '@liferaft/core/contexts';
import { useDebounce } from '@liferaft/core/hooks';

import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';
import { DJANGO_API_URL } from '@/environment';

const schema = yup.object({
  replacement_disclosure: yup
    .boolean()
    .required()
    .label('Replacement for current coverage'),
  fl_current_coverage_company: yup
    .string()
    .label('Current health coverage company name'),
  fl_current_coverage_policy_number: yup
    .string()
    .label('Current health coverage company policy number'),
  aig_document_delivery: yup
    .mixed<DocumentDelivery>()
    .oneOf(Object.values(DocumentDelivery))
    .required()
    .label('Document Delivery'),
  aig_electronic_delivery_consent: yup
    .boolean()
    .label('Consent to recieve documents electronically'),
});

type FormData = yup.InferType<typeof schema>;

export function AIGDisclosures() {
  const { user } = useUserContext();
  const {
    data: { egress, id },
    next,
  } = useApplicationFlow();

  const [replacementDisclosure, setReplacementDisclosure] =
    React.useState<boolean>();

  const [flCurrentCoverageCompany, setFLCurrentCoverageCompany] =
    React.useState<string>('');

  const [flCurrentCoveragePolicyNumber, setFLCurrentCoveragePolicyNumber] =
    React.useState<string>('');

  const [electronicConsent, setElectronicConsent] = React.useState<boolean>();

  const [documentDelivery, setDocumentDelivery] =
    React.useState<DocumentDelivery>();

  const productNames = egress?.product_selections
    ?.filter((s) => {
      if ('carrier' in s) {
        return s.carrier === CarrierKey.AIG;
      }
      return false;
    })
    ?.map((s) => s.name);

  const [handleSubmit, isSubmitting] = useDebounce(
    async (validatedData: FormData, setErrors: FormErrorsSetter<FormData>) => {
      const network = new NetworkController();

      const result = await network.request<FormData, Application>(
        updateApplication(id as string, validatedData)
      );

      if (result.error) {
        setErrors(result.data as ParsedDjangoErrorResponse<Egress>);
        return;
      }

      network.request(
        createDisclosureAcknowledgement(user.id, {
          disclosure_type: DisclosureType.REPLACEMENT_DISCLOSURE,
          response: DisclosureAcknowledgementResponse.ACCEPTED,
        })
      );

      if (electronicConsent) {
        network.request(
          createDisclosureAcknowledgement(user.id, {
            disclosure_type: DisclosureType.E_DELIVERY,
            response: DisclosureAcknowledgementResponse.ACCEPTED,
          })
        );
      }

      return next();
    }
  );

  return (
    <ApplyWrapper
      footer={<ApplyWrapperFooter rightDisabled={isSubmitting} />}
      heading="Agreement to Terms">
      <Card bodyClasses="" cardClasses="card-bleed shadow-light">
        {productNames && (
          <div className="d-flex w-100 align-items-center mb-4 mt-2">
            {productNames.map((name, index) => {
              return (
                <div className="font-size-lg" key={index}>
                  {name.toUpperCase().replaceAll('-', ' ')}
                  {index === productNames.length - 1 ? '' : ','}
                </div>
              );
            })}
          </div>
        )}
        <div className="strike-line ml-0 mb-4" />
        <ValidatedForm<FormData>
          controlledData={{
            aig_document_delivery: documentDelivery,
            aig_electronic_delivery_consent: electronicConsent,
            fl_current_coverage_company: flCurrentCoverageCompany,
            fl_current_coverage_policy_number: flCurrentCoveragePolicyNumber,
            replacement_disclosure: replacementDisclosure,
          }}
          id={APPLY_FORM_ID}
          onSubmit={handleSubmit}
          validationSchema={schema}>
          {({ errors }) => (
            <>
              <div>
                <div className="d-flex w-100 align-items-center mb-3 mt-4">
                  <div className="font-size-lg">Replacement Disclosure</div>
                  <div className="strike-line" />
                </div>

                <Form.Group controlId="termAgreementCheckbox">
                  <Form.Label>
                    Is the purchase of this coverage intended to replace any
                    other accident and sickness insurance now in force?
                  </Form.Label>
                  <div className="ml-5">
                    <Form.Check
                      checked={replacementDisclosure === true}
                      id="aig-is-replacing"
                      isInvalid={!!errors.replacement_disclosure}
                      key="aig-is-replacing"
                      label="Yes"
                      name="replacement-disclosure"
                      onChange={() => setReplacementDisclosure(true)}
                      required
                      type="radio"
                    />
                    <Form.Check
                      checked={replacementDisclosure === false}
                      id="aig-is-not-replacing"
                      isInvalid={!!errors.replacement_disclosure}
                      key="aig-is-not-replacing"
                      label="No"
                      name="replacement-disclosure"
                      onChange={() => setReplacementDisclosure(false)}
                      required
                      type="radio"
                    />
                    {errors.replacement_disclosure && (
                      <div className="invalid-feedback d-block">
                        {errors.replacement_disclosure}
                      </div>
                    )}
                  </div>
                </Form.Group>
                <p className="mt-5">
                  {' '}
                  If you intend to lapse or otherwise terminate existing
                  accident and sickness insurance and replace it with a new
                  policy offered by National Union Fire Insurance Company of
                  Pittsburgh, Pa., you should be aware of and consider certain
                  factors that may affect the insurance protection available to
                  you under the new policy. (1) Health conditions that you may
                  presently have (preexisting conditions) may not be immediately
                  or fully covered under the new policy. This could result in
                  denial or delay of a claim for benefits under the new policy,
                  whereas a similar claim might have been payable under your
                  present policy. (2) You may wish to secure the advice of your
                  present insurer or its agent regarding the proposed
                  replacement of your present policy. This is not only your
                  right, but it is also in your best interests to make sure you
                  understand all the relevant factors involved in replacing your
                  present coverage. (3) If, after due consideration, you still
                  wish to terminate your present policy and replace it with new
                  coverage, read the copy of the application attached to your
                  new policy once it is delivered and be sure that all questions
                  are answered fully and correctly and correct any omissions or
                  misstatements within 10 days of receipt.
                </p>
              </div>
              {egress?.state === 'FL' && (
                <div>
                  <div>
                    <div className="d-flex w-100 align-items-center mb-3 mt-4">
                      <div className="font-size-lg">
                        Florida Current Coverage
                      </div>
                      <div className="strike-line" />
                    </div>
                  </div>
                  <div className="my-3">
                    <span>
                      Please provide details of the coverage you currently have.
                    </span>
                    <Form>
                      <Input
                        aria-labelledby="company-name-prompt"
                        error={errors.fl_current_coverage_company}
                        id="company-name"
                        label="Company name"
                        labelClasses="mt-3"
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setFLCurrentCoverageCompany(e.target.value)
                        }
                        placeholder="Company name"
                        required
                        type="text"
                        value={flCurrentCoverageCompany}
                      />
                      <Input
                        aria-labelledby="policy-number-prompt"
                        error={errors.fl_current_coverage_policy_number}
                        id="policy-number"
                        label="Policy Name"
                        labelClasses="mt-3"
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setFLCurrentCoveragePolicyNumber(e.target.value)
                        }
                        placeholder="Policy name"
                        required
                        type="text"
                        value={flCurrentCoveragePolicyNumber}
                      />
                    </Form>
                  </div>
                </div>
              )}
              <div className="mt-3">
                <div className="d-flex w-100 align-items-center mb-3 mt-4">
                  <div className="font-size-lg">Document Delivery</div>
                  <div className="strike-line" />
                </div>

                <Form.Group controlId="deliveryCheckbox">
                  <Form.Label>
                    Would you like your plan documents delivered by email or
                    postal mail?
                  </Form.Label>
                  <div className="ml-5">
                    <Form.Check
                      checked={documentDelivery === DocumentDelivery.EMAIL}
                      id="document-delivery-email"
                      isInvalid={!!errors.aig_document_delivery}
                      key="email"
                      label="Email"
                      name="document-delivery"
                      onChange={() =>
                        setDocumentDelivery(DocumentDelivery.EMAIL)
                      }
                      required
                      type="radio"
                    />
                    <Form.Check
                      checked={documentDelivery === DocumentDelivery.MAIL}
                      id="document-delivery-mail"
                      isInvalid={!!errors.aig_document_delivery}
                      key="mail"
                      label="Postal Mail"
                      name="document-delivery"
                      onChange={() =>
                        setDocumentDelivery(DocumentDelivery.MAIL)
                      }
                      required
                      type="radio"
                    />
                    {errors.aig_document_delivery && (
                      <div className="invalid-feedback d-block">
                        {errors.aig_document_delivery}
                      </div>
                    )}
                  </div>
                </Form.Group>
                <p className="mt-5">
                  {' '}
                  The Privacy Notice and Replacement Notice will be provided
                  with your insurance documents. View the{' '}
                  <a
                    href={`${DJANGO_API_URL}/static/aig/docs/medicare_notice.pdf`}
                    rel="noreferrer"
                    target="_blank">
                    Importance Notice to Persons on Medicare
                  </a>{' '}
                  and{' '}
                  <a
                    href={`${DJANGO_API_URL}/static/aig/docs/medicare_and_you.pdf`}
                    rel="noreferrer"
                    target="_blank">
                    Medicare & You
                  </a>
                  , the official U.S. Government Medicare handbook.
                </p>
              </div>
              {documentDelivery === DocumentDelivery.EMAIL && (
                <div>
                  <div className="my-3">
                    <div className="d-flex w-100 align-items-center mb-3 mt-4">
                      <div className="font-size-lg">Electronic Consent</div>
                      <div className="strike-line" />
                    </div>
                  </div>
                  <Form.Group>
                    <Form.Label>
                      I have read and agree to the terms of the document titled{' '}
                      <a
                        href={`${DJANGO_API_URL}/static/aig/docs/electronic_consent.pdf`}
                        rel="noreferrer"
                        target="_blank">
                        {' '}
                        Our Consent to Electronic Delivery{' '}
                      </a>
                      provided as part of my application under the Plan. I
                      understand that by providing my consent, I agree to
                      receive documents (including fulfillment materials) and
                      future correspondence electronically, until such time as I
                      withdraw my consent. (Allow 3-5 days for delivery.)
                    </Form.Label>
                    <div className="ml-5">
                      <Form.Check
                        checked={electronicConsent === true}
                        id="has-agreed-document"
                        isInvalid={!!errors.aig_electronic_delivery_consent}
                        key="has-agreed-document"
                        label="Yes"
                        name="electronic-consent"
                        onChange={() => setElectronicConsent(true)}
                        required
                        type="radio"
                      />
                      <Form.Check
                        checked={electronicConsent === false}
                        id="has-not-agreed-document"
                        isInvalid={!!errors.aig_electronic_delivery_consent}
                        key="has-not-agreed-document"
                        label="No"
                        name="electronic-consent"
                        onChange={() => setElectronicConsent(false)}
                        required
                        type="radio"
                      />
                      {errors.aig_electronic_delivery_consent && (
                        <div className="invalid-feedback d-block">
                          {errors.aig_electronic_delivery_consent}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </div>
              )}
            </>
          )}
        </ValidatedForm>
      </Card>
    </ApplyWrapper>
  );
}
