import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import * as yup from 'yup';

import { updateApplication } from '@liferaft/api/resources/application';
import type { Application, Egress } from '@liferaft/api/types';
import { CarrierKey, CoverageType } from '@liferaft/api/types';
import type { ParsedDjangoErrorResponse } from '@liferaft/api/utils/django-utils';
import { NON_FIELD_ERROR_LABEL } from '@liferaft/api/utils/django-utils';
import { NetworkController } from '@liferaft/api/utils/network';

import { ReactComponent as RemoveDependentIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Code/Minus.svg';
import { ReactComponent as AddDependentIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Code/Plus.svg';
import { Card } from '@liferaft/core/components';
import type { FormErrorsSetter } from '@liferaft/core/components/forms';
import { Sex, ValidatedForm } from '@liferaft/core/components/forms';
import { useDebounce } from '@liferaft/core/hooks';
import * as dates from '@liferaft/core/utils/dates';

import type { PersonalInfoData } from '@/components';
import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  PersonalInfoInput,
  makeBlankPersonalInfo,
  useApplicationFlow,
} from '@/components';
import { fromDependent, isDirty } from '@/components/personal-info-input';
import { useProductMatrixContext } from '@/contexts';
import { SpouseTerminology } from '@/utils/data-shaping';

const schema = yup.object({
  spouse: yup
    .object({
      first_name: yup.string().required().label('First name'),
      last_name: yup.string().required().label('Last name'),
      date_of_birth: yup
        .string()
        .transform(dates.toString)
        .required()
        .label('Date of birth'),
      sex: yup.mixed<Sex>().oneOf(Object.values(Sex)).required().label('Sex'),
    })
    .default(undefined)
    .notRequired(),
  children: yup.array(
    yup.object({
      first_name: yup.string().required().label('First name'),
      last_name: yup.string().required().label('Last name'),
      date_of_birth: yup
        .string()
        .transform(dates.toString)
        .required()
        .label('Date of birth'),
      sex: yup.mixed<Sex>().oneOf(Object.values(Sex)).required().label('Sex'),
    })
  ),
});

type FormData = yup.InferType<typeof schema>;

export function FamilyInformation() {
  const {
    data: { egress, id },
    next,
  } = useApplicationFlow();

  const context = useProductMatrixContext(egress);
  const selectedInsuranceOfferings = context.selectedInsuranceOfferings;

  const requiresSpouse =
    selectedInsuranceOfferings.filter(
      ([, offering]) =>
        offering.coverage_type === CoverageType.INDIVIDUAL_SPOUSE
    ).length > 0;

  const requiresChildren =
    selectedInsuranceOfferings.filter(
      ([, offering]) =>
        offering.coverage_type === CoverageType.INDIVIDUAL_CHILDREN
    ).length > 0;

  React.useEffect(() => {
    if (egress?.spouse && isDirty(fromDependent(egress.spouse))) {
      setSpouse({
        name: {
          firstName: egress?.spouse?.first_name || '',
          lastName: egress?.spouse?.last_name || '',
        },
        dateOfBirth: egress?.spouse?.date_of_birth
          ? dates.fromString(egress.spouse.date_of_birth)
          : {},
        sex: egress?.spouse?.sex,
      });
    }

    if (
      egress?.children &&
      egress.children.some((dependent) => isDirty(fromDependent(dependent)))
    ) {
      setDependents(
        egress?.children?.map((dependent) => ({
          name: {
            firstName: dependent.first_name || '',
            lastName: dependent.last_name || '',
          },
          dateOfBirth: dates.fromString(dependent.date_of_birth),
          sex: dependent.sex,
        })) || [makeBlankPersonalInfo()]
      );
    }
  }, [egress]);

  const [spouse, setSpouse] = React.useState<Partial<PersonalInfoData>>(
    makeBlankPersonalInfo()
  );

  const [dependents, setDependents] = React.useState<
    Partial<PersonalInfoData>[]
  >([makeBlankPersonalInfo()]);

  const handleDependentChange = (
    updatedDependent: Partial<PersonalInfoData>,
    dependentIndex: number
  ): void => {
    const newDependents = [...dependents];
    newDependents[dependentIndex] = updatedDependent;
    setDependents(newDependents);
  };

  const addDependent = (): void => {
    setDependents([...dependents, makeBlankPersonalInfo()]);
  };

  const removeDependent = (): void => {
    const newDependents = [...dependents];
    newDependents.pop();
    setDependents(newDependents);
  };

  const [handleSubmit, isSubmitting] = useDebounce(
    async (validatedData: FormData, setErrors: FormErrorsSetter<FormData>) => {
      const network = new NetworkController();

      const result = await network.request<FormData, Application>(
        updateApplication(id as string, validatedData)
      );
      if (result.error) {
        setErrors(result.data as ParsedDjangoErrorResponse<Egress>);
        return;
      }

      return next();
    }
  );

  const controlledData: Record<string, any> = {};

  const serializedDependents = dependents.map((dependent) => ({
    first_name: dependent?.name?.firstName,
    last_name: dependent?.name?.lastName,
    date_of_birth: dependent.dateOfBirth,
    sex: dependent.sex,
  }));

  const dependentsDirty = dependents.some((dependent) => isDirty(dependent));
  const spouseDirty = isDirty(spouse);

  if (requiresChildren || dependentsDirty) {
    controlledData.children = serializedDependents;
  }

  const serializedSpouse = {
    first_name: spouse?.name?.firstName,
    last_name: spouse?.name?.lastName,
    date_of_birth: spouse.dateOfBirth,
    sex: spouse.sex,
  };

  if (requiresSpouse || spouseDirty) {
    controlledData.spouse = serializedSpouse;
  }

  if (!requiresSpouse && !requiresChildren) {
    if (
      (dependentsDirty && spouseDirty) ||
      (!dependentsDirty && !spouseDirty)
    ) {
      controlledData.children = serializedDependents;
      controlledData.spouse = serializedSpouse;
    } else if (dependentsDirty) {
      controlledData.children = serializedDependents;
    } else if (spouseDirty) {
      controlledData.spouse = serializedSpouse;
    }
  }

  const hasAIG = context.selectedInsuranceOfferings.some(
    ([, offering]) => offering.carrier === CarrierKey.AIG
  );

  const hasAmeritas = context.selectedInsuranceOfferings.some(
    ([, offering]) => offering.carrier === CarrierKey.AMR
  );

  let spouseTerm = 'Spouse';

  if (context.residentState) {
    spouseTerm = SpouseTerminology(
      hasAIG ? CarrierKey.AIG : CarrierKey.LIB,
      context.residentState as string,
      true
    );
  }

  let wrapperSubheading = (
    <>
      {spouseTerm} will only be enrolled for the plans where "Family" or
      "Individual + Spouse" coverage was selected.
      <br />
      Children will only be enrolled for the plans where "Family" or "Individual
      + Children" coverage was selected.
    </>
  );
  if (hasAmeritas) {
    wrapperSubheading = (
      <>
        {spouseTerm} will only be enrolled for the plans where "Family" or
        "Individual + Spouse" coverage was selected.
        <br />
        Children will only be enrolled for the plans where "Family" or
        "Individual + Children" coverage was selected.
        <br />
        For "Individual + Child" dental and vision plans, only the first child
        listed below will be enrolled.
      </>
    );
  }

  return (
    <ApplyWrapper
      footer={<ApplyWrapperFooter rightDisabled={isSubmitting} />}
      heading="Family Information"
      subheading={wrapperSubheading}>
      <ValidatedForm<FormData>
        controlledData={controlledData}
        id={APPLY_FORM_ID}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        {({ errors }) => (
          <Card cardClasses="card-bleed shadow-light">
            {errors[NON_FIELD_ERROR_LABEL] && (
              <Alert className="mb-3" variant="danger">
                <ul>
                  {errors[NON_FIELD_ERROR_LABEL].map(
                    (err: string, i: number) => (
                      <li key={i}>{err}</li>
                    )
                  )}
                </ul>
              </Alert>
            )}
            <PersonalInfoInput
              errors={{
                name: {
                  firstName: errors.spouse?.first_name,
                  lastName: errors.spouse?.last_name,
                },
                dateOfBirth: errors.spouse?.date_of_birth,
                sex: errors.spouse?.sex,
              }}
              fieldsetClasses="form-group"
              nameLabel={`${spouseTerm} Information`}
              onChange={setSpouse}
              required={requiresSpouse}
              sexHelper="Please select the sex most commonly used for government forms."
              sexRadioPrefix="spouse-sex"
              showNameLabels
              value={spouse}
            />
            {dependents.length > 0 &&
              dependents.map((dependent, dependentIndex) => {
                const dependentHelper =
                  dependentIndex < 1
                    ? 'Please select the sex most commonly used on government forms for this dependent.'
                    : '';

                const childErrors = errors.children?.[dependentIndex];

                return (
                  <PersonalInfoInput
                    errors={{
                      name: {
                        firstName: childErrors?.first_name,
                        lastName: childErrors?.last_name,
                      },
                      dateOfBirth: childErrors?.date_of_birth,
                      sex: childErrors?.sex,
                    }}
                    fieldsetClasses="form-group"
                    key={dependentIndex}
                    label={`Dependent Child ${
                      dependentIndex + 1
                    }'s Information`}
                    onChange={(dependent) =>
                      handleDependentChange(dependent, dependentIndex)
                    }
                    required={requiresChildren}
                    sexHelper={dependentHelper}
                    sexRadioPrefix={`sex${dependentIndex + 1}`}
                    showNameLabels
                    value={dependent}
                  />
                );
              })}
            <div className="mt-8">
              <Button
                className="p-0"
                id="add-child"
                onClick={addDependent}
                type="button"
                variant="empty">
                <span className="icon text-primary">
                  <AddDependentIcon viewBox="0 0 24 24" />
                </span>
              </Button>
              <Button
                className="p-0"
                id="remove-child"
                onClick={removeDependent}
                type="button"
                variant="empty">
                <span className="icon text-primary">
                  <RemoveDependentIcon viewBox="0 0 24 24" />
                </span>
              </Button>
              <span className="ml-2 mb-1">Add/Remove Dependent Child</span>
            </div>
          </Card>
        )}
      </ValidatedForm>
    </ApplyWrapper>
  );
}
