import React from 'react';
import { Form } from 'react-bootstrap';

// options object formatted as "selection display label": "selection value"
type Props = {
  required?: boolean;
  error?: string;
  groupClasses?: string;
  help?: string;
  id?: string;
  label?: string;
  labelClasses?: string;
  options: Record<string, string>;
  selectClasses?: string;
  onChange: (value: string) => void;
  value?: string;
};

export function SelectInput({
  required = false,
  error,
  groupClasses = '',
  help,
  id = 'options',
  label,
  labelClasses = '',
  options,
  selectClasses = '',
  onChange,
  value,
}: Props) {
  return (
    <Form.Group className={groupClasses} data-testid={`${id}-form-group`}>
      {label && (
        <Form.Label className={labelClasses} htmlFor={id}>
          {label}
        </Form.Label>
      )}
      <Form.Select
        className={'form-control' + selectClasses}
        data-testid={`${id}-select`}
        id={id}
        isInvalid={!!error}
        name={id}
        onChange={(event) => onChange(event.target.value)}
        required={required}
        value={value || ''}>
        <option disabled value="">
          Select an option
        </option>
        {Object.keys(options).map((option) => (
          <option
            data-testid={`${id}-option`}
            key={option}
            value={options[option]}>
            {option}
          </option>
        ))}
      </Form.Select>
      {help && (
        <Form.Text id={`${id}-help`} muted>
          {help}
        </Form.Text>
      )}
      {error && (
        <Form.Control.Feedback data-testid={`${id}-error`} type="invalid">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}
