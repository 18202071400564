import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
    },
    planFacts: {
      dental: new Map([
        ['Lite Plan In- and Out-of-Network Coinsurance', null],
        [
          'Lite Plan Coinsurance',
          <>
            Year 1 - 100/50/10
            <br />
            Year 2 - 100/80/20
          </>,
        ],
      ]),
    },
    fraudNotice: (
      <>
        <p>
          After 2 years from the date of issue or reinstatement of this policy,
          no misstatements made by the applicant in the application shall be
          used to void the policy or deny a claim for loss commencing after the
          expiration of such 2 year period.
        </p>
        <p>
          Pursuant to NCGS 58-2-161(b), any person who knowingly and with the
          intent to injure, defraud, or deceive an insurer or insurance claimant
          is guilty of a crime (Class H felony) which may subject the person to
          criminal and civil penalties.
        </p>
        <p>
          Individuals may be charged a one-time application fee that will not
          exceed $35. Billing fees will be applied on a per bill basis and may
          be reduced for bills paid on an automatic basis (credit card, bank
          withdraw, etc.). This billing fee will not exceed $8 per bill.
        </p>
      </>
    ),
  },
};

export default stateModule;
