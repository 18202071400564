import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
    },
    authorizationAgreement: (
      <>
        <p>
          I authorize Ameritas Life Insurance Corp. of New York ("Ameritas of
          New York") to initiate electronic debit entries to my account chosen
          above for payment of my insurance premium. I certify that I am an
          authorized user on the above listed account. I acknowledge that debits
          to my account for premium due will occur on a regular recurring basis
          based on the payment frequency indicated above until such time as
          coverage terminates or until I notify Ameritas of New York to
          terminate these transactions. I understand that it may take up to two
          weeks to process a request to discontinue recurring payments. In order
          to make changes to this authorization (such as change in bank account,
          method of payment, or termination of payment) I must provide Ameritas
          of New York at least two weeks notice in advance of the next scheduled
          payment date.
        </p>
        <p> Payment Information: </p>
        <ol>
          <li>
            Initial premium will be withdrawn within 3 days of your policy
            effective date, subsequent premiums are due on the day of the month
            in which the policy was effective.
          </li>
          <li>
            For initial payments I acknowledge that Ameritas of New York may
            debit my account upon acceptance and approval of my application.
            Based upon my authorization, Ameritas of New York will process
            reoccurring payments on or within three business days of the date of
            the month in which my policy was first effective.
          </li>
        </ol>
        <p>
          If any authorized payment is returned or dishonored by my bank, I
          acknowledge that I am responsible for any fees my bank may charge. I
          understand also that I may incur a return payment fee of $25 charged
          by Ameritas of New York if the return is due to insufficient funds. I
          acknowledge that such a fee, if charged, may be automatically debited
          from my authorized account on the next payment date. I am responsible
          for remitting payment within the policy grace period. If payment is
          not received by Ameritas of New York within the defined grace period I
          acknowledge that my coverage may be cancelled in accordance with the
          terms of the insurance contract.
        </p>
        <p>
          I also acknowledge that I have read the following information from
          Ameritas of New York regarding this electronic signature.
        </p>
        <ul>
          <li>
            I may return my policy within the right-to-cancel period as
            described in my policy;
          </li>
          <li>
            I acknowledge receipt of the Outline of Coverage (in states where
            required by law);
          </li>
          <li>
            I understand the policy I am applying for provides dental and (if
            chosen) vision benefits only and is not a Medicare supplement;
          </li>
          <li>
            I acknowledge that the agent of record, if applicable, is my
            insurance agent for purposes of the Ameritas of New York Privacy
            Policy; and
          </li>
          <li>
            I understand that it is my responsibility to give notice to Ameritas
            of New York of changes in my email address or any information above,
            as well as my status and my family's status that affect coverage,
            such as marriage, births, or death of someone covered under the
            policy. I will provide notice via email at{' '}
            <a href="mailto:cs@ameritas.com">cs@ameritas.com</a>, fax at
            402-309-2598 or in writing to Ameritas of New York or its designee:
            PO Box 82607, Lincoln, NE 68501-2607.
          </li>
        </ul>
        <p>
          You are encouraged to print a copy of your electronic forms to retain
          for your own records.
        </p>
      </>
    ),
    fraudNotice: (
      <p>
        Any person who knowingly and with intent to defraud any insurance
        company or other person files an application for insurance or statement
        of claim containing any materially false information, or conceals for
        the purpose of misleading, information concerning any fact material
        thereto, commits a fraudulent insurance act, which is a crime, and shall
        also be subject to a civil penalty not to exceed five thousand dollars
        and the stated value of the claim for each such violation.
      </p>
    ),
    planFacts: {
      dental: new Map([
        [
          'Waiting Periods',
          <p key="description">
            No waiting period applies to Preventive (Type 1), Basic (Type 2)
            Services, Major (Type 3) Services. A 12-month waiting period applies
            to Boost Plan Orthodontic Services.
          </p>,
        ],
        [
          'Lite Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/50/50 INN & 70/50/50 OON
            <br />
            Year 2 - 100/80/50 INN & 70/50/50 OON
          </>,
        ],
        [
          'Boost Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/65/50 INN & 80/50/50 OON
            <br />
            Year 2 - 100/80/50 INN & 80/60/50 OON
          </>,
        ],
        [
          'Complete Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/80/50 INN & 80/70/50 OON
            <br />
            Year 2 - 100/90/50 INN & 80/80/50 OON
          </>,
        ],
      ]),
    },
    planHelper: {
      dental: () => (
        <>
          <small>
            Underwritten by Ameritas Life Insurance Corp. of New York | 1350
            Broadway Suite 2201 New York, NY 10018
          </small>
          <br />
          <small>
            This is not a certificate of insurance or guarantee of coverage.
            Plan designs may not be available in all areas and are subject to
            individual state regulations. This information is provided by
            Ameritas Life Insurance Corp. of New York (Ameritas of New York).
            Dental and vision products (9000 NY Rev. 03-15 for Group and 9000 NY
            Rev. 03-18 for Individual) are issued by Ameritas of New York.
          </small>
          <br />
          <small>
            Ameritas, the bison design and "fulfilling life" are service marks
            or registered service marks of Ameritas Life, affiliate Ameritas
            Holding Company or Ameritas Mutual Holding Company. © 2023 Ameritas
            Mutual Holding Company.
          </small>
        </>
      ),
      vision: () => (
        <>
          <small>
            Underwritten by Ameritas Life Insurance Corp. of New York | 1350
            Broadway Suite 2201 New York, NY 10018
          </small>
          <br />
          <small>
            This is not a certificate of insurance or guarantee of coverage.
            Plan designs may not be available in all areas and are subject to
            individual state regulations. This information is provided by
            Ameritas Life Insurance Corp. of New York (Ameritas of New York).
            Dental and vision products (9000 NY Rev. 03-15 for Group and 9000 NY
            Rev. 03-18 for Individual) are issued by Ameritas of New York.
          </small>
          <br />
          <small>
            Ameritas, the bison design and "fulfilling life" are service marks
            or registered service marks of Ameritas Life, affiliate Ameritas
            Holding Company or Ameritas Mutual Holding Company. © 2023 Ameritas
            Mutual Holding Company.
          </small>
        </>
      ),
    },
    dentalLimitations: (
      <>
        <p>No coverage is available under this Policy for the following:</p>
        <ol type="A">
          <li>
            Convalescent and Custodial Care. We do not Cover services related to
            rest cures, custodial care or transportation. "Custodial care";
            means help in transferring, eating, dressing, bathing, toileting and
            other such related activities. Custodial care does not include
            Covered Services determined to be Medically Necessary.
          </li>
          <li>
            Cosmetic Services, except as specifically listed in the Table of
            Dental Procedures. We do not Cover cosmetic services or surgery
            unless otherwise specified, except that cosmetic surgery shall not
            include reconstructive surgery when such service is incidental to or
            follows surgery resulting from trauma, infection or diseases of the
            involved part, and reconstructive surgery because of congenital
            disease or anomaly of a covered Child which has resulted in a
            functional defect. Cosmetic surgery does not include surgery
            determined to be Medically Necessary. If a claim for a procedure
            listed in 11 NYCRR 56 (e.g., certain plastic surgery and dermatology
            procedures) is submitted retrospectively and without medical
            information, any denial will not be subject to the Utilization
            Review process in the Utilization Review and External Appeals
            sections of this Policy unless medical information is submitted.
          </li>
          <li>
            Elimination Period. We do not cover Dental Expenses in the first 12
            months that a person is insured if the person is a Late Entrant;
            except for evaluations, prophylaxis (cleanings), and fluoride
            application. There will be no longer than a 12 month wait for
            benefits.
          </li>
          <li>
            Experimental or Investigational Treatment. We do not Cover any
            health care service, procedure, treatment, or device that is
            experimental or investigational. However, We will Cover experimental
            or investigational treatments, including treatment for Your rare
            disease or patient costs for Your participation in a clinical trial,
            when Our denial of services is overturned by an External Appeal
            Agent certified by the State. However, for clinical trials, We will
            not Cover the costs of any investigational drugs or devices,
            non-health services required for You to receive the treatment, the
            costs of managing the research, or costs that would not be Covered
            under the Policy for non-investigational treatments. See the
            Utilization Review and External Appeal sections of this Policy for a
            further explanation of Your Appeal rights.
          </li>
          <li>
            Felony Participation. We do not Cover any illness, treatment or
            medical condition due to Your participation in a felony, riot or
            insurrection
          </li>
          <li>
            Government Facility. We do not Cover care or treatment provided in a
            Hospital that is owned or operated by any federal, state or other
            governmental entity, except as otherwise required by law.
          </li>
          <li>
            Medical Services. We do not Cover medical services or dental
            services that are medical in nature, including any Hospital charges
            or prescription drug charges.
          </li>
          <li>
            Medically Necessary. In general, We will not Cover any dental
            service, procedure, treatment, test or device that We determine is
            not Medically Necessary. If an External Appeal Agent certified by
            the State overturns Our denial, however, We will Cover the service,
            procedure, treatment, test or device for which coverage has been
            denied, to the extent that such service, procedure, treatment, test
            or device, is otherwise Covered under the terms of this Policy.
          </li>
          <li>
            Medicare or Other Governmental Program. We do not Cover services if
            benefits are provided for such services under the federal Medicare
            program or other governmental program (except Medicaid).
          </li>
          <li>
            Military Service. We do not Cover an illness, treatment or medical
            condition due to service in the Armed Forces or auxiliary units.
          </li>
          <li>
            Pre-Existing Conditions. For a period of 12 months from the
            enrollment date, we do not Cover any conditions for which medical
            advice was given, treatment was recommended by or received from a
            Physician within six (6) months before the effective date of Your
            coverage. The 12-month exclusionary period may be shortened by
            crediting the time You were covered under creditable coverage. We
            will credit the time You were covered under another dental plan, if
            You were enrolled in the prior coverage within 63 days before
            enrolling in this Policy. We will not treat genetic information as a
            pre-existing condition in the absence of a diagnosis of the
            condition related to such information. There will be no longer than
            a 12 month wait for benefits.
          </li>
          <li>
            Services Not Listed. We do not Cover services that are not listed in
            this Policy as being Covered.
          </li>
          <li>
            Services Provided by a Family Member. We do not Cover services
            performed by a member of the covered person's immediate family.
            "Immediate family" shall mean a child, spouse, mother, father,
            sister, or brother of You or Your Spouse.
          </li>
          <li>
            Services Separately Billed by Hospital Employees. We do not Cover
            services rendered and separately billed by employees of Hospitals,
            laboratories or other institutions.
          </li>
          <li>
            Services with No Charge. We do not Cover services for which no
            charge is normally made.
          </li>
          <li>
            War. We do not Cover an illness, treatment or medical condition due
            to war, declared or undeclared.
          </li>
          <li>
            Workers' Compensation. We do not Cover services if benefits for such
            services are provided under any state or federal Workers'
            Compensation, employers' liability or occupational disease law.
          </li>
        </ol>
      </>
    ),
  },
  revisions: {
    '2024Q2': {
      planHelper: {
        dental: () => (
          <>
            <small>
              Underwritten by Ameritas Life Insurance Corp. of New York | 1350
              Broadway Suite 1710 New York, NY 10018
            </small>
            <br />
            <small>
              This is not a certificate of insurance or guarantee of coverage.
              Plan designs may not be available in all areas and are subject to
              individual state regulations. This information is provided by
              Ameritas Life Insurance Corp. of New York (Ameritas of New York).
              Dental and vision products (9000 NY Rev. 03-15 for Group and 9000
              NY Rev. 03-18 for Individual) are issued by Ameritas of New York.
              Ameritas, the bison design and “fulfilling life” are service marks
              or registered service marks of Ameritas Life, affiliate Ameritas
              Holding Company or Ameritas Mutual Holding Company. © 2024
              Ameritas Mutual Holding Company.
            </small>
          </>
        ),
        vision: () => (
          <>
            <small>
              Underwritten by Ameritas Life Insurance Corp. of New York | 1350
              Broadway Suite 1710 New York, NY 10018
            </small>
            <br />
            <small>
              This is not a certificate of insurance or guarantee of coverage.
              Plan designs may not be available in all areas and are subject to
              individual state regulations. This information is provided by
              Ameritas Life Insurance Corp. of New York (Ameritas of New York).
              Dental and vision products (9000 NY Rev. 03-15 for Group and 9000
              NY Rev. 03-18 for Individual) are issued by Ameritas of New York.
              Ameritas, the bison design and “fulfilling life” are service marks
              or registered service marks of Ameritas Life, affiliate Ameritas
              Holding Company or Ameritas Mutual Holding Company. © 2024
              Ameritas Mutual Holding Company.
            </small>
          </>
        ),
      },
    },
  },
};

export default stateModule;
