import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
    },
    fraudNotice: (
      <p>
        Any person who, with intent to defraud or knowing that he is
        facilitating a fraud against an insurer, submits an application or files
        a claim containing a false or deceptive statement is guilty of insurance
        fraud.
      </p>
    ),
    planDescriptions: {
      dental: (
        <p>
          These Primestar® Dental Plans help you get the dental coverage you
          need at affordable rates. Your coverage begins next-day with no
          waiting periods on most dental insurance plans. There are no
          enrollment fees with any plan. Preventive visits, such as exams and
          cleanings, are covered up to 100%. Dental plans may include additional
          benefits such as LASIK, orthodontia or hearing care. A deductible may
          apply for coverage; please see Coverage Quick Facts for more details.
        </p>
      ),
    },
    planFacts: {
      dental: new Map([
        [
          'Waiting Periods',
          <p key="description">
            No waiting period applies to Preventive (Type 1), Basic (Type 2)
            Services, and Total or Access Plan Major (Type 3) Services. A
            12-month waiting period applies to Value Plan Major (Type 3)
            Services.
          </p>,
        ],
        [
          'Value Plan Coinsurance',
          <>
            Year 1 - 90/50/0
            <br />
            Year 2 - 100/85/15
          </>,
        ],
        [
          'Access Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/65/20 INN & 80/45/10 OON
            <br />
            Year 2 - 100/80/50 INN & 80/60/30 OON
          </>,
        ],
        [
          'Total Plan Coinsurance',
          <>
            Year 1 - 100/80/20
            <br />
            Year 2 - 100/90/50
          </>,
        ],
        [
          'Value Plan',
          <p key="description">
            Type 1: Exams, Cleanings
            <br />
            Type 2: Fluoride (under age 16), Sealants, Space Maintainers,
            Bitewing X-rays, Fillings
            <br />
            Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
            Crowns, Bridges, Dentures
          </p>,
        ],
        ['Lite Plan In- and Out-of-Network Coinsurance', null],
        ['Lite Plan', null],
        [
          'Access Plan',
          <p key="description">
            Type 1: Exams, Cleanings, Fluoride (under age 16), Sealants, Space
            Maintainers, Bitewing X-rays
            <br />
            Type 2: Fillings, Simple Extractions
            <br />
            Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
            Crowns, Bridges, Dentures, Implants, Teeth Whitening
          </p>,
        ],
        ['Boost Plan In- and Out-of-Network Coinsurance', null],
        ['Boost Plan', null],
        [
          'Total Plan',
          <p key="description">
            Type 1: Exams, Cleanings, Bitewing X-rays
            <br />
            Type 2: Fillings, Simple Extractions
            <br />
            Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
            Crowns, Bridges, Dentures, Implants
          </p>,
        ],
        ['Complete Plan In- and Out-of-Network Coinsurance', null],
        ['Complete Plan', null],
        [
          'Preventive Plus',
          <p key="description">
            Type 1 Preventive procedures are not deducted from the plan's annual
            maximum benefit. This saves all of the annual benefit to help pay
            for more expensive Type 2 and 3 procedures.
          </p>,
        ],
      ]),
    },
  },
  revisions: {
    '2024Q2': {
      planFacts: {
        'accidental-medical-expense': new Map(),
        'critical-illness': new Map(),
        'hospital-indemnity': new Map(),
        dental: new Map([
          ['Waiting Periods', null],
          ['Value Plan Coinsurance', null],
          ['Value Plan In- and Out-of-Network Coinsurance', null],
          ['Value Plan', null],
          ['Lite Plan Coinsurance', null],
          [
            'Lite Plan In- and Out-of-Network Coinsurance',
            <>
              Year 1 - 100/50/10 INN & 70/25/5 OON
              <br />
              Year 2 - 100/80/20 INN & 70/40/10 OON
            </>,
          ],
          [
            'Lite Plan',
            <p key="description">
              Type 1: Exams, Cleanings
              <br />
              Type 2: Fluoride (Up to age 16), Sealants, Space Maintainers,
              Bitewing X-Rays, Fillings
              <br />
              Type 3: All Other X-rays, Oral Surgery, Simple Extractions,
              Endodontics, Periodontics, Crowns, Bridges, Dentures
            </p>,
          ],
          ['Access Plan Coinsurance', null],
          ['Access Plan In- and Out-of-Network Coinsurance', null],
          ['Access Plan', null],
          ['Boost Plan Coinsurance', null],
          [
            'Boost Plan In- and Out-of-Network Coinsurance',
            <>
              Year 1 - 100/65/20 INN & 80/45/10 OON
              <br />
              Year 2 - 100/80/50 INN & 80/60/30 OON
            </>,
          ],
          [
            'Boost Plan',
            <p key="description">
              Type 1: Exams, Cleanings, Fluoride (Up to age 16), Sealants, Space
              Maintainers, Bitewing X-rays
              <br />
              Type 2: Fillings, Simple Extractions
              <br />
              Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
              Crowns, Bridges, Dentures, Implants, Teeth Whitening
            </p>,
          ],
        ]),
      },
    },
  },
};

export default stateModule;
