import React from 'react';
import { Button, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { Edit3 } from 'react-feather';

import type { Offering } from '@liferaft/api/types';
import {
  ApplicationFlowKey,
  CarrierKey,
  CoverageDisplay,
  CoverageType,
  InsuranceProductKey,
  PaymentType,
} from '@liferaft/api/types';

import { ReactComponent as FamilyIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Communication/Group.svg';
import { ReactComponent as ClipboardIcon } from '@liferaft/core/assets/images/icons/duotone-icons/General/Clipboard.svg';
import { ReactComponent as IndividualIcon } from '@liferaft/core/assets/images/icons/duotone-icons/General/User.svg';
import { ReactComponent as DollarIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Shopping/Dollar.svg';
import type { AmeritasRevision } from '@liferaft/core/content/products/ameritas';
import { activeAmeritasRevision } from '@liferaft/core/content/products/ameritas/current';
import { useDebounce } from '@liferaft/core/hooks';
import { usDollarFormat } from '@liferaft/core/utils/money';

import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';
import { useProductMatrixContext } from '@/contexts';
import { Path } from '@/flows/paths';
import {
  getSummaryBenefitsList,
  getSummaryFootnote,
} from '@/pages/product-matrix/content';

function capitalize(word: string): string {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export function Confirm() {
  const {
    goTo,
    next,
    data: { egress, flow_key, referrer_details },
  } = useApplicationFlow();

  const context = useProductMatrixContext(egress);
  const ameritasRevision = activeAmeritasRevision();

  const selectedInsuranceOfferings = context.selectedInsuranceOfferings;
  const fullName = `${egress?.first_name || ''} ${egress?.last_name || ''}`;
  const residentialState = context.residentState || '';

  const hasSelectedAmr = selectedInsuranceOfferings.some(
    ([, offering]) => offering.carrier === CarrierKey.AMR
  );

  const [handleSubmit, isSubmitting] = useDebounce(
    async (event: React.SyntheticEvent<EventTarget>) => {
      event.preventDefault();
      return next();
    }
  );

  const showChildren = selectedInsuranceOfferings
    .map(([, offering]) => offering.coverage_type)
    .some(
      (coverageType) =>
        coverageType === CoverageType.INDIVIDUAL_CHILDREN ||
        coverageType === CoverageType.FAMILY
    );

  const showSpouse = selectedInsuranceOfferings
    .map(([, offering]) => offering.coverage_type)
    .some(
      (coverageType) =>
        coverageType === CoverageType.INDIVIDUAL_SPOUSE ||
        coverageType === CoverageType.FAMILY
    );

  const showFamilySection = showSpouse || showChildren;

  const inReapplyFlow = flow_key == ApplicationFlowKey.REAPPLY;

  const editOfferings = () => {
    goTo(Path.PRODUCT_MATRIX);
  };

  const applicationFee = referrer_details?.application_fee_amount;
  const shouldApplyApplicationFee =
    flow_key === ApplicationFlowKey.APPLY &&
    applicationFee &&
    applicationFee > 0;

  let total = usDollarFormat.format(Number(context.totalPremium));
  if (shouldApplyApplicationFee) {
    total = usDollarFormat.format(
      Number(applicationFee) + Number(context.totalPremium)
    );
  }

  return (
    <ApplyWrapper
      footer={
        <ApplyWrapperFooter
          rightDisabled={isSubmitting}
          submitLabel="Confirm"
        />
      }
      heading="Your Information"
      subheading={
        <>
          Carefully review the information below and edit as needed.
          <br />
          Changing your age, state of residence, or smoking status may affect
          your plan and product options.
        </>
      }>
      <Form id={APPLY_FORM_ID} onSubmit={handleSubmit}>
        <ConfirmCard headerIcon={<IndividualIcon />} heading="Your Information">
          <ListGroup.Item className="no-border">
            <div className="d-flex justify-content-between mb-3">
              <Row className="flex-grow-1 no-gutters">
                <Col lg={3} xs={12}>
                  <b>Name</b>
                </Col>
                <Col className="d-flex justify-content-between" lg={9} xs={12}>
                  <div>{fullName}</div>
                  {!inReapplyFlow && (
                    <div>
                      <Button
                        className="btn-link m-0 p-0"
                        onClick={() => goTo(Path.CREATE_ACCOUNT)}
                        variant="empty">
                        <Edit3 viewBox="0 0 30 30" />
                        Edit
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <div className="mb-3 d-flex">
              <Row className="flex-grow-1 no-gutters">
                <Col lg={3} xs={12}>
                  <b>Date of Birth</b>
                </Col>
                <Col className="d-flex justify-content-between" lg={9} xs={12}>
                  <div>{egress?.date_of_birth || ''}</div>
                  {!inReapplyFlow && (
                    <div>
                      <Button
                        className="btn-link m-0 p-0"
                        onClick={() => goTo(Path.RATING_DATA)}
                        variant="empty">
                        <Edit3 viewBox="0 0 30 30" />
                        Edit
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <div className="mb-3 d-flex">
              <Row className="flex-grow-1 no-gutters">
                <Col lg={3} xs={12}>
                  <b>Smoking Status</b>
                </Col>
                <Col className="d-flex justify-content-between" lg={9} xs={12}>
                  <div>
                    <p>
                      {egress?.is_tobacco_user ? 'Tobacco User' : 'Non-smoker'}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mb-3 d-flex">
              <Row className="flex-grow-1 no-gutters">
                <Col lg={3} xs={12}>
                  <b>State</b>
                </Col>
                <Col className="d-flex justify-content-between" lg={9} xs={12}>
                  <div>{egress?.state || ''}</div>
                </Col>
              </Row>
            </div>
            <div className="mb-3 d-flex">
              <Row className="flex-grow-1 no-gutters">
                <Col lg={3} xs={12}>
                  <b>Phone</b>
                </Col>
                <Col className="d-flex justify-content-between" lg={9} xs={12}>
                  <div>{egress?.phone_number || ''}</div>
                  {!inReapplyFlow && (
                    <div>
                      <Button
                        className="btn-link m-0 p-0"
                        onClick={() => goTo(Path.CONTACT_INFO)}
                        variant="empty">
                        <Edit3 viewBox="0 0 30 30" />
                        Edit
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <div className="d-flex">
              <Row className="flex-grow-1 no-gutters">
                <Col lg={3} xs={12}>
                  <b>Address</b>
                </Col>
                <Col className="d-flex flex-column" lg={9} xs={12}>
                  <div>{egress?.address?.primary || ''}</div>
                  <div>{egress?.address?.secondary || ''}</div>
                  <div>{`${egress?.address?.city || ''} ${
                    egress?.address?.state || ''
                  } ${egress?.address?.zip_code || ''}`}</div>
                </Col>
              </Row>
            </div>
          </ListGroup.Item>
        </ConfirmCard>
        {(egress?.children || egress?.spouse) && showFamilySection && (
          <ConfirmCard headerIcon={<FamilyIcon />} heading="Family Information">
            {egress?.spouse && showSpouse && (
              <ConfirmFamily
                dateOfBirth={egress.spouse.date_of_birth}
                name={`${egress.spouse.first_name} ${egress.spouse.last_name}`}
                onEdit={() => goTo(Path.DEPENDENTS)}
                relationship="Spouse"
              />
            )}
            {showChildren &&
              egress?.children?.map((child, i) => (
                <ConfirmFamily
                  dateOfBirth={child.date_of_birth}
                  key={i}
                  name={`${child.first_name} ${child.last_name}`}
                  onEdit={() => goTo(Path.DEPENDENTS)}
                  relationship="Children"
                />
              ))}
          </ConfirmCard>
        )}
        <ConfirmCard headerIcon={<ClipboardIcon />} heading="Plan Selections">
          <ListGroup.Item className="no-border">
            {selectedInsuranceOfferings.map(
              ([productKey, offering]: [InsuranceProductKey, Offering]) => (
                <div key={productKey}>
                  <ConfirmPlan
                    ameritasRevision={ameritasRevision}
                    offering={offering}
                    onEdit={editOfferings}
                    productKey={productKey}
                    residentialState={residentialState}
                  />
                  <hr />
                </div>
              )
            )}
            {hasSelectedAmr && (
              <p className="font-weight-bold text-danger">
                NOTE: Applicant and dependents cannot have duplicate policies
                underwritten by Ameritas, or any brand that is underwritten by
                Ameritas (i.e. two dental or two vision plans).
              </p>
            )}
          </ListGroup.Item>
        </ConfirmCard>
        <ConfirmCard headerIcon={<DollarIcon />} heading="Billing & Payment">
          <ListGroup.Item className="no-border pb-0">
            <Row>
              <Col lg={3} xs={12}>
                <b>Billing Frequency</b>
              </Col>
              <Col lg={9} xs={12}>
                Monthly
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item className="no-border pb-0">
            <div className="mb-3 d-flex">
              <Row className="flex-grow-1">
                <Col className="mb-lg-3 mb-0" lg={3} xs={12}>
                  <b>Payment Method</b>
                </Col>
                {egress?.payment_via === PaymentType.CC && (
                  <Col
                    className="d-flex justify-content-between"
                    lg={9}
                    xs={12}>
                    <div>
                      {`${
                        egress?.credit_card?.type
                          ? capitalize(egress?.credit_card?.type)
                          : 'Card'
                      } ending in ${egress?.credit_card?.last4 || 'XXXX'}`}
                      <br />
                      Name on card: {egress?.credit_card?.name || ''}
                      <br />
                      Expiration date:{' '}
                      {egress?.credit_card?.expiration_date || ''}
                      <br />
                    </div>
                    {!inReapplyFlow && (
                      <div>
                        <Button
                          className="btn-link m-0 p-0"
                          onClick={() => goTo(Path.PAYMENT_INFO)}
                          variant="empty">
                          <Edit3 viewBox="0 0 30 30" />
                          Edit
                        </Button>
                      </div>
                    )}
                  </Col>
                )}
                {egress?.payment_via === PaymentType.ACH && (
                  <Col
                    className="d-flex justify-content-between"
                    lg={9}
                    xs={12}>
                    <div>
                      Checking Account
                      <br />
                      Bank Name: {egress?.ach_info?.bank_name || ''}
                      <br />
                    </div>
                    {!inReapplyFlow && (
                      <div>
                        <Button
                          className="btn-link m-0 pb-0"
                          onClick={() => goTo(Path.PAYMENT_INFO)}
                          variant="empty">
                          <Edit3 viewBox="0 0 30 30" />
                          Edit
                        </Button>
                      </div>
                    )}
                  </Col>
                )}
              </Row>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="no-border pb-0">
            {shouldApplyApplicationFee && (
              <Row>
                <Col lg={6} xs={12}>
                  <p className="font-weight-bold">Application Fee</p>
                </Col>
                <Col className="text-lg-right" lg={6} xs={12}>
                  {`${usDollarFormat.format(Number(applicationFee))}`}
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={6} xs={12}>
                <p className="font-weight-bold mb-lg-3 mb-0">Monthly Total</p>
              </Col>
              <Col className="text-lg-right" lg={6} xs={12}>
                {`${usDollarFormat.format(Number(context.totalPremium))}`}
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item className="no-border pb-3">
            <Row>
              <Col lg={6} xs={12}>
                <p className="mb-lg-3 mb-0">
                  <span className="font-weight-bold mr-1">Total</span>
                  <span>(due today)</span>
                  <br />
                </p>
              </Col>
              <Col
                className="text-lg-right font-weight-bold mb-3"
                lg={6}
                xs={12}>
                {total}
              </Col>
            </Row>
          </ListGroup.Item>
        </ConfirmCard>
      </Form>
    </ApplyWrapper>
  );
}

type ConfirmCardProps = {
  heading: string;
  headerIcon: React.ReactNode;
  children?: React.ReactNode;
};

export function ConfirmCard({
  heading,
  headerIcon,
  children,
}: ConfirmCardProps) {
  return (
    <Col xs={12}>
      <div className="card card-bleed shadow-light mb-5 text-secondary">
        <div className="card-header">
          <span className="text-primary text-uppercase font-weight-bold">
            {heading}
          </span>
          <div className="float-right">{headerIcon}</div>
        </div>
        <ListGroup>{children}</ListGroup>
      </div>
    </Col>
  );
}

type ConfirmFamilyProps = {
  onEdit: () => void;
  dateOfBirth: string;
  name: string;
  relationship: string;
};

export function ConfirmFamily({
  onEdit,
  dateOfBirth,
  name,
  relationship,
}: ConfirmFamilyProps) {
  return (
    <ListGroup.Item>
      <div className="d-flex ">
        <Row className="flex-grow-1 no-gutters">
          <Col lg={6} xs={12}>
            <b>{`${relationship} Information`}</b>
          </Col>
        </Row>
        <div className="text-right">
          <Button className="btn-link m-0 p-0" onClick={onEdit} variant="empty">
            <Edit3 viewBox="0 0 30 30" />
            Edit
          </Button>
        </div>
      </div>
      <Row className="mt-4">
        <Col lg={3} xs={12}>
          <b>Name</b>
        </Col>
        <Col lg={9} xs={12}>
          {name}
        </Col>
        <Col lg={3} xs={12}>
          <b>Date of Birth</b>
        </Col>
        <Col lg={9} xs={12}>
          {dateOfBirth}
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

type ConfirmPlanProps = {
  onEdit: () => void;
  productKey: InsuranceProductKey;
  offering: Offering;
  ameritasRevision: AmeritasRevision;
  residentialState: string;
};

export function ConfirmPlan({
  onEdit,
  productKey,
  offering,
  ameritasRevision,
  residentialState,
}: ConfirmPlanProps) {
  let productBenefits: string[] = [];
  if (offering)
    productBenefits = getSummaryBenefitsList(
      productKey,
      offering,
      residentialState
    );

  let planFootnote: React.ReactNode;
  if (offering.carrier === CarrierKey.AIG) {
    if (productKey === InsuranceProductKey.CRITICAL) {
      planFootnote = (
        <h6>
          This insurance product does not require smoker status. <br /> Offered
          through an Accidental Death Plus Plan under an accident and sickness
          insurance policy.
        </h6>
      );
    } else {
      planFootnote = (
        <h6>This insurance product does not require smoker status.</h6>
      );
    }
  }

  let selectedCoverageDisplay = CoverageDisplay[offering.coverage_type];
  if (
    offering.coverage_type === CoverageType.INDIVIDUAL_CHILDREN &&
    offering.carrier === CarrierKey.AMR
  ) {
    selectedCoverageDisplay = CoverageDisplay.AMR_EC;
  }
  return (
    <div className="mb-4 d-flex">
      <Col className="px-0" xs={12}>
        <Row className="flex-grow-1">
          <Col className="font-weight-bold" lg={3} xs={12}>
            {offering.product_display_name}
          </Col>
          <Col className="d-flex justify-content-between" lg={9} xs={12}>
            <div>{`$${offering.monthly_premium}`}</div>
            <div>
              <Button
                className="btn-link m-0 p-0"
                onClick={onEdit}
                variant="empty">
                <Edit3 viewBox="0 0 30 30" />
                Edit
              </Button>
            </div>
          </Col>
        </Row>
        {planFootnote && (
          <Row className="flex-grow-1 no-gutters">{planFootnote}</Row>
        )}
        <Row className="mb-2">
          <Col lg={3} xs={12}>
            <b>Selected Plan</b>
          </Col>
          <Col className="d-flex justify-content-between" lg={9} xs={12}>
            <div>{offering.display_label}</div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={3} xs={12}>
            <b>Coverage</b>
          </Col>
          <Col className="d-flex justify-content-between" lg={9} xs={12}>
            <div>{selectedCoverageDisplay}</div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={3} xs={12}>
            <b>Benefit</b>
          </Col>
          <Col lg={9} xs={12}>
            {productBenefits.length > 0 &&
              productBenefits.map((benefit: string, i) => {
                return <div key={i}>{benefit}</div>;
              })}
            {offering.carrier === CarrierKey.AMR && (
              <>
                {getSummaryFootnote(
                  productKey,
                  offering.carrier,
                  residentialState,
                  ameritasRevision,
                  offering.display_label
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
    </div>
  );
}
