import React from 'react';

import { updateApplication } from '@liferaft/api/resources/application';
import type {
  ACHInfo,
  Application,
  CreditCardInfo,
  Egress,
} from '@liferaft/api/types';
import { PaymentType } from '@liferaft/api/types';
import { NetworkController } from '@liferaft/api/utils/network';

import { ReactComponent as ChoiceTwoIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Custom/ach.svg';
import { ReactComponent as ChoiceOneIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Custom/cc.svg';
import { Card } from '@liferaft/core/components';
import type { VGSForm } from '@liferaft/core/components/forms';
import {
  ACHForm,
  CreditCardForm,
  PolarChoiceInput,
} from '@liferaft/core/components/forms';
import { useMoov } from '@liferaft/core/hooks';

import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';
import {
  VGS_ENVIRONMENT,
  VGS_SATELLITE_PORT,
  VGS_VAULT_ID,
} from '@/environment';

export function PaymentInformation() {
  const {
    data: { id },
    next,
  } = useApplicationFlow();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [paymentType, setPaymentType] = React.useState<PaymentType>();
  const { moov } = useMoov();

  const handleSubmit = async (paymentData: ACHInfo | CreditCardInfo) => {
    const network = new NetworkController();
    if (moov === null) return;

    const { token } = await moov.accounts.getTermsOfServiceToken();

    if (paymentType) {
      const egress: Partial<Egress> = {
        moov_tos_token: token,
        payment_via: paymentType,
      };

      paymentType === PaymentType.CC
        ? (egress.credit_card = paymentData as CreditCardInfo)
        : (egress.ach_info = paymentData as ACHInfo);

      const result = await network.request<Partial<Egress>, Application>(
        updateApplication(id as string, egress as Pick<Egress, keyof Egress>)
      );

      if (result.error) return;

      return next();
    }
  };

  const handleCreditCardSubmit =
    (form: VGSForm) => async (_status: any, data: any) => {
      const submissionBin = form.state.card_number.bin;
      const submissionLast4 = form.state.card_number.last4;
      const submissionCardType = form.state.card_number.cardType;

      const expirationMonth = data.json.card_expiration_date.month;
      let expirationYear = data.json.card_expiration_date.year;
      if (expirationYear.length === 2) {
        expirationYear = '20' + expirationYear;
      }
      const expirationDateIso = `${expirationYear}-${expirationMonth}-01`;

      const payload = {
        name: data.json.card_name,
        number: data.json.card_number,
        cvc: data.json.card_cvc,
        nickname: data.json.card_nickname,
        expiration_date: expirationDateIso,
        bin: submissionBin,
        last4: submissionLast4,
        type: submissionCardType,
      };

      return handleSubmit(payload);
    };

  const handleACHInfoSubmit = () => async (_status: any, data: any) => {
    const payload = {
      bank_name: data.json.ach_bank_name,
      routing_number: data.json.ach_routing_number,
      account_number: data.json.ach_account_number,
    };

    return handleSubmit(payload);
  };

  return (
    <ApplyWrapper
      footer={
        <ApplyWrapperFooter rightDisabled={!paymentType || isSubmitting} />
      }
      heading="Payment Information"
      subheading="Would you like to pay by credit card or by withdrawal from your bank account?">
      <PolarChoiceInput
        choiceOne={{
          text: 'Credit Card',
          value: PaymentType.CC,
          icon: <ChoiceOneIcon viewBox="0 0 512 512" />,
        }}
        choiceTwo={{
          text: 'Bank Transfer',
          value: PaymentType.ACH,
          icon: <ChoiceTwoIcon viewBox="0 0 512 512" />,
        }}
        groupClasses="form-group"
        onChange={setPaymentType}
        value={paymentType}
      />
      {paymentType && (
        <Card cardClasses="card-bleed shadow-light">
          {paymentType == PaymentType.CC && (
            <CreditCardForm
              id={APPLY_FORM_ID}
              onSubmit={handleCreditCardSubmit}
              setIsSubmitting={setIsSubmitting}
              vgsEnvironment={VGS_ENVIRONMENT as string}
              vgsRoute="/post"
              vgsSatellitePort={Number(VGS_SATELLITE_PORT)}
              vgsVaultId={VGS_VAULT_ID as string}
            />
          )}
          {paymentType == PaymentType.ACH && (
            <ACHForm
              id={APPLY_FORM_ID}
              onSubmit={handleACHInfoSubmit}
              setIsSubmitting={setIsSubmitting}
              vgsEnvironment={VGS_ENVIRONMENT as string}
              vgsRoute="/post"
              vgsSatellitePort={Number(VGS_SATELLITE_PORT)}
              vgsVaultId={VGS_VAULT_ID as string}
            />
          )}
        </Card>
      )}
    </ApplyWrapper>
  );
}
