export type DateData = {
  month: number;
  day: number;
  year: number;
};

export type MonthData = {
  name: string;
  numDays: number;
};

export const Months: Record<number, MonthData> = {
  1: { name: 'January', numDays: 31 },
  2: { name: 'February', numDays: 29 },
  3: { name: 'March', numDays: 31 },
  4: { name: 'April', numDays: 30 },
  5: { name: 'May', numDays: 31 },
  6: { name: 'June', numDays: 30 },
  7: { name: 'July', numDays: 31 },
  8: { name: 'August', numDays: 31 },
  9: { name: 'September', numDays: 30 },
  10: { name: 'October', numDays: 31 },
  11: { name: 'November', numDays: 30 },
  12: { name: 'December', numDays: 31 },
};

export function febDays(year: number): number {
  if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
    return 29;
  } else {
    return 28;
  }
}

export function toString(dateData: DateData): string {
  return `${dateData.year}-${String(dateData.month).padStart(2, '0')}-${String(
    dateData.day
  ).padStart(2, '0')}`;
}

export function fromString(date: string): DateData {
  const dateGroups = date.split('-');

  return {
    day: Number(dateGroups[2]),
    month: Number(dateGroups[1]),
    year: Number(dateGroups[0]),
  };
}

export function getDisplayDate(date?: string): string {
  if (!date) return '';

  const dateArray = date.split('-').map((piece) => parseInt(piece));
  const dateDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);

  const string = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(dateDate);

  return string.slice(0, 3) + '.' + string.slice(3);
}

export function getDisplayDateTime(date: string): string {
  const string = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date));

  return string;
}

export function getTime(
  date: string,
  {
    isUTC = true,
    relative = true,
  }: { isUTC?: boolean; relative?: boolean } = {}
) {
  const workingDate = new Date(isUTC ? date + 'Z' : date);
  const now = new Date();

  let hours = workingDate.getHours();
  const minutes = workingDate.getMinutes();

  const amPm = hours >= 12 ? 'pm' : 'am';

  hours = hours % 12;
  hours = hours === 0 ? 12 : hours;

  const time = `${hours}:${String(minutes).padStart(2, '0')} ${amPm}`;

  if (isSameDay(workingDate, now) && relative) {
    return time;
  } else {
    const dateString = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).format(workingDate);

    return `${dateString.slice(0, 3)}.${dateString.slice(3)} ${time} `;
  }
}

function isSameDay(date1: Date, date2: Date) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
