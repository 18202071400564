import React from 'react';
import { Container, Dropdown, Navbar } from 'react-bootstrap';

import { ReactComponent as SignoutIcon } from '../../assets/images/icons/duotone-icons/Navigation/Sign-out.svg';
import { ReactComponent as LogoIcon } from '../../assets/images/logos/liferaft_horizontallogo_currentColor.svg';
import './authenticated-header.scss';

export type MenuOption = {
  url: string;
  description: string;
};

export type RoleOption = {
  url: string;
  description: string;
};

export type Props = {
  hideMenu?: boolean;
  baseUrl: string;
  currentRole?: string;
  menuOptions?: MenuOption[];
  navTabs?: React.ReactFragment;
  roleOptions?: RoleOption[];
};

export function AuthenticatedHeader({
  hideMenu = false,
  baseUrl,
  currentRole,
  menuOptions,
  navTabs,
  roleOptions,
}: Props) {
  return (
    <Navbar bg="primary-extra-light" data-testid="auth-header" expand={false}>
      <Container fluid>
        <Navbar.Brand data-testid="navbar-logo" href={baseUrl}>
          <LogoIcon alt="Logo" className="navbar-brand-img" />
        </Navbar.Brand>

        {navTabs}

        {roleOptions && roleOptions.length > 0 && (
          <Dropdown className="ml-auto mr-0 mr-md-3 mt-3">
            <Dropdown.Toggle
              className="dropdown-override"
              data-testid="auth-header-role-toggle"
              variant="btn btn-light btn-sm">
              {currentRole}
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-right">
              {roleOptions &&
                roleOptions.map((option, i) => (
                  <Dropdown.Item
                    data-testid="role-dropdown-item"
                    href={option.url}
                    key={i}>
                    {option.description}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        )}

        {!hideMenu && (
          <Dropdown className="ml-auto ml-md-0 mt-3">
            <Dropdown.Toggle
              className="dropdown-override"
              data-testid="auth-header-menu-toggle"
              variant="btn btn-outline-primary btn-sm">
              Menu
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="dropdown-menu-right"
              data-testid="auth-header-menu-dropdown">
              <Dropdown.Item data-testid="menu-dropdown-item" href={baseUrl}>
                Home
              </Dropdown.Item>
              {menuOptions &&
                menuOptions.map((option, i) => (
                  <Dropdown.Item
                    data-testid="menu-dropdown-item"
                    href={option.url}
                    key={i}>
                    {option.description}
                  </Dropdown.Item>
                ))}
              <Dropdown.Item
                data-testid="menu-dropdown-item"
                href={`${baseUrl}/user/settings/`}>
                Settings
              </Dropdown.Item>
              <Dropdown.Item
                data-testid="menu-dropdown-item"
                href={`${baseUrl}/user/logout/`}>
                Log out
                <span className="icon text-primary icon-xs">
                  <SignoutIcon />
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Container>
    </Navbar>
  );
}
