import type { InsuranceProductKey } from '../../../../../api/src/types';
import { DJANGO_API_URL } from '../../../environment';
import { isStateAbbr } from '../../states';
import {
  AmeritasDentalStates,
  AmeritasVisionStates,
  ameritasDentalNonBaseStates,
  ameritasVisionNonBaseStates,
} from './states';
import type { AmeritasContent, AmeritasRevision } from './types';

const RevisionSuffix: Record<
  AmeritasRevision,
  Record<InsuranceProductKey, string>
> = {
  '2023Q3': {
    'accidental-medical-expense': '',
    'critical-illness': '',
    'hospital-indemnity': '',
    dental: '_2023_Q3',
    vision: '_2023',
  },
  '2024Q2': {
    'accidental-medical-expense': '',
    'critical-illness': '',
    'hospital-indemnity': '',
    dental: '_2024_Q2',
    vision: '_2024_Q2',
  },
};

export function ameritasSummaryOfBenefitsLink(
  state: string,
  ameritasRevision: AmeritasRevision,
  product: InsuranceProductKey
): string {
  const suffix = RevisionSuffix[ameritasRevision][product];
  let stateLabel = 'base';
  if (isStateAbbr(state)) {
    if (
      (product === 'dental' && !AmeritasDentalStates.includes(state)) ||
      (product === 'vision' && !AmeritasVisionStates.includes(state))
    ) {
      return '';
    }
    if (
      (product === 'dental' &&
        ameritasDentalNonBaseStates(ameritasRevision).includes(state)) ||
      (product === 'vision' &&
        ameritasVisionNonBaseStates(ameritasRevision).includes(state))
    ) {
      stateLabel = state;
    }
  }
  return `${DJANGO_API_URL}/static/ameritas/docs/benefits/${product}/${product}_${stateLabel}_benefits${suffix}.pdf`;
}

export function ameritasContentSummaryOfBenefitsLink(
  ameritasContent: AmeritasContent,
  product: InsuranceProductKey
): string {
  if (!ameritasContent.offersProduct[product]) return '';
  const stateLabel = ameritasContent.isBaseState[product]
    ? 'base'
    : ameritasContent.abbr;
  const suffix = RevisionSuffix[ameritasContent.revision][product];

  return `${DJANGO_API_URL}/static/ameritas/docs/benefits/${product}/${product}_${stateLabel}_benefits${suffix}.pdf`;
}
