import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    fraudNotice: (
      <p>
        Any person who knowingly presents a false or fraudulent claim for
        payment of a loss or benefit or who knowingly presents false information
        in an application for insurance is guilty of a crime and may be subject
        to restitution fines or confinement in prison, or any combination
        thereof.
      </p>
    ),
  },
};

export default stateModule;
