import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    fraudNotice: (
      <p>
        WARNING: It is a crime to provide false or misleading information to an
        insurer for the purpose of defrauding the insurer or any other person.
        Penalties include imprisonment and/or fines. In addition, an insurer may
        deny insurance benefits if false information materially related to a
        claim was provided by the applicant. Individuals may be charged a
        one-time application fee that will not exceed $35. Billing fees will be
        applied on a per bill basis and may be reduced for bills paid on an
        automatic basis (credit card, bank withdraw, etc.) This billing fee will
        not exceed $8 per bill.
      </p>
    ),
  },
};

export default stateModule;
