import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    planFacts: {
      dental: new Map([
        ['Access Plan In- and Out-of-Network Coinsurance', null],
        ['Lite Plan In- and Out-of-Network Coinsurance', null],
        ['Boost Plan In- and Out-of-Network Coinsurance', null],
        ['Complete Plan In- and Out-of-Network Coinsurance', null],
      ]),
    },
    planFactsFootnote: {
      dental: (
        <>
          Claim allowance (PPO): If you visit a network provider, payments are
          based on the dentist's contracted fees (MAC/maximum allowable charge),
          which may result in lower out-of-pocket costs. If you visit an
          out-of-network dentist, covered benefits are paid at the 80th
          percentile of usual and customary (80th U&C) charges. This means we
          expect 8 out of 10 charges from dental providers to be within the
          amount we'll pay for a covered procedure. You pay the difference
          between what the plan pays and the dentist's actual charge.{' '}
          <a
            href="https://dentalnetwork.ameritas.com/classic"
            rel="noreferrer"
            target="_blank">
            Find a Classic (PPO) network provider near you.
          </a>
        </>
      ),
    },
  },
  revisions: {
    '2024Q2': {
      isBaseState: {
        dental: false,
      },
      planFacts: {
        dental: new Map([
          ['Lite Plan In- and Out-of-Network Coinsurance', null],
          [
            'Lite Plan Coinsurance',
            <>
              Year 1 - 100/50/10
              <br />
              Year 2 - 100/80/20
            </>,
          ],
          ['Boost Plan In- and Out-of-Network Coinsurance', null],
          [
            'Boost Plan Coinsurance',
            <>
              Year 1 - 100/65/20
              <br />
              Year 2 - 100/80/50
            </>,
          ],
          ['Complete Plan In- and Out-of-Network Coinsurance', null],
          [
            'Complete Plan Coinsurance',
            <>
              Year 1 - 100/80/20
              <br />
              Year 2 - 100/90/50
            </>,
          ],
        ]),
      },
    },
  },
};

export default stateModule;
