export function openFromBlob(
  blob: Blob,
  filename: string,
  newTab = false
): void {
  const blobUrl = URL.createObjectURL(blob);
  const anchor = document.createElement('a');

  anchor.style.display = 'none';
  anchor.href = blobUrl;
  if (newTab) {
    anchor.target = '_blank';
    anchor.rel = 'noopener';
  } else {
    anchor.download = filename;
  }

  console.log(anchor.outerHTML);

  function cleanup() {
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
      anchor.removeEventListener('click', cleanup);
      anchor.remove();
    }, 150);
  }

  anchor.addEventListener('click', cleanup);
  anchor.click();
}
