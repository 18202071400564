import { createReducibleContext, useReducibleContext } from '.';

const initialState = { authenticating: false, shouldAuthenticate: false };

type AuthenticationState = {
  authenticating: boolean;
  isAuthenticated?: boolean;
  shouldAuthenticate?: boolean;
};

type AuthenticationAction =
  | { type: 'AUTHENTICATED' }
  | { type: 'AUTHENTICATING' }
  | { type: 'REFRESH' }
  | { type: 'UNAUTHENTICATED' };

function reducer(
  state: AuthenticationState,
  action: AuthenticationAction
): AuthenticationState {
  switch (action.type) {
    case 'AUTHENTICATED': {
      return {
        ...state,
        authenticating: false,
        isAuthenticated: true,
        shouldAuthenticate: false,
      };
    }
    case 'AUTHENTICATING': {
      return {
        ...state,
        authenticating: true,
        shouldAuthenticate: false,
      };
    }
    case 'REFRESH': {
      return {
        ...state,
        shouldAuthenticate: true,
      };
    }
    case 'UNAUTHENTICATED': {
      return {
        ...state,
        isAuthenticated: false,
        authenticating: false,
        shouldAuthenticate: false,
      };
    }
  }
}

const wrappedActions = {
  setAuthenticated: function (dispatch: React.Dispatch<AuthenticationAction>) {
    return function () {
      dispatch({ type: 'AUTHENTICATED' });
    };
  },
  setAuthenticating: function (dispatch: React.Dispatch<AuthenticationAction>) {
    return function () {
      dispatch({ type: 'AUTHENTICATING' });
    };
  },
  setUnauthenticated: function (
    dispatch: React.Dispatch<AuthenticationAction>
  ) {
    return function () {
      dispatch({ type: 'UNAUTHENTICATED' });
    };
  },
  refreshAuth: function (dispatch: React.Dispatch<AuthenticationAction>) {
    return function () {
      dispatch({ type: 'REFRESH' });
    };
  },
};

const [Context, Provider] = createReducibleContext<
  AuthenticationState,
  AuthenticationAction
>(reducer, initialState, wrappedActions);

function useAuthenticationContext() {
  return useReducibleContext(Context);
}

export {
  Context as AuthenticationContext,
  Provider as AuthenticationProvider,
  useAuthenticationContext,
};
