import React from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';

import {
  createDisclosureAcknowledgement,
  updateApplication,
} from '@liferaft/api/resources/application';
import type {
  Application,
  Egress,
  ProductSelection,
} from '@liferaft/api/types';
import {
  CarrierKey,
  DisclosureAcknowledgementResponse,
  DisclosureType,
  DocumentDelivery,
  InsuranceProductKey,
} from '@liferaft/api/types';
import type { ParsedDjangoErrorResponse } from '@liferaft/api/utils/django-utils';
import { NetworkController } from '@liferaft/api/utils/network';

import { Card } from '@liferaft/core/components';
import type { FormErrorsSetter } from '@liferaft/core/components/forms';
import {
  EmailInput,
  Input,
  ValidatedForm,
} from '@liferaft/core/components/forms';
import { activeAmeritasRevision } from '@liferaft/core/content/products/ameritas/current';
import { useUserContext } from '@liferaft/core/contexts';
import { useDebounce } from '@liferaft/core/hooks';

import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';

const schema = yup.object({
  amr_has_current_coverage: yup.boolean().required().label('Current coverage'),
  amr_replacement_disclosure: yup
    .boolean()
    .label('Replacement for current coverage'),
  amr_has_fully_insured_dental: yup
    .boolean()
    .label('Replacing fully insured dental coverage'),
  amr_current_coverage_company: yup
    .string()
    .label('Current dental coverage company name'),
  amr_current_coverage_policy: yup
    .string()
    .label('Current dental coverage policy number'),
  amr_current_dental_coverage_termination: yup
    .string()
    .label('Current dental coverage termination date'),
  amr_document_delivery: yup
    .mixed<DocumentDelivery>()
    .oneOf(Object.values(DocumentDelivery))
    .required()
    .label('Document Delivery'),
  amr_electronic_delivery_consent: yup
    .boolean()
    .label('Consent to recieve documents electronically'),
  amr_electronic_delivery_email: yup
    .string()
    .label('Address for electronic document delivery'),
});

type FormData = yup.InferType<typeof schema>;

export function AmeritasDisclosures() {
  const { user } = useUserContext();
  const {
    data: { egress, id },
    next,
  } = useApplicationFlow();
  const ameritasRevision = activeAmeritasRevision();

  const [hasCurrentCoverage, setHasCurrentCoverage] = React.useState<boolean>();
  const [replacementDisclosure, setReplacementDisclosure] =
    React.useState<boolean>();
  const [hasCurrentDental, setHasCurrentDental] =
    React.useState<boolean>(false);
  const [hasFullyInsuredDental, setHasFullyInsuredDental] =
    React.useState<boolean>();
  const [currentCoverageCompany, setCurrentCoverageCompany] =
    React.useState<string>('');
  const [currentCoveragePolicy, setCurrentCoveragePolicy] =
    React.useState<string>('');
  const [currentDentalTermination, setCurrentDentalTermination] =
    React.useState<string>('');

  const [documentDelivery, setDocumentDelivery] =
    React.useState<DocumentDelivery>(DocumentDelivery.EMAIL);
  const [eDeliveryEmail, setEDeliveryEmail] = React.useState<string>(
    egress?.email || ''
  );
  const [electronicConsent, setElectronicConsent] = React.useState<boolean>();

  const productNames = egress?.product_selections
    ?.filter((s) => {
      if ('carrier' in s) {
        return s.carrier === CarrierKey.AMR;
      }
      return false;
    })
    ?.map((s) => s.name.toUpperCase().replaceAll('-', ' '));

  const [handleSubmit, isSubmitting] = useDebounce(
    async (validatedData: FormData, setErrors: FormErrorsSetter<FormData>) => {
      const network = new NetworkController();

      const result = await network.request<FormData, Application>(
        updateApplication(id as string, validatedData)
      );

      if (result.error) {
        setErrors(result.data as ParsedDjangoErrorResponse<Egress>);
        return;
      }

      network.request(
        createDisclosureAcknowledgement(user.id, {
          disclosure_type: DisclosureType.REPLACEMENT_DISCLOSURE,
          response: DisclosureAcknowledgementResponse.ACCEPTED,
        })
      );

      if (electronicConsent) {
        network.request(
          createDisclosureAcknowledgement(user.id, {
            disclosure_type: DisclosureType.E_DELIVERY,
            response: DisclosureAcknowledgementResponse.ACCEPTED,
          })
        );
      }

      return next();
    }
  );

  const handleDentalTerminationDateChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const dentalDate = new Date(`${e.target.value} 00:00:00`);
    try {
      const dentalDateString = dentalDate.toISOString().split('T')[0];
      setCurrentDentalTermination(dentalDateString);
    } catch {
      setCurrentDentalTermination('');
    }
  };

  const state = egress?.state;
  let replacementNoticeLink: string;
  switch (state) {
    case 'IN':
      replacementNoticeLink = 'https://www.ameritas.com/OCM/GetFile?doc=407582';
      break;
    case 'NC':
      replacementNoticeLink = 'https://www.ameritas.com/OCM/GetFile?doc=407583';
      break;
    case 'LA':
      replacementNoticeLink = 'https://www.ameritas.com/OCM/GetFile?doc=533109';
      break;
    case 'SC':
    default:
      replacementNoticeLink = 'https://www.ameritas.com/OCM/GetFile?doc=407584';
      break;
  }

  const productSelections = egress?.product_selections as ProductSelection[];

  const hasSelectedDental = productSelections.some((selection) => {
    if ('carrier' in selection && 'name' in selection) {
      return (
        selection.name === InsuranceProductKey.DENTAL &&
        selection.carrier === CarrierKey.AMR
      );
    } else {
      return false;
    }
  });

  return (
    <ApplyWrapper
      footer={<ApplyWrapperFooter rightDisabled={isSubmitting} />}
      heading="Agreement to Terms">
      <Card bodyClasses="" cardClasses="card-bleed shadow-light">
        {productNames && (
          <div className="d-flex w-100 align-items-center mb-4 mt-2">
            <div className="font-size-lg">{productNames.join(', ')}</div>
          </div>
        )}
        <div className="strike-line ml-0 mb-4" />
        <ValidatedForm<FormData>
          controlledData={{
            amr_has_current_coverage: hasCurrentCoverage,
            amr_replacement_disclosure: replacementDisclosure,
            amr_has_fully_insured_dental: hasFullyInsuredDental,
            amr_current_coverage_company: currentCoverageCompany,
            amr_current_coverage_policy: currentCoveragePolicy,
            amr_current_dental_coverage_termination: currentDentalTermination,
            amr_document_delivery: documentDelivery,
            amr_electronic_delivery_consent: electronicConsent,
            amr_electronic_delivery_email: eDeliveryEmail,
          }}
          id={APPLY_FORM_ID}
          onSubmit={handleSubmit}
          validationSchema={schema}>
          {({ errors }) => (
            <>
              <div>{ameritasRevision}</div>
              <div>
                <div className="d-flex w-100 align-items-center mb-3 mt-4">
                  <div className="font-size-lg">Previous Insurance</div>
                  <div className="strike-line" />
                </div>
                <Form.Group controlId="amrCurrentCoverageBool">
                  <Form.Label>
                    {ameritasRevision === '2024Q2' ? (
                      <>
                        Do you currently (or within the last 2 months) have
                        dental insurance?
                      </>
                    ) : (
                      <>Do you have current or recent coverage?</>
                    )}
                  </Form.Label>
                  <div className="ml-5">
                    <Form.Check
                      checked={hasCurrentCoverage === true}
                      id="amr-has-coverage"
                      isInvalid={!!errors.amr_has_current_coverage}
                      key="amr-has-coverage"
                      label="Yes"
                      name="current-coverage"
                      onChange={() => {
                        setHasCurrentCoverage(true);
                        setReplacementDisclosure(undefined);
                      }}
                      required
                      type="radio"
                    />
                    <Form.Check
                      checked={hasCurrentCoverage === false}
                      id="amr-has-no-coverage"
                      isInvalid={!!errors.amr_has_current_coverage}
                      key="amr-has-no-coverage"
                      label="No"
                      name="current-coverage"
                      onChange={() => {
                        setHasCurrentCoverage(false);
                        setReplacementDisclosure(false);
                      }}
                      required
                      type="radio"
                    />
                    {errors.amr_has_current_coverage && (
                      <div className="invalid-feedback d-block">
                        {errors.amr_has_current_coverage}
                      </div>
                    )}
                  </div>
                </Form.Group>
                {hasCurrentCoverage && (
                  <Form.Group controlId="amrReplacementDisclosureBool">
                    <Form.Label>
                      {ameritasRevision === '2024Q2' ? (
                        <>
                          Is the dental insurance applied for intended to
                          replace any existing insurance?
                        </>
                      ) : (
                        <>
                          Is the insurance applied for intended to replace any
                          existing insurance?
                        </>
                      )}
                    </Form.Label>
                    <div className="ml-5">
                      <Form.Check
                        checked={replacementDisclosure === true}
                        id="amr-is-replacing"
                        isInvalid={!!errors.amr_replacement_disclosure}
                        key="amr-is-replacing"
                        label="Yes"
                        name="replacement-disclosure"
                        onChange={() => setReplacementDisclosure(true)}
                        required
                        type="radio"
                      />
                      <Form.Check
                        checked={replacementDisclosure === false}
                        id="amr-is-not-replacing"
                        isInvalid={!!errors.amr_replacement_disclosure}
                        key="amr-is-not-replacing"
                        label="No"
                        name="replacement-disclosure"
                        onChange={() => setReplacementDisclosure(false)}
                        required
                        type="radio"
                      />
                      {errors.amr_replacement_disclosure && (
                        <div className="invalid-feedback d-block">
                          {errors.amr_replacement_disclosure}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                )}
                {hasCurrentCoverage && replacementDisclosure && (
                  <>
                    <div className="d-flex w-100 align-items-center mb-3 mt-4">
                      <div className="font-size-lg">
                        Policy Replacement Notice
                      </div>
                      <div className="strike-line" />
                    </div>
                    <a
                      href={replacementNoticeLink}
                      rel="noreferrer"
                      target="_blank">
                      Customer Notice
                    </a>
                  </>
                )}
                {state === 'LA' &&
                  hasCurrentCoverage &&
                  replacementDisclosure && (
                    <>
                      {' | '}
                      <a
                        href="https://www.ameritas.com/OCM/GetFile?doc=533110"
                        rel="noreferrer"
                        target="_blank">
                        Assisting Agent Notice
                      </a>
                    </>
                  )}
                {state === 'SC' &&
                  hasCurrentCoverage &&
                  replacementDisclosure === false && (
                    <>
                      {ameritasRevision === '2023Q3' ? (
                        <p>Policy Replacement Notice</p>
                      ) : (
                        <p>SC Replacement Notice</p>
                      )}
                      <a
                        href="https://www.ameritas.com/OCM/GetFile?doc=597539"
                        rel="noreferrer"
                        target="_blank">
                        Duplication Notice
                      </a>
                    </>
                  )}
                {hasCurrentCoverage &&
                  replacementDisclosure &&
                  hasSelectedDental && (
                    <div>
                      {ameritasRevision === '2023Q3' && (
                        <div>
                          <div className="d-flex w-100 align-items-center mb-3 mt-4">
                            <div className="font-size-lg">
                              Please tell us about the policy you are replacing
                            </div>
                            <div className="strike-line" />
                          </div>
                        </div>
                      )}
                      <div className="my-3">
                        {ameritasRevision === '2023Q3' ? (
                          <Form.Group>
                            <Form.Check
                              checked={hasCurrentDental}
                              id="has-current-dental"
                              inline
                              label="Check if you currently have dental insurance"
                              name="currentDental"
                              onChange={() =>
                                setHasCurrentDental(!hasCurrentDental)
                              }
                              type="checkbox"
                            />
                          </Form.Group>
                        ) : (
                          <Form.Group controlId="amrHasFullyInsuredDental">
                            <Form.Label>
                              Is the dental insurance applied for replacing a
                              fully insured plan (not DHMO, discount, or
                              schedule plan)?
                            </Form.Label>
                            <div className="ml-5">
                              <Form.Check
                                checked={hasFullyInsuredDental === true}
                                id="amr-has-fully-insured-dental"
                                isInvalid={
                                  !!errors.amr_has_fully_insured_dental
                                }
                                key="amr-has-fully-insured-dental"
                                label="Yes"
                                name="fully-insured-dental"
                                onChange={() => setHasFullyInsuredDental(true)}
                                required
                                type="radio"
                              />
                              <Form.Check
                                checked={hasFullyInsuredDental === false}
                                id="amr-has-not-fully-insured-dental"
                                isInvalid={
                                  !!errors.amr_has_fully_insured_dental
                                }
                                key="amr-has-not-fully-insured-dental"
                                label="No"
                                name="fully-insured-dental"
                                onChange={() => setHasFullyInsuredDental(false)}
                                required
                                type="radio"
                              />
                              {errors.amr_has_fully_insured_dental && (
                                <div className="invalid-feedback d-block">
                                  {errors.amr_has_fully_insured_dental}
                                </div>
                              )}
                            </div>
                          </Form.Group>
                        )}
                        {(hasCurrentDental || hasFullyInsuredDental) && (
                          <>
                            <Input
                              aria-describedby="dental-termination-date"
                              error={
                                errors.amr_current_dental_coverage_termination
                              }
                              id="dental-termination-date"
                              label="Dental policy termination date"
                              labelClasses="mt-3"
                              max="9999-12-31"
                              min="1970-01-01"
                              onChange={handleDentalTerminationDateChange}
                              placeholder="Termination date"
                              required
                              type="date"
                            />
                            <Input
                              aria-describedby="dental-company-name"
                              error={errors.amr_current_coverage_company}
                              id="dental-company-name"
                              label="Dental insurance company name"
                              labelClasses="mt-3"
                              onChange={(
                                e: React.ChangeEvent<HTMLTextAreaElement>
                              ) => setCurrentCoverageCompany(e.target.value)}
                              placeholder="Company name"
                              required
                              type="text"
                              value={currentCoverageCompany}
                            />
                            <Input
                              aria-describedby="dental-policy-number"
                              error={errors.amr_current_coverage_policy}
                              id="dental-policy-number"
                              label="Dental policy number"
                              labelClasses="mt-3"
                              onChange={(
                                e: React.ChangeEvent<HTMLTextAreaElement>
                              ) => setCurrentCoveragePolicy(e.target.value)}
                              placeholder="Policy number"
                              required
                              type="text"
                              value={currentCoveragePolicy}
                            />
                            {ameritasRevision === '2023Q3' ? (
                              <div className="mt-3">
                                By providing a valid policy number and company
                                name, policyholders may receive credit for prior
                                coverage (CPC) with increased plan benefits. On
                                day one, the policyholder receives the year two
                                coinsurance for Preventive, Basic and Major
                                dental services. Waiting periods are waived on
                                Major procedures, if applicable. CPC is not
                                available if the prior plan was a DHMO, discount
                                or schedule plan. The gap in coverage must be no
                                more than 2 months from the new plan effective
                                date, and prior coverage should have been in
                                effect for at least 12 months. Orthodontia
                                waiting periods will not be waived. The
                                insurance company may deny CPC benefits if false
                                information is provided by the claimant. CPC is
                                not available in all states. If you are unable
                                to provide the information requested above, you
                                will be issued a policy without CPC.
                              </div>
                            ) : (
                              <div className="mt-3">
                                Credit for prior coverage (CPC) is available for
                                those replacing a fully insured dental plan from
                                another carrier. CPC is not available if the
                                prior plan was a DHMO, discount or schedule
                                plan. The gap in coverage must be no more than
                                two months from the new plan effective date, and
                                prior coverage should have been in effect for at
                                least 12 months. By providing the policy number
                                and company name, policyholders may receive
                                increased benefit coverage (co-insurance) day
                                one for Preventive, Basic and Major dental
                                services. There will be no changes day one to
                                the annual maximum or benefit coverage levels
                                for orthodontia or hearing benefits, including
                                waiting periods for these additional benefits.
                                CPC is not available in all states.
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                {ameritasRevision === '2024Q2' &&
                  hasCurrentCoverage &&
                  (replacementDisclosure === false ||
                    hasFullyInsuredDental === false) && (
                    <p className="text-muted">You are not eligible for CPC.</p>
                  )}
                {hasCurrentCoverage && (
                  <p className="font-weight-bold text-danger">
                    NOTE: Applicant and dependents cannot have duplicate
                    policies underwritten by Ameritas, or any brand that is
                    underwritten by Ameritas (i.e. two dental or two vision
                    plans).
                  </p>
                )}
              </div>
              <div className="mt-3">
                <div className="d-flex w-100 align-items-center mb-3 mt-4">
                  <div className="font-size-lg">Document Delivery</div>
                  <div className="strike-line" />
                </div>
                <Form.Group controlId="deliveryCheckbox">
                  <Form.Label>
                    Would you like your plan documents delivered by email or
                    postal mail?
                  </Form.Label>
                  <div className="ml-5">
                    <Form.Check
                      checked={documentDelivery === DocumentDelivery.EMAIL}
                      id="document-delivery-email"
                      isInvalid={!!errors.amr_document_delivery}
                      key="email"
                      label="Email"
                      name="document-delivery"
                      onChange={() => {
                        setDocumentDelivery(DocumentDelivery.EMAIL);
                        setElectronicConsent(true);
                      }}
                      required
                      type="radio"
                    />
                    <Form.Check
                      checked={documentDelivery === DocumentDelivery.MAIL}
                      id="document-delivery-mail"
                      isInvalid={!!errors.amr_document_delivery}
                      key="mail"
                      label="Postal Mail"
                      name="document-delivery"
                      onChange={() => {
                        setDocumentDelivery(DocumentDelivery.MAIL);
                        setElectronicConsent(undefined);
                      }}
                      required
                      type="radio"
                    />
                    {errors.amr_document_delivery && (
                      <div className="invalid-feedback d-block">
                        {errors.amr_document_delivery}
                      </div>
                    )}
                  </div>
                </Form.Group>
                {documentDelivery === DocumentDelivery.EMAIL && (
                  <Form.Group>
                    <EmailInput
                      error={errors.amr_electronic_delivery_email}
                      groupClasses="form-group"
                      label="Email for electronic delivery"
                      onChange={setEDeliveryEmail}
                      required
                      value={eDeliveryEmail}
                    />
                    {errors.amr_electronic_delivery_email && (
                      <div className="invalid-feedback d-block">
                        {errors.amr_electronic_delivery_email}
                      </div>
                    )}
                  </Form.Group>
                )}
                {documentDelivery === DocumentDelivery.EMAIL && (
                  <div>
                    <div className="my-3">
                      <div className="d-flex w-100 align-items-center mb-3 mt-4">
                        <div className="font-size-lg">Electronic Consent</div>
                        <div className="strike-line" />
                      </div>
                    </div>
                    <Form.Group>
                      <Form.Label>
                        I agree to receive plan documents, ID cards and
                        Explanation of Benefits (EOB) electronically, and agree
                        to the terms and conditions.
                      </Form.Label>
                      <div className="ml-5">
                        <Form.Check
                          checked={electronicConsent === true}
                          id="has-agreed-document"
                          isInvalid={!!errors.amr_electronic_delivery_consent}
                          key="has-agreed-document"
                          label="Yes"
                          name="electronic-consent"
                          onChange={() => setElectronicConsent(true)}
                          required
                          type="radio"
                        />
                        <Form.Check
                          checked={electronicConsent === false}
                          id="has-not-agreed-document"
                          isInvalid={!!errors.amr_electronic_delivery_consent}
                          key="has-not-agreed-document"
                          label="No"
                          name="electronic-consent"
                          onChange={() => {
                            setElectronicConsent(false);
                            setDocumentDelivery(DocumentDelivery.MAIL);
                          }}
                          required
                          type="radio"
                        />
                        {errors.amr_electronic_delivery_consent && (
                          <div className="invalid-feedback d-block">
                            {errors.amr_electronic_delivery_consent}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                )}
                <p className="mt-5 mb-0">Terms and Conditions</p>
                <small>
                  We are pleased to offer our Policyholders the ability to
                  receive their electronic plan documents and information
                  online. If you choose to receive this online service, you will
                  no longer receive paper copies of your plan documents. Instead
                  you will be able to access this information through your
                  personal computer.*
                  <br />
                  You understand that by choosing this option you will no longer
                  receive paper copies of your plan documents. You will be given
                  access to view them in electronic form in lieu of physical
                  copies. You also understand that you can unsubscribe from
                  electronic delivery of plan documents by visiting the{' '}
                  <a
                    href="https://www.ameritas.com/applications/group/member"
                    rel="noreferrer"
                    target="_blank">
                    Ameritas member portal
                  </a>
                  . If you choose to unsubscribe, you will no longer have access
                  to view these plan documents electronically. There is no
                  additional charge to have these plan documents mailed to you
                  in paper form.
                  <br />
                  *Enrollment in this electronic delivery service requires that
                  you have a personal computer with appropriate browser
                  software, e-mail software as well as communications access to
                  the Internet. Browser requirements include the most current or
                  previous version of one of the following: Chrome, Firefox,
                  Safari, or Edge. Web pages are best viewed at an 800 x 600
                  screen resolution (web browser size of 760 x 480), and allowed
                  to be viewed at various resolutions with minimal degradation
                  of content display. Although we will not charge for electronic
                  delivery you may incur charges from Internet Service Providers
                  and local telephone companies. Should you wish to print
                  materials that have been delivered electronically, you must
                  have a printer as well. Some files are available in Adobe
                  Acrobat Format. In order to view this format (.pdf), you will
                  need to have Adobe Acrobat Reader software. Visit Adobe.com to
                  obtain the most current free version of Adobe Reader.
                </small>
              </div>
            </>
          )}
        </ValidatedForm>
      </Card>
    </ApplyWrapper>
  );
}
