type Props = {
  href: string;
};

export function Redirect({ href }: Props) {
  window.location.href = href;
  return null;
}

export function RedirectWithSearchParams({ href }: Props) {
  // preserve search params from the current url
  const currentUrl = new URL(window.location.href);
  const currentSearchParams = currentUrl.searchParams;

  const newUrl = new URL(href, window.location.href);
  const newSearchParams = newUrl.searchParams;

  // merge new and existing search params, preferring new where they overlap
  for (const [key, value] of currentSearchParams) {
    if (!newSearchParams.has(key)) {
      newSearchParams.set(key, value);
    }
  }

  newUrl.search = newSearchParams.toString();

  window.location.href = newUrl.href;
  return null;
}
