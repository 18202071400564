export type NextParams<T> = {
  currentPath?: string | null;
  returnPath?: string | null;
  state: T;
};

type StepOptions<T> = {
  collectables?: string[];
  isFulfilled?: (state: T) => boolean;
  isRequired?: (state: T) => boolean;
  next?: (params: NextParams<T>) => string;
};

export class Step<T extends Record<string, any>> {
  public readonly path: string;
  public readonly collectables: string[];
  public readonly dataKey?: string;

  public isRequired: (state: T) => boolean;
  public isFulfilled: (state: T) => boolean;
  public next?: (params: NextParams<T>) => string;

  constructor(
    path: string,
    { collectables, isFulfilled, isRequired, next }: StepOptions<T> = {}
  ) {
    this.path = path;
    this.collectables = collectables || [];
    this.isRequired = isRequired || (() => true);
    this.isFulfilled =
      isFulfilled ||
      ((state: T): boolean => {
        let data = state;

        if (this.dataKey) {
          data = state[this.dataKey];
        }

        for (const collectable of this.collectables) {
          let toCheck;

          if (collectable.includes('.')) {
            const keys = collectable.split('.');
            let temp = data;

            for (const key of keys) {
              temp = temp[key];

              if (temp === undefined) {
                break;
              }
            }

            toCheck = temp;
          } else {
            toCheck = data[collectable];
          }

          if (toCheck === undefined || toCheck === null) {
            return false;
          }
        }

        return true;
      });
    this.next = next;
  }

  setIsFulfilled(isFulfilled: (state: T) => boolean): Step<T> {
    return { ...this, isFulfilled };
  }

  setNext(next: (params: NextParams<T>) => string): Step<T> {
    return { ...this, next };
  }
}

export class Flow<T extends Record<string, any>> {
  public steps: Step<T>[];

  constructor(steps: Step<T>[]) {
    this.steps = steps;
  }

  determinePrevious({
    currentPath,
    state,
  }: {
    currentPath: string;
    state: T;
  }): string {
    let workingPath = this.steps[0].path;
    let previous = currentPath;

    while (workingPath !== currentPath) {
      previous = workingPath;

      const calculatedPath = this.determineNext({
        currentPath: workingPath,
        state,
      });

      if (!calculatedPath) {
        throw new Error('Unable to determine previous path');
      }

      workingPath = calculatedPath;
    }

    return previous;
  }

  determineNext({
    currentPath,
    returnPath,
    goToPath,
    staleCollectables,
    state,
  }: {
    currentPath: string | null;
    returnPath?: string | null;
    goToPath?: string | null;
    staleCollectables?: string[];
    state: T;
  }): string | null {
    if (!state) {
      return this.steps[0].path;
    }

    if (currentPath) {
      if (returnPath && goToPath) {
        /*
         * This branch handles the case where there is a current path, and that current path
         * is also from a goTo operation (an operation where the flow has jumped to another step out of order).
         */

        if (staleCollectables && staleCollectables.length > 0) {
          const stalePath = this._determineStalePath(state, staleCollectables);
          if (stalePath) return stalePath;
        }

        const returnPathIndex = this.steps.findIndex(
          (s) => s.path === returnPath
        );

        for (const step of this.steps.slice(0, returnPathIndex)) {
          if (!step.isFulfilled(state) && step.isRequired(state))
            return step.path;
        }

        const goToPathIndex = this.steps.findIndex((s) => s.path === goToPath);
        const currentPathIndex = this.steps.findIndex(
          (s) => s.path === currentPath
        );

        if (goToPathIndex > currentPathIndex) {
          const currentStep = this.steps.find((s) => s.path === currentPath);

          if (!currentStep) {
            throw new Error(`Step does not exist for path: ${currentPath}`);
          }
          if (currentStep?.next) {
            return currentStep.next({ currentPath, returnPath, state });
          } else {
            const nextIndex = this.steps.indexOf(currentStep) + 1;

            return nextIndex === this.steps.length
              ? null
              : this.steps[nextIndex].path;
          }
        } else if (goToPathIndex === currentPathIndex) {
          const goToStep = this.steps.find((s) => s.path === goToPath);

          if (goToStep?.next) {
            return goToStep.next({ currentPath, returnPath, state });
          } else {
            return returnPath;
          }
        }

        return returnPath;
      } else {
        /*
         * This branch handles the case where there is a current path and the next
         * path should be determined by interrogating the current step for the next step.
         */

        const currentStep = this.steps.find((s) => s.path === currentPath);

        if (!currentStep) {
          throw new Error(`Step does not exist for path: ${currentPath}`);
        }

        if (currentStep?.next) {
          return currentStep.next({ currentPath, returnPath, state });
        } else {
          const nextIndex = this.steps.indexOf(currentStep) + 1;

          return nextIndex === this.steps.length
            ? null
            : this.steps[nextIndex].path;
        }
      }
    } else {
      /*
       * This branch handles a case where the flow is being started fresh
       * and there is no current path to use to detemine the next path.
       *
       * The next step's path is detemined by interrogating each steps 'next' step,
       * until a step that isn't fulfilled and is required.
       */

      if (staleCollectables && staleCollectables.length > 0) {
        const stalePath = this._determineStalePath(state, staleCollectables);
        if (stalePath) return stalePath;
      }

      let targetPath;
      let currentStep: Step<T> = this.steps[0];

      if (!currentStep) throw Error('Could not determine first step.');

      while (!targetPath) {
        if (!currentStep.isRequired(state) || currentStep.isFulfilled(state)) {
          if (currentStep.next) {
            const newPath = currentStep.next({
              currentPath,
              returnPath,
              state,
            });
            const newStep = this.steps.find((s) => s.path === newPath);

            if (!newStep)
              throw new Error(`Step does not exist for path: ${newPath}`);

            currentStep = newStep;
          } else {
            const nextIndex = this.steps.indexOf(currentStep) + 1;

            if (nextIndex === this.steps.length) {
              targetPath = null;
            } else {
              currentStep = this.steps[nextIndex];
            }
          }
        } else {
          targetPath = currentStep.path;
        }
      }

      return targetPath;
    }
  }

  public isStepFulfilled(path: string, state: T): boolean {
    const step = this.steps.find((s) => s.path === path);

    if (!step) return false;

    return step.isFulfilled(state);
  }

  private _determineStalePath(
    state: T,
    staleCollectables: string[]
  ): string | undefined {
    const staleSteps = this.steps.filter((step) =>
      staleCollectables.some((collectable: string) =>
        step.collectables.includes(collectable)
      )
    );

    if (staleSteps.length > 0) {
      for (const step of staleSteps) {
        if (step.isRequired(state)) return step.path;
      }
    }
  }
}
