import React from 'react';

import type {
  Egress,
  Offering,
  ProductKey,
  ProductMatrix,
  ProductSelection,
} from '@liferaft/api/types';
import {
  CarrierKey,
  CoverageType,
  InsuranceProductKey,
  YesNo,
} from '@liferaft/api/types';
import type { ProductMatrixConfig } from '@liferaft/api/types';

import { createContext } from '@liferaft/core/contexts';
import { carrierSort } from '@liferaft/core/utils/sort';

type PMContext = {
  totalPremium: string;
  config: Record<ProductKey, ProductMatrixConfig>;
  getSelectedInsuranceOfferingByProduct: (
    productKey: InsuranceProductKey
  ) => Offering | undefined;
  matrix: ProductMatrix | undefined;
  residentState: string | undefined;
  selectCarrier: (productKey: InsuranceProductKey, carrier: CarrierKey) => void;
  selectCoverage: (
    productKey: InsuranceProductKey,
    coverage: CoverageType
  ) => void;
  saveInsuranceOfferingID: (
    productKey: InsuranceProductKey,
    id: string
  ) => void;
  acceptedHospitalServices: YesNo;
  selectHospitalServices: (value: YesNo) => void;
  setInitialSelections: React.Dispatch<
    React.SetStateAction<ProductSelection[] | undefined>
  >;
  setMatrix: React.Dispatch<React.SetStateAction<ProductMatrix | undefined>>;
  setResidentState: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedInsuranceOfferings: [InsuranceProductKey, Offering][];
};

const [useContext, Provider] = createContext<PMContext>();

export const useProductMatrixContext = (
  egress?: Partial<Egress>
): PMContext => {
  const context = useContext();

  React.useLayoutEffect(() => {
    if (egress) {
      context.setInitialSelections(egress?.product_selections);
      context.setMatrix(egress?.product_matrix);
      context.setResidentState(egress?.state);
    }
  }, [egress]);

  return context;
};

type Props = {
  children?: React.ReactNode;
};

export function ProductMatrixContextProvider({ children }: Props) {
  const [acceptedHospitalServices, setAcceptededHospitalServices] =
    React.useState<YesNo>(YesNo.NO);
  const [matrix, setMatrix] = React.useState<ProductMatrix>();
  const [residentState, setResidentState] = React.useState<string>();

  const [config, setConfig] = React.useState<
    Record<ProductKey, ProductMatrixConfig>
  >({
    [InsuranceProductKey.ACCIDENT]: {
      carrierSelected: CarrierKey.LIB,
      coverageSelected: CoverageType.INDIVIDUAL,
    },
    [InsuranceProductKey.CRITICAL]: {
      carrierSelected: CarrierKey.LIB,
      coverageSelected: CoverageType.INDIVIDUAL,
    },
    [InsuranceProductKey.HOSPITAL]: {
      carrierSelected: CarrierKey.LIB,
      coverageSelected: CoverageType.INDIVIDUAL,
    },
    [InsuranceProductKey.DENTAL]: {
      carrierSelected: CarrierKey.AMR,
      coverageSelected: CoverageType.INDIVIDUAL,
    },
    [InsuranceProductKey.VISION]: {
      carrierSelected: CarrierKey.AMR,
      coverageSelected: CoverageType.INDIVIDUAL,
    },
  });

  const [selectedOfferings, setSelectedOfferings] = React.useState<
    Record<ProductKey, string | YesNo>
  >({
    [InsuranceProductKey.ACCIDENT]: '',
    [InsuranceProductKey.CRITICAL]: '',
    [InsuranceProductKey.HOSPITAL]: '',
    [InsuranceProductKey.DENTAL]: '',
    [InsuranceProductKey.VISION]: '',
  });

  const [initialSelections, setInitialSelections] =
    React.useState<ProductSelection[]>();

  React.useEffect(() => {
    if (matrix) {
      const selections = { ...selectedOfferings };
      const currentConfig: Partial<Record<ProductKey, ProductMatrixConfig>> =
        {};

      if (initialSelections) {
        initialSelections.forEach((selection: ProductSelection) => {
          if ('carrier' in selection) {
            const product = matrix.products[selection.name];

            if (product) {
              const carrierOfferings =
                product['carriers'][selection.carrier]?.offerings;
              const offering = carrierOfferings?.find(
                (o) => o.offering_id === selection.offering_id
              );

              if (offering) {
                currentConfig[selection.name] = {
                  carrierSelected: offering.carrier,
                  coverageSelected: offering.coverage_type,
                };
                selections[selection.name] = offering.offering_id;
              }
            }
          }
        });
      }

      Object.values(InsuranceProductKey).forEach((key: InsuranceProductKey) => {
        if (currentConfig[key] === undefined) {
          const carrierOptions = matrix?.products[key]?.carriers;

          let carrierKeys: CarrierKey[] = [];

          if (carrierOptions) {
            carrierKeys = Object.keys(carrierOptions) as CarrierKey[];
          }

          currentConfig[key] = {
            carrierSelected: carrierKeys.sort((a: CarrierKey, b: CarrierKey) =>
              carrierSort(a, b)
            )[0],
            coverageSelected: CoverageType.INDIVIDUAL,
          };
        }
      });

      setConfig(currentConfig as Record<ProductKey, ProductMatrixConfig>);
      setSelectedOfferings(selections);
    }
  }, [matrix, initialSelections]);

  function getSelectedInsuranceOfferingByProduct(
    productKey: InsuranceProductKey
  ): Offering | undefined {
    const selectedCarrier: CarrierKey = config[productKey]
      .carrierSelected as CarrierKey;

    const offerings: Offering[] =
      matrix?.products[productKey]?.carriers[selectedCarrier]?.offerings || [];

    const selectedOffering: Offering | undefined = offerings?.find(
      (offering) => offering.offering_id === selectedOfferings[productKey]
    );

    return selectedOffering;
  }

  function saveInsuranceOfferingID(
    productKey: InsuranceProductKey,
    id: string
  ): void {
    setSelectedOfferings({
      ...selectedOfferings,
      [productKey]: id,
    });
  }

  function selectCarrier(
    productKey: InsuranceProductKey,
    carrier: CarrierKey
  ): void {
    let coverage = config[productKey].coverageSelected;

    if (
      productKey === InsuranceProductKey.HOSPITAL &&
      carrier === CarrierKey.AIG &&
      (coverage === CoverageType.INDIVIDUAL_SPOUSE ||
        coverage === CoverageType.INDIVIDUAL_CHILDREN)
    ) {
      coverage = CoverageType.INDIVIDUAL;
    }

    setConfig({
      ...config,
      [productKey]: {
        ...config[productKey],
        carrierSelected: carrier,
        coverageSelected: coverage,
      },
    });

    const currentOffering = getSelectedInsuranceOfferingByProduct(productKey);
    const currentLevel = currentOffering?.level;
    const currentCancer = currentOffering?.details.cancer_only;

    const offerings: Offering[] =
      matrix?.products[productKey]?.carriers[carrier]?.offerings || [];

    const selectedOffering: Offering | undefined = offerings?.find(
      (offering) =>
        offering.level === currentLevel &&
        offering.coverage_type === coverage &&
        offering.details.cancer_only === currentCancer &&
        offering.has_non_insurance ===
          (carrier === CarrierKey.AIG
            ? acceptedHospitalServices === YesNo.YES
            : false)
    );

    if (currentLevel && selectedOffering) {
      saveInsuranceOfferingID(productKey, selectedOffering.offering_id);
    }
  }

  function selectCoverage(
    productKey: InsuranceProductKey,
    coverage: CoverageType
  ): void {
    setConfig({
      ...config,
      [productKey]: { ...config[productKey], coverageSelected: coverage },
    });
  }

  function selectHospitalServices(selection: YesNo): void {
    const currentOffering = getSelectedInsuranceOfferingByProduct(
      InsuranceProductKey.HOSPITAL
    );

    const currentLevel = currentOffering?.level;
    const currentCoverage = currentOffering?.coverage_type;
    const offerings: Offering[] =
      matrix?.products[InsuranceProductKey.HOSPITAL]?.carriers[CarrierKey.AIG]
        ?.offerings || [];

    const newOffering: Offering | undefined = offerings?.find(
      (offering) =>
        offering.level === currentLevel &&
        offering.coverage_type === currentCoverage &&
        offering.has_non_insurance === (selection === YesNo.YES)
    );

    if (newOffering) {
      saveInsuranceOfferingID(
        InsuranceProductKey.HOSPITAL,
        newOffering.offering_id
      );
    }

    setAcceptededHospitalServices(selection);
  }

  const value = {
    get totalPremium() {
      const accidentOffering = getSelectedInsuranceOfferingByProduct(
        InsuranceProductKey.ACCIDENT
      );
      const accidentPremium = accidentOffering
        ? accidentOffering.monthly_premium
        : 0;

      const criticalOffering = getSelectedInsuranceOfferingByProduct(
        InsuranceProductKey.CRITICAL
      );
      const criticalPremium = criticalOffering
        ? Number(criticalOffering.monthly_premium)
        : 0;

      const hospitalSelection = getSelectedInsuranceOfferingByProduct(
        InsuranceProductKey.HOSPITAL
      );

      const hospitalPremium = hospitalSelection
        ? Number(hospitalSelection.monthly_premium) +
          Number(hospitalSelection.monthly_non_insurance_premium)
        : 0;

      const dentalOffering = getSelectedInsuranceOfferingByProduct(
        InsuranceProductKey.DENTAL
      );
      const dentalPremium = dentalOffering ? dentalOffering.monthly_premium : 0;

      const visionOffering = getSelectedInsuranceOfferingByProduct(
        InsuranceProductKey.VISION
      );
      const visionPremium = visionOffering ? visionOffering.monthly_premium : 0;

      return (
        Number(accidentPremium) +
        Number(criticalPremium) +
        hospitalPremium +
        Number(dentalPremium) +
        Number(visionPremium)
      )
        .toFixed(2)
        .toString();
    },
    get selectedInsuranceOfferings() {
      return Object.entries(selectedOfferings)
        .map(([productKey]) => {
          if (
            Object.values(InsuranceProductKey).includes(
              productKey as InsuranceProductKey
            )
          ) {
            return [
              productKey,
              getSelectedInsuranceOfferingByProduct(
                productKey as InsuranceProductKey
              ),
            ] as const;
          }
        })
        .filter((o) => o && o[1]) as [InsuranceProductKey, Offering][];
    },
    config,
    getSelectedInsuranceOfferingByProduct,
    matrix,
    residentState,
    selectCarrier,
    selectCoverage,
    selectedOfferings,
    saveInsuranceOfferingID,
    acceptedHospitalServices,
    selectHospitalServices,
    setMatrix,
    setResidentState,
    setInitialSelections,
  };

  return <Provider value={value}>{children}</Provider>;
}
