import React from 'react';
import { Table } from 'react-bootstrap';

import { Modal } from '@liferaft/core/components';

import type { PlanFact } from '@/pages/product-matrix/content';

type Props = {
  show: boolean;
  facts: PlanFact[];
  footnote?: React.ReactNode;
  onClose: () => void;
};

export function FactsModal({ show, facts, footnote, onClose }: Props) {
  return (
    <Modal modalTitle="Coverage Quick Facts" onHide={onClose} show={show}>
      <>
        <Table className="table-sm table-bordered">
          <tbody className="font-size-sm">
            {facts.length > 0 &&
              facts.map((element, index) => {
                return (
                  <tr key={index}>
                    <td>{element.topic}</td>
                    <td>{element.description}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {footnote && <p className="font-size-sm mb-0">{footnote}</p>}
      </>
    </Modal>
  );
}
