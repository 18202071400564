import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { StateInput, ZipCodeInput } from '../forms';

export type AddressData = {
  primary: string;
  secondary?: string;
  city: string;
  state: string;
  zipCode: string;
};

export type AddressErrors = {
  primary?: string;
  secondary?: string;
  city?: string;
  state?: string;
  zipCode?: string;
};

type Props = {
  errors?: AddressErrors;
  label?: string;
  disabled?: boolean;
  disableState?: boolean;
  disableZipCode?: boolean;
  hideSecondaryStreet?: boolean;
  showFieldLabels?: boolean;
  fieldsetClasses?: string;
  value?: Partial<AddressData>;
  onChange: (value: Partial<AddressData>) => void;
};

export function AddressInput({
  errors,
  label,
  disabled = false,
  disableState = false,
  disableZipCode = false,
  hideSecondaryStreet = false,
  showFieldLabels = false,
  fieldsetClasses,
  value,
  onChange,
}: Props) {
  const handlePrimaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, primary: event.target.value });
  };

  const handleSecondaryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChange({ ...value, secondary: event.target.value });
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, city: event.target.value });
  };

  const handleStateChange = (newState: string) => {
    onChange({ ...value, state: newState });
  };

  const handleZipCodeChange = (newZipCode: string) => {
    onChange({ ...value, zipCode: newZipCode });
  };

  return (
    <fieldset className={fieldsetClasses} data-testid="address-fieldset">
      {label && (
        <legend className="col-form-label font-weight-bold pt-0">
          {label}
        </legend>
      )}
      <Form.Group className="form-group">
        {showFieldLabels && <Form.Label>Street Address</Form.Label>}
        <Form.Control
          data-testid="address-primary-input"
          disabled={disabled}
          id="primary"
          isInvalid={!!errors?.primary}
          name="primary"
          onChange={handlePrimaryChange}
          placeholder="Street Address"
          required
          type="text"
          value={value?.primary || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.primary}
        </Form.Control.Feedback>
      </Form.Group>
      {!hideSecondaryStreet && (
        <Form.Group className="form-group">
          {showFieldLabels && <Form.Label>Apartment / Suite Number</Form.Label>}
          <Form.Control
            data-testid="address-secondary-input"
            disabled={disabled}
            id="secondary"
            isInvalid={!!errors?.secondary}
            name="secondary"
            onChange={handleSecondaryChange}
            placeholder="Apartment / Suite Number"
            type="text"
            value={value?.secondary || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.secondary}
          </Form.Control.Feedback>
        </Form.Group>
      )}
      <Row>
        <Col sm={4}>
          <Form.Group className="form-group">
            {showFieldLabels && <Form.Label>City</Form.Label>}
            <Form.Control
              data-testid="address-city-input"
              disabled={disabled}
              id="city"
              isInvalid={!!errors?.city}
              name="city"
              onChange={handleCityChange}
              placeholder="City"
              required
              type="text"
              value={value?.city || ''}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.city}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <StateInput
            disableState={disabled || disableState}
            error={errors?.state}
            groupClasses="form-group"
            label={showFieldLabels ? 'State' : ''}
            onChange={handleStateChange}
            required
            value={value?.state || ''}
          />
        </Col>
        <Col sm={4}>
          <ZipCodeInput
            disableZipCode={disabled || disableZipCode}
            error={errors?.zipCode}
            groupClasses="form-group"
            label={showFieldLabels ? 'Zip Code' : ''}
            onChange={handleZipCodeChange}
            required
            value={value?.zipCode || ''}
          />
        </Col>
      </Row>
    </fieldset>
  );
}
