import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

import type { InsuranceProductKey, Offering } from '@liferaft/api/types';
import { CarrierKey, CoverageDisplay, CoverageType } from '@liferaft/api/types';

import { ReactComponent as FamilyCoverageIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Custom/coverage-family.svg';
import { ReactComponent as ChildrenCoverageIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Custom/coverage-individual-children.svg';
import { ReactComponent as SpouseCoverageIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Custom/coverage-individual-spouse.svg';
import { ReactComponent as IndividualCoverageIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Custom/coverage-individual.svg';

import { useProductMatrixContext } from '@/contexts';

import './coverage-toggle.scss';

type Props = {
  productKey: InsuranceProductKey;
};

const COVERAGE_SORT = ['EE', 'ES', 'EC', 'F'];

export function CoverageToggle({ productKey }: Props) {
  const context = useProductMatrixContext();

  const selectedCoverage = context.config[productKey].coverageSelected;
  const selectedCarrier = context.config[productKey].carrierSelected;

  const carriers = context.matrix?.products[productKey]?.carriers;
  let availableCarriers;
  if (carriers) {
    availableCarriers = Object.keys(carriers);
  }

  let coverageOptions: CoverageType[] = [];
  if (
    availableCarriers &&
    carriers &&
    selectedCarrier &&
    availableCarriers.includes(selectedCarrier)
  ) {
    coverageOptions = carriers[selectedCarrier]?.coverages || [];
  }

  const coverageButtonGroup =
    coverageOptions.length > 2 ? 'mb-5 button-group-wrap' : 'mb-5';

  let coverageIcon: React.ReactNode;

  const handleToggle = (coverage: CoverageType) => {
    context.selectCoverage(productKey, coverage);

    const currentOffering =
      context.getSelectedInsuranceOfferingByProduct(productKey);
    const currentLevel = currentOffering?.level;
    const currentCancer = currentOffering?.details.cancer_only;
    const currentNonInsurance = currentOffering?.has_non_insurance;

    const offerings: Offering[] =
      context.matrix?.products[productKey]?.carriers[selectedCarrier]
        ?.offerings || [];

    const selectedOffering: Offering | undefined = offerings?.find(
      (offering) =>
        offering.level === currentLevel &&
        offering.coverage_type === coverage &&
        offering.details.cancer_only === currentCancer &&
        offering.has_non_insurance === currentNonInsurance
    );

    if (currentLevel && selectedOffering) {
      context.saveInsuranceOfferingID(productKey, selectedOffering.offering_id);
    }
  };

  return (
    <>
      {coverageOptions.length > 1 && (
        <div>
          <h3 className="font-size-lg font-weight-bold mb-4">
            Select your coverage
          </h3>
          <ButtonGroup
            aria-label="coverage-select"
            className={coverageButtonGroup}
            data-toggle="buttons"
            id={`${productKey}-coverage-toggle`}>
            {coverageOptions
              .sort(
                (a, b) => COVERAGE_SORT.indexOf(a) - COVERAGE_SORT.indexOf(b)
              )
              .map((coverage, index) => {
                switch (coverage) {
                  case CoverageType.INDIVIDUAL:
                    coverageIcon = <IndividualCoverageIcon />;
                    break;
                  case CoverageType.INDIVIDUAL_SPOUSE:
                    coverageIcon = <SpouseCoverageIcon />;
                    break;
                  case CoverageType.INDIVIDUAL_CHILDREN:
                    coverageIcon = <ChildrenCoverageIcon />;
                    break;
                  case CoverageType.FAMILY:
                    coverageIcon = <FamilyCoverageIcon />;
                    break;
                }

                return (
                  <Button
                    className={selectedCoverage === coverage ? 'active' : ''}
                    key={index}
                    onClick={() => handleToggle(coverage)}
                    variant="outline-primary">
                    <span className="icon icon-xs mr-3">{coverageIcon}</span>
                    {coverage === CoverageType.INDIVIDUAL_CHILDREN &&
                    selectedCarrier === CarrierKey.AMR
                      ? 'Individual + Child'
                      : CoverageDisplay[coverage]}
                  </Button>
                );
              })}
          </ButtonGroup>
        </div>
      )}
    </>
  );
}
