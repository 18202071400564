import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
    },
    fraudNotice: (
      <p>
        Any person who knowingly and with intent to defraud any insurance
        company or other person files a statement of claim containing any
        materially false information or conceals, for the purpose of misleading,
        information concerning any fact material thereto commits a fraudulent
        insurance act, which is a crime.
      </p>
    ),
    planFacts: {
      dental: new Map([
        ['Lite Plan In- and Out-of-Network Coinsurance', null],
        [
          'Lite Plan Coinsurance',
          <>
            Year 1 - 100/50/10
            <br />
            Year 2 - 100/80/20
          </>,
        ],
        ['Boost Plan In- and Out-of-Network Coinsurance', null],
        [
          'Boost Plan Coinsurance',
          <>
            Year 1 - 100/65/20
            <br />
            Year 2 - 100/80/50
          </>,
        ],
        ['Complete Plan In- and Out-of-Network Coinsurance', null],
        [
          'Complete Plan Coinsurance',
          <>
            Year 1 - 100/80/20
            <br />
            Year 2 - 100/90/50
          </>,
        ],
      ]),
    },
  },
  revisions: {
    '2024Q2': {
      isBaseState: {
        dental: true,
      },
      planFacts: {
        dental: new Map([
          ['Lite Plan Coinsurance', null],
          [
            'Lite Plan In- and Out-of-Network Coinsurance',
            <>
              Year 1 - 100/50/10 INN & 70/25/5 OON
              <br />
              Year 2 - 100/80/20 INN & 70/40/10 OON
            </>,
          ],
          ['Boost Plan Coinsurance', null],
          [
            'Boost Plan In- and Out-of-Network Coinsurance',
            <>
              Year 1 - 100/65/20 INN & 80/45/10 OON
              <br />
              Year 2 - 100/80/50 INN & 80/60/30 OON
            </>,
          ],
          ['Complete Plan Coinsurance', null],
          [
            'Complete Plan In- and Out-of-Network Coinsurance',
            <>
              Year 1 - 100/80/20 INN & 90/70/15 OON
              <br />
              Year 2 - 100/90/50 INN & 90/80/40 OON
            </>,
          ],
        ]),
      },
    },
  },
};

export default stateModule;
