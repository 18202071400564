import React from 'react';

import type { User } from '@liferaft/api/types';
import type {
  NetworkResult,
  NoBody,
  RequestConfiguration,
} from '@liferaft/api/utils/network';
import { NetworkController } from '@liferaft/api/utils/network';

import { createContext } from '.';
import { useAnalyticsContext } from './analytics-context';

export type UserUtility = {
  user: User;
  refresh: () => void;
};

const [useContext, Provider] = createContext<UserUtility>();

export const useUserContext = useContext;

type Props = {
  children?: React.ReactNode;
  getUser: () => RequestConfiguration<NoBody>;
};

export function UserContextProvider({ children, getUser }: Props) {
  const [user, setUser] = React.useState<User>();
  const [needsRefresh, setNeedsRefresh] = React.useState<boolean>(true);
  const analytics = useAnalyticsContext();

  React.useEffect(() => {
    if (!user) return;
    analytics.identifyUser(user);
  }, [user]);

  React.useEffect(() => {
    if (needsRefresh) {
      const network = new NetworkController();
      network.request<NoBody, User>(
        getUser(),
        (result: NetworkResult<User>) => {
          setNeedsRefresh(false);

          if (result.error) {
            throw new Error('Could not fetch user');
          }

          setUser(result.data);
        }
      );

      return () => void network.cancel();
    }
  }, [needsRefresh]);

  const refresh = (): void => {
    setNeedsRefresh(true);
    return;
  };

  if (!user) {
    return null;
  }

  return <Provider value={{ user, refresh }}>{children}</Provider>;
}
