import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    fraudNotice: (
      <p>
        Any person who knowingly and with intent to defraud any insurance
        company or other person files an application for insurance or statement
        of claim containing any materially false information or conceals for the
        purpose of misleading, information concerning any fact material thereto
        commits a fraudulent insurance act, which is a crime and subjects such
        person to criminal and civil penalties.
      </p>
    ),
  },
  revisions: {
    '2024Q2': {
      isBaseState: {
        dental: false,
      },
    },
  },
  zipcodeOverrides: [
    {
      zipcodes: [
        '15828',
        '16217',
        '16239',
        '16321',
        '16322',
        '16353',
        '16370',
        '16720',
        '16746',
        '16748',
        '16915',
        '16922',
        '16923',
        '16927',
        '16937',
        '16941',
        '16948',
        '17729',
      ] as const,
      overrides: {
        default: {
          planFacts: {
            dental: new Map([
              ['Access Plan In- and Out-of-Network Coinsurance', null],
              ['Lite Plan In- and Out-of-Network Coinsurance', null],
              ['Boost Plan In- and Out-of-Network Coinsurance', null],
              ['Complete Plan In- and Out-of-Network Coinsurance', null],
            ]),
          },
          planFactsFootnote: {
            dental: (
              <>
                Claim allowance (Indemnity): Covered benefits are paid at the
                80th percentile of usual and customary (80th U&C) charges. This
                means we expect 8 out of 10 charges from dental providers to be
                within the amount insurance pays for a covered procedure. You
                pay the difference between what the plan pays and the dentist's
                actual charge.
              </>
            ),
          },
        },
        revisions: {
          '2024Q2': {
            planFacts: {
              dental: new Map([
                ['Lite Plan In- and Out-of-Network Coinsurance', null],
                [
                  'Lite Plan Coinsurance',
                  <>
                    Year 1 - 100/50/10
                    <br />
                    Year 2 - 100/80/20
                  </>,
                ],
                ['Boost Plan In- and Out-of-Network Coinsurance', null],
                [
                  'Boost Plan Coinsurance',
                  <>
                    Year 1 - 100/65/20
                    <br />
                    Year 2 - 100/80/50
                  </>,
                ],
                ['Complete Plan In- and Out-of-Network Coinsurance', null],
                [
                  'Complete Plan Coinsurance',
                  <>
                    Year 1 - 100/80/20
                    <br />
                    Year 2 - 100/90/50
                  </>,
                ],
              ]),
            },
          },
        },
      },
    },
  ],
};

export default stateModule;
