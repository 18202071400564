import React from 'react';

type WrappedAction<ActionType> = (
  dispatch: React.Dispatch<ActionType>
) => (...args: any[]) => void;

export type WrappedActions<ActionType> = Record<
  string,
  WrappedAction<ActionType>
>;

type ContextValue<StateType, ActionType> = {
  dispatch: React.Dispatch<ActionType>;
  state: StateType;
  wrappedActions: WrappedActions<ActionType>;
};

export function createReducibleContext<StateType, ActionType>(
  reducer: React.Reducer<StateType, ActionType>,
  initialState: StateType,
  wrappedActions: WrappedActions<ActionType>
) {
  const defaultDispatch: React.Dispatch<ActionType> = () => initialState;

  const Context = React.createContext<ContextValue<StateType, ActionType>>({
    state: initialState,
    dispatch: defaultDispatch,
    wrappedActions: wrappedActions,
  });

  function Provider(props: React.PropsWithChildren<Record<string, unknown>>) {
    const [state, dispatch] = React.useReducer<
      React.Reducer<StateType, ActionType>
    >(reducer, initialState);

    return (
      <Context.Provider
        value={{ state, wrappedActions, dispatch }}
        {...props}
      />
    );
  }

  return [Context, Provider] as const;
}

export function useReducibleContext<StateType, ActionType>(
  Context: React.Context<ContextValue<StateType, ActionType>>
) {
  const { wrappedActions, dispatch, ...context } = React.useContext(Context);

  const actions = Object.entries(wrappedActions).reduce(
    (
      obj: Record<string, () => void>,
      [name, wrappedAction]: [
        name: string,
        wrappedAction: WrappedAction<ActionType>
      ]
    ) => {
      obj[name] = wrappedAction(dispatch);
      return obj;
    },
    {}
  );

  return {
    ...context,
    actions,
  };
}
