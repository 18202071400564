import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
    },
    fraudNotice: (
      <p>
        Any person who knowingly, and with intent to injure, defraud or deceive
        any insurer files a statement of claim or an application containing any
        false, incomplete, or misleading information is guilty of a felony of
        the third degree.
      </p>
    ),
    planFacts: {
      dental: new Map([
        ['Waiting Periods', null],
        ['Lite Plan In- and Out-of-Network Coinsurance', null],
        [
          'Lite Plan Coinsurance',
          <>
            Year 1 - 100/50/10
            <br />
            Year 2 - 100/80/20
          </>,
        ],
        ['Boost Plan In- and Out-of-Network Coinsurance', null],
        [
          'Boost Plan Coinsurance',
          <>
            Year 1 - 100/65/20
            <br />
            Year 2 - 100/80/50
          </>,
        ],
        ['Complete Plan In- and Out-of-Network Coinsurance', null],
        [
          'Complete Plan Coinsurance',
          <>
            Year 1 - 100/80/20
            <br />
            Year 2 - 100/90/50
          </>,
        ],
        [
          'Max Benefit',
          <p key="description">
            Maximum benefit is per person for Basic and Major services combined.
          </p>,
        ],
        [
          'Preventive Plus',
          <p key="description">
            Type 1 Preventive procedures are not deducted from the plan's annual
            maximum benefit. This saves all of the annual benefit to help pay
            for more expensive Type 2 and 3 procedures.
          </p>,
        ],
      ]),
    },
  },
};

export default stateModule;
