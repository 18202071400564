import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
    },
    stateNotice: (
      <>
        <p>
          The undersigned applicant and agent (if applicable) certify that the
          applicant has read, or had read to him, the completed application and
          that the applicant realizes that any false statement or
          misrepresentation in the application may result in loss of coverage
          under the policy.
        </p>
        <p>
          THIS IS AN EXCEPTED BENEFITS PLAN. IT PROVIDES COVERAGE ONLY FOR THE
          LIMITED BENEFITS OR SERVICES SPECIFIED IN THE POLICY.
        </p>
        <p>
          THIS IS A STAND-ALONE DENTAL PLAN THAT IS NOT EXCHANGE CERTIFIED AND
          MAY NOT PROVIDE MINIMUM ESSENTIAL PEDIATRIC DENTAL BENEFITS.
        </p>
      </>
    ),
    fraudNotice: (
      <p>
        Any person who, with intent to defraud or knowing that he is
        facilitating a fraud against insurer, submits an application or files a
        claim containing a false or deceptive statement may have violated state
        law.
      </p>
    ),
    planDescriptions: {
      dental: (
        <p>
          These Primestar® Dental Plans help you get the dental coverage you
          need at affordable rates. Your coverage begins next-day with no
          waiting periods on most dental insurance plans. There are no
          enrollment fees with any plan. Preventive visits, such as exams and
          cleanings, are covered up to 100%. Dental plans may include additional
          benefits such as LASIK, orthodontia or hearing care. A deductible may
          apply for coverage; please see Coverage Quick Facts for more details.
        </p>
      ),
    },
    planFacts: {
      dental: new Map([
        ['Waiting Periods', null],
        ['Value Plan', null],
        ['Lite Plan In- and Out-of-Network Coinsurance', null],
        ['Lite Plan', null],
        ['Boost Plan In- and Out-of-Network Coinsurance', null],
        ['Boost Plan', null],
        ['Complete Plan In- and Out-of-Network Coinsurance', null],
        ['Complete Plan', null],
        [
          'Value Plan Coinsurance',
          <>
            Year 1 - 90/50/0
            <br />
            Year 2 - 100/85/15
          </>,
        ],
        [
          'Access Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/65/20 INN & 80/45/10 OON
            <br />
            Year 2 - 100/80/50 INN & 80/60/30 OON
          </>,
        ],
        [
          'Access Plan',
          <p key="description">
            Type 1: Exams, Cleanings, Fluoride (under age 16), Sealants, Space
            Maintainers, Bitewing X-rays
            <br />
            Type 2: Fillings, Simple Extractions
            <br />
            Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
            Crowns, Bridges, Dentures, Implants, Teeth Whitening
          </p>,
        ],
        [
          'Total Plan Coinsurance',
          <>
            Year 1 - 100/80/20
            <br />
            Year 2 - 100/90/50
          </>,
        ],
        [
          'Total Plan',
          <p key="description">
            Type 1: Exams, Cleanings, Bitewing X-rays
            <br />
            Type 2: Fillings, Simple Extractions
            <br />
            Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
            Crowns, Bridges, Dentures, Implants
          </p>,
        ],
        [
          'Preventive Plus',
          <p key="description">
            Type 1 Preventive procedures are not deducted from the plan's annual
            maximum benefit. This saves all of the annual benefit to help pay
            for more expensive Type 2 and 3 procedures.
          </p>,
        ],
      ]),
    },
  },
  revisions: {
    '2024Q2': {
      planFacts: {
        dental: new Map([['Value Plan Coinsurance', null]]),
      },
    },
  },
};

export default stateModule;
