import * as React from 'react';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';

import { ReactComponent as NavigationCloseIcon } from '../assets/images/icons/duotone-icons/Navigation/Close.svg';

type ModalProps = {
  onHide?: () => void;
  show: boolean;
  children: React.ReactNode;
  modalTitle?: string;
  footer?: React.ReactNode;
};

export function Modal({
  onHide,
  show,
  modalTitle,
  children,
  footer,
}: ModalProps) {
  return (
    <BootstrapModal
      centered
      data-testid="modal-wrapper"
      onHide={onHide}
      show={show}
      size="lg">
      <BootstrapModal.Body className="my-0" data-testid="modal-body">
        {onHide && (
          <Button
            aria-label="close"
            className="float-right modal__close icon icon-sm text-primary"
            data-dismiss="modal"
            data-testid="modal-close"
            onClick={onHide}
            type="button"
            variant="empty">
            <NavigationCloseIcon viewBox="0 0 24 24" />
          </Button>
        )}
        {modalTitle && (
          <h3 className="mb-6 font-weight-bold" data-testid="modal-title">
            {modalTitle}
          </h3>
        )}
        {children}
      </BootstrapModal.Body>
      {footer && <BootstrapModal.Footer>{footer}</BootstrapModal.Footer>}
    </BootstrapModal>
  );
}
