import React from 'react';
import { Button, Image } from 'react-bootstrap';

import type { ProductKey } from '@liferaft/api/types';
import { InsuranceProductKey } from '@liferaft/api/types';

import { ReactComponent as InfoIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Custom/info.svg';
import { activeAmeritasRevision } from '@liferaft/core/content/products/ameritas/current';

import ameritasLogo from '@/assets/images/Ameritas.png';
import ameritasNYLogo from '@/assets/images/AmeritasNewYork.png';
import { useApplicationFlow } from '@/components';
import { useProductMatrixContext } from '@/contexts';
import { FactsModal } from '@/pages/product-matrix';
import type { PlanFact } from '@/pages/product-matrix/content';
import {
  getPlanDescription,
  getPlanFacts,
  getPlanFactsFootnote,
} from '@/pages/product-matrix/content';

import './plan-info.scss';

type Props = {
  productKey: ProductKey;
  planName: string;
};

export function PlanInfo({ productKey, planName }: Props) {
  const [showPlanFactsModal, setShowPlanFactsModal] = React.useState(false);

  const {
    data: { egress },
  } = useApplicationFlow();
  const context = useProductMatrixContext();

  const ameritasRevision = activeAmeritasRevision();
  const state = context.residentState;
  const zipCode = egress?.zip_code as string;
  const selectedCarrier = context.config[productKey].carrierSelected;

  let description;
  let facts: PlanFact[] = [];
  let footnote;
  if (selectedCarrier && state) {
    if (planName == 'Cancer-Only' || planName == 'Cancer') {
      description = getPlanDescription(
        'critical-illness-cancer',
        selectedCarrier,
        state
      );

      facts = getPlanFacts(
        'critical-illness-cancer',
        selectedCarrier,
        state,
        zipCode,
        ameritasRevision
      );

      footnote = getPlanFactsFootnote(
        'critical-illness-cancer',
        selectedCarrier,
        state,
        zipCode,
        ameritasRevision
      );
    } else {
      description = getPlanDescription(productKey, selectedCarrier, state);
      facts = getPlanFacts(
        productKey,
        selectedCarrier,
        state,
        zipCode,
        ameritasRevision
      );
      footnote = getPlanFactsFootnote(
        productKey,
        selectedCarrier,
        state,
        zipCode,
        ameritasRevision
      );
    }
  }

  const handleShowPlanFactsModal = () => {
    setShowPlanFactsModal(true);
  };

  return (
    <>
      <div className="d-flex flex-wrap w-100 align-items-center mb-3">
        <div className="d-flex align-items-center mr-auto">
          <h3 className="font-weight-bold mb-md-0 mb-5 pr-3">{planName}</h3>
        </div>
        {facts.length > 0 && (
          <Button
            className="text-nowrap mb-md-0 mb-3"
            onClick={handleShowPlanFactsModal}
            size="sm"
            variant="outline-black">
            <span className="icon icon-xs">
              <InfoIcon />
            </span>
            {' Coverage Quick Facts'}
          </Button>
        )}
      </div>
      <div className="d-flex flex-wrap flex-sm-nowrap w-100 mb-3">
        {description && <div className="w-max-800 mr-6">{description}</div>}
        {(productKey === InsuranceProductKey.DENTAL ||
          productKey === InsuranceProductKey.VISION) && (
          <div className="ml-md-auto">
            <Image
              alt="Ameritas logo"
              className="mr-6 mt-sm-6 mb-6 mb-sm-0"
              src={state === 'NY' ? ameritasNYLogo : ameritasLogo}
              width="200"
            />
          </div>
        )}
      </div>
      <FactsModal
        facts={facts}
        footnote={footnote}
        onClose={() => setShowPlanFactsModal(false)}
        show={showPlanFactsModal}
      />
    </>
  );
}
