import type { User } from '../types';
import { DjangoRequestConfiguration } from '../utils/django-utils';
import type { RequestConfiguration } from '../utils/network';
import type { NoBody } from '../utils/network';

export function createUser(
  body: Partial<User>
): DjangoRequestConfiguration<Partial<User>> {
  return new DjangoRequestConfiguration<Partial<User>>()
    .post()
    .body(body)
    .url('/api/users/');
}

export function updateUser(
  body: Partial<User>
): DjangoRequestConfiguration<Partial<User>> {
  return new DjangoRequestConfiguration<Partial<User>>()
    .patch()
    .body(body)
    .url(`/api/user/`);
}

export function getProductEligibility(
  userId: string
): RequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/users/${userId}/product-eligibility/`);
}

export function getUser(): RequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>().get().url('/api/user/');
}

export function getUserProductSummary(
  userId: string
): RequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/users/${userId}/product-summary/`);
}

export function getUserProfile(userId: string): RequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/users/${userId}/profile/`);
}
