import React from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';

import { updateApplication } from '@liferaft/api/resources/application';
import type { Application, Egress } from '@liferaft/api/types';
import { UnderwritingLevels } from '@liferaft/api/types';
import type { ParsedDjangoErrorResponse } from '@liferaft/api/utils/django-utils';
import { NON_FIELD_ERROR_LABEL } from '@liferaft/api/utils/django-utils';
import { NetworkController } from '@liferaft/api/utils/network';

import { Card } from '@liferaft/core/components';
import type {
  DateOfBirthData,
  FormErrorsSetter,
} from '@liferaft/core/components/forms';
import {
  DateOfBirthInput,
  SelectInput,
  Sex,
  SexInput,
  StateInput,
  ValidatedForm,
  ZipCodeInput,
} from '@liferaft/core/components/forms';
import { useDebounce } from '@liferaft/core/hooks';
import * as dates from '@liferaft/core/utils/dates';

import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';
import { SUPPORT_EMAIL } from '@/environment';

const schema = yup.object({
  ca_has_current_coverage: yup
    .boolean()
    .label('Current coverage in CA question'),
  date_of_birth: yup
    .string()
    .transform(dates.toString)
    .required()
    .label('Date of birth'),
  doctor_visits: yup
    .mixed<UnderwritingLevels>()
    .oneOf(Object.values(UnderwritingLevels))
    .required()
    .label('Number of doctor visits per year'),
  exercise: yup
    .mixed<UnderwritingLevels>()
    .oneOf(Object.values(UnderwritingLevels))
    .required()
    .label('Amount of physical activity per week'),
  is_tobacco_user: yup.boolean().required().label('Tobacco usage'),
  selected_sex: yup
    .mixed<Sex>()
    .oneOf(Object.values(Sex))
    .required()
    .label('Sex'),
  state: yup.string().required().label('State of residence'),
  zip_code: yup.string().required().label('Zip code'),
});

type FormData = yup.InferType<typeof schema>;

export function RatingData() {
  const {
    data: { egress, id },
    next,
  } = useApplicationFlow();

  const [state, setState] = React.useState<string>(egress?.state || '');
  const [zipCode, setZipCode] = React.useState<string>(egress?.zip_code || '');
  const [sex, setSex] = React.useState<Sex | undefined>(egress?.selected_sex);
  const [dateOfBirth, setDateOfBirth] = React.useState<
    DateOfBirthData | undefined
  >(egress?.date_of_birth ? dates.fromString(egress.date_of_birth) : undefined);

  const [caHasCurrentCoverage, setCAHasCurrentCoverage] = React.useState<
    boolean | undefined
  >(egress?.ca_has_current_coverage);

  const [isTobaccoUser, setIsTobaccoUser] = React.useState<boolean | undefined>(
    egress?.is_tobacco_user
  );
  const [exercise, setExercise] = React.useState<
    UnderwritingLevels | undefined
  >(egress?.exercise);
  const [doctorVisits, setDoctorVisits] = React.useState<
    UnderwritingLevels | undefined
  >(egress?.doctor_visits);

  const [handleSubmit, isSubmitting] = useDebounce(
    async (validatedData: FormData, setErrors: FormErrorsSetter<FormData>) => {
      const network = new NetworkController();

      const result = await network.request<FormData, Application>(
        updateApplication(id as string, validatedData)
      );

      if (result.error) {
        setErrors(result.data as ParsedDjangoErrorResponse<Egress>);
        return;
      }

      return next();
    }
  );

  const handleStateChange = (state: string) => {
    if (state !== 'CA') {
      setCAHasCurrentCoverage(undefined);
    }

    setState(state);
  };

  const handleExerciseChange = (level: string) => {
    setExercise(level as UnderwritingLevels);
  };

  const handleDoctorVisitChange = (level: string) => {
    setDoctorVisits(level as UnderwritingLevels);
  };

  const underwritingOptions = {
    '0': UnderwritingLevels.ZERO,
    '1': UnderwritingLevels.ONE,
    '2': UnderwritingLevels.TWO,
    '3': UnderwritingLevels.THREE,
    '4': UnderwritingLevels.FOUR,
    '5': UnderwritingLevels.FIVE,
    '6+': UnderwritingLevels.MORE,
  };

  return (
    <ApplyWrapper
      footer={
        <ApplyWrapperFooter
          footnote={
            <>
              Coverage is not available in all states. Don't see your state?{' '}
              <a href={`mailto:${SUPPORT_EMAIL}`}>Contact us</a> to let us know.
            </>
          }
          rightDisabled={isSubmitting}
          submitLabel="View Plans & Pricing"
        />
      }
      heading="Tell Us About Yourself"
      subheading="See what options we have available for you.">
      <ValidatedForm<FormData>
        controlledData={{
          ca_has_current_coverage: caHasCurrentCoverage,
          date_of_birth: dateOfBirth,
          doctor_visits: doctorVisits,
          exercise,
          is_tobacco_user: isTobaccoUser,
          selected_sex: sex,
          state,
          zip_code: zipCode,
        }}
        id={APPLY_FORM_ID}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        {({ errors }) => (
          <Card cardClasses="card-bleed shadow-light">
            {errors[NON_FIELD_ERROR_LABEL] && (
              <Alert
                className="mb-3"
                data-testid="top-level-error"
                variant="danger">
                <ul>
                  {errors[NON_FIELD_ERROR_LABEL].map(
                    (err: string, i: number) => (
                      <li key={i}>{err}</li>
                    )
                  )}
                </ul>
              </Alert>
            )}
            <DateOfBirthInput
              error={errors.date_of_birth}
              groupClasses="form-group"
              label="What is your date of birth?"
              maxAge={120}
              minAge={18}
              onChange={setDateOfBirth}
              value={dateOfBirth}
            />
            <Row className="d-flex">
              <Col sm={6} xs={12}>
                <StateInput
                  error={errors.state}
                  groupClasses="form-group"
                  label="In which state do you live?"
                  onChange={handleStateChange}
                  required
                  value={state}
                />
              </Col>
              <Col sm={6} xs={12}>
                <ZipCodeInput
                  error={errors.zip_code}
                  groupClasses="form-group"
                  label="What is your zip code?"
                  onChange={setZipCode}
                  required
                  value={zipCode}
                />
              </Col>
            </Row>
            {state === 'CA' && (
              <Form.Group className="form-group">
                <Form.Label id="ca-has-current-coverage-prompt">
                  Are the proposed insureds currently covered by an individual
                  or group policy that provides non-supplemental medical,
                  hospital and surgical coverage?
                </Form.Label>
                <Form.Check
                  aria-describedby="ca-has-current-coverage-help"
                  aria-labelledby="ca-has-current-coverage-prompt"
                  checked={caHasCurrentCoverage == true}
                  data-testid="ca-has-current-coverage-yes"
                  id="ca-has-current-coverage-yes"
                  isInvalid={errors.ca_has_current_coverage}
                  label="Yes"
                  name="ca-has-current-coverage"
                  onChange={() => setCAHasCurrentCoverage(true)}
                  required
                  type="radio"
                  value="yes"
                />
                <Form.Check
                  aria-describedby="has-ca-current-coverage-help"
                  aria-labelledby="has-ca-current-coverage-prompt"
                  checked={caHasCurrentCoverage == false}
                  data-testid="ca-has-current-coverage-no"
                  id="ca-has-current-coverage-no"
                  isInvalid={errors.ca_has_current_coverage}
                  label="No"
                  name="ca-has-current-coverage"
                  onChange={() => setCAHasCurrentCoverage(false)}
                  required
                  type="radio"
                  value="no"
                />
                <Form.Text id="ca-has-current-coverage-help" muted>
                  Health coverage question for California residents.
                </Form.Text>
                {errors.ca_has_current_coverage && (
                  <div
                    className="invalid-feedback d-block"
                    data-testid="current-coverage-error">
                    {errors.ca_has_current_coverage}
                  </div>
                )}
              </Form.Group>
            )}
            <Form.Group className="form-group">
              <Form.Label id="is-tobacco-user-prompt">
                Have you smoked cigarettes or used tobacco in any form within
                the last 12 months? (Tobacco use includes smoking, vaping,
                dipping, chewing or nicotine patches.)
              </Form.Label>
              <Form.Check
                aria-describedby="is-tobacco-user-help"
                aria-labelledby="is-tobacco-user-prompt"
                checked={isTobaccoUser == true}
                data-testid="is-tobacco-user-yes"
                id="is-tobacco-user-yes"
                isInvalid={errors.is_tobacco_user}
                label="Yes"
                name="is-tobacco-user"
                onChange={() => setIsTobaccoUser(true)}
                required
                type="radio"
                value="yes"
              />
              <Form.Check
                aria-describedby="is-tobacco-user-help"
                aria-labelledby="is-tobacco-user-prompt"
                checked={isTobaccoUser == false}
                data-testid="is-tobacco-user-no"
                id="is-tobacco-user-no"
                isInvalid={errors.is_tobacco_user}
                label="No"
                name="is-tobacco-user"
                onChange={() => setIsTobaccoUser(false)}
                required
                type="radio"
                value="no"
              />
              <Form.Text id="is-tobacco-user-help" muted>
                Smoking status is required for some insurance applications.
              </Form.Text>
              {errors.is_tobacco_user && (
                <div
                  className="invalid-feedback d-block"
                  data-testid="tobacco-error">
                  {errors.is_tobacco_user}
                </div>
              )}
            </Form.Group>
            <SexInput
              error={errors.selected_sex}
              groupClasses="form-group"
              help="Please select the sex you most commonly use for government forms."
              label="Sex"
              onChange={setSex}
              value={sex}
            />
            <SelectInput
              error={errors.exercise}
              groupClasses="form-group"
              help="Physical activity level enables us to serve you better and does not impact eligibility or cost of coverage."
              id="exercise"
              label="How many times per week do you do moderate or vigorous physical activity?"
              onChange={handleExerciseChange}
              options={underwritingOptions}
              required
              value={exercise}
            />
            <SelectInput
              error={errors.doctor_visits}
              groupClasses="form-group"
              help="Doctor visit frequency enables us to serve you better and does not impact eligibility or cost of coverage."
              id="doctor-visit"
              label="How many times have you seen your doctor in their office in the last year?"
              onChange={handleDoctorVisitChange}
              options={underwritingOptions}
              required
              value={doctorVisits}
            />
          </Card>
        )}
      </ValidatedForm>
    </ApplyWrapper>
  );
}
