import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import type { DateData } from '../../utils/dates';
import { Months, febDays } from '../../utils/dates';

export type DateOfBirthData = Partial<DateData>;

type Props = {
  disabled?: boolean;
  label: string;
  onChange: (value: DateOfBirthData) => void;
  error?: string;
  groupClasses?: string;
  labelClasses?: string;
  selectClasses?: string;
  colClasses?: {
    day?: string;
    month?: string;
    year?: string;
  };
  minAge?: number;
  maxAge?: number;
  required?: boolean;
  value?: DateOfBirthData;
};

export function DateOfBirthInput({
  disabled = false,
  error,
  label,
  groupClasses,
  labelClasses = '',
  selectClasses = '',
  colClasses = {
    day: '',
    month: '',
    year: '',
  },
  minAge,
  maxAge,
  value,
  onChange,
  required = true,
}: Props) {
  const today = new Date();
  const thisYear = Number(today.getFullYear());
  const minYear = maxAge ? thisYear - maxAge : 1920;
  const maxYear = minAge ? thisYear - minAge : thisYear;
  if (value?.year) Months[2].numDays = febDays(value.year);
  const maxDays = value?.month ? Months[value?.month].numDays : 31;
  const days = Array.from({ length: maxDays }, (_, i) => i + 1);

  const years = Array.from(
    { length: maxYear - minYear + 1 },
    (_, i) => maxYear - i
  );

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange({ ...value, month: Number(event.target.value) });
  };

  const handleDayChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange({ ...value, day: Number(event.target.value) });
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange({ ...value, year: Number(event.target.value) });
  };

  return (
    <Form.Group className={groupClasses}>
      {label && (
        <Form.Label className={labelClasses} id="dob-prompt">
          {label}
        </Form.Label>
      )}
      <Row>
        <Col className={colClasses?.month} xs={4}>
          <Form.Select
            aria-labelledby="dob-prompt"
            className={'form-control ' + selectClasses}
            data-testid="date-of-birth-month"
            defaultValue=""
            disabled={disabled}
            id="date-of-birth-month"
            isInvalid={!!error}
            name="dateOfBirthMonth"
            onChange={handleMonthChange}
            required={required}
            value={value?.month}>
            <option disabled value="">
              Month
            </option>
            {Object.keys(Months).map((month) => (
              <option key={month} value={month}>
                {Months[+month].name}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col className={colClasses?.day} xs={4}>
          <Form.Select
            aria-labelledby="dob-prompt"
            className={'form-control ' + selectClasses}
            data-testid="date-of-birth-day"
            defaultValue=""
            disabled={disabled}
            id="date-of-birth-day"
            isInvalid={!!error}
            name="dateOfBirthDay"
            onChange={handleDayChange}
            required={required}
            value={value?.day}>
            <option disabled value="">
              Day
            </option>
            {days.map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col className={colClasses?.year} xs={4}>
          <Form.Select
            aria-labelledby="dob-prompt"
            className={'form-control ' + selectClasses}
            data-testid="date-of-birth-year"
            defaultValue=""
            disabled={disabled}
            id="date-of-birth-year"
            isInvalid={!!error}
            name="dateOfBirthYear"
            onChange={handleYearChange}
            required={required}
            value={value?.year}>
            <option disabled value="">
              Year
            </option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      {error && (
        <Row>
          <Col>
            <div
              className="invalid-feedback d-block"
              data-testid="date-of-birth-error">
              {error}
            </div>
          </Col>
        </Row>
      )}
    </Form.Group>
  );
}
