import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
    },
    fraudNotice: (
      <p>
        Any person who with a purpose to injure, defraud, or deceive any
        insurance company, files a statement of claim containing any false,
        incomplete, or misleading information is subject to prosecution and
        punishment for insurance fraud, as provided by RSA 638.20
      </p>
    ),
  },
};

export default stateModule;
