import React from 'react';
import { Alert, Button } from 'react-bootstrap';

import type { BannerContent, BannerUtility } from '../contexts';
import './banner.scss';

type BannerGroupProps = {
  bannerUtil?: BannerUtility;
};

export function BannerGroup({ bannerUtil }: BannerGroupProps) {
  return (
    <>
      {bannerUtil?.bannerList.map((banner: BannerContent) => {
        return (
          <Banner
            isDismissible={banner.isDismissible}
            key={banner.id}
            mode={banner.mode}
            onDismiss={() => bannerUtil?.delete(banner.id)}
            title={banner.title}
          />
        );
      })}
    </>
  );
}

type BannerProps = {
  title: string;
  mode?: 'primary' | 'secondary' | 'info' | 'success' | 'warning';
  isDismissible?: boolean;
  onDismiss?: () => void;
  alertClasses?: string[];
};

export function Banner({
  title,
  mode,
  alertClasses,
  isDismissible,
  onDismiss,
}: BannerProps) {
  const classes: string[] = ['mb-0 d-flex justify-content-between no-radius'];

  if (alertClasses) classes.push(...alertClasses);

  return (
    <Alert className={classes.join(' ')} role="alert" variant={mode}>
      {title}
      {isDismissible && (
        <Button
          aria-label="close"
          data-dismiss="alert"
          onClick={onDismiss}
          variant="empty">
          ×
        </Button>
      )}
    </Alert>
  );
}
