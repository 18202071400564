import React from 'react';
import { Form } from 'react-bootstrap';

export enum Sex {
  MALE = 'male',
  FEMALE = 'female',
}

type Props = {
  error?: string;
  label?: string;
  radioPrefix?: string;
  groupClasses?: string;
  labelClasses?: string;
  help?: string;
  required?: boolean;
  value?: string;
  onChange: (value: Sex) => void;
  inline?: boolean;
};

export function SexInput({
  error,
  label,
  radioPrefix = 'sex',
  groupClasses,
  labelClasses = '',
  help,
  required = true,
  value,
  onChange,
  inline = false,
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as Sex);
  };

  return (
    <Form.Group className={groupClasses} data-testid="sex-form-group">
      {label && (
        <Form.Label className={labelClasses} id={`${radioPrefix}-prompt`}>
          {label}
        </Form.Label>
      )}
      <Form.Check
        aria-describedby={`${radioPrefix}-help`}
        aria-labelledby={`${radioPrefix}-prompt`}
        checked={value == Sex.MALE}
        data-testid={`${radioPrefix}-male`}
        id={`${radioPrefix}-male`}
        inline={inline}
        isInvalid={!!error}
        label="Male"
        name={radioPrefix}
        onChange={handleChange}
        required={required}
        type="radio"
        value={Sex.MALE}
      />
      <Form.Check
        aria-describedby={`${radioPrefix}-help`}
        aria-labelledby={`${radioPrefix}-prompt`}
        checked={value == Sex.FEMALE}
        data-testid={`${radioPrefix}-female`}
        id={`${radioPrefix}-female`}
        inline={inline}
        isInvalid={!!error}
        label="Female"
        name={radioPrefix}
        onChange={handleChange}
        required={required}
        type="radio"
        value={Sex.FEMALE}
      />
      {help && (
        <Form.Text id="sex-help" muted>
          {help}
        </Form.Text>
      )}
      {error && (
        <div className="invalid-feedback d-block" data-testid="sex-error">
          {error}
        </div>
      )}
    </Form.Group>
  );
}
