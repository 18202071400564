import type {
  PlaidLinkAccount,
  PlaidLinkAccountStatus,
  PlaidLinkToken,
} from '../types';
import { DjangoRequestConfiguration } from '../utils/django-utils';
import type { NoBody } from '../utils/network';
import type { RequestConfiguration } from '../utils/network';

export type CreatePlaidLinkAccountParams = {
  account_id: string;
  account_type: string;
  institution?: string;
  public_token: string;
  status?: PlaidLinkAccountStatus;
};

export function createPlaidLinkAccount(
  accountParams: CreatePlaidLinkAccountParams
): DjangoRequestConfiguration<CreatePlaidLinkAccountParams> {
  return new DjangoRequestConfiguration<CreatePlaidLinkAccountParams>()
    .post()
    .body(accountParams)
    .url('/api/plaid/link/account/');
}

type PlaidLinkTokenParams = {
  access_token?: string;
  redirect_uri?: string;
};

export function createPlaidLinkToken(
  access_token?: string,
  redirectURI?: string
): DjangoRequestConfiguration<PlaidLinkTokenParams, PlaidLinkToken> {
  return new DjangoRequestConfiguration<PlaidLinkTokenParams, PlaidLinkToken>()
    .post()
    .body({
      access_token: access_token,
      redirect_uri: redirectURI,
    })
    .url('/api/plaid/link/token/');
}

export type CreatePlaidProcessorTokenParams = {
  access_token: string;
  account_id: string;
  processor: 'moov' | 'unit';
};

export function createPlaidProcessorToken(
  tokenParams: CreatePlaidProcessorTokenParams
): DjangoRequestConfiguration<CreatePlaidProcessorTokenParams> {
  return new DjangoRequestConfiguration<CreatePlaidProcessorTokenParams>()
    .post()
    .body(tokenParams)
    .url('/api/plaid/processor-token/');
}

export function getPlaidLinkAccount(
  plaidLinkAccountId: string
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/plaid/link/account/${plaidLinkAccountId}/`);
}

export function updatePlaidLinkAccount(
  plaidLinkAccountId: string,
  account: Partial<PlaidLinkAccount>
): RequestConfiguration<Partial<PlaidLinkAccount>> {
  return new DjangoRequestConfiguration<Partial<PlaidLinkAccount>>()
    .patch()
    .body(account)
    .url(`/api/plaid/link/account/${plaidLinkAccountId}/`);
}
