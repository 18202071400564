import React from 'react';
import { Form } from 'react-bootstrap';

type Props = {
  required?: boolean;
  error?: string;
  help?: string;
  groupClasses?: string;
  label?: string;
  labelClasses?: string;
  onChange: (value: string) => void;
  value?: string;
};

export function PasswordInput({
  required = false,
  error,
  groupClasses,
  help,
  label,
  labelClasses = '',
  onChange,
  value,
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Form.Group className={groupClasses} data-testid="password-form-group">
      {label && <Form.Label className={labelClasses}>{label}</Form.Label>}
      <Form.Control
        autoComplete="new-password"
        data-testid="password-input"
        id="password"
        isInvalid={!!error}
        name="password"
        onChange={handleChange}
        placeholder="Enter your password"
        required={required}
        type="password"
        value={value}
      />
      {help && (
        <Form.Text id="password-help" muted>
          {help}
        </Form.Text>
      )}
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
}
