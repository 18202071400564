import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { createContext } from '.';

type BannerMode = 'primary' | 'secondary' | 'info' | 'success' | 'warning';

export type BannerContent = {
  id: string;
  title: string;
  mode: BannerMode;
  isDismissible: boolean;
};

export type BannerUtility = {
  add: (
    title: string,
    mode?: 'primary' | 'secondary' | 'info' | 'success' | 'warning',
    isDismissible?: boolean
  ) => void;
  delete: (bannerId: string) => void;
  bannerList: BannerContent[];
};

const [useContext, Provider] = createContext<BannerUtility>();
export const useBannerContext = useContext;

type Props = {
  children?: React.ReactNode;
};

export function BannerContextProvider({ children }: Props) {
  const [banners, setBanners] = React.useState<BannerContent[]>([]);

  const addBanner = React.useCallback(
    (
      title: string,
      mode: 'primary' | 'secondary' | 'info' | 'success' | 'warning' = 'info',
      isDismissible = true
    ): void => {
      const newBanner = {
        id: uuidv4(),
        title,
        mode,
        isDismissible,
      };

      setBanners((prevBanners) => [...prevBanners, newBanner]);
    },
    [banners, setBanners]
  );

  const deleteBanner = React.useCallback(
    (bannerId: string): void => {
      if (banners) {
        const currentBanners = banners.filter(function (banner: BannerContent) {
          return banner.id !== bannerId;
        });
        setBanners(currentBanners);
      }
    },
    [banners, setBanners]
  );

  const utility: BannerUtility = {
    add: addBanner,
    delete: deleteBanner,
    bannerList: banners,
  };

  return <Provider value={utility}>{children}</Provider>;
}
