import type { ProductKey } from '@liferaft/api/types';
import {
  CarrierKey,
  GroupBenefitType,
  InsuranceProductKey,
} from '@liferaft/api/types';

const CARRIER_SORT = [
  CarrierKey.AIG,
  CarrierKey.LIB,
  CarrierKey.AMR,
  CarrierKey.LR,
];

export function carrierSort(c1: CarrierKey, c2: CarrierKey): number {
  return CARRIER_SORT.indexOf(c1) - CARRIER_SORT.indexOf(c2);
}

const PRODUCT_SORT = [
  InsuranceProductKey.ACCIDENT,
  InsuranceProductKey.CRITICAL,
  InsuranceProductKey.HOSPITAL,
  InsuranceProductKey.DENTAL,
  InsuranceProductKey.VISION,
];

// sort available products by specified order or with broker's preferred product first
export function productSort(
  p1: ProductKey,
  p2: ProductKey,
  preferredProduct?: string
): number {
  if (p1 === preferredProduct) {
    return PRODUCT_SORT.length * -1;
  } else if (p2 === preferredProduct) {
    return PRODUCT_SORT.length;
  } else {
    return PRODUCT_SORT.indexOf(p1) - PRODUCT_SORT.indexOf(p2);
  }
}

const PLAN_TYPE_SORT = ['Cancer-Only', 'Critical Illness', ''];

export function planTypeSort(p1: string, p2: string): number {
  return PLAN_TYPE_SORT.indexOf(p1) - PLAN_TYPE_SORT.indexOf(p2);
}

const GROUP_BENEFIT_SORT = [
  GroupBenefitType.LEVEL_FUNDED_MEC,
  GroupBenefitType.GROUP_HRA,
  GroupBenefitType.QSEHRA,
  GroupBenefitType.ICHRA,
  GroupBenefitType.SUPPLEMENTAL,
];

export function groupBenefitSort(
  b1: GroupBenefitType,
  b2: GroupBenefitType
): number {
  return GROUP_BENEFIT_SORT.indexOf(b1) - GROUP_BENEFIT_SORT.indexOf(b2);
}
