import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
    },
    fraudNotice: (
      <p>
        Any person who knowingly presents a false or fraudulent claim for
        payment of a loss or benefit or knowingly presents false information in
        an application for insurance is guilty of a crime and may be subject to
        fines and confinement in prison.
      </p>
    ),
    dentalLimitations: (
      <>
        <p>
          Covered expenses will not include and benefits will not be payable for
          expenses incurred:
        </p>
        <ul>
          <li>
            for any treatment which is for cosmetic purposes, except as
            specifically listed in the Table of Dental Procedures.
          </li>
          <li>
            to replace any crowns, inlays, onlays, veneers, complete or partial
            dentures within five years of the date of the last placement of
            these items. But if a replacement is required because of an
            accidental bodily injury sustained while the Insured person is
            covered under this contract, it will be a covered expense.
          </li>
          <li>
            for any procedure begun before the insured person was covered under
            the policy.
          </li>
          <li>
            for any procedure begun after the insured person’s insurance under
            the policy terminates; or for any prosthetic dental appliances
            installed or delivered more than 90 days after the insured’s
            insurance under the policy terminates.
          </li>
          <li>to replace lost or stolen appliances.</li>
          <li>
            for appliances, restorations, or procedures to:
            <ul>
              <li>alter vertical dimension;</li>
              <li>restore or maintain occlusion; or</li>
              <li>
                splint or replace tooth structure lost as a result of abrasion
                or attrition.
              </li>
            </ul>
          </li>
          <li>
            for any procedure which is not shown on the Table of Dental
            Procedures. (There may be additional frequencies and limitations
            that apply, please see the Table of Dental Procedures in the
            policy.)
          </li>
          <li>
            for which the insured person is entitled to benefits under any
            workmen’s compensation or similar law, or charges for services or
            supplies received as a result of any dental condition caused or
            contributed to by an injury or sickness arising out of or in the
            course of any employment for wage or profit.
          </li>
          <li>
            for charges for which the insured person is not liable or which
            would not have been made had no insurance been in force.
          </li>
          <li>
            for services which are not required for necessary care and treatment
            or are not within the generally accepted parameters of care.
          </li>
          <li>because of war or any act of war, declared or not.</li>
          <li>
            if two or more procedures are considered adequate and appropriate
            treatment to correct a certain condition under generally accepted
            standards of dental care, the amount of the covered expense will be
            equal to the charge for the least expensive procedure.
          </li>
        </ul>
      </>
    ),
    planFacts: {
      dental: new Map([
        ['Lite Plan In- and Out-of-Network Coinsurance', null],
        [
          'Lite Plan Coinsurance',
          <>
            Year 1 - 100/50/10
            <br />
            Year 2 - 100/80/20
          </>,
        ],
        ['Boost Plan In- and Out-of-Network Coinsurance', null],
        [
          'Boost Plan Coinsurance',
          <>
            Year 1 - 100/65/20
            <br />
            Year 2 - 100/80/50
          </>,
        ],
        ['Complete Plan In- and Out-of-Network Coinsurance', null],
        [
          'Complete Plan Coinsurance',
          <>
            Year 1 - 100/80/20
            <br />
            Year 2 - 100/90/50
          </>,
        ],
      ]),
    },
    planFactsFootnote: {
      dental: (
        <>
          Claim allowance (PPO): If you visit a network provider, payments are
          based on the dentist's contracted fees (MAC/maximum allowable charge),
          which may result in lower out-of-pocket costs. If you visit an
          out-of-network dentist, covered benefits are paid at the 80th
          percentile of usual and customary (80th U&C) charges. This means we
          expect 8 out of 10 charges from dental providers to be within the
          amount we'll pay for a covered procedure. You pay the difference
          between what the plan pays and the dentist's actual charge.{' '}
          <a
            href="https://dentalnetwork.ameritas.com/classic"
            rel="noreferrer"
            target="_blank">
            Find a Classic (PPO) network provider near you.
          </a>
        </>
      ),
    },
  },
  revisions: {
    '2024Q2': {
      planFacts: {
        dental: new Map([
          ['Lite Plan In- and Out-of-Network Coinsurance', null],
          [
            'Lite Plan Coinsurance',
            <>
              Year 1 - 100/50/10
              <br />
              Year 2 - 100/80/20
            </>,
          ],
          ['Boost Plan In- and Out-of-Network Coinsurance', null],
          [
            'Boost Plan Coinsurance',
            <>
              Year 1 - 100/65/20
              <br />
              Year 2 - 100/80/50
            </>,
          ],
          ['Complete Plan In- and Out-of-Network Coinsurance', null],
          [
            'Complete Plan Coinsurance',
            <>
              Year 1 - 100/80/20
              <br />
              Year 2 - 100/90/50
            </>,
          ],
        ]),
      },
    },
  },
};

export default stateModule;
