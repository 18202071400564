import React from 'react';

import { getAuthToken, verifyAuthToken } from '@liferaft/api/resources/auth';
import type {
  NetworkController,
  NetworkResult,
} from '@liferaft/api/utils/network';

import { AuthenticationBoundary } from './authentication-boundary';

type Props = {
  loadingApp: React.ReactNode;
  pollingIntervalMs?: number;
  protectedApp: React.ReactNode;
  publicApp: React.ReactNode;
};

export function TokenAuthenticationBoundary({
  loadingApp,
  pollingIntervalMs,
  protectedApp,
  publicApp,
}: Props) {
  const handleAuthentication = ({
    onSuccess,
    onFailure,
    network,
  }: {
    onSuccess: () => void;
    onFailure: () => void;
    network: NetworkController;
  }) => {
    network.request(verifyAuthToken(), (verifyResult: NetworkResult) => {
      if (verifyResult.error) {
        network.request(getAuthToken(), (getTokenResult: NetworkResult) => {
          if (getTokenResult.error) {
            onFailure();
          } else {
            onSuccess();
          }
        });
      } else {
        onSuccess();
      }
    });
  };

  return (
    <AuthenticationBoundary
      handleAuthentication={handleAuthentication}
      loadingApp={loadingApp}
      pollingIntervalMs={pollingIntervalMs}
      protectedApp={protectedApp}
      publicApp={publicApp}
    />
  );
}
