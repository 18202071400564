import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { getUser } from '@liferaft/api/resources/user';

import { BannerGroup } from '@liferaft/core/components';
import { CompanyFooter } from '@liferaft/core/components/footers';
import { ColumnarLayout } from '@liferaft/core/components/layouts';
import { UserContextProvider, useBannerContext } from '@liferaft/core/contexts';
import { NotFound } from '@liferaft/core/pages';

import { ApplyEntry, ApplyHeader } from '@/components';
import { DJANGO_API_URL } from '@/environment';
import { AmeritasContentPreview } from '@/pages/product-matrix/ameritas-content-preview';

export function ProtectedEntry() {
  const bannerUtil = useBannerContext();

  const layout = (
    <ColumnarLayout
      banners={bannerUtil.bannerList && <BannerGroup bannerUtil={bannerUtil} />}
      footer={<CompanyFooter baseUrl={DJANGO_API_URL as string} />}
      header={<ApplyHeader />}
    />
  );

  return (
    <UserContextProvider getUser={getUser}>
      <Routes>
        <Route element={layout} path="/">
          <Route element={<ApplyEntry />} path={'apply/:id/*'} />
          <Route element={<AmeritasContentPreview />} path="amr-states" />
          <Route element={<NotFound />} path="*" />
        </Route>
      </Routes>
    </UserContextProvider>
  );
}
