import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    fraudNotice: (
      <p>
        For your protection Arizona law requires the following statement to
        appear on this form. Any person who knowingly presents a false or
        fraudulent claim for payment of a loss is subject to criminal and civil
        penalties.
      </p>
    ),
  },
};

export default stateModule;
