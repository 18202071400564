import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      vision: false,
    },
    fraudNotice: (
      <p>
        Any person who knowingly presents a false or fraudulent claim for
        payment of a loss or benefit or knowingly presents false information in
        an application for insurance is guilty of a crime and may be subject to
        fines and confinement in prison.
      </p>
    ),
    planDescriptions: {
      vision: (
        <p>
          This PrimeStar® Vision Plan helps protect your eye health and ensure
          that you have access to the eyeglasses or contacts you rely on. Your
          coverage begins next-day with no waiting periods and no enrollment
          fees. A deductible may apply for coverage; please see Coverage Quick
          Facts for more details.
        </p>
      ),
    },
    planFacts: {
      dental: new Map([
        ['Lite Plan In- and Out-of-Network Coinsurance', null],
        [
          'Lite Plan Coinsurance',
          <>
            Year 1 - 100/50/10
            <br />
            Year 2 - 100/80/20
          </>,
        ],
        ['Boost Plan In- and Out-of-Network Coinsurance', null],
        [
          'Boost Plan Coinsurance',
          <>
            Year 1 - 100/65/20
            <br />
            Year 2 - 100/80/50
          </>,
        ],
        ['Complete Plan In- and Out-of-Network Coinsurance', null],
        [
          'Complete Plan Coinsurance',
          <>
            Year 1 - 100/80/20
            <br />
            Year 2 - 100/90/50
          </>,
        ],
      ]),
      vision: new Map([
        ['Premium Contacts Deductible', null],
        ['Medically Necessary Contacts', null],
        ['Select Vision Plan Partner', null],
        ['Choice Vision Plan Partner', null],
        ['Select Vision Plan Out-of-Network Maximum', null],
        ['Choice Vision Plan Out-of-Network Maximum', null],
        [
          'Deductibles',
          <p key="description">
            $50 eyeglass lenses or frames deductible per person per lifetime. A
            maximum of 3 individual deductibles per family will apply.
          </p>,
        ],
        [
          'Waiting Periods',
          <p key="description">
            No waiting periods apply to the eye exam, glasses lenses, glasses
            frames, or contact lenses benefits.
          </p>,
        ],
        [
          'Glasses Lenses Benefit',
          <>
            <p>
              Max benefit amount for additional glasses lenses options after $50
              deductible:
            </p>
            <ul>
              <li>$40 single vision</li>
              <li>$60 lined bifocal</li>
              <li>$70 lined trifocal</li>
              <li>$100 no line bifocal</li>
              <li>$100 standard progressive</li>
              <li>$100 lenticular</li>
            </ul>
          </>,
        ],
      ]),
    },
    planFactsFootnote: {
      dental: (
        <>
          Claim allowance (Indemnity): Covered benefits are paid at the 80th
          percentile of usual and customary (80th U&C) charges. This means we
          expect 8 out of 10 charges from dental providers to be within the
          amount insurance pays for a covered procedure. You pay the difference
          between what the plan pays and the dentist's actual charge.
        </>
      ),
    },
  },
  revisions: {
    '2024Q2': {
      isBaseState: {
        dental: false,
      },
    },
  },
};

export default stateModule;
