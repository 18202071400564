import React from 'react';
import { Button } from 'react-bootstrap';

import './credit-card-form.scss';
import type { VGSForm } from './vgs-collect-form';
import { VGSCollectForm, VGSCollectInput } from './vgs-collect-form';

type Props = {
  deniedBins?: string[];
  setIsSubmitting?: (value: boolean) => void;
  onSubmit: (form: VGSForm) => (status: any, response: any) => Promise<any>;
  submitLabel?: React.ReactNode;
  vgsRoute: string;
  vgsVaultId: string;
  vgsEnvironment: string;
  vgsSatellitePort?: number;
} & React.PropsWithChildren<Record<string, unknown>>;

export const CreditCardForm = React.forwardRef<HTMLFormElement, Props>(
  (
    {
      deniedBins,
      setIsSubmitting,
      submitLabel,
      onSubmit,
      vgsRoute,
      vgsEnvironment,
      vgsVaultId,
      vgsSatellitePort,
      ...passProps
    }: Props,
    ref
  ) => {
    const [errors, setErrors] = React.useState<Record<string, string>>({});

    const handleStateChange = (state: any) => {
      if (state) {
        const newErrors: Record<string, string> = {};

        for (const field in state) {
          if (
            state[field].errorMessages &&
            !state[field].isValid &&
            !state[field].isFocused &&
            state[field].isDirty
          ) {
            newErrors[field] = state[field].errorMessages[0];
          } else if (state[field].isValid || state[field].isFocused) {
            newErrors[field] = '';
          }
        }

        if (
          state['card_number'] &&
          state['card_number']['cardType'] === 'amex'
        ) {
          newErrors['card_number'] =
            'Sorry; we do not accept American Express for payment.';
          state['card_number'].errorMessages = [
            'American Express is an invalid card type',
          ];
        }

        if (deniedBins) {
          if (
            state['card_number'] &&
            state['card_number']['bin'] &&
            deniedBins.includes(state['card_number']['bin'])
          ) {
            newErrors['card_number'] =
              'Unfortunately, we are not able to accept this payment method at this time. Please try a different credit or debit card, or use ACH bank withdrawals instead.';
            state['card_number'].errorMessages = [
              'Unfortunately, we are not able to accept this payment method at this time. Please try a different credit or debit card, or use ACH bank withdrawals instead.',
            ];
          }
        }

        setErrors(newErrors);
      }
    };

    return (
      <VGSCollectForm
        environment={vgsEnvironment}
        onStateChange={handleStateChange}
        onSubmit={onSubmit}
        ref={ref}
        route={vgsRoute}
        satellitePort={vgsSatellitePort}
        setIsSubmitting={setIsSubmitting}
        vaultId={vgsVaultId}
        {...passProps}>
        {(isSubmitting: boolean, form: VGSForm) => (
          <>
            <VGSCollectInput
              dataName="card_name"
              error={errors['card_name']}
              fieldId="cc-name"
              form={form}
              label="Cardholder Name"
              register={(f, dataName) => {
                f.field('#cc-name', {
                  type: 'text',
                  name: dataName,
                  placeholder: 'Fullname as it appears on your card',
                  showCardIcon: true,
                  validations: ['required'],
                });
              }}
            />
            <VGSCollectInput
              dataName="card_number"
              error={errors['card_number']}
              fieldId="cc-number"
              form={form}
              label="Credit Card Number"
              register={(f, dataName) => {
                f.field('#cc-number', {
                  type: 'card-number',
                  name: dataName,
                  successColor: '#4F8A10',
                  errorColor: '#D8000C',
                  placeholder: '0000 0000 0000 0000',
                  showCardIcon: true,
                  validations: ['required', 'validCardNumber'],
                });
              }}
            />
            <VGSCollectInput
              dataName="card_cvc"
              error={errors['card_cvc']}
              fieldId="cc-cvc"
              form={form}
              label="CVC"
              register={(f, dataName) => {
                f.field('#cc-cvc', {
                  type: 'card-security-code',
                  name: dataName,
                  placeholder: 'CVC',
                  showCardIcon: true,
                  validations: ['required', 'validCardSecurityCode'],
                });
              }}
            />
            <VGSCollectInput
              dataName="card_expiration_date"
              error={errors['card_expiration_date']}
              fieldId="cc-expiration-date"
              form={form}
              label="Expiration Date"
              register={(f, dataName) => {
                f.field('#cc-expiration-date', {
                  type: 'card-expiration-date',
                  name: dataName,
                  yearLength: '4',
                  placeholder: 'MM / YYYY',
                  validations: ['required', 'validCardExpirationDate'],
                  serializers: [
                    {
                      name: 'separate',
                      options: { monthName: 'month', yearName: 'year' },
                    },
                  ],
                });
              }}
            />
            {submitLabel && (
              <div className="d-flex mt-8">
                <Button
                  className="ml-auto btn-block"
                  disabled={isSubmitting}
                  type="submit"
                  variant="primary">
                  {isSubmitting ? (
                    <span className="spinner-border" />
                  ) : (
                    submitLabel
                  )}
                </Button>
              </div>
            )}
          </>
        )}
      </VGSCollectForm>
    );
  }
);

CreditCardForm.displayName = 'CreditCardForm';
