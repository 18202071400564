import React from 'react';
import { Spinner } from 'react-bootstrap';

import './loader.scss';

export function Loader() {
  return (
    <div
      className="d-flex justify-content-center align-items-center vh-100"
      data-testid="loader">
      <Spinner
        animation="border"
        className="spinner-custom"
        variant="primary"
      />
    </div>
  );
}
