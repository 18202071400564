import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    fraudNotice: (
      <p>
        Any person who, with intent to defraud or knowing that he is
        facilitating a fraud against insurer, submits an application or files a
        claim containing a false or deceptive statement may have violated state
        law.
      </p>
    ),
  },
};

export default stateModule;
