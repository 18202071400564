import React from 'react';
import type { PlaidLinkError } from 'react-plaid-link';

import {
  createPlaidLinkToken,
  getPlaidLinkAccount,
} from '@liferaft/api/resources/plaid';
import type { PlaidLinkAccount } from '@liferaft/api/types';
import type { NoBody } from '@liferaft/api/utils/network';
import { NetworkController } from '@liferaft/api/utils/network';

import type { Store } from '../hooks';
import { useLocalStorage } from '../hooks';

export class Plaid {
  public static LINK_TOKEN_STORAGE_KEY = 'plaidLinkToken';
  public static OAUTH_RECEIVED_REDIRECT_URI_STORAGE_KEY =
    'oauthReceivedRedirectStorageKey';

  public static BASE_PATH = '/plaid/link';
  public static OAUTH_PATH = `${Plaid.BASE_PATH}/oauth`;
  public static FLOW_TRIGGER_KEY = 'flowTriggerKey';

  public static getReceivedRedirectUri(store: Store): string | null {
    return store.getItem(Plaid.OAUTH_RECEIVED_REDIRECT_URI_STORAGE_KEY);
  }

  public static setReceivedRedirectUri(store: Store, uri: string): void {
    store.setItem(Plaid.OAUTH_RECEIVED_REDIRECT_URI_STORAGE_KEY, uri);
  }

  public static clearReceivedRedirectUri(store: Store): void {
    store.removeItem(Plaid.OAUTH_RECEIVED_REDIRECT_URI_STORAGE_KEY);
  }

  public static getToken(store: Store): string | null {
    return store.getItem(Plaid.LINK_TOKEN_STORAGE_KEY);
  }

  public static async createToken(
    store: Store,
    {
      force = false,
      redirectURI,
      access_token,
    }: {
      force?: boolean;
      redirectURI?: string;
      access_token?: string;
    } = {}
  ): Promise<string | null> {
    let token = Plaid.getToken(store);

    if ((token && force) || token === null) {
      const network = new NetworkController();
      const result = await network.request(
        createPlaidLinkToken(access_token, redirectURI)
      );

      if (result.error) {
        console.error('Could not create plaid link token');
        token = null;
      } else if (result.data.link_token) {
        token = result.data.link_token as string;
        store.setItem(Plaid.LINK_TOKEN_STORAGE_KEY, token);
      }
    }

    return token;
  }

  public static clearToken(store: Store): void {
    store.removeItem(Plaid.LINK_TOKEN_STORAGE_KEY);
  }

  public static getTriggerKey(store: Store): string | null {
    return store.getItem(Plaid.FLOW_TRIGGER_KEY);
  }

  public static setTriggerKey(store: Store, key: string): void {
    store.setItem(Plaid.FLOW_TRIGGER_KEY, key);
  }

  public static clearTriggerKey(store: Store): void {
    store.removeItem(Plaid.FLOW_TRIGGER_KEY);
  }
}

type PlaidUtility = {
  handleExit: (err: PlaidLinkError | null, metadata: any) => void;
  handleSuccess: (publicToken: string, metadata: any) => void;
  plaidLinkAccount: PlaidLinkAccount | null | undefined;
  plaidLinkToken: string | null;
  receivedRedirectUri: string | null;
  setShowPlaid: React.Dispatch<React.SetStateAction<boolean>>;
  showPlaid: boolean;
  plaidReady: boolean;
};

export function usePlaid({
  onExit,
  onSuccess,
  plaidLinkAccountId,
  redirectURI,
  show = false,
  triggerKey,
}: {
  onExit?: (err: PlaidLinkError | null, metadata: any) => void;
  onSuccess?: (
    publicToken: string,
    metadata: any,
    plaidLinkAccount?: PlaidLinkAccount | null
  ) => void;
  plaidLinkAccountId?: string;
  redirectURI?: string;
  show?: boolean;
  triggerKey?: string;
} = {}): PlaidUtility {
  const store = useLocalStorage();
  const [showPlaid, setShowPlaid] = React.useState<boolean>(
    Plaid.getReceivedRedirectUri(store) !== null || show
  );

  const [plaidLinkToken, setPlaidLinkToken] = React.useState<string | null>(
    Plaid.getToken(store)
  );

  const [receivedRedirectUri, setReceivedRedirectUri] = React.useState<
    string | null
  >(Plaid.getReceivedRedirectUri(store));

  const [plaidLinkAccount, setPlaidLinkAccount] = React.useState<
    PlaidLinkAccount | null | undefined
  >();

  const handleExit = (err: PlaidLinkError | null, metadata: any) => {
    setReceivedRedirectUri(null);
    Plaid.clearReceivedRedirectUri(store);

    setPlaidLinkToken(null);
    Plaid.clearToken(store);
    Plaid.clearTriggerKey(store);

    onExit?.(err, metadata);
  };

  const handleSuccess = (publicToken: string, metadata: any) => {
    setReceivedRedirectUri(null);
    Plaid.clearReceivedRedirectUri(store);

    setPlaidLinkToken(null);
    Plaid.clearToken(store);
    Plaid.clearTriggerKey(store);

    onSuccess?.(publicToken, metadata, plaidLinkAccount);
  };

  const plaidReady = React.useMemo(() => {
    if (plaidLinkAccountId) {
      return (
        Boolean(plaidLinkAccountId && plaidLinkAccount) &&
        (Boolean(plaidLinkToken && showPlaid) ||
          Boolean(plaidLinkToken && receivedRedirectUri))
      );
    } else {
      return (
        Boolean(plaidLinkToken && showPlaid) ||
        Boolean(plaidLinkToken && receivedRedirectUri)
      );
    }
  }, [plaidLinkAccount, plaidLinkToken, receivedRedirectUri, showPlaid]);

  React.useEffect(() => {
    if (plaidReady && triggerKey) {
      Plaid.setTriggerKey(store, triggerKey);
    }
  }, [plaidReady]);

  React.useEffect(() => {
    const network = new NetworkController();

    (async () => {
      if (plaidLinkAccountId) {
        const result = await network.request<NoBody, PlaidLinkAccount>(
          getPlaidLinkAccount(plaidLinkAccountId)
        );

        if (result.error) {
          setPlaidLinkAccount(null);
        } else {
          setPlaidLinkAccount(result.data);
        }
      }
    })();

    return () => void network.cancel();
  }, []);

  React.useEffect(() => {
    (async () => {
      let ready = showPlaid;

      if (plaidLinkAccountId) {
        ready = ready && Boolean(plaidLinkAccount);
      }

      if (ready) {
        let token = plaidLinkToken;

        if (token === null || (token && !receivedRedirectUri)) {
          const newToken = await Plaid.createToken(store, {
            force: true,
            redirectURI,
            access_token: plaidLinkAccount?.access_token,
          });

          if (newToken) {
            Plaid.clearReceivedRedirectUri(store);
            token = newToken;
          }
        }

        if (token) {
          setPlaidLinkToken(token);
        } else {
          setShowPlaid(false);
        }
      }
    })();
  }, [showPlaid, plaidLinkAccount]);

  return {
    handleExit,
    handleSuccess,
    plaidLinkAccount,
    plaidLinkToken,
    plaidReady,
    receivedRedirectUri,
    setShowPlaid,
    showPlaid,
  };
}
