import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import hipaa from '../../assets/images/badges/hipaa.svg';
import soc2 from '../../assets/images/badges/soc2.png';
import facebook from '../../assets/images/icons/social/square-facebook.svg';
import linkedin from '../../assets/images/icons/social/square-linkedin.svg';
import youtube from '../../assets/images/icons/social/square-youtube.svg';

type Props = {
  baseUrl: string;
};

export function CompanyFooter({ baseUrl }: Props) {
  return (
    <footer className="py-8 py-md-10 bg-primary-extra-light">
      <Container>
        <Row className="mb-9">
          <Col lg={9}>
            <Row>
              <Col lg={3} xs={6}>
                <h5 className="font-weight-bold text-nowrap">Products</h5>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href={`${baseUrl}/company/employers/`}>
                    HRA for Health Insurance
                  </a>
                </p>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href={`${baseUrl}/company/specialty-hra/`}>
                    HRA for Specialty Care
                  </a>
                </p>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href={`${baseUrl}/company/branded-white-labeling/`}>
                    Branded Solutions
                  </a>
                </p>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href={`${baseUrl}/company/build-your-own-custom/`}>
                    Build-Your-Own
                  </a>
                </p>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href={`${baseUrl}/company/dpc-mec/`}>
                    DPC + MEC
                  </a>
                </p>
              </Col>
              <Col lg={3} xs={6}>
                <h5 className="font-weight-bold text-nowrap">Partnerships</h5>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href={`${baseUrl}/company/broker-partnerships/`}>
                    Brokers
                  </a>
                </p>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href={`${baseUrl}/company/digital-health/`}>
                    Digital Health Companies
                  </a>
                </p>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href={`${baseUrl}/company/professional-employment-organization-peo/`}>
                    PEOs
                  </a>
                </p>
              </Col>
              <Col lg={3} xs={6}>
                <h5 className="font-weight-bold text-nowrap">Resources</h5>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href="https://blog.liferaft.co/health-reimbursement-arrangement-hra/what-is-an-hra"
                    rel="noreferrer"
                    target="_blank">
                    What is an HRA?
                  </a>
                </p>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href={`${baseUrl}/company/pricing/`}>
                    HRA Pricing
                  </a>
                </p>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href="https://blog.liferaft.co/case-studies"
                    rel="noreferrer"
                    target="_blank">
                    Case Studies
                  </a>
                </p>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href="https://blog.liferaft.co/"
                    rel="noreferrer"
                    target="_blank">
                    Blog
                  </a>
                </p>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href="https://blog.liferaft.co/webinars"
                    rel="noreferrer"
                    target="_blank">
                    Webinars
                  </a>
                </p>
              </Col>
              <Col lg={3} xs={6}>
                <h5 className="font-weight-bold text-nowrap">About</h5>
                <p className="text-nowrap small mb-1">
                  <a className="text-black" href={`${baseUrl}/company/about/`}>
                    About Liferaft
                  </a>
                </p>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href={`${baseUrl}/company/careers/`}>
                    Careers
                  </a>
                </p>
                <p className="text-nowrap small mb-1">
                  <a
                    className="text-black"
                    href={`${baseUrl}/company/contact/`}>
                    Contact Us
                  </a>
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="align-items-start" md={3}>
            <div className="d-flex justify-content-end">
              <div
                style={{
                  maxWidth: '70px',
                  margin: 'auto 0',
                }}>
                <div className="img-fluid svg-shim">
                  <img alt="..." src={soc2} />
                </div>
              </div>
              <div
                style={{
                  maxWidth: '100px',
                  margin: 'auto 0',
                }}>
                <div className="img-fluid svg-shim ml-3">
                  <img alt="..." src={hipaa} />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <hr className="hr col-12"></hr>

        <Row>
          <Col className="mt-4 mb-6" xs={12}>
            <h6 className="text-gray-800">
              Insurance products are sold by Liferaft Insurances Services, LLC a
              licensed insurance producer with California license #6003070 and
              National Producer Number #17313354. Liferaft Insurance Services,
              LLC is a licensed producer in in all 50 U.S. states and DC with
              its principal place of business at 8605 Santa Monica Blvd PMB
              38776, West Hollywood, CA 90069.
            </h6>
          </Col>
        </Row>
        <Row className="align-items-center my-6">
          <Col xs={6}>
            <h6 className="d-flex">
              <a href={`${baseUrl}/company/privacy/`}>
                <p className="text-gray-800 mb-1 text-nowrap">Privacy Policy</p>
              </a>
              &emsp;|&emsp;
              <a href={`${baseUrl}/company/tos/`}>
                <p className="text-gray-800 mb-1 text-nowrap">
                  Terms of Service
                </p>
              </a>
              &emsp;|&emsp;
              <a href={`${baseUrl}/company/licenses/`}>
                <p className="text-gray-800 mb-1 text-nowrap">Licenses</p>
              </a>
            </h6>
          </Col>
          <Col xs={6}>
            <div className="d-flex justify-content-end">
              <ul className="list-unstyled list-inline list-social ml-5 mt-2">
                <li className="list-inline-item list-social-item mr-3">
                  <a
                    className="text-decoration-none"
                    href="https://www.linkedin.com/company/liferaftrisk/"
                    rel="noreferrer"
                    target="_blank">
                    <img
                      alt="..."
                      className="list-social-icon"
                      src={linkedin}
                    />
                  </a>
                </li>
                <li className="list-inline-item list-social-item mr-3">
                  <a
                    className="text-decoration-none"
                    href="https://www.facebook.com/liferaftco/"
                    rel="noreferrer"
                    target="_blank">
                    <img
                      alt="..."
                      className="list-social-icon"
                      src={facebook}
                    />
                  </a>
                </li>
                <li className="list-inline-item list-social-item mr-3">
                  <a
                    className="text-decoration-none"
                    href="https://www.youtube.com/channel/UCe_NyycNVBWL5Eh9IhotIeA"
                    rel="noreferrer"
                    target="_blank">
                    <img alt="..." className="list-social-icon" src={youtube} />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
