import React from 'react';
import { Alert, Form } from 'react-bootstrap';
import * as yup from 'yup';

import {
  createDisclosureAcknowledgement,
  updateApplication,
} from '@liferaft/api/resources/application';
import type { Application, Egress } from '@liferaft/api/types';
import {
  DisclosureAcknowledgementResponse,
  DisclosureType,
} from '@liferaft/api/types';
import type { ParsedDjangoErrorResponse } from '@liferaft/api/utils/django-utils';
import { NON_FIELD_ERROR_LABEL } from '@liferaft/api/utils/django-utils';
import { NetworkController } from '@liferaft/api/utils/network';

import { Card } from '@liferaft/core/components';
import type {
  FormErrorsSetter,
  NameData,
} from '@liferaft/core/components/forms';
import {
  EmailInput,
  NameInput,
  PasswordInput,
  ValidatedForm,
} from '@liferaft/core/components/forms';
import { useUserContext } from '@liferaft/core/contexts';
import { useDebounce } from '@liferaft/core/hooks';

import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';
import { DJANGO_API_URL } from '@/environment';

const schema = yup.object({
  email: yup.string().required(),
  first_name: yup.string().required().label('First name'),
  last_name: yup.string().required().label('Last name'),
  middle_name: yup.string().label('Middle name'),
  password: yup.string().label('Password'),
});

type FormData = yup.InferType<typeof schema>;

export function CreateAccount() {
  const { user, refresh } = useUserContext();

  const {
    data: { egress, id, was_filled_out_by_broker },
    next,
  } = useApplicationFlow();

  const [fullName, setFullName] = React.useState<Partial<NameData>>({
    firstName: egress?.first_name || '',
    middleName: egress?.middle_name || '',
    lastName: egress?.last_name || '',
  });

  const [email, setEmail] = React.useState<string>(egress?.email || '');
  const [password, setPassword] = React.useState<string>('');
  const [hasAgreedTerms, setHasAgreedTerms] = React.useState<boolean>(false);

  const [handleSubmit, isSubmitting] = useDebounce(
    async (validatedData: FormData, setErrors: FormErrorsSetter<FormData>) => {
      const network = new NetworkController();

      const result = await network.request<FormData, Application>(
        updateApplication(id as string, validatedData)
      );

      if (result.error) {
        setErrors(result.data as ParsedDjangoErrorResponse<Egress>);
        return;
      }

      network.request(
        createDisclosureAcknowledgement(user.id, {
          disclosure_type: DisclosureType.LIFERAFT_TOS,
          response: DisclosureAcknowledgementResponse.ACCEPTED,
        })
      );

      refresh();
      return next();
    }
  );

  return (
    <ApplyWrapper
      footer={<ApplyWrapperFooter rightDisabled={isSubmitting} />}
      heading="Create your account"
      subheading="Please enter your full legal name.">
      <ValidatedForm<FormData>
        controlledData={{
          first_name: fullName.firstName,
          last_name: fullName.lastName,
          middle_name: fullName.middleName,
          email,
          password,
        }}
        id={APPLY_FORM_ID}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        {({ errors }) => (
          <Card cardClasses="card-bleed shadow-light">
            {errors[NON_FIELD_ERROR_LABEL] && (
              <Alert className="mb-3" variant="danger">
                <ul>
                  {errors[NON_FIELD_ERROR_LABEL].map(
                    (err: string, i: number) => (
                      <li key={i}>{err}</li>
                    )
                  )}
                </ul>
              </Alert>
            )}
            <NameInput
              errors={{
                firstName: errors.first_name,
                lastName: errors.last_name,
                middleName: errors.middle_name,
              }}
              fieldsetClasses="form-group"
              onChange={setFullName}
              required
              showFieldLabels
              showMiddle
              value={fullName}
            />
            <EmailInput
              error={errors.email}
              groupClasses="form-group"
              label="Email"
              onChange={setEmail}
              required
              value={email}
            />
            {!egress?.email && !was_filled_out_by_broker && (
              <PasswordInput
                error={errors.password}
                groupClasses="form-group"
                label="Password"
                onChange={setPassword}
                required
                value={password}
              />
            )}
            <Form.Group>
              <Form.Check
                checked={hasAgreedTerms}
                id="has-agreed-terms"
                label={
                  <>
                    I have read and agree to Liferaft's{' '}
                    <a
                      href={`${DJANGO_API_URL}/company/tos/`}
                      rel="noreferrer"
                      target="_blank">
                      Terms and Conditions
                    </a>{' '}
                    and{' '}
                    <a
                      href={`${DJANGO_API_URL}/company/privacy/`}
                      rel="noreferrer"
                      target="_blank">
                      Privacy Policy
                    </a>
                    .
                  </>
                }
                name="has-agreed-terms"
                onChange={() => setHasAgreedTerms(!hasAgreedTerms)}
                required
                type="checkbox"
              />
            </Form.Group>
          </Card>
        )}
      </ValidatedForm>
    </ApplyWrapper>
  );
}
