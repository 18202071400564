import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
    },
    offersProduct: {
      vision: false,
    },
    fraudNotice: (
      <p>
        Any person who knowingly or willfully presents a false or fraudulent
        claim for payment of a loss or benefit or who knowingly or willfully
        presents false information in an application for insurance is guilty of
        a crime and may be subject to fines and confinement in prison.
      </p>
    ),
    dentalLimitations: (
      <>
        <p>
          Covered expenses will not include and benefits will not be payable for
          expenses incurred:
        </p>
        <ul>
          <li>for any treatment which is for cosmetic purposes.</li>
          <li>
            to replace any crowns, inlays, onlays, veneers, complete or partial
            dentures within five years of the date of the last placement of
            these items.But if a replacement is required because of an
            accidental bodily injury sustained while the Insured person is
            covered under this contract, it will be a covered expense.
          </li>
          <li>
            for initial placement of any dental prosthesis or prosthetic crown
            unless such placement is needed because of the extraction of one or
            more teeth while the insured person is covered under this
            contract.But the extraction of a third molar(wisdom tooth) will not
            qualify under the above.Any such appliance or fixed partial denture
            must include the replacement of the extracted tooth or teeth.
          </li>
          <li>
            for any procedure begun before the insured person was covered under
            the policy.
          </li>
          <li>
            for any procedure begun after the insured person's insurance under
            the policy terminates; or for any prosthetic dental appliances
            installed or delivered more than 90 days after the insured's
            insurance under the policy terminates.
          </li>
          <li>to replace lost or stolen appliances.</li>
          <li>
            for appliances, restorations, or procedures to:
            <ul>
              <li>alter vertical dimension;</li>
              <li>restore or maintain occlusion; or</li>
              <li>
                splint or replace tooth structure lost as a result of abrasion
                or attrition.
              </li>
            </ul>
          </li>
          <li>
            for any procedure which is not shown on the Table of Dental
            Procedures. (There may be additional frequencies and limitations
            that apply, please see the Table of Dental Procedures in the
            policy.)
          </li>
          <li>
            for which the insured person is entitled to benefits under any
            workmen's compensation or similar law, or charges for services or
            supplies received as a result of any dental condition caused or
            contributed to by an injury or sickness arising out of or in the
            course of any employment for wage or profit(except in CA & KY).
          </li>
          <li>
            for charges for which the insured person is not liable or which
            would not have been made had no insurance been in force.
          </li>
          <li>
            for services which are not required for necessary care and treatment
            or are not within the generally accepted parameters of care.
          </li>
          <li>because of war or any act of war, declared or not.</li>
          <li>
            if two or more procedures are considered adequate and appropriate
            treatment to correct a certain condition under generally accepted
            standards of dental care, the amount of the covered expense will be
            equal to the charge for the least expensive procedure.
          </li>
        </ul>
      </>
    ),
    planFacts: {
      dental: new Map([
        ['Value Plan', null],
        ['Lite Plan', null],
        ['Access Plan In- and Out-of-Network Coinsurance', null],
        ['Lite Plan In- and Out-of-Network Coinsurance', null],
        [
          'Boost Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/65/20 INN & 80/45/10 OON
            <br />
            Year 2 - 100/80/50 INN & 80/60/30 OON
          </>,
        ],
        [
          'Complete Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/80/20 INN & 90/70/15 OON
            <br />
            Year 2 - 100/90/50 INN & 90/80/40 OON
          </>,
        ],
      ]),
    },
  },
};

export default stateModule;
