import React from 'react';
import { Form } from 'react-bootstrap';

type Props = {
  error?: string;
  required?: boolean;
  disableZipCode?: boolean;
  label?: string;
  groupClasses?: string;
  labelClasses?: string;
  formClasses?: string;
  value?: string;
  onChange: (value: string) => void;
};

export function ZipCodeInput({
  error,
  required = false,
  disableZipCode = false,
  label,
  groupClasses,
  labelClasses = '',
  formClasses = '',
  value,
  onChange,
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const matches = event.target.value.replace(/\D/g, '').match(/\d{0,5}/);

    if (!matches) {
      onChange(event.target.value);
      return;
    }

    onChange(matches[0]);
  };

  return (
    <Form.Group className={groupClasses} data-testid="zip-code-form-group">
      {label && (
        <Form.Label className={labelClasses} htmlFor="zip-code">
          {label}
        </Form.Label>
      )}
      <Form.Control
        className={formClasses}
        data-testid="zip-code-input"
        disabled={disableZipCode}
        id="zip-code"
        isInvalid={!!error}
        name="zip-code"
        onChange={handleChange}
        pattern="^[0-9]{5}$"
        placeholder="Zip Code"
        required={required}
        type="text"
        value={value}
      />
      {error && (
        <Form.Control.Feedback data-testid="zip-code-error" type="invalid">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}
