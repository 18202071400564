import React from 'react';
import { Form } from 'react-bootstrap';

type Props = {
  disabled?: boolean;
  required: boolean;
  error?: string;
  groupClasses?: string;
  help?: string;
  label?: string;
  labelClasses?: string;
  value?: string;
  onChange: (value: string) => void;
};

export function EmailInput({
  disabled = false,
  required = false,
  error,
  groupClasses,
  help,
  label,
  labelClasses = '',
  value,
  onChange,
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validInput = event.target.value.replace(/\s/g, '');
    onChange(validInput);
  };

  return (
    <Form.Group className={groupClasses} data-testid="email-form-group">
      {label && <Form.Label className={labelClasses}>{label}</Form.Label>}
      <Form.Control
        data-testid="email-input"
        disabled={disabled}
        id="email"
        isInvalid={!!error}
        name="email"
        onChange={handleChange}
        pattern={'\\S+@\\S+\\.\\S+'}
        placeholder="name@address.com"
        required={required}
        type="email"
        value={value}
      />
      {help && (
        <Form.Text id="email-help" muted>
          {help}
        </Form.Text>
      )}
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
}
