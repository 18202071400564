import { DJANGO_API_URL } from '../environment';
import type { NetworkFailure, NetworkSuccess, ResponseParser } from './network';
import { RequestConfiguration } from './network';

export type DjangoRetrieveResponse<T> = T;

export type DjangoListResponse<T> = {
  pagination: {
    current_page_num: number;
    next_page_url?: string;
    page_first_item_num: number;
    page_last_item_num: number;
    previous_page_url?: string;
    total_item_count: number;
    total_page_count: number;
  };
  results: T[];
};

export const NON_FIELD_ERROR_LABEL = '__top_level_error';
export type NonFieldErrorKey = typeof NON_FIELD_ERROR_LABEL;

type DjangoFieldError<T> = {
  detail: string | Record<string, string[]>;
  field_name: keyof T;
  type: string;
};

export type DjangoNonFieldError = {
  detail: string;
  field_name: NonFieldErrorKey;
  type: string;
};

type DjangoError<T> = DjangoFieldError<T> | DjangoNonFieldError;

export type DjangoErrorResponse<T> = {
  detail: string;
  title: string;
  type: string;
  errors: DjangoError<T>[];
};

export type DjangoAPIResponse<T> =
  | DjangoListResponse<T>
  | DjangoRetrieveResponse<T>
  | DjangoErrorResponse<T>;

export type DjangoSuccessResponse<T> =
  | DjangoListResponse<T>
  | DjangoRetrieveResponse<T>;

export type ParsedDjangoErrorResponse<T> = Partial<
  Record<
    keyof T | NonFieldErrorKey,
    Record<string, string[]> | string | string[]
  >
>;

export type DjangoNetworkResult<S, F = undefined> =
  | NetworkSuccess<DjangoSuccessResponse<S>>
  | NetworkFailure<ParsedDjangoErrorResponse<F>>;

export class DjangoResponseParser<Resource> implements ResponseParser {
  parse(
    response: DjangoAPIResponse<Resource>,
    isSuccess: boolean
  ):
    | DjangoListResponse<Resource>
    | DjangoRetrieveResponse<Resource>
    | ParsedDjangoErrorResponse<Resource> {
    if (isSuccess) {
      return response as DjangoSuccessResponse<Resource>;
    }

    const errorResponse = response as DjangoErrorResponse<Resource>;
    const errors: ParsedDjangoErrorResponse<Resource> = {};

    if (errorResponse?.errors) {
      for (const error of errorResponse.errors) {
        if (error.field_name === NON_FIELD_ERROR_LABEL) {
          if (errors[NON_FIELD_ERROR_LABEL] === undefined) {
            errors[NON_FIELD_ERROR_LABEL] = [];
          }
          if (Array.isArray(errors[NON_FIELD_ERROR_LABEL])) {
            if (Array.isArray(error.detail)) {
              errors[NON_FIELD_ERROR_LABEL].push(...error.detail);
            } else {
              errors[NON_FIELD_ERROR_LABEL].push(error.detail);
            }
          }
        } else {
          errors[error.field_name as keyof Resource] = error.detail;
        }
      }
    }

    return errors;
  }
}

export class DjangoRequestConfiguration<
  Body,
  Return = undefined
> extends RequestConfiguration<Body> {
  public responseParser: ResponseParser = new DjangoResponseParser<Return>();

  responseParse(responseParser: ResponseParser): this {
    this.responseParser = responseParser;
    return this;
  }

  url(path: string): this {
    const url = `${DJANGO_API_URL}${path}`;
    this.options.url = url;
    return this;
  }
}
