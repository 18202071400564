import React from 'react';

import { UserRole } from '@liferaft/api/types';

import type { Props as AuthenticatedHeaderProps } from '@liferaft/core/components/headers';
import { AuthenticatedHeader } from '@liferaft/core/components/headers';
import { useUserContext } from '@liferaft/core/contexts';

import { DJANGO_API_URL } from '@/environment';

export function ApplyHeader() {
  const { user } = useUserContext();

  const props: AuthenticatedHeaderProps = {
    baseUrl: DJANGO_API_URL as string,
  };

  props.hideMenu = user.roles.includes(UserRole.GUEST);

  if (user.organization_id) {
    props.menuOptions = [
      {
        url: `${DJANGO_API_URL}/documents/`,
        description: 'Sales Tools',
      },
      {
        url: `${DJANGO_API_URL}/organization/documents/`,
        description: 'Documents',
      },
      {
        url: `${DJANGO_API_URL}/broker/client-portfolio/`,
        description: 'Client Portfolio',
      },
    ];
  }

  return <AuthenticatedHeader {...props} />;
}
