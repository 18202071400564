import { AnalyticsBrowser } from '@segment/analytics-next';
import React from 'react';
import { useLocation } from 'react-router-dom';

import type { User } from '@liferaft/api/types';

import { createContext } from '.';

export type AnalyticsUtility = {
  analytics: AnalyticsBrowser;
  isLoaded: boolean;
  setPathnameFilter: (newPathnameFilter?: (pathname: string) => string) => void;
  identifyUser: (user: User) => void;
};

const emptyAnalytics: AnalyticsUtility = {
  analytics: new AnalyticsBrowser(),
  isLoaded: false,
  setPathnameFilter: () => void 0,
  identifyUser: () => void 0,
};

const [useContext, Provider] = createContext<AnalyticsUtility>(emptyAnalytics);
export const useAnalyticsContext = useContext;

type Props = {
  writeKey?: string;
  pathnameFilter?: (pathname: string) => string;
  children?: React.ReactNode;
};

export function AnalyticsContextProvider({
  writeKey,
  pathnameFilter,
  children,
}: Props) {
  const [analytics, setAnalytics] = React.useState<AnalyticsBrowser>(
    new AnalyticsBrowser()
  );
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

  const location = useLocation();

  function pageFunction() {
    let path = location.pathname;
    if (pathnameFilter) {
      path = pathnameFilter(path);
    }
    return analytics.page({ path });
  }

  function setPathnameFilter(newPathnameFilter?: (pathname: string) => string) {
    pathnameFilter = newPathnameFilter;
  }

  function identifyUser(user: User) {
    analytics.identify(user.id, {
      email: user.email,
      organization_id: user.organization_id,
    });
    pageFunction();
  }

  // Establish hooks
  React.useEffect(() => {
    if (!writeKey) return;
    setAnalytics(analytics.load({ writeKey }));
    setIsLoaded(true);
  }, []);

  React.useEffect(() => {
    pageFunction();
  }, [location]);

  // Create and return Provider
  const utility: AnalyticsUtility = {
    analytics,
    isLoaded,
    setPathnameFilter,
    identifyUser,
  };
  return <Provider value={utility}>{children}</Provider>;
}
