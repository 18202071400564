import { Flag } from '@liferaft/api/types';

import { useWaffleContext } from '../../../contexts';
import type { AmeritasRevision } from './types';

export function activeAmeritasRevision(): AmeritasRevision {
  try {
    const waffle = useWaffleContext();
    if (waffle.isFlagActive(Flag.AMR_2024Q2_CONTENT)) {
      return '2024Q2';
    }
  } catch (ignore) {
    // if we can't get the waffle context, just return the default
    // this usually occurs in testing or for values that do not vary
    // by revision
  }
  return '2023Q3';
}
