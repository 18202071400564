import type { ReactNode } from 'react';

import type { InsuranceProductKey } from '@liferaft/api/types';

import type { StateAbbr } from '../../states';

const ALL_AMERITAS_REVISIONS = ['2023Q3', '2024Q2'] as const;
type AmeritasRevisionTuple = typeof ALL_AMERITAS_REVISIONS;
export type AmeritasRevision = AmeritasRevisionTuple[number];
export const AllAmeritasRevisions: ReadonlyArray<AmeritasRevision> =
  ALL_AMERITAS_REVISIONS;

type LabelDependentNode = (label: string) => ReactNode;

export type StateContent = {
  offersProduct: Record<InsuranceProductKey, boolean>;
  isBaseState: Record<InsuranceProductKey, boolean>;
  stateNotice: ReactNode;
  fraudNotice: ReactNode;
  authorizationAgreement: ReactNode;
  dentalLimitations: ReactNode;
  planDescriptions: Record<InsuranceProductKey, ReactNode>;
  planFacts: Record<InsuranceProductKey, Map<string, ReactNode>>;
  planFactsFootnote: Record<InsuranceProductKey, ReactNode>;
  planHelper: Record<InsuranceProductKey, LabelDependentNode>;
  summaryFootnote: Record<InsuranceProductKey, LabelDependentNode>;
};

type Override<T1, T2> = Omit<Partial<T1>, keyof T2> & Partial<T2>;

export type StateContentOverride = Override<
  StateContent,
  {
    offersProduct: Partial<Record<InsuranceProductKey, boolean>>;
    isBaseState: Partial<Record<InsuranceProductKey, boolean>>;
    planDescriptions: Partial<Record<InsuranceProductKey, ReactNode>>;
    planFacts: Partial<Record<InsuranceProductKey, Map<string, ReactNode>>>;
    planFactsFootnote: Partial<Record<InsuranceProductKey, ReactNode>>;
    planHelper: Partial<Record<InsuranceProductKey, LabelDependentNode>>;
    summaryFootnote: Partial<Record<InsuranceProductKey, LabelDependentNode>>;
  }
>;

export function mergeStateOverride(
  content: StateContent,
  override?: StateContentOverride
): StateContent {
  if (!override) return content;
  const mergedContent = { ...content };
  let key: keyof StateContentOverride;
  for (key in override) {
    if (override[key] === undefined) continue;
    if (key === 'offersProduct' || key === 'isBaseState') {
      // for offersProduct and isBaseState, override record entries replace base record entries
      mergedContent[key] = { ...content[key], ...override[key] };
    } else if (key === 'planDescriptions' || key === 'planFactsFootnote') {
      // for planDescriptions and planFactsFootnote, override record entries replace base record entries
      mergedContent[key] = { ...content[key], ...override[key] };
    } else if (key === 'planHelper' || key === 'summaryFootnote') {
      mergedContent[key] = { ...content[key], ...override[key] };
    } else if (key === 'planFacts') {
      // for planFacts, override record entries are merged with base record entries
      const mergedPlanFacts = { ...content[key] };
      let product: InsuranceProductKey;
      for (product in override[key]) {
        // for each planFacts[product], override map entries replace base map entries
        const overrideFactMap = override[key]?.[product];
        if (!overrideFactMap) continue;
        const mergedFactMap = new Map(mergedPlanFacts[product]);
        for (const fact of overrideFactMap.keys()) {
          mergedFactMap.set(fact, overrideFactMap.get(fact));
        }
        mergedPlanFacts[product] = mergedFactMap;
      }
      mergedContent[key] = mergedPlanFacts;
    } else {
      mergedContent[key] = override[key];
    }
  }
  return mergedContent;
}

type ZipRange = string | Readonly<[string, string]>;
export function zipcodeInRange(zipcode: string, range: ZipRange): boolean {
  if (Array.isArray(range)) {
    const [start, end] = range;
    return start <= zipcode && zipcode <= end;
  }
  return range === zipcode;
}

type ZipcodeOverride = {
  zipcodes: ReadonlyArray<ZipRange>;
  overrides: {
    default?: StateContentOverride;
    revisions?: Partial<Record<AmeritasRevision, StateContentOverride>>;
  };
};

export type StateModule = {
  default?: StateContentOverride;
  revisions?: Partial<Record<AmeritasRevision, StateContentOverride>>;
  zipcodeOverrides?: ZipcodeOverride[];
};

export type AmeritasContent = StateContent & {
  name: string;
  abbr: StateAbbr;
  revision: AmeritasRevision;
};
