import React from 'react';
import { Accordion, Button, Form } from 'react-bootstrap';

import type { OOCParams } from '@liferaft/api/resources/application';
import {
  createDisclosureAcknowledgement,
  createOOC,
  hasOOC,
} from '@liferaft/api/resources/application';
import {
  CarrierKey,
  DisclosureAcknowledgementResponse,
  DisclosureType,
  InsuranceProductKey,
} from '@liferaft/api/types';
import type { NetworkResult } from '@liferaft/api/utils/network';
import { NetworkController } from '@liferaft/api/utils/network';

import { Card } from '@liferaft/core/components';
import {
  ameritasContentSummaryOfBenefitsLink,
  getAmeritasStateContent,
} from '@liferaft/core/content/products/ameritas';
import type { AmeritasRevision } from '@liferaft/core/content/products/ameritas';
import { activeAmeritasRevision } from '@liferaft/core/content/products/ameritas/current';
import { useUserContext } from '@liferaft/core/contexts';
import { useDebounce } from '@liferaft/core/hooks';
import { openFromBlob } from '@liferaft/core/utils/file';

import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';
import { useProductMatrixContext } from '@/contexts';

import './ameritas-disclaimer.scss';

export function AmeritasDisclaimer() {
  const [hasDentalOOC, setHasDentalOOC] = React.useState<boolean>();
  const [hasVisionOOC, setHasVisionOOC] = React.useState<boolean>();

  const { user } = useUserContext();
  const {
    data: { egress },
    next,
  } = useApplicationFlow();

  const context = useProductMatrixContext(egress);

  const productNames = egress?.product_selections
    ?.filter((s) => {
      if ('carrier' in s) {
        return s.carrier === CarrierKey.AMR;
      }
      return false;
    })
    ?.map((s) => s.name);

  const state = egress?.state as string;
  const zipCode = egress?.zip_code as string;
  const insuranceSelections = context.selectedInsuranceOfferings;

  const hasDental = insuranceSelections.some(
    ([productKey, offering]) =>
      productKey === InsuranceProductKey.DENTAL &&
      offering.carrier === CarrierKey.AMR
  );
  const selectedDental = context.getSelectedInsuranceOfferingByProduct(
    InsuranceProductKey.DENTAL
  );

  React.useEffect(() => {
    if (hasDental) {
      const network = new NetworkController();
      network.request(
        hasOOC(state, InsuranceProductKey.DENTAL),
        (result: NetworkResult<boolean>) => {
          if (result.error) {
            return;
          }
          setHasDentalOOC(result.data);
        }
      );
      return () => void network.cancel();
    }
  }, [hasDental]);

  const downloadDentalOOC = async () => {
    if (hasDentalOOC && selectedDental) {
      const network = new NetworkController();
      const planCode = selectedDental.plan_code;

      const result = await network.request<OOCParams, Blob>(
        createOOC({
          coverageType: selectedDental.coverage_type,
          planCode,
          product: InsuranceProductKey.DENTAL,
          zipCode,
        })
      );

      if (result.error) return;
      openFromBlob(
        result.data,
        `AmeritasDentalOOC_${planCode}_${zipCode}.pdf`,
        true
      );
    }
  };

  const hasVision = insuranceSelections.some(
    ([productKey, offering]) =>
      productKey === InsuranceProductKey.VISION &&
      offering.carrier === CarrierKey.AMR
  );
  const selectedVision = context.getSelectedInsuranceOfferingByProduct(
    InsuranceProductKey.VISION
  );
  const selectedVisionDisplayLabel = selectedVision?.display_label;

  React.useEffect(() => {
    if (hasVision) {
      const network = new NetworkController();
      network.request(
        hasOOC(state, InsuranceProductKey.VISION),
        (result: NetworkResult<boolean>) => {
          if (result.error) {
            return;
          }
          setHasVisionOOC(result.data);
        }
      );
      return () => void network.cancel();
    }
  }, [hasVision]);

  const downloadVisionOOC = async () => {
    if (hasVisionOOC && selectedVision) {
      const network = new NetworkController();
      const planCode = selectedVision.plan_code;

      const result = await network.request<OOCParams, Blob>(
        createOOC({
          coverageType: selectedVision.coverage_type,
          planCode,
          product: InsuranceProductKey.VISION,
          zipCode,
        })
      );

      if (result.error) return;
      openFromBlob(
        result.data,
        `AmeritasVisionOOC_${planCode}_${zipCode}.pdf`,
        true
      );
    }
  };

  const [handleSubmit, isSubmitting] = useDebounce(
    (event: React.SyntheticEvent<EventTarget>) => {
      event.preventDefault();

      const network = new NetworkController();

      network.request(
        createDisclosureAcknowledgement(user.id, {
          disclosure_type: DisclosureType.AMERITAS_DISCLAIMER,
          response: DisclosureAcknowledgementResponse.ACCEPTED,
        })
      );

      return next();
    }
  );

  const ameritasRevision: AmeritasRevision = activeAmeritasRevision();
  const ameritasContent = getAmeritasStateContent(state, ameritasRevision);

  return (
    <ApplyWrapper
      footer={<ApplyWrapperFooter rightDisabled={isSubmitting} />}
      heading="Agreement to Terms">
      <Card bodyClasses="" cardClasses="card-bleed shadow-light">
        {productNames && (
          <div className="d-flex w-100 align-items-center mb-4 mt-2">
            {productNames.map((name, index) => {
              return (
                <div className="font-size-lg" key={index}>
                  {name.toUpperCase().replaceAll('-', ' ')}
                  {index === productNames.length - 1 ? '' : ','}
                </div>
              );
            })}
          </div>
        )}
        <div className="strike-line ml-0 mb-4" />
        <Form id={APPLY_FORM_ID} onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Check
              id="has-agreed-terms"
              label="I have read and agree to the Authorization Agreements, Limitations & Exclusions, Fraud Notices, Outline of Coverage (if applicable), and Replacement Notices (if applicable)"
              name="has-agreed-terms"
              required
              type="checkbox"
            />
          </Form.Group>
        </Form>
        <p className="mt-4">
          The policy provides dental and/or vision benefits only. Review your
          policy certificate carefully. By submitting your application using
          this form, you hereby apply for coverage. This policy has limitations,
          exclusions, terms, and conditions under which the policy may be
          continued in force or discontinued.
        </p>
        <div className="d-flex w-100 align-items-center mb-3 mt-4">
          <div className="font-size-lg">Authorization Agreement</div>
          <div className="strike-line" />
        </div>
        {ameritasContent.authorizationAgreement}
        <div className="d-flex w-100 align-items-center mb-3 mt-4">
          <div className="font-size-lg">Outline of Coverage</div>
          <div className="strike-line"></div>
        </div>
        {(hasDental || hasVision) && (
          <div>
            {hasDental && (
              <div>
                <a
                  href={ameritasContentSummaryOfBenefitsLink(
                    ameritasContent,
                    InsuranceProductKey.DENTAL
                  )}
                  rel="noreferrer"
                  target="_blank">
                  Dental Summary of Benefits
                </a>
                {hasDentalOOC && (
                  <>
                    {' | '}
                    <Button
                      className="btn-link m-0 p-0"
                      onClick={downloadDentalOOC}
                      variant="empty">
                      Dental Outline of Coverage
                    </Button>
                  </>
                )}
              </div>
            )}
            {hasVision && (
              <div>
                <a
                  href={ameritasContentSummaryOfBenefitsLink(
                    ameritasContent,
                    InsuranceProductKey.VISION
                  )}
                  rel="noreferrer"
                  target="_blank">
                  Vision Summary of Benefits
                </a>
                {hasVisionOOC && (
                  <>
                    {' | '}
                    <Button
                      className="btn-link m-0 p-0"
                      onClick={downloadVisionOOC}
                      variant="empty">
                      Vision Outline of Coverage
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        )}

        <div className="d-flex w-100 align-items-center mb-3 mt-4">
          <div className="font-size-lg">Verification of Benefits</div>
          <div className="strike-line" />
        </div>
        <p>
          Please note: Your insurance is effective on the date you selected
          during enrollment, but you may need to call for verification of
          benefits if you have an appointment during the first few days of
          coverage. Our application process may take up to five business days
          after your application is submitted. If you visit your dental or
          vision provider during that time, you may need to contact Ameritas
          customer service at 800-300-9566 for verification of benefits.
        </p>
        <div className="d-flex w-100 align-items-center mb-3 mt-4">
          <div className="font-size-lg">Privacy</div>
          <div className="strike-line" />
        </div>
        <a
          href="https://www.ameritas.com/about/privacy/"
          rel="noreferrer"
          target="_blank">
          Ameritas Privacy Policy
        </a>
        <div className="d-flex w-100 align-items-center mb-3 mt-4">
          <div className="font-size-lg">Fraud Notices</div>
          <div className="strike-line" />
        </div>
        {ameritasContent.fraudNotice}
        {ameritasContent.stateNotice !== null && (
          <>
            <div className="d-flex w-100 align-items-center mb-3 mt-4">
              <div className="font-size-lg">State Notices</div>
              <div className="strike-line" />
            </div>
            {ameritasContent.stateNotice}
          </>
        )}
        <div className="d-flex w-100 align-items-center mb-3 mt-4">
          <div className="font-size-lg">Limitations and Exclusions</div>
          <div className="strike-line" />
        </div>
        <Accordion flush>
          {hasVision && selectedVisionDisplayLabel && (
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Vision Limitations and Exclusions
              </Accordion.Header>
              <Accordion.Body className="ml-6">
                {getVisionLimitations(selectedVisionDisplayLabel)}
              </Accordion.Body>
            </Accordion.Item>
          )}
          {hasDental && (
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Dental Limitations and Exclusions
              </Accordion.Header>
              <Accordion.Body className="ml-6">
                {ameritasContent.dentalLimitations}
              </Accordion.Body>
            </Accordion.Item>
          )}
          {hasDental && (
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Orthodontia Limitations and Exclusions
              </Accordion.Header>
              <Accordion.Body className="ml-6">
                <p>
                  Covered Expenses will not include and no benefits will be
                  payable for expenses incurred:
                </p>
                <ul>
                  <li>
                    for a Program begun on or after the Insured's 19th birthday.
                  </li>
                  <li>
                    for a Program which uses a material other than metal
                    brackets for treatment. The benefit will be considered as
                    though metal brackets were applied.
                  </li>
                  <li>
                    for a Program begun before the Insured became covered under
                    this section,
                  </li>
                  <li>
                    in any quarter of a Program if the Insured was not covered
                    under this section for the entire quarter.
                  </li>
                  <li>for a Program more than once in a lifetime.</li>
                  <li>
                    if the Insured's insurance under this section terminates.
                  </li>
                  <li>
                    for which the Insured is entitled to benefits under any
                    workmen's compensation or similar law, or for charges for
                    services or supplies received as a result of any dental
                    condition caused or contributed to by an injury or sickness
                    arising out of or in the course of any employment for wage
                    or profit.
                  </li>
                  <li>
                    for charges the Insured is not legally required to pay or
                    would not have been made had no insurance been in force.
                  </li>
                  <li>
                    for services not required for necessary care and treatment
                    or not within the generally accepted parameters of care.
                  </li>
                  <li>because of war or any act of war, declared or not.</li>
                  <li>
                    to fix or repair broken or damaged orthodontic appliances.
                  </li>
                  <li>
                    to replace lost, missing or stolen orthodontic appliances.
                  </li>
                  <li>
                    for expenses incurred as a result of the Insured not being
                    compliant with the Treatment Program.
                  </li>
                  <li>
                    for services to alter vertical dimension and/or restore or
                    maintain the occlusion due to, but not limited to the
                    following: equilibration, periodontal splinting, full mouth
                    rehabilitation, and restoration for misalignment of teeth.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {hasDental && (
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Hearing Limitations and Exclusions
              </Accordion.Header>
              <Accordion.Body className="ml-6">
                <p>
                  Covered Expenses will not include and no benefits will be
                  payable for expenses incurred for:
                </p>
                <ul>
                  <li>
                    examinations performed before the Insured was covered under
                    this section.
                  </li>
                  <li>
                    any examination performed after the Insured's coverage under
                    this section ceases.
                  </li>
                  <li>
                    any hearing examination required by an employer as a
                    condition of employment, including but not limited to, any
                    mandatory worksite programs designed to satisfy OSHA hearing
                    conservation programs.
                  </li>
                  <li>
                    medical or surgical treatment of any part of the ear,
                    including but not limited to, cochlear implants, or tubes in
                    the ears.
                  </li>
                  <li>
                    which the Insured person is entitled to benefits under any
                    workers' compensation or similar law, or charges for
                    services or supplies received as a result of any hearing
                    loss caused or contributed to by an injury or sickness
                    arising out of or in the course of any employment for wage
                    or profit, including an occupational hearing loss.
                  </li>
                  <li>
                    charges for which the Insured person is not liable or which
                    would not have been made had no insurance been in force.
                  </li>
                  <li>
                    any procedure not shown in the Schedule of Hearing Care
                    Services.
                  </li>
                  <li>any treatment which is for cosmetic purposes.</li>
                  <li>
                    assistive hearing devices not listed in the Schedule of
                    Hearing Care Services, such as phone amplification, cellular
                    phone amplifier, hearing aid dehumidifier, loop system, etc.
                  </li>
                  <li>
                    charges for services not provided by a licensed provider,
                    such as an audiologist, hearing aid specialist,
                    otolaryngologist (ENT) or otologist (ear doctor), within the
                    scope of that license.
                  </li>
                  <li>
                    services which are not related to a conductive or
                    sensorineural hearing loss, such as any nonorganic hearing
                    loss or occupational hearing loss.
                  </li>
                  <li>
                    charges for a hearing screening performed as a part of or in
                    the course of any non-hearing routine examination.
                  </li>
                  <li>because of war or any act of war, declared or not.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {hasDental && (
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                LASIK Limitations and Exclusions
              </Accordion.Header>
              <Accordion.Body className="ml-6">
                <ul>
                  <li>
                    No benefit will be payable for any HCPCS Level II codes not
                    listed below in the definition of Covered Procedures.
                  </li>
                  <li>
                    No benefit will be payable for any Insured under the age of
                    18.
                  </li>
                  <li>
                    Each Insured Person is eligible for only one Covered
                    Procedure benefit payment per eye. No benefit will be
                    payable for multiple laser vision correction treatments on
                    the same eye.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          )}
        </Accordion>
      </Card>
    </ApplyWrapper>
  );
}

function getVisionLimitations(
  selectedVisionDisplayLabel: string
): React.ReactNode {
  let visionLimit: React.ReactNode = null;

  if (selectedVisionDisplayLabel.includes('Basic')) {
    visionLimit = (
      <>
        <p>
          Covered expenses will not include and no benefits will be payable for:
        </p>
        <ul>
          <li>
            Vision examinations, lenses and frames more than the frequency as
            indicated on the plan summary page.
          </li>
          <li>
            Examinations performed or frames or lenses ordered before the
            Insured was covered under this section.
          </li>
          <li>
            Any examination performed or frame or lens ordered after the
            Insured's coverage under this section ceases, subject to Extension
            of Benefits.
          </li>
          <li>
            Sub-normal vision aids; orthoptic or vision training or any
            associated testing.
          </li>
          <li>Non-prescription lenses.</li>
          <li>
            Replacement or repair of lost or broken lenses or frames except at
            normal intervals
          </li>
          <li>
            Any eye examination or corrective eyewear required by an employer as
            a condition of employment.
          </li>
          <li>Medical or surgical treatment of the eyes.</li>
          <li>
            Any service or supply not shown on the Schedule of Eye Care
            Services.
          </li>
          <li>
            Coated lenses; oversize lenses (exceeding 71 mm); photo-gray lenses;
            polished edges; UV-400 coating and facets, and tints other than
            solid.
          </li>
          <li>
            Lenses and frames during the first twelve months that a person is
            insured under this section, when the person is a Late Entrant, as
            defined.
          </li>
        </ul>
      </>
    );
  }

  if (selectedVisionDisplayLabel.includes('Choice')) {
    visionLimit = (
      <>
        <p>This plan does not cover:</p>
        <ul>
          <li>
            Services and/or materials not specifically included in this Schedule
            as covered Plan Benefits,
          </li>
          <li>
            Plano lenses (lenses with refractive correction of less than plus or
            minus .50 diopter) except as specifically allowed in the frames
            benefit section below,
          </li>
          <li>
            Services or materials that are cosmetic, including Plano contact
            lenses to change eye color and artistically painted Contact Lenses,
          </li>
          <li>Two pairs of glasses in lieu of Bifocals, </li>
          <li>
            Replacement of Spectacle Lenses, Frames, and/or contact lenses
            furnished under this plan that are lost or damaged, except at the
            normal intervals when services are otherwise available,
          </li>
          <li>
            Orthoptics or vision training and any associated supplemental
            testing,
          </li>
          <li>Medical or surgical treatment of the eyes, </li>
          <li>Contact lens modification, polishing or cleaning, </li>
          <li>
            The refitting of Contact Lenses after the initial 90-day filing
            period.
          </li>
          <li>Contact Lens insurance policies or service contracts. </li>
          <li>
            Additional office visits associated with contact lens pathology,
          </li>
          <li>
            Local, state and/or federal taxes, except where law requires us to
            pay,
          </li>
          <li>
            Membership fees for any retail center in which an Affiliate or Open
            Access provider office may be located. Covered persons may be
            required to purchase a membership in such entities as a condition of
            accessing Plan Benefits.
          </li>
        </ul>
      </>
    );
  }

  if (selectedVisionDisplayLabel.includes('Select')) {
    visionLimit = (
      <>
        <p>This plan has the following limitations:</p>
        <ul>
          <li>
            Vision examinations, lenses and frames more than the frequency as
            indicated on the plan summary page.
          </li>
          <li>
            This plan does not cover Medically Necessary Contact Lenses more
            than once in any 24-month period. The treating provider determines
            if an Insured meets the coverage criteria for this benefit as listed
            below. This benefit is in lieu of Elective Contact Lenses.
          </li>
          <ul>
            <li>
              For Keratoconus where the patient is not correctable to 20/30 in
              either or both eyes using standard spectacle lenses.
            </li>
            <li>
              Patients whose vision can be corrected two lines of improvement on
              the visual acuity chart when compared to best standard spectacle
              lens correction.
            </li>
            <li>Anisometropia of 3D or more.</li>
            <li>High Ametropia exceeding -10D or +10D in meridian powers. </li>
          </ul>
          <li>
            This plan does not cover Orthoptics or vision training and any
            associated testing.
          </li>
          <li>This plan does not cover Plano Lenses. </li>
          <li>This plan does not cover non-prescribed Lenses or sunglasses.</li>
          <li>
            This plan does not cover two pairs of glasses in lieu of Bifocals.
          </li>
          <li>
            This plan does not cover replacement of Lenses and Frames that are
            lost or broken outside of the normal coverage intervals.
          </li>
          <li>
            This plan does not cover medical or surgical treatment of the eyes
            or supporting structures.
          </li>
          <li>
            This plan does not cover services for claims filed more than one
            year after completion of the service. An exception is if the Insured
            shows it was not possible to submit the proof of loss within this
            period.
          </li>
          <li>
            This plan does not cover any procedure not listed on the Schedule of
            Eye Care Services.
          </li>
        </ul>
      </>
    );
  }
  return visionLimit;
}

type qcContent = {
  authorizationAgreement: React.ReactNode;
  fraudNotice: React.ReactNode;
  stateNotice: React.ReactNode;
  dentalLimitations: React.ReactNode;
};

export function getQCContent(
  state: string,
  ameritasRevision: AmeritasRevision
): qcContent {
  const ameritasContent = getAmeritasStateContent(state, ameritasRevision);
  return {
    authorizationAgreement: ameritasContent.authorizationAgreement,
    fraudNotice: ameritasContent.fraudNotice,
    stateNotice: ameritasContent.stateNotice,
    dentalLimitations: ameritasContent.dentalLimitations,
  };
}
