import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    fraudNotice: (
      <p>
        Any person who knowingly, and with intent to defraud or deceive any
        insurance company, files a statement or claim containing any false,
        incomplete, or misleading information is guilty of a felony.
      </p>
    ),
  },
};

export default stateModule;
