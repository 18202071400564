export const usDollarFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const usDollarsNoCentsFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});
