import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    fraudNotice: (
      <>
        <p>
          For your protection California law requires the following to appear on
          this form: Any person who knowingly presents false or fraudulent
          information to obtain or amend insurance coverage or to make a claim
          for the payment of a loss is guilty of a crime and may be subject to
          fines and confinement in state prison. California law prohibits an HIV
          test from being required or used by health insurance companies as a
          condition of obtaining health insurance coverage. For policies issued,
          amended, delivered, or renewed in California, dependent coverage
          includes individuals who are registered domestic partners and their
          dependents.
        </p>
        <p>
          No Cost Language Services. You can get an interpreter and have
          documents read to you in your language. For help, call us at the
          number listed on your ID card or 877-233-3797. For more help call the
          CA Dept. of Insurance at 800-927-4357.
        </p>
        <p>
          Servicios de idiomas sin costo. Puede obtener un intérprete y que le
          lean los documentos en español. Para obtener ayuda, llámenos al número
          que figura en su tarjeta de identificación o al 877-233-2797. Para
          obtener más ayuda, llame al Departamento de Seguros de CA al
          800-927-4357.
        </p>
      </>
    ),
  },
};

export default stateModule;
