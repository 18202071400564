import React from 'react';
import { Alert } from 'react-bootstrap';
import * as yup from 'yup';

import { updateApplication } from '@liferaft/api/resources/application';
import type { Application, Egress } from '@liferaft/api/types';
import type { ParsedDjangoErrorResponse } from '@liferaft/api/utils/django-utils';
import { NON_FIELD_ERROR_LABEL } from '@liferaft/api/utils/django-utils';
import { NetworkController } from '@liferaft/api/utils/network';

import { Card } from '@liferaft/core/components';
import type { FormErrorsSetter } from '@liferaft/core/components/forms';
import { Input, ValidatedForm } from '@liferaft/core/components/forms';
import { useDebounce } from '@liferaft/core/hooks';

import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';

const schema = yup.object({
  alias: yup.string().required().label('Application name'),
});

type FormData = yup.InferType<typeof schema>;

export function ApplicationAlias() {
  const {
    data: { egress, id },
    next,
  } = useApplicationFlow();
  const [alias, setAlias] = React.useState<string>(egress?.alias || '');

  const [handleSubmit, isSubmitting] = useDebounce(
    async (validatedData: FormData, setErrors: FormErrorsSetter<FormData>) => {
      const network = new NetworkController();

      const result = await network.request<FormData, Application>(
        updateApplication(id as string, validatedData)
      );

      if (result.error) {
        setErrors(result.data as ParsedDjangoErrorResponse<Egress>);
        return;
      }

      return next();
    }
  );

  return (
    <ApplyWrapper
      footer={
        <ApplyWrapperFooter
          footnote={
            <>
              <b>Remember</b> - All questions in this application are for your
              client.
            </>
          }
          rightDisabled={isSubmitting}
        />
      }
      heading="Name This Application"
      subheading="Name this application to ensure you can find it later.">
      <ValidatedForm<FormData>
        controlledData={{ alias }}
        id={APPLY_FORM_ID}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        {({ errors }) => (
          <Card cardClasses="card-bleed shadow-light">
            {errors[NON_FIELD_ERROR_LABEL] && (
              <Alert className="mb-3" variant="danger">
                <ul>
                  {errors[NON_FIELD_ERROR_LABEL].map(
                    (err: string, i: number) => (
                      <li key={i}>{err}</li>
                    )
                  )}
                </ul>
              </Alert>
            )}
            <Input
              data-testid="application-alias"
              error={errors.alias}
              id="application-alias"
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                setAlias(event.target.value)
              }
              placeholder="John Doe"
              required
              type="text"
              value={alias}
            />
          </Card>
        )}
      </ValidatedForm>
    </ApplyWrapper>
  );
}
