import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { Card } from '../card';
import './polar-choice-input.scss';

export type PolarChoice = {
  text?: string;
  value: string;
  icon: React.ReactNode;
};

type Props = {
  label?: string;
  groupClasses?: string;
  choiceOne: PolarChoice;
  choiceTwo: PolarChoice;
  value?: string;
  onChange: (value: any) => void;
};

export function PolarChoiceInput({
  label,
  groupClasses,
  choiceOne,
  choiceTwo,
  value,
  onChange,
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) onChange(event.target.value);
  };

  return (
    <Form.Group className={groupClasses} data-testid="polar-choice-form-group">
      {label && (
        <Form.Label className="form-label" id="polar-choice-prompt">
          {label}
        </Form.Label>
      )}
      <Row className="justify-content-center">
        <Form.Check
          aria-labelledby="polar-choice-prompt"
          checked={value == choiceOne.value}
          className="hide-input"
          data-testid="polar-choice-one"
          id="polar-choice-one"
          name="polarChoice"
          onChange={handleChange}
          required
          type="radio"
          value={choiceOne.value}
        />
        <Col md={4} xs={6}>
          <Form.Label className="block" htmlFor="polar-choice-one">
            <Card
              bodyClasses="text-center p-3"
              cardClasses="card-border-bottom border-primary shadow-light-lg lift mb-5 cursor-pointer">
              <div className="icon icon-lg text-primary my-3">
                {choiceOne.icon}
                {choiceOne.text && (
                  <p className="text-secondary my-3">{choiceOne.text}</p>
                )}
              </div>
            </Card>
          </Form.Label>
        </Col>
        <Form.Check
          aria-labelledby="polar-choice-prompt"
          checked={value == choiceTwo.value}
          className="hide-input"
          data-testid="polar-choice-two"
          id="polar-choice-two"
          name="polarChoice"
          onChange={handleChange}
          required
          type="radio"
          value={choiceTwo.value}
        />
        <Col md={4} xs={6}>
          <Form.Label className="block" htmlFor="polar-choice-two">
            <Card
              bodyClasses="text-center p-3"
              cardClasses="card-border-bottom border-primary shadow-light-lg lift mb-5 cursor-pointer">
              <div className="icon icon-lg text-primary my-3">
                {choiceTwo.icon}
                {choiceTwo.text && (
                  <p className="text-secondary my-3">{choiceTwo.text}</p>
                )}
              </div>
            </Card>
          </Form.Label>
        </Col>
      </Row>
    </Form.Group>
  );
}
