export enum Path {
  ALIAS = 'alias',
  RATING_DATA = 'rating-data',
  PRODUCT_MATRIX = 'product-matrix',
  PLAN_SUMMARY = 'plan-summary',
  CREATE_ACCOUNT = 'create-account',
  CONTACT_INFO = 'contact-info',
  DEPENDENTS = 'dependents',
  PAYMENT_INFO = 'payment-info',
  LIBERTY_DISCLAIMER = 'liberty-disclaimer',
  AIG_DISCLOSURES = 'aig-disclosures',
  AIG_DISCLAIMER = 'aig-disclaimer',
  AMERITAS_DISCLOSURES = 'ameritas-disclosures',
  AMERITAS_DISCLAIMER = 'ameritas-disclaimer',
  CONFIRM = 'confirm',
  SUBMIT = 'submit',
  FINISHED_NOTICE = 'finished',
}
