import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
    },
    planFacts: {
      dental: new Map([
        ['Access Plan In- and Out-of-Network Coinsurance', null],
        [
          'Lite Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/50/50 INN & 70/50/50 OON
            <br />
            Year 2 - 100/80/50 INN & 70/50/50 OON
          </>,
        ],
        [
          'Boost Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/65/50 INN & 80/50/50 OON
            <br />
            Year 2 - 100/80/50 INN & 80/60/50 OON
          </>,
        ],
        [
          'Complete Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/80/50 INN & 80/70/50 OON
            <br />
            Year 2 - 100/90/50 INN & 80/80/50 OON
          </>,
        ],
      ]),
    },
  },
};

export default stateModule;
