import React from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { Check } from 'react-feather';

import type { Offering } from '@liferaft/api/types';
import { CarrierKey, InsuranceProductKey, YesNo } from '@liferaft/api/types';

import type { AmeritasRevision } from '@liferaft/core/content/products/ameritas';

import { useProductMatrixContext } from '@/contexts';
import {
  getQuoteGridSections,
  getQuoteTableRowKeys,
  getQuoteTableRowLabels,
} from '@/pages/product-matrix/content';
import { getSummaryBenefitsList } from '@/pages/product-matrix/content';

import './quotes-grid.scss';

function dentalMatrixPdfLink(
  ameritasRevision: AmeritasRevision,
  displayLabel: string
): React.ReactNode {
  let linkFile = null;
  if (displayLabel.includes('Boost')) {
    if (ameritasRevision === '2024Q2') {
      linkFile = require('./amr_ca/CA_Matrix_Primestar_Boost_80001DENPA040MAN.pdf');
    } else {
      linkFile = require('./amr_ca/CA_Matrix_Primestar_PrimeStar_Boost.pdf');
    }
  }
  if (displayLabel.includes('Complete')) {
    if (ameritasRevision === '2024Q2') {
      linkFile = require('./amr_ca/CA_Matrix_Primestar_Complete_80001DENPB040MAN.pdf');
    } else {
      linkFile = require('./amr_ca/CA_Matrix_Primestar_PrimeStar_Complete.pdf');
    }
  }
  if (displayLabel.includes('Lite')) {
    if (ameritasRevision === '2024Q2') {
      linkFile = require('./amr_ca/CA_Matrix_Primestar_Lite_80001DENPC040MAN.pdf');
    } else {
      linkFile = require('./amr_ca/CA_Matrix_Primestar_PrimeStar_Lite.pdf');
    }
  }
  if (linkFile !== null) {
    return (
      <a href={linkFile} rel="noreferrer" target="_blank">
        Dental Matrix
      </a>
    );
  }
  return null;
}

function nySamplePolicyPdfLink(
  product: InsuranceProductKey,
  displayLabel: string
): React.ReactNode {
  let linkFile = null;
  if (product === InsuranceProductKey.DENTAL) {
    if (displayLabel.includes('Boost'))
      linkFile = require('./amr_ny/Sample_NY_Policy_Primestar_Boost.pdf');
    if (displayLabel.includes('Complete'))
      linkFile = require('./amr_ny/Sample_NY_Policy_Primestar_Complete.pdf');
    if (displayLabel.includes('Lite'))
      linkFile = require('./amr_ny/Sample_NY_Policy_Primestar_Lite.pdf');
  }
  if (product === InsuranceProductKey.VISION) {
    if (displayLabel.includes('Choice'))
      linkFile = require('./amr_ny/Sample_NY_Policy_Primestar_Choice.pdf');
    if (displayLabel.includes('Select'))
      linkFile = require('./amr_ny/Sample_NY_Policy_Primestar_Select.pdf');
  }

  if (linkFile !== null) {
    return (
      <a href={linkFile} rel="noreferrer" target="_blank">
        NY Sample Policy
      </a>
    );
  }
  return null;
}

function getBenefits(
  product: string,
  benefitKey: string,
  quote: Offering
): string | React.ReactNode {
  if (
    product === InsuranceProductKey.ACCIDENT ||
    product === InsuranceProductKey.HOSPITAL ||
    product === InsuranceProductKey.CRITICAL
  ) {
    return `$${quote.details.benefits[benefitKey]?.toLocaleString() || ''}`;
  }

  if (
    product === InsuranceProductKey.DENTAL ||
    product === InsuranceProductKey.VISION
  ) {
    const benefits = quote.details.benefits[benefitKey] as string[];
    const benefitItems = benefits.map((benefit, i) => (
      <li key={i}>{benefit}</li>
    ));
    return <ul className="list-unstyled">{benefitItems}</ul>;
  }
}

type Props = {
  productKey: InsuranceProductKey;
  ameritasRevision: AmeritasRevision;
};

export function QuotesGrid({ productKey, ameritasRevision }: Props) {
  const context = useProductMatrixContext();

  const selectedOfferingID =
    context.getSelectedInsuranceOfferingByProduct(productKey)?.offering_id;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked)
      context.saveInsuranceOfferingID(
        productKey as InsuranceProductKey,
        event.target.value
      );
  };

  const handleClick = (id: string): void => {
    context.saveInsuranceOfferingID(productKey as InsuranceProductKey, id);
  };

  const selectedCoverage = context.config[productKey].coverageSelected;
  const selectedCarrier = context.config[productKey]
    .carrierSelected as CarrierKey;

  const carriers = context.matrix?.products[productKey]?.carriers;
  let availableCarriers: string[] = [];
  if (carriers) {
    availableCarriers = Object.keys(carriers);
  }

  let offerings: Offering[] = [];
  if (
    availableCarriers &&
    carriers &&
    selectedCarrier &&
    availableCarriers.includes(selectedCarrier)
  ) {
    offerings = carriers[selectedCarrier]?.offerings || [];
  }

  let availableOfferings: Offering[] = [];

  if (productKey === InsuranceProductKey.HOSPITAL) {
    availableOfferings = offerings?.filter(
      (offering) =>
        offering.carrier === selectedCarrier &&
        offering.coverage_type === selectedCoverage &&
        offering.has_non_insurance ===
          (selectedCarrier === CarrierKey.AIG
            ? context?.acceptedHospitalServices === YesNo.YES
            : false)
    );
  } else {
    availableOfferings = offerings?.filter(
      (offering) =>
        offering.carrier === selectedCarrier &&
        offering.coverage_type === selectedCoverage
    );
  }

  let quotes = availableOfferings.sort((a, b) => a.level - b.level);

  const hasCancerSplit = quotes.some(
    (quote) => quote.details.cancer_only === true
  );
  const tableSections = getQuoteGridSections(productKey, selectedCarrier);
  if (productKey === InsuranceProductKey.CRITICAL && hasCancerSplit) {
    const criticalQuotes = quotes?.slice(0);
    quotes = criticalQuotes?.sort(
      (a, b) => Number(b.details.cancer_only) - Number(a.details.cancer_only)
    );
  }

  let benefitKeys: string[] = [];
  let rowLabels: Record<string, string> = {};
  if (selectedCoverage && context.residentState) {
    benefitKeys = getQuoteTableRowKeys(
      productKey,
      selectedCarrier,
      selectedCoverage,
      context.residentState
    );
    rowLabels = getQuoteTableRowLabels(
      productKey,
      selectedCarrier,
      selectedCoverage,
      context.residentState
    );
  }

  return (
    <div>
      {selectedCarrier && selectedCoverage && availableOfferings && (
        <>
          {/* display as table for desktop */}
          <div className="d-none d-xl-block">
            <Table className="w-100 quote-border quotes-table">
              <thead>
                {productKey === InsuranceProductKey.CRITICAL &&
                  hasCancerSplit && (
                    <tr>
                      <th className="w-max-200 border-bottom-0 p-0" />
                      <th
                        className="p-0 align-middle rounded-top plan__border plan__border-top plan__border-left plan__border-right border-bottom-0"
                        colSpan={availableOfferings.length / 2}>
                        <h3 className="font-weight-bold mb-0 bg-secondary text-white px-6 py-3 mb-3 rounded">
                          {tableSections[0]}
                        </h3>
                      </th>
                      <th
                        className="p-0 align-middle rounded-top plan__border plan__border-top plan__border-left plan__border-right border-bottom-0"
                        colSpan={availableOfferings.length / 2}>
                        <h3 className="font-weight-bold mb-0 bg-primary text-white px-6 py-3 mb-3 rounded">
                          {tableSections[1]}
                        </h3>
                      </th>
                    </tr>
                  )}
                <tr>
                  <th className="w-max-200 border-bottom-0 " />
                  {quotes &&
                    quotes.map((quote, index) => {
                      return (
                        <th
                          className={
                            selectedOfferingID === quote.offering_id
                              ? 'align-middle rounded-top plan__border plan__border-top plan__border-left plan__border-right border-bottom-0 bg-primary-soft active'
                              : 'align-middle rounded-top plan__border plan__border-top plan__border-left plan__border-right border-bottom-0'
                          }
                          key={index}>
                          <h5 className="font-weight-bold">
                            {`${quote.display_label} Plan`}
                          </h5>
                          {productKey === InsuranceProductKey.CRITICAL &&
                            hasCancerSplit && (
                              <div>
                                <span className="h4 mb-0 mt-2">$</span>{' '}
                                <h2 className="price mb-0 font-weight-bold">
                                  {quote.monthly_premium}
                                </h2>{' '}
                                <span className="h4 align-self-end mb-1">
                                  /mo
                                </span>
                              </div>
                            )}
                          {!hasCancerSplit && (
                            <div>
                              <span className="h3 mb-0 mt-2">$</span>{' '}
                              <span className="price display-4 mb-0 font-weight-bold">
                                {quote.monthly_premium}
                              </span>{' '}
                              <span className="h3 align-self-end mb-1">
                                /mo
                              </span>
                            </div>
                          )}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {context.residentState === 'CA' &&
                  productKey === InsuranceProductKey.DENTAL && (
                    <tr key="dentalmatrix">
                      <td />
                      {quotes?.map((quote, index) => (
                        <td
                          className={
                            selectedOfferingID === quote.offering_id
                              ? 'align-middle plan__border plan__border-right plan__border-left bg-primary-soft active'
                              : 'align-middle plan__border plan__border-right plan__border-left'
                          }
                          key={index}>
                          {dentalMatrixPdfLink(
                            ameritasRevision,
                            quote.display_label
                          )}
                        </td>
                      ))}
                    </tr>
                  )}
                {context.residentState === 'NY' &&
                  (productKey === InsuranceProductKey.DENTAL ||
                    productKey === InsuranceProductKey.VISION) && (
                    <tr key="nysamplepolicy">
                      <td />
                      {quotes?.map((quote, index) => (
                        <td
                          className={
                            selectedOfferingID === quote.offering_id
                              ? 'align-middle plan__border plan__border-right plan__border-left bg-primary-soft active'
                              : 'align-middle plan__border plan__border-right plan__border-left'
                          }
                          key={index}>
                          {nySamplePolicyPdfLink(
                            productKey,
                            quote.display_label
                          )}
                        </td>
                      ))}
                    </tr>
                  )}
                {benefitKeys &&
                  benefitKeys.length > 0 &&
                  quotes &&
                  benefitKeys.map((benefitKey: string) => {
                    return (
                      <tr key={benefitKey}>
                        <td className="w-max-200">
                          <p>{rowLabels[benefitKey]}</p>
                        </td>
                        {quotes?.map((quote, index) => {
                          return (
                            <td
                              className={
                                selectedOfferingID === quote.offering_id
                                  ? 'align-middle plan__border plan__border-right plan__border-left bg-primary-soft active'
                                  : 'align-middle plan__border plan__border-right plan__border-left'
                              }
                              key={index}>
                              {getBenefits(productKey, benefitKey, quote)}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                <tr>
                  <td className="w-max-200">
                    <Button
                      className={
                        selectedOfferingID === ''
                          ? 'btn-block btn-outline-primary'
                          : 'btn-block'
                      }
                      onClick={() => handleClick('')}
                      type="button"
                      variant="primary-soft">
                      No, Thanks
                    </Button>
                  </td>
                  {quotes &&
                    quotes.map((quote, index) => {
                      return (
                        <td
                          className={
                            selectedOfferingID === quote.offering_id
                              ? 'align-middle plan__border plan__border-right plan__border-left plan__border-bottom rounded-bottom bg-primary-soft active'
                              : 'align-middle plan__border plan__border-right plan__border-left plan__border-bottom rounded-bottom'
                          }
                          key={index}>
                          <Button
                            className="btn-block"
                            id={quote.offering_id}
                            onClick={() => handleClick(quote.offering_id)}
                            type="button"
                            variant="primary-soft">
                            Select
                          </Button>
                        </td>
                      );
                    })}
                </tr>
              </tbody>
            </Table>
          </div>
          {/* display as cards for screens smaller than desktop */}
          <div className="d-block d-xl-none">
            <Form.Group data-testid={`${productKey} plan select cards`}>
              {quotes &&
                (productKey !== InsuranceProductKey.CRITICAL ||
                  (productKey === InsuranceProductKey.CRITICAL &&
                    !hasCancerSplit)) && (
                  <>
                    <QuotesCardRow
                      benefitKeys={benefitKeys}
                      handleChange={handleChange}
                      product={productKey}
                      quotes={quotes}
                      rowLabels={rowLabels}
                      selection={selectedOfferingID}
                    />
                    <Row>
                      <Col className="d-flex w-100 mb-3" xs={12}>
                        <div className="font-size-lg font-weight-bold">
                          Decline Coverage
                        </div>
                        <div className="strike-line" />
                      </Col>
                      <Col xs={12}>
                        <div className="w-100">
                          <div
                            className={
                              selectedOfferingID == ''
                                ? 'card mb-6 hover-pointer border border-primary w-max-300 m-auto'
                                : 'card mb-6 hover-pointer border w-max-300 m-auto'
                            }>
                            <Form.Check.Label
                              htmlFor={`${productKey}-selection-decline`}>
                              <div className="px-4 pt-4 pb-6">
                                <div className="text-center mb-3">
                                  <p className="mb-1">No, thanks</p>
                                  <Form.Check
                                    checked={selectedOfferingID == ''}
                                    className="text-center mb-3 mt-3"
                                    data-testid={`${productKey}-selection-decline`}
                                    id={`${productKey}-selection-decline`}
                                    name={`${productKey}Select Decline`}
                                    onChange={handleChange}
                                    type="radio"
                                    value=""
                                  />
                                </div>
                              </div>
                            </Form.Check.Label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              {quotes &&
                productKey === InsuranceProductKey.CRITICAL &&
                hasCancerSplit && (
                  <>
                    <div className="d-flex w-100 align-items-center mb-3">
                      <h3 className="font-weight-bold">{tableSections[0]}</h3>
                    </div>
                    <QuotesCardRow
                      benefitKeys={benefitKeys}
                      handleChange={handleChange}
                      product={productKey}
                      quotes={quotes.slice(0, availableOfferings.length / 2)}
                      rowLabels={rowLabels}
                      selection={selectedOfferingID}
                    />
                    <div className="d-flex w-100 align-items-center mb-3">
                      <h3 className="font-weight-bold">{tableSections[1]}</h3>
                    </div>
                    <QuotesCardRow
                      benefitKeys={benefitKeys}
                      handleChange={handleChange}
                      product={productKey}
                      quotes={quotes.slice(availableOfferings.length / 2)}
                      rowLabels={rowLabels}
                      selection={selectedOfferingID}
                    />
                    <Row>
                      <Col className="d-flex w-100 mb-3" xs={12}>
                        <div className="font-size-lg font-weight-bold">
                          Decline Coverage
                        </div>
                        <div className="strike-line" />
                      </Col>
                      <Col xs={12}>
                        <div className="w-100">
                          <div
                            className={
                              selectedOfferingID == ''
                                ? 'card mb-6 hover-pointer border border-primary w-max-300 m-auto'
                                : 'card mb-6 hover-pointer border w-max-300 m-auto'
                            }>
                            <Form.Check.Label
                              htmlFor={`${productKey}-selection-decline`}>
                              <div className="card-body">
                                <div className="text-center mb-3">
                                  <p className="mb-1">No, thanks</p>
                                  <Form.Check
                                    checked={selectedOfferingID == ''}
                                    className="text-center mb-3 mt-3"
                                    data-testid={`${productKey}-selection-decline`}
                                    id={`${productKey}-selection-decline`}
                                    name={`${productKey}Select Decline`}
                                    onChange={handleChange}
                                    type="radio"
                                    value=""
                                  />
                                </div>
                              </div>
                            </Form.Check.Label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
            </Form.Group>
          </div>
        </>
      )}
    </div>
  );
}

type QuotesCardRowProps = {
  product: string;
  quotes: Offering[];
  benefitKeys: string[];
  rowLabels: Record<string, string>;
  selection: string | undefined;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function QuotesCardRow({
  product,
  quotes,
  benefitKeys,
  rowLabels,
  selection,
  handleChange,
}: QuotesCardRowProps) {
  const context = useProductMatrixContext();

  return (
    <div className="d-flex flex-column">
      <div className="d-inline-block m-auto">
        {quotes?.map((quote, index) => {
          return (
            <div key={index}>
              <div
                className={
                  selection === quote.offering_id
                    ? 'card mb-6 hover-pointer border border-primary'
                    : 'card mb-6 hover-pointer border'
                }>
                <Form.Check.Label
                  htmlFor={`${product}-selection-${quote.offering_id}`}>
                  <div className="px-6 pt-6 pb-8">
                    <div className="text-center mb-3">
                      <span className="badge badge-pill badge-primary badge-wrap">
                        <span className="h6 text-uppercase">{`${quote.display_label} Plan`}</span>
                      </span>
                    </div>
                    <div className="d-flex justify-content-center text-secondary">
                      <h2 className="mb-0 mt-2">$</h2>
                      <span className="price display-4 mb-0 font-weight-bold">
                        {quote.monthly_premium}
                      </span>
                      <span className="h2 align-self-end mb-1">/mo</span>
                    </div>
                    <p className="text-center text-muted mt-4">
                      Benefit Amounts
                    </p>
                    <div className="d-flex flex-column justify-content-center">
                      {(product === InsuranceProductKey.DENTAL ||
                        product === InsuranceProductKey.VISION) &&
                        context.residentState &&
                        getSummaryBenefitsList(
                          product,
                          quote,
                          context.residentState
                        ).map((benefit: string, i: number) => {
                          return (
                            <div className="d-flex d-inline-block" key={i}>
                              <div className="badge badge-rounded-circle">
                                <Check />
                              </div>
                              <p>{benefit}</p>
                            </div>
                          );
                        })}
                      {product !== InsuranceProductKey.DENTAL &&
                        product !== InsuranceProductKey.VISION &&
                        benefitKeys.length > 0 &&
                        quotes &&
                        benefitKeys.map((benefitKey: string, benefitIndex) => {
                          return (
                            <div
                              className="d-flex d-inline-block"
                              key={benefitIndex}>
                              <div className="badge badge-rounded-circle">
                                <Check />
                              </div>
                              <p>{`${getBenefits(product, benefitKey, quote)} ${
                                rowLabels[benefitKey]
                              }`}</p>
                            </div>
                          );
                        })}
                    </div>
                    <Form.Check
                      checked={selection == quote.offering_id}
                      className="text-center"
                      data-testid={`${product}-selection-${index}`}
                      id={`${product}-selection-${quote.offering_id}`}
                      name={`${product}Select ${quote.offering_id}`}
                      onChange={handleChange}
                      type="radio"
                      value={quote.offering_id}
                    />
                  </div>
                </Form.Check.Label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
