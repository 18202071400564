import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  revisions: {
    '2024Q2': {
      isBaseState: {
        dental: false,
      },
    },
  },
  zipcodeOverrides: [
    {
      zipcodes: [
        ['82100', '82199'],
        ['82200', '82299'],
        ['82700', '82799'],
        ['82800', '82899'],
        ['83000', '83099'],
      ] as const,
      overrides: {
        revisions: {
          '2024Q2': {
            planFacts: {
              dental: new Map([
                ['Lite Plan In- and Out-of-Network Coinsurance', null],
                [
                  'Lite Plan Coinsurance',
                  <>
                    Year 1 - 100/50/10
                    <br />
                    Year 2 - 100/80/20
                  </>,
                ],
                ['Boost Plan In- and Out-of-Network Coinsurance', null],
                [
                  'Boost Plan Coinsurance',
                  <>
                    Year 1 - 100/65/20
                    <br />
                    Year 2 - 100/80/50
                  </>,
                ],
                ['Complete Plan In- and Out-of-Network Coinsurance', null],
                [
                  'Complete Plan Coinsurance',
                  <>
                    Year 1 - 100/80/20
                    <br />
                    Year 2 - 100/90/50
                  </>,
                ],
              ]),
            },
            planFactsFootnote: {
              dental: (
                <>
                  Claim allowance (Indemnity): Covered benefits are paid at the
                  80th percentile of usual and customary (80th U&C) charges.
                  This means we expect 8 out of 10 charges from dental providers
                  to be within the amount insurance pays for a covered
                  procedure. You pay the difference between what the plan pays
                  and the dentist's actual charge.
                </>
              ),
            },
          },
        },
      },
    },
  ],
};

export default stateModule;
