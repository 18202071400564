import React from 'react';
import { Row } from 'react-bootstrap';

import type { Offering, ProductKey, YesNo } from '@liferaft/api/types';

import { Card } from '@liferaft/core/components';

import { YesNoSelect } from '@/components';
import { useProductMatrixContext } from '@/contexts';
import type { Service } from '@/pages/product-matrix/content';

type Props = {
  productKey: ProductKey;
  servicesName: string;
  services: Service[];
};

export function ServicesCard({ productKey, servicesName, services }: Props) {
  const context = useProductMatrixContext();

  const selectedCoverage = context.config[productKey].coverageSelected;
  const selectedCarrier = context.config[productKey].carrierSelected;

  const offerings: Offering[] =
    context.matrix?.products[productKey]?.carriers[selectedCarrier]
      ?.offerings || [];

  const availableOfferings = offerings?.filter(
    (offering) =>
      offering.carrier === selectedCarrier &&
      offering.coverage_type === selectedCoverage &&
      offering.has_non_insurance === true
  );

  const availableServicesCost: string =
    availableOfferings[0].monthly_non_insurance_premium || '0';

  const handleAddServices = (selection: YesNo) => {
    context.selectHospitalServices(selection);
  };

  return (
    <Card cardClasses="my-5">
      <Row className="col-12">
        <div className="d-flex flex-wrap w-100 justify-content-between align-items-top mb-3">
          <div>
            <h3 className="font-weight-bold mb-6">{servicesName}</h3>
            {services.length > 0 &&
              services.map((element, index) => {
                return (
                  <div className="d-flex w-max-700 mb-3" key={index}>
                    <div>
                      <div className="icon icon-sm mr-3 text-secondary border border-2 rounded-circle border-primary p-4">
                        {element.icon}
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="font-weight-bold">{element.title}</div>
                      <div>{element.description}</div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div>
            <h5 className="font-weight-bold mb-0">Cost</h5>
            <div>
              <span className="price display-4 mb-0 font-weight-bold">
                {`$${availableServicesCost}`}
              </span>
              <span className="align-self-end mb-1 h3"> /mo</span>
            </div>
          </div>
        </div>
      </Row>
      <YesNoSelect
        falseLabel="No, thanks"
        idPrefix={productKey}
        onChange={handleAddServices}
        trueLabel="Yes, add non-insurance services"
        value={context?.acceptedHospitalServices}
      />
    </Card>
  );
}
