import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
    },
    fraudNotice: (
      <p>
        WARNING: Any person who knowingly, and with intent to injure, defraud or
        deceive any insurer, makes any claim for the proceeds of an insurance
        policy containing any false, incomplete or misleading information is
        guilty of a felony.
      </p>
    ),
    planFacts: {
      dental: new Map([
        ['Lite Plan In- and Out-of-Network Coinsurance', null],
        [
          'Lite Plan Coinsurance',
          <>
            Year 1 - 100/50/10
            <br />
            Year 2 - 100/80/20
          </>,
        ],
      ]),
    },
  },
};

export default stateModule;
