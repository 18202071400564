import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
      vision: false,
    },
    fraudNotice: (
      <p>
        Any person who knowingly presents a false or fraudulent claim for the
        payment of a loss is guilty of a crime and may be subject to fines and
        confinement in state prison.
      </p>
    ),
    planFacts: {
      dental: new Map([
        ['Access Plan In- and Out-of-Network Coinsurance', null],
        ['Lite Plan In- and Out-of-Network Coinsurance', null],
        [
          'Lite Plan Coinsurance',
          <>
            Year 1 - 100/50/10
            <br />
            Year 2 - 100/80/20
          </>,
        ],
        ['Boost Plan In- and Out-of-Network Coinsurance', null],
        [
          'Boost Plan Coinsurance',
          <>
            Year 1 - 100/65/20
            <br />
            Year 2 - 100/80/50
          </>,
        ],
        ['Complete Plan In- and Out-of-Network Coinsurance', null],
        [
          'Complete Plan Coinsurance',
          <>
            Year 1 - 100/80/20
            <br />
            Year 2 - 100/90/50
          </>,
        ],
      ]),
      vision: new Map([
        [
          'Medically Necessary Contacts',
          <>
            No coverage is provided for Medically Necessary Contacts in the
            state of Texas
          </>,
        ],
        [
          'Enrollment Start Date',
          <p key="description">
            Coverage begins next-day after application is submitted.
          </p>,
        ],
        [
          'Premium Contacts Deductible',
          <p key="description">
            Select Vision Plan: 90% of retail charge minus $55 allowance for
            premium contact lens fit and exam
            <br />
            Choice Vision Plan: Up to $60
          </p>,
        ],
        [
          'Select Vision Plan Partner',
          <p key="description">
            Select Vision Plan features the EyeMed vision network. Find EyeMed
            Access network providers at{' '}
            <a href="https://eyemed.com" rel="noreferrer" target="_blank">
              eyemed.com
            </a>
            . Retail chains accepting EyeMed insurance include LensCrafters,
            Pearle Vision and Target Optical. Online options in the EyeMed
            network are{' '}
            <a href="https://www.glasses.com" rel="noreferrer" target="_blank">
              Glasses.com
            </a>{' '}
            and{' '}
            <a
              href="https://www.contactsdirect.com"
              rel="noreferrer"
              target="_blank">
              ContactsDirect
            </a>
            . Based on applicable laws, reduced cost may vary by doctor
            location.
          </p>,
        ],
        [
          'Select Vision Plan Out-of-Network Maximum',
          <p key="description">
            $50 exams, $70 glasses frames, glasses lenses - $50 single vision,
            $75 bifocal, $75 progressive, $100 trifocal, $75 lenticular, $105
            elective contacts, $40 contacts standard & premium fit and follow-up
          </p>,
        ],
        [
          'Choice Vision Plan Partner',
          <p key="description">
            Choice Vision Plan features the VSP vision network. Find VSP Choice
            network providers at{' '}
            <a href="https://www.vsp.com" rel="noreferrer" target="_blank">
              vsp.com
            </a>
            . Retail chains accepting VSP insurance include Costco Optical*,
            Sam's Club, Visionworks and Walmart. An online option in the VSP
            network is{' '}
            <a href=" https://www.eyeconic.com" target="_blank">
              eyeconic.com
            </a>
            . Based on applicable laws, reduced cost may vary by doctor
            location.
            <br />
            <small>
              * Not all providers at Costco locations are VSP network providers
              - verify before seeking services.
            </small>
          </p>,
        ],
        [
          'Choice Vision Plan Out-of-Network Maximum',
          <p key="description">
            $45 exams, $70 glasses frames, glasses lenses - $30 single vision,
            $50 bifocal, $50 progressive, $65 trifocal, $100 lenticular, $105
            elective contacts
          </p>,
        ],
      ]),
    },
  },
};

export default stateModule;
