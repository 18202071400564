import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    fraudNotice: (
      <p>
        A person who knowingly, and with intent to defraud an insurer files a
        statement of claim containing any false, incomplete, or misleading
        information commits a felony.
      </p>
    ),
  },
};

export default stateModule;
