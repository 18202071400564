/*
----- Types -----
*/

export type ACHInfo = {
  account_number: string;
  bank_name: string;
  routing_number: string;
};

export type Admin = {
  firstName: string;
  lastName: string;
  email: string;
};

// address type for packages/api/src/resources/address
// used in Member, Landlord, and Property types
export type Address = {
  city: string;
  created_at: string;
  effective_types: string[];
  effective_types_display: string[];
  id: string;
  nickname?: string;
  postal_code: string;
  state: string;
  street_address_1: string;
  street_address_2?: string;
  types?: AddressType[];
  types_display: string[];
  updated_at: string;
  user_id: string;
};

export type AddressData = {
  city: string;
  primary: string;
  secondary?: string;
  state: string;
  zipCode: string;
};

export type Application = {
  application_data_email: string;
  application_data_first_name: string;
  application_data_last_name: string;
  broker_details: Referrer;
  created_at: string;
  egress: InProgressEgress;
  flow_key: ApplicationFlowKey;
  flow_state: { stale_fields: string[] };
  id: string;
  label: string;
  product_selections: Product[];
  referrer_details: Referrer;
  short_uuid: string;
  source: string;
  status: ApplicationStatus;
  status_display: string;
  type: string;
  updated_at: string;
  user_id: string;
  was_filled_out_by_broker: boolean;
  origin: string;
};

export type ApplicationAddress = {
  primary: string;
  secondary?: string;
  city: string;
  state: string;
  zip_code: string;
};

export type ApplicationStats = {
  num_in_progress_client_referral: number;
  num_in_progress_broker: number;
  num_finished_all: number;
  num_finished_last_30_days: number;
};

export type AvailableProduct = {
  display_name: string;
  carriers: Partial<Record<CarrierKey, Carrier>>;
};

export type Benefit = {
  amount: number | string[];
  display_name: string;
  type: string;
  unit_name: string;
};

export type BrokerGroupCensus = {
  id: string;
  organization_id: string;
  census_file: File;
  census_file_url: string;
  error_file_url: string;
  result_file_url: string;
  status: string;
  label: string;
  updated_at: string;
};

export type CareerJob = {
  about_you: string[];
  bonus_nice_to_haves: string[];
  created_at: string;
  deleted_at: string;
  description: string;
  experiences: string[];
  id: string;
  is_active: boolean;
  name: string;
  ordering_priority: number;
  sample_tasks: string[];
  slug: string;
  summary: string;
  team_id: string;
  team_name: string;
  updated_at: string;
};

export type CareerTeam = {
  created_at: string;
  deleted_at: string;
  id: string;
  jobs: CareerJob[];
  name: string;
  ordering_priority: number;
  summary: string;
  updated_at: string;
};

export type Carrier = {
  coverages: CoverageType[];
  has_non_insurance: boolean;
  offerings: Offering[];
};

export type Claim = {
  created_at: string;
  id: string;
  policy_id: string;
  status: string;
  status_display: string;
  user_id: string;
};

export type CreditCardInfo = {
  name: string;
  number: string;
  cvc: string;
  nickname: string;
  expiration_date: string;
  bin: string;
  last4: string;
  type: string;
};

export type COBRAElection = {
  created_at: string;
  elected_on: string;
  end_date: string;
  id: string;
  payment_method_id?: string;
  qualifying_life_event_id: string;
  start_date: string;
};

export type Dependent = {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  sex: Sex;
};

export type DependentData = {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  sex: string;
};

export type DisclosureAcknowledgement = {
  created_at: string;
  content?: string;
  disclosure_type: DisclosureType;
  id: string;
  related_uuid?: string;
  response: DisclosureAcknowledgementResponse;
  signature?: string;
  user_id: string;
};

export type DistributionPartner = {
  created_at: string;
  id: string;
  updated_at: string;
  npn?: string;
  enrollment_url?: string;
};

export type Document = {
  display_name: string;
  type: string;
  url: string;
};

export type Egress = {
  address: ApplicationAddress;
  ach_info: ACHInfo;
  aig_document_delivery?: DocumentDelivery;
  aig_electronic_delivery_consent?: boolean;
  alias: string;
  amr_has_current_coverage?: boolean;
  amr_replacement_disclosure?: boolean;
  amr_has_fully_insured_dental?: boolean;
  amr_current_coverage_company?: string;
  amr_current_coverage_policy?: string;
  amr_current_dental_coverage_termination?: string;
  amr_document_delivery?: DocumentDelivery;
  amr_electronic_delivery_consent?: boolean;
  amr_electronic_delivery_email?: string;
  ca_has_current_coverage?: boolean;
  credit_card: CreditCardInfo;
  date_of_birth: string;
  doctor_visits: UnderwritingLevels;
  email: string;
  exercise: UnderwritingLevels;
  experience_key: ExperienceKey;
  first_name: string;
  fl_current_coverage_company?: string;
  fl_current_coverage_policy_number?: string;
  is_tobacco_user: boolean;
  last_name: string;
  lib_document_delivery?: DocumentDelivery;
  lib_electronic_delivery_consent?: boolean;
  middle_name?: string;
  moov_tos_token: string;
  password?: string;
  payment_via: PaymentType;
  phone_number: string;
  plaid_link_account_id?: string;
  raw_phone_number: string;
  product_matrix: ProductMatrix;
  product_selections: ProductSelection[];
  replacement_disclosure: boolean;
  selected_sex: Sex;
  spouse?: Dependent;
  children?: Dependent[];
  state: string;
  zip_code: string;
};

export type FlowProgress = {
  current_step: string;
  history: string[];
};

export type Group = {
  billing_account_id?: string;
  city: string;
  created_at: string;
  id: string;
  legal_name: string;
  payment_account_id: string;
  phone_number?: string;
  plaid_link_account_id?: string;
  postal_code: string;
  state: string;
  street_address_1: string;
  street_address_2: string;
  tax_id_number: string;
  updated_at: string;
  payment_methods: PaymentMethod[];
  reimbursement_account_id?: string;
  documents: GroupDocument[];
  servicer?: Servicer;
};

export type GroupAdmin = {
  created_at: string;
  group_id: string;
  id: string;
  title?: string;
  updated_at: string;
  user_email: string;
  user_first_name: string;
  user_id: string;
  user_last_name: string;
  user_phone_number?: string;
};

export enum ICHRAFundingMode {
  EMPLOYEE_REIMBURSED = 'employee_reimbursed',
  EMPLOYER_PURCHASED = 'employer_purchased',
}

interface IGroupBenefit<T extends GroupBenefitType> {
  id: string;
  benefit_type: T;
  benefit_type_display: string;
  group_id: string;
  group_name: string;
  product_id: string;
  product_code: string;
  product_name: string;
  created_at: string;
  updated_at: string;
}

export interface IGroupPlanYear {
  admin_fee_per_member: string;
  benefit_id: string;
  charge_enrolled_only: boolean;
  created_at: string;
  documents: GroupDocument[];
  end_date: string;
  enrollment_deadline: string;
  id: string;
  start_date: string;
  updated_at: string;
}

export type GuideMessage = {
  content: string;
  created_at: string;
  id: string;
  session_id: string;
  user_id: string;
};

export type GuideSession = {
  created_at: string;
  id: string;
  messages: GuideMessage[];
  model: LanguageModel;
  name?: string;
  user_id: string;
};

export interface IYearlyBenefit<Y extends IGroupPlanYear> {
  plan_years: Y[];
}

export interface IHRAPlanYear {
  does_accrue: boolean;
  does_rollover: boolean;
  individual_level_funding_amount: string;
  family_level_funding_amount: string;
  funding_frequency: FundingFrequency;
  expense_claim_filing_process: string;
  expense_claim_submission_deadline: string;
  covered_expenses: string[];
}

export type ICHRAPlanYear = {
  allow_auto_reimbursement?: boolean;
} & IGroupPlanYear &
  IHRAPlanYear;

export type HRAPlanYear = {
  rollover_policy?: string;
} & IGroupPlanYear &
  IHRAPlanYear;

export type LevelFundedMECPlanYear = {
  reserve_amount_per_member: string;
} & IGroupPlanYear;

export type ICHRA = {
  funding_mode: ICHRAFundingMode;
} & IGroupBenefit<GroupBenefitType.ICHRA> &
  IYearlyBenefit<ICHRAPlanYear>;

export enum HRAType {
  GROUP_HRA = 'group_hra',
  QSEHRA = 'qsehra',
}

export type HRA = {
  hra_type: HRAType;
  hra_type_display: string;
  lifetime_max: string;
} & IGroupBenefit<GroupBenefitType.GROUP_HRA | GroupBenefitType.QSEHRA> &
  IYearlyBenefit<HRAPlanYear>;

export enum SupplementalFundingMode {
  EMPLOYEE_PURCHASED = 'employee_purchased',
  EMPLOYEE_REIMBURSED = 'employee_reimbursed',
  EMPLOYER_PURCHASED = 'employer_purchased',
}

export type Supplemental = {
  admin_fee_per_member: string;
  carrier: string;
  charge_enrolled_only: boolean;
  display_carrier: string;
  documents: GroupDocument[];
  enrollment_deadline: string;
  supplemental_funding_mode: SupplementalFundingMode;
  supplemental_funding_mode_display: string;
  level: string;
  product_type: InsuranceProductKey;
  product_type_display: string;
} & IGroupBenefit<GroupBenefitType.SUPPLEMENTAL>;

export type GroupPlanYear = HRAPlanYear | ICHRAPlanYear;

export type GroupBenefit = HRA | ICHRA | Supplemental;

export type YearlyGroupBenefit<Y extends GroupPlanYear> = IYearlyBenefit<Y>;

interface IGroupEnrollment<
  T extends GroupBenefitType,
  S extends GroupEnrollmentType
> {
  id: string;
  benefit_id: string;
  benefit_type: T;
  benefit_type_display: string;
  group_member_id: string;
  created_at: string;
  updated_at: string;
  status: string;
  status_display: string;
  coverage_type: string;
  dependents: string;
  enrollment_deadline?: string;
  enrollment_type: S;
  documents: GroupDocument[];
}

interface IYearlyEnrollment {
  start_date: string;
  end_date: string;
  plan_year: {
    start_date: string;
    end_date: string;
    benefit_id: string;
  };
}

interface ICOBRAEligibleEnrollment {
  cobra_election?: COBRAElection;
}

interface IHRAEnrollment {
  does_accrue: boolean;
  does_rollover: boolean;
  funding_frequency: FundingFrequency;
  funding_frequency_display: string;
  funding_amount: string;
  expense_claim_filing_process?: string;
  expense_claim_submission_deadline?: string;
  claims: GroupBenefitClaim[];
  covered_expenses: string[];
}

export type HealthPlan = {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  carrier: string;
  portal_url: string;
  premium_per_month: string;
};

export type ICHRAEnrollment = {
  funding_mode: ICHRAFundingMode;
  health_plan?: HealthPlan;
  auto_reimbursement_enabled: boolean;
  auto_reimbursement_requests?: GroupAutoReimbursementRequest[];
} & IGroupEnrollment<GroupBenefitType.ICHRA, GroupEnrollmentType.ICHRA> &
  IYearlyEnrollment &
  IHRAEnrollment;

export type HRAEnrollment = {
  hra_type: HRAType;
  hra_type_display: string;
  health_plan?: HealthPlan;
  rollover_policy: string;
  available_balance_as_of_this_month: string;
} & IGroupEnrollment<
  GroupBenefitType.GROUP_HRA | GroupBenefitType.QSEHRA,
  GroupEnrollmentType.HRA
> &
  IYearlyEnrollment &
  IHRAEnrollment;

export type SupplementalEnrollment = {
  application_id: string;
  benefit_id: string;
  supplemental_funding_mode: string;
  supplemental_funding_mode_display: string;
  product_type: string;
  product_type_display: string;
  level: string;
  carrier: CarrierKey;
  carrier_display: string;
} & IGroupEnrollment<
  GroupBenefitType.SUPPLEMENTAL,
  GroupEnrollmentType.SUPPLEMENTAL
>;

export type LevelFundedMECEnrollment = {
  per_member_admin_fee: string;
  per_member_reserve_amount: string;
  claims: GroupBenefitClaim[];
} & IGroupEnrollment<
  GroupBenefitType.LEVEL_FUNDED_MEC,
  GroupEnrollmentType.LEVEL_FUNDED_MEC
> &
  IYearlyEnrollment &
  ICOBRAEligibleEnrollment;

export type GroupEnrollment =
  | HRAEnrollment
  | ICHRAEnrollment
  | SupplementalEnrollment
  | LevelFundedMECEnrollment;

export type YearlyGroupEnrollment =
  | HRAEnrollment
  | ICHRAEnrollment
  | LevelFundedMECEnrollment;

export type COBRAEligibleEnrollment = LevelFundedMECEnrollment;

export type GroupAutoReimbursementRequest = {
  approved_on?: string;
  date_of_first_claim: string;
  documents: GroupBenefitClaimDocument[];
  canceled_on?: string;
  created_at: string;
  enrollment_id: string;
  id: string;
  member_name: string;
  premium_amount_usd: string;
  status: GroupAutoReimbursementRequestStatus;
  updated_at: string;
};

export type GroupBenefitClaim = {
  admin_notes?: string;
  allowed_amount_usd: string;
  auto_reimbursement_request_id?: string;
  created_at: string;
  date_of_expense: string;
  description: string;
  documents: GroupBenefitClaimDocument[];
  enrollment_id: string;
  id: string;
  member_name: string;
  requested_amount_usd: string;
  status: GroupBenefitClaimStatus;
  updated_at: string;
};

export type GroupBenefitClaimDocument = {
  id: string;
  file_name: string;
  file_url: string;
};

export type GroupCensus = {
  census_data: GroupCensusLine[];
  created_at: string;
  deleted_at: string;
  file: File;
  file_name: string;
  file_url: string;
  group_id: string;
  id: string;
  nickname?: string;
  updated_at: string;
};

export type GroupCensusLine = {
  first_name: string;
  last_name: string;
  dob_year: string;
  dob_month: string;
  dob_day: string;
  medical_ichra: GroupCensusRowCoverageSelectionType;
  level_funded: GroupCensusRowCoverageSelectionType;
  supplemental: GroupCensusRowCoverageSelectionType;
  postal_code: string;
  gender: string;
  employment_status?: EmploymentStatus;
  email?: string;
  salary?: string;
  job_title?: string;
};

export type GroupClient = {
  id: string;
  email: string;
  group_contact_name: string;
  group_name: string;
  phone_extension: string;
  phone_number: string;
  group_id: string;
};

export type GroupDocument = {
  id: string;
  name: GroupDocumentName;
  display_name: string;
  document_type: GroupDocumentType;
  document_display_type: string;
  label?: string;
  notes?: string;
  url: string;
  effective_from?: string;
  effective_to?: string;
  benefit_type?: string;
};

export type GroupInvoice = {
  amount: number;
  display_status: string;
  documents: GroupInvoiceDocument[];
  generated_as_of: string;
  group_id: string;
  id: string;
  last_attempted_at: string;
  notes: string;
  number_of_eligible_members: number;
  billing_account_id: string;
  billing_account_last_four: string;
  scheduled_for: string;
  status: GroupInvoiceStatus;
};

export type GroupInvoiceDocument = {
  id: string;
  name: string;
  url: string;
};

export type GroupMember = {
  created_at: string;
  effective_on: string;
  email_address: string;
  employment_status: EmploymentStatus;
  employment_status_display: string;
  enrollments: GroupEnrollment[];
  first_name: string;
  group_id: string;
  group_name: string;
  group_servicer_name: ServicerName;
  id: string;
  last_name: string;
  updated_at: string;
  termination_date: string;
  reimbursement_account_id?: string;
};

export type GroupOnboardingCode = {
  code: string;
  created_at: string;
  group_id?: string;
  id: string;
  intended_group_name?: string;
  last_used_at?: string;
  notes?: string;
  updated_at: string;
};

export type IndividualClient = {
  broker_id: string;
  broker_name: string;
  date_of_birth: string;
  first_name: string;
  id: string;
  last_name: string;
  latest_quote_set: QuoteSet;
  middle_name: string;
  state: string;
};

export type IndividualClientQuoteSet = {
  id: string;
  individual_client: IndividualClient;
  quote_set: QuoteSet;
};

export type InProgressApplication = Partial<Application>;

export type InProgressEgress = Partial<Egress>;

export type InsuranceProductSelection = {
  carrier: CarrierKey;
  code: string;
  name: InsuranceProductKey;
  offering_id: string;
  coverage_type: CoverageType;
};

export type Landlord = {
  business_address?: Address;
  business_address_id?: string;
  business_email?: string;
  business_name?: string;
  business_phone_number?: string;
  created_at: string;
  id: string;
  num_properties: number;
  num_units: number;
  source?: SDSPartner | string;
  user_id: string;
  updated_at: string;
};

export type LegacyAddress = {
  primary: string;
  secondary?: string;
  city: string;
  state: string;
  zip_code: string;
};

export type LevelFundedConfigurationSelectionsDisplay = {
  name: string;
  display: string;
  value: string;
};

export type LevelFundedDetails = {
  claims_reserve_amount_per_member: string;
  configuration_selections: Record<string, string>;
  configuration_selections_display: LevelFundedConfigurationSelectionsDisplay[];
  created_at: string;
  id: string;
  stop_loss_fee_per_member: string;
  updated_at: string;
};

export type MedicalPlan = {
  id: string;
  name: string;
  state: string;
  monthly_premium: string;
  deductible: string;
  maximum_out_of_pocket: string;
  co_insurance?: string;
  co_pay?: string;
};

export type MoovAccessToken = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
};

interface IMoovPaymentMethod<T extends MoovPaymentMethodType> {
  created_at: string;
  id: string;
  payment_account_id: string;
  payment_method_type: T;
  moov_funding_source_id: string;
  verification_locked: boolean;
}

export type MoovBankAccount = {
  bank_account_type: string;
  bank_account_verification_status: MoovBankAccountVerificationStatus;
  bank_name: string;
  bank_last_four_account_number: string;
  routing_number: string;
  plaid_processor_token: string;
} & IMoovPaymentMethod<MoovPaymentMethodType.BANK_ACCOUNT>;

export type MoovCreditCard = {
  card_billing_address: string;
  card_bin: string;
  card_brand: string;
  card_expiration: string;
  card_holder_name: string;
  card_issuer: string;
  card_issuer_country: string;
  card_last_four: string;
  card_type: string;
  card_verification: string;
} & IMoovPaymentMethod<MoovPaymentMethodType.CARD>;

export type MoovPaymentMethod = MoovCreditCard | MoovBankAccount;

export type Offering = {
  rate_id: number;
  carrier: CarrierKey;
  coverage_type: CoverageType;
  details: OfferingDetails;
  display_label: string;
  has_non_insurance: boolean;
  monthly_non_insurance_premium?: string;
  monthly_premium: string;
  level: number;
  offering_id: string;
  plan_type: string;
  plan_code: string;
  product_name: string;
  product_display_name: string;
};

export type OfferingDetails = {
  benefits: Record<string, number | string[]>;
  cancer_only?: boolean;
};

export type OnboardingFlowState = {
  group: {
    legalName: string;
    dbaName: string;
    address: AddressData;
    phoneNumber: string;
    businessType: BusinessType;
    description?: string;
    taxIdNumber: string;
  };
  admins: Admin[];
  representative: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    dateOfBirth: string;
    address: AddressData;
    ssnLast4: string;
    jobTitle: string;
    isOwner: boolean;
    ownershipPercentage?: number;
    isController: boolean;
  };
  plaidLink: {
    account_id: string;
    account_type: string;
    institution?: string;
    public_token: string;
  };
  moovToken: string;
  achInfo: ACHInfo;
};

export type Organization = {
  can_use_code: boolean;
  code: string;
  id: string;
  name: string;
  referral_link: string;
  score: number;
  tier: string;
};

export type PaymentAccount = {
  created_at: string;
  id: string;
  account_type: string;
  moov_account_id: string;
};

export type PaymentMethod = {
  ach_account_number?: string;
  ach_account_type?: string;
  ach_bank_name?: string;
  ach_routing_number?: string;
  card_bin?: string;
  card_cvc?: string;
  card_expiration_date?: string;
  card_holder_name?: string;
  card_last_4?: string;
  card_number?: string;
  card_type?: string;
  created_at?: string;
  id?: string;
  nickname?: string;
  type?: PaymentType;
  user_id?: string;
};

export type PlaidLinkAccount = {
  account_id: string;
  access_token: string;
  account_type: string;
  id: string;
  institution: string;
  status: PlaidLinkAccountStatus;
};

export type PlaidLinkToken = {
  expiration: string;
  link_token: string;
  request_id: string;
};

export type PlaidProcessorToken = {
  processor_token: string;
};

export type Policy = {
  billing_frequency: string;
  carrier_code: CarrierKey;
  carrier_name: string;
  coverage_start_date: string;
  created_at: string;
  dependents: {
    spouse: {
      first_name?: string;
      last_name?: string;
    };
    children: Dependent[];
  };
  documents: Document[];
  id: string;
  short_uuid: string;
  number: string;
  plan_benefits: Benefit[];
  plan_name: string;
  plan_type: string;
  primary_insured_city: string;
  primary_insured_date_of_birth: string;
  primary_insured_first_name: string;
  primary_insured_last_name: string;
  primary_insured_middle_name: string;
  primary_insured_phone_number: string;
  primary_insured_postal_code: string;
  primary_insured_state: string;
  primary_insured_street_address_1: string;
  primary_insured_street_address_2: string;
  product: string;
  payment_cost: string;
  has_payment_failed: boolean;
  next_payment_date: string;
  is_payment_in_progress: boolean;
  status: string;
  status_display: string;
  updated_at: string;
  user_id: string;
  aig_policy_details: {
    hospital_payout?: number;
    icu_payout?: number;
  };
  has_noninsurance_services: boolean;
  is_manually_cancelled: boolean;
};

export type Product = {
  carrier_code: CarrierKey;
  carrier_display: string;
  plan_code: string;
  plan_tier: string;
  product_name: string;
};

export type ProductEligibility = {
  is_eligible: boolean;
  products: UpsellProduct[];
};

export type ProductKey = InsuranceProductKey;

export type ProductMatrix = {
  version: number;
  products: Partial<Record<ProductKey, AvailableProduct>>;
};

export type ProductMatrixConfig = {
  carrierSelected: CarrierKey;
  coverageSelected: CoverageType;
};

export type ProductSelection = InsuranceProductSelection;

export type ProductStudy = {
  product_name: string;
  offering_id: string;
  broker_medical_plan_tier: string;
  broker_medical_plan_coverage_amount?: string;
  customer_cost?: string;
  customer_cost_with_liferaft?: string;
  expenses?: string;
  liferaft_coverage_amount?: string;
  liferaft_benefit?: string;
  explanation: string;
};

export type Property = {
  address: Address;
  id: string;
  mailing_address?: Address;
  property_type?: string;
  building_class: BuildingClass;
  building_type: BuildingType;
  num_units: number;
  property_value_usd: number;
  nickname?: string;
  user_id?: string;
};

export type PropertyRatingData = {
  average_lease_term_months: number;
  average_monthly_rent_per_unit: number;
  average_turnover_rate: number; // percentage as integer
  debt_income_ratio: number; // percentage as integer
  num_evictions_last_3: number; // number of evictions in past 3 years
  num_missed_rents_defaults_last_3: number; // number in past 3 years
  occupancy_rate: number; // percentage as integer
  property_management_company: string;
  using_property_management_company: boolean;
};

export type Provider = {
  languages_spoken: string;
  practice_email: string;
  practice_name: string;
  practice_phone_number: string;
  practice_specialty: string;
  contact_email: string;
  contact_name: string;
  contact_phone_number: string;
  street_address_1: string;
  street_address_2: string;
  city: string;
  state: string;
  postal_code: string;
  sort_order: number;
  line_items: ProviderLineItem[];
};

export type ProviderLineItem = {
  title: string;
  details: string;
  price_range: string;
  sort_order: number;
};

export type QualifyingLifeEventNotice = {
  created_at: string;
  delivery_methods: DeliveryMethod;
  id: string;
  notice_sent_on: string;
  qualifying_life_event_id: string;
};

export type QualifyingLifeEvent = {
  admin_notified_on: string;
  created_at: string;
  details: string;
  election_deadline?: string;
  election_process_complete: boolean;
  event_type: string;
  event_type_display: string;
  group_member_id: string;
  has_elections: boolean;
  id: string;
  notices: QualifyingLifeEventNotice[];
  occured_on: string;
};

export type Quote = {
  id: string;
  created_at: string;
  carrier: string;
  coverage_type: string;
  plan_type: string;
  plan_code: string;
  benefit_details: Benefit[];
  product_details: QuoteProductDetails;
  display_level: string;
  level: number;
  monthly_premium: string;
  selected: boolean;
  quote_set_id: string;
  product_display_name: string;
  product_name: InsuranceProductKey;
  rate_id: string;
  summary_of_benefits_link: string;
};

export type QuoteProductDetails = {
  has_non_insurance: boolean;
  cancer_only?: boolean;
};

export type QuoteSelection = {
  product: string;
  plan_code: string;
  coverage_type: string;
};

export type QuoteSet = {
  created_at: string;
  id: string;
  updated_at: string;
  quotes: Quote[];
  quoting_matrix: ProductMatrix;
  quoting_configuration: QuotingConfiguration;
};

export type QuotingConfiguration = {
  date_of_birth: string;
  first_name: string;
  is_tobacco_user: boolean;
  ca_has_current_coverage?: boolean;
  last_name: string;
  selected_sex: string;
  state: string;
  zip_code?: string;
};

export type Referrer = {
  application_fee_amount: number;
  organization_id: string;
  organization_name: string;
  user_email: string;
  user_id: string;
};

export type ReimbursementAccount = {
  account_number_last_four: string;
  account_type: string;
  created_at: string;
  bank_name: string;
  id: string;
  plaid_link_account_id?: string;
  routing_number: string;
};

export type SDSClaim = {
  approved_amount_usd: number;
  claim_data: Partial<SDSClaimData>;
  claimed_amount_usd: number;
  created_at: string;
  id: string;
  sent_to_partner_at: string;
  status: SDSClaimStatus;
  status_display: string;
  subscription: SDSSubscription;
  subscription_id: string;
  updated_at: string;
};

export type SDSClaimData = {
  claim_type: string;
  damage_date: string;
  description: string;
};

export type SDSProduct = {
  created_at: string;
  id: string;
  landlord: Landlord;
  landlord_id: string;
  partner_code: SDSPartner;
  partner_code_display: SDSPartnerDisplayName;
  property_id: string;
  selected_subscription_options: SDSSubscriptionOption[];
  status: SDSProductStatus;
  status_display: SDSProductStatusDisplayName;
  subscription_options: SDSSubscriptionOption[];
  underwriting_data: Partial<SDSProductUnderwritingData>;
  updated_at: string;
};

export type SDSProductUnderwritingData = {
  property_rating_data: Partial<PropertyRatingData>;
  tenant_criteria: Partial<TenantCriteria>;
};

export type SDSSubscription = {
  amount_down_upfront_usd: number;
  available_subscription_options: SDSAvailableSubscriptionOption[];
  billing_amount_usd: number;
  billing_frequency: BillingFrequency;
  billing_frequency_display: string;
  building_address: Address;
  claim_filed_date?: string;
  created_at: string;
  end_date: string;
  id: string;
  lease_term_months: number;
  monthly_rent_usd: number;
  next_billing_amount_usd: number;
  next_billing_date: string;
  payment_method_display: string;
  payment_method_id: string;
  product_id: string;
  security_deposit_total_amount_usd: number;
  selected_subscription_option: string;
  selected_subscription_option_details?: SDSAvailableSubscriptionOption;
  selected_subscription_option_display: string;
  start_date: string;
  status: SDSSubscriptionStatus;
  status_display: string;
  tenant_email: string;
  tenant_first_name: string;
  tenant_id: string;
  tenant_invite_email?: string;
  tenant_invite_first_name?: string;
  tenant_invite_last_name?: string;
  tenant_last_name: string;
  total_escrow_amount_usd: number;
  underwriting_data: Partial<SDSSubscriptionUnderwritingData>;
  unit_identifier: string;
  updated_at: string;
};

export type SDSSubscriptionUnderwritingData = {
  annual_salary: number;
  credit_score: number;
  date_of_birth: string;
  employment_tenure_years: number;
  has_prior_bankruptcy: boolean;
  has_prior_evictions: boolean;
  has_prior_rent_default: boolean;
  is_employed: boolean;
  is_self_employed: boolean;
  is_student: boolean;
  is_us_resident: boolean;
};

export type SDSSubscriptionOption = {
  option_id: SDSSubscriptionOptionCode;
  title: SDSSubscriptionOptionCodeDisplayName;
  description: string;
  details: SDSSubscriptionOptionDetail[];
};

export type SDSSubscriptionOptionDetail = {
  title: string;
  description: string;
};

export type SDSAvailableSubscriptionOption = {
  option_id: SDSSubscriptionOptionCode;
  title: SDSSubscriptionOptionCodeDisplayName;
  description: string;
  details: SDSSubscriptionOptionDetail[];
  annual_broker_fee: number;
  annual_premium: number;
  annual_taxes: number;
  assurely_id: string;
  escrow_at_lease_end: number;
  escrow_schedule: any[];
  escrow_upfront: number;
  monthly_broker_fee: number;
  monthly_premium: number;
  monthly_processing_fee: number;
  quote_number: string;
  total_annual_cost: number;
  total_first_monthly_payment: number;
  total_monthly_payment: number;
};

export type Tenant = {
  created_at: string;
  id: string;
  updated_at: string;
  user_id: string;
};

export type TenantCriteria = {
  checking_references: boolean;
  confirming_identity: boolean;
  doing_credit_checks: boolean;
  max_rent_of_income_percentage: number;
  minimum_tenant_age: number;
  minimum_tenant_fico: number;
  reviewing_bank_balances: boolean;
  running_background_checks: boolean;
  same_onboarding_procedure_at_all_properties: boolean;
  screening_service: string;
  using_screening_service: boolean;
  verifying_employment: boolean;
};

export type UpsellProduct = {
  display_name: string;
  name: string;
  description: string;
};

export type User = {
  addresses: Address[];
  date_of_birth: string;
  email?: string;
  first_name?: string;
  id: string;
  last_name?: string;
  middle_name?: string;
  organization_id: string;
  payment_account_id?: string;
  phone_number?: string;
  roles: UserRole[];
  unit_tracking_id: string;
};

export type UserProductSummary = {
  has_applications: boolean;
  has_insurance_policies: boolean;
  has_insurance_claims: boolean;
};

export type UserProfile = {
  date_of_birth: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  user_id: string;
  is_email_verified: boolean;
  is_phone_number_verified: boolean;
};

export type Waffle = {
  flags: Partial<Record<string, WaffleStatus>>;
  switches: Partial<Record<string, WaffleStatus>>;
  samples: Partial<Record<string, WaffleStatus>>;
};

export type WaffleStatus = {
  is_active: boolean;
  last_modified: string;
};

export type SEPLetter = {
  enrollment_id: string;
  enrollment_type: string;
};

export type Servicer = {
  name: ServicerName;
};

/*
----- Enums -----
*/

export enum AccrualFrequency {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
}

export enum AddressType {
  DEFAULT = 'default',
  HOME = 'home',
  BILLING = 'billing',
  WORK = 'work',
}

export enum AddressTypeDisplay {
  'default' = 'Default',
  'home' = 'Home',
  'billing' = 'Billing',
  'work' = 'Work',
}

export enum ApplicationFlowKey {
  APPLY = 'apply',
  REAPPLY = 'reapply',
  QUOTING_TOOL_ON_BEHALF_OF = 'quoting_tool_on_behalf_of',
}

export enum ApplicationOrigin {
  BULK_UPLOAD = 'bulk_upload',
  CUSTOMER_FLOW = 'customer_flow',
  ON_BEHALF_OF_FLOW = 'on_behalf_of_flow',
  REAPPLY_FLOW = 'reapply_flow',
  REAPPLY_ON_BEHALF_OF_FLOW = 'reapply_on_behalf_of_flow',
  QUOTING_TOOL_ON_BEHALF_OF = 'quoting_tool_on_behalf_of',
  GROUP_BENEFIT_FLOW = 'group_benefit_flow',
}

export enum ApplicationStatus {
  FINISHED = 'finished',
  IN_PROGRESS = 'in_progress',
}

export enum BillingFrequency {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum BuildingClass {
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
}

export enum BuildingClassDisplayName {
  'a' = 'Class A',
  'b' = 'Class B',
  'c' = 'Class C',
  'd' = 'Class D',
}

export enum BuildingType {
  SFH = 'single_family_home',
  DUPLEX = 'duplex',
  TRIPLEX = 'triplex',
  FOURPLEX = 'fourplex',
  CONDO = 'condominium',
  TOWNHOUSE = 'townhouse',
  LOW_RISE_COMPLEX = 'low_rise_complex',
  HIGH_RISE_COMMPLEX = 'high_rise_complex',
  WORKFORCE_HOUSING = 'workforce_housing',
}

export enum BuildingTypeDisplay {
  'single_family_home' = 'Single Family Home',
  'duplex' = 'Duplex',
  'triplex' = 'Triplex',
  'fourplex' = 'Fourplex',
  'condominium' = 'Condominium',
  'townhouse' = 'Townhouse',
  'low_rise_complex' = 'Low Rise Apartment Complex',
  'high_rise_complex' = 'High Rise Apartment Complex',
  'workforce_housing' = 'Workforce Housing',
}

export enum BusinessType {
  INCORPORATED_NON_PROFIT = 'incorporatedNonProfit',
  LLC = 'llc',
  PARTNERSHIP = 'partnership',
  PRIVATE_CORPORATION = 'privateCorporation',
  PUBLIC_CORPORATION = 'publicCorporation',
  SOLE_PROPRIETORSHIP = 'soleProprietorship',
  TRUST = 'trust',
  UNINCORPORATED_ASSOCIATION = 'unincorporatedAssociation',
  UNINCORPORATED_NON_PROFIT = 'unincorporatedNonProfit',
}

export enum BusinessTypeDisplay {
  'incorporatedNonProfit' = 'Incorporated Non-profit',
  'llc' = 'LLC',
  'partnership' = 'Partnership',
  'privateCorporation' = 'Private Corporation',
  'publicCorporation' = 'Public Corporation',
  'soleProprietorship' = 'Sole Proprietorship',
  'trust' = 'Trust',
  'unincorporatedAssociation' = 'Unincorporated Association',
  'unincorporatedNonProfit' = 'Unincorporated Non-profit',
}

export enum CarrierDisplay {
  AIG = 'AIG',
  AMR = 'Ameritas',
  APL = 'APL',
  LIB = 'Liberty Mutual',
  LR = 'Liferaft',
  MET = 'MetLife',
  NFC = 'NFC',
}

export enum CarrierKey {
  AIG = 'AIG',
  AMR = 'AMR',
  APL = 'APL',
  LIB = 'LIB',
  LR = 'LR',
  MET = 'MET',
  NFC = 'NFC',
}

export enum CoverageDisplay {
  EE = 'Individual',
  ES = 'Individual + Spouse',
  EC = 'Individual + Children',
  AMR_EC = 'Individual + Child',
  F = 'Family',
}

export enum CoverageType {
  INDIVIDUAL = 'EE',
  INDIVIDUAL_SPOUSE = 'ES',
  INDIVIDUAL_CHILDREN = 'EC',
  FAMILY = 'F',
}

export enum DeliveryMethod {
  MAIL = 'mail',
  EMAIL = 'email',
}

export enum DisclosureAcknowledgementResponse {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  VIEWED = 'VIEWED',
}

export enum DisclosureType {
  E_DELIVERY = 'E_DELIVERY',
  AIG_STATE_DISCLAIMER = 'AIG_STATE_DISCLAIMER',
  ORG_PAYEE_DISCLAIMER = 'ORG_PAYEE_DISCLAIMER',
  REPLACEMENT_DISCLOSURE = 'REPLACEMENT_DISCLOSURE',
  REPLACEMENT_DISCLOSURE_FL = 'REPLACEMENT_DISCLOSURE_FL',
  DC_FULL_BENEFITS = 'DC_FULL_BENEFITS',
  DC_LIMITED_BENEFITS = 'DC_LIMITED_BENEFITS',
  LIFERAFT_TOS = 'LIFERAFT_TOS',
  LIBERTY_DISCLAIMER = 'LIBERTY_MUTUAL_DISCLAIMER',
  LIBERTY_E_DELIVERY = 'LIBERTY_MUTUAL_E_DELIVERY',
  LIBERTY_HI_REPLACEMENT = 'LIBERTY_HI_REPLACEMENT',
  AMERITAS_DISCLAIMER = 'AMERITAS_DISCLAIMER',
  SDS_LANDLORD_DOCUMENTS = 'SDS_LANDLORD_DOCUMENTS',
}

export enum DocumentDelivery {
  EMAIL = 'email',
  MAIL = 'mail',
}

export enum EmploymentStatus {
  FORMER_EMPLOYEE_ON_COBRA = 'cobra',
  FULL_TIME = 'full_time',
  PART_TIME = 'part_time',
  OTHER = 'other',
  NOT_APPLICABLE = 'not_applicable',
}

export enum EmploymentStatusDisplay {
  cobra = 'Former employee on COBRA',
  full_time = 'Full-Time',
  part_time = 'Part-Time',
  other = 'Other',
  not_applicable = 'Not Applicable',
}

export enum ExperienceKey {
  PRODUCT_MATRIX = 'product-matrix',
}

export enum Flag {
  TEST = 'test_flag',
  AMR_2024Q2_CONTENT = 'amr_2024q2_content',
}

export enum FundingFrequency {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
}

export enum GroupAutoReimbursementRequestStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  CANCELED = 'canceled',
}

export enum GroupBenefitClaimType {
  LEVEL_FUNDED_PAYOUT = 'level_funded_payout',
  HRA_EXPENSE_REIMBURSEMENT = 'hra_expense_reimbursement',
}

export enum GroupBenefitClaimStatus {
  SUBMITTED = 'submitted',
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
  REIMBURSEMENT_APPROVED = 'reimbursement_approved',
  REIMBURSEMENT_DENIED = 'reimbursement_denied',
  REIMBURSEMENT_SUBMITTED = 'reimbursement_submitted',
  REIMBURSEMENT_FAILED = 'reimbursement_failed',
  REIMBURSED = 'reimbursed',
}

export enum GroupEnrollmentType {
  HRA = 'hraenrollment',
  ICHRA = 'ichraenrollment',
  SUPPLEMENTAL = 'supplementalenrollment',
  LEVEL_FUNDED_MEC = 'levelfundedmecenrollment',
}

export enum GroupBenefitType {
  GROUP_HRA = 'group_hra',
  QSEHRA = 'qsehra',
  ICHRA = 'ichra',
  SUPPLEMENTAL = 'supplemental',
  LEVEL_FUNDED_MEC = 'levelfundedmec',
}

export enum GroupCensusRowCoverageSelectionDisplay {
  'CH' = 'Child of employee',
  'DP' = 'Dependent of employee',
  'EC' = 'Employee and children',
  'EF' = 'Employee and family',
  'ES' = 'Employee and spouse',
  'EE' = 'Employee only',
  'SP' = 'Spouse of employee',
  'waived' = 'Waived',
}

export enum GroupCensusRowCoverageSelectionType {
  CHILD_OF_EMPLOYEE = 'CH',
  DEPENDENT_OF_EMPLOYEE = 'DP',
  EMPLOYEE_AND_CHILDREN = 'EC',
  EMPLOYEE_AND_FAMILY = 'EF',
  EMPLOYEE_AND_SPOUSE = 'ES',
  EMPLOYEE_ONLY = 'EE',
  SPOUSE_OF_EMPLOYEE = 'SP',
  WAIVED = 'waived',
}

export enum GroupDocumentName {
  PLAN_DOCUMENT = 'plan_document',
  SUMMARY_PLAN_DESCRIPTION = 'summary_plan_description',
  PLAN_AT_A_GLANCE = 'plan_at_a_glance',
}

export enum GroupDocumentType {
  EMPLOYER = 'employer',
  EMPLOYEE = 'employee',
}

export enum GroupEnrollmentCoverageDisplayName {
  EE = 'Employee Only',
  EC = 'Employee and Child',
  ES = 'Employee and Spouse',
  EF = 'Employee and Family',
  waived = 'Waived',
}

export enum GroupEnrollmentCoverageType {
  EMPLOYEE_ONLY = 'EE',
  EMPLOYEE_AND_CHILDREN = 'EC',
  EMPLOYEE_AND_SPOUSE = 'ES',
  EMPLOYEE_AND_FAMILY = 'EF',
  WAIVED = 'waived',
}

export enum GroupEnrollmentStatus {
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  PENDING = 'pending',
  REQUIRES_RATING = 'requires_rating',
}

export enum GroupInvoiceStatus {
  PAID = 'paid',
  PENDING = 'pending',
  WAIVED = 'waived',
}

export enum GroupSupplementalBenefitType {
  EMPLOYEE_PURCHASED = 'employee_purchased',
  EMPLOYEE_REIMBURSED = 'employee_reimbursed',
  EMPLOYER_PURCHASED = 'employer_purchased',
}

export enum GroupSupplementalTypeDisplayLabel {
  'employee_purchased' = 'Employee Purchased',
  'employee_reimbursed' = 'Employee Reimbursed',
  'employer_purchased' = 'Employer Purchased',
}

export enum HRADisplayLabel {
  'group_hra' = 'HRA',
  'ichra' = 'ICHRA',
  'ichra_employer_purchased' = 'ICHRA',
  'qsehra' = 'Qualified Small Employer HRA',
}

export enum GroupBenefitDisplayLabel {
  'group_hra' = 'Group HRA',
  'ichra' = 'ICHRA',
  'ichra_employer_purchased' = 'ICHRA',
  'level_funded' = 'Level Funded Plan',
  'supplemental' = 'Supplemental',
}

export enum GroupAutoReimbursementRequestStatusDisplay {
  'pending' = 'Pending',
  'active' = 'Active',
  'canceled' = 'Canceled',
}

export enum GroupBenefitClaimStatusDisplayLabel {
  'submitted' = 'Submitted',
  'pending' = 'Pending',
  'approved' = 'Approved',
  'denied' = 'Denied',
  'reimbursement_approved' = 'Reimbursement Approved',
  'reimbursement_denied' = 'Reimbursement Denied',
  'reimbursement_submitted' = 'Reimbursement Submitted',
  'reimbursement_failed' = 'Reimbursement Failed',
  'reimbursed' = 'Reimbursed',
}

export enum GroupBenefitClaimTypeDisplayLabel {
  'level_funded_payout' = 'Level Funded Payout',
  'hra_expense_reimbursement' = 'HRA Expense Reimbursement',
}

export enum InsuranceProductKey {
  ACCIDENT = 'accidental-medical-expense',
  CRITICAL = 'critical-illness',
  HOSPITAL = 'hospital-indemnity',
  DENTAL = 'dental',
  VISION = 'vision',
}

export enum MoovBankAccountVerificationStatus {
  ERRORED = 'errored',
  NEW = 'new',
  PENDING = 'pending',
  VERIFICATION_FAILED = 'verificationFailed',
  VERIFIED = 'verified',
}

export enum MoovPaymentMethodType {
  BANK_ACCOUNT = 'bank_account',
  CARD = 'card',
  WALLET = 'wallet',
}

export enum LanguageModel {
  GPT_3_5_TURBO = 'gpt-3.5-turbo',
  GPT_4 = 'gpt-4',
}

export enum PaymentType {
  ACH = 'ach',
  CC = 'cc',
}

export enum PlaidLinkAccountStatus {
  PENDING = 'pending_manual_verification',
  MANUALLY_VERIFIED = 'manually_verified',
  VERIFIED = 'verified',
}

export enum ProductDisplayName {
  'accidental-medical-expense' = 'Accident Supplement',
  'critical-illness' = 'Critical Supplement',
  'hospital-indemnity' = 'Hospital Supplement',
  'dental' = 'Dental',
  'vision' = 'Vision',
}

export enum PropertyStatus {
  PENDING_UNDERWRITING = 'pending_underwriting',
  PENDING_CONFIGURATION = 'pending_configuration',
  ACTIVE = 'active',
  TERMINATED = 'terminated',
}

export enum QualifyingLifeEventType {
  DEATH_OF_EMPLOYEE = 'death_of_employee',
  EMPLOYER_BANKRUPTCY = 'employer_bankruptcy',
  MEDICARE_ENTITLEMENT = 'medicare_entitlement',
  REDUCTION_IN_HOURS = 'reduction_in_hours',
  TERMINATION = 'termination',
}

export enum Sample {
  TEST = 'test_sample',
}

export enum SDSClaimStatus {
  APPROVED = 'approved',
  DRAFT = 'draft',
  REJECTED = 'rejected',
  SUBMITTED = 'submitted',
}

export enum SDSPartner {
  ASSURELY = 'assurely',
  JPMC = 'jpmc',
}

export enum SDSPartnerDisplayName {
  'assurely' = 'Assurely',
  'jpmc' = 'JPMorgan Chase',
}

export enum SDSProductStatus {
  ACTIVE = 'active',
  AWAITING_SIGNATURES = 'awaiting_signatures',
  DRAFT = 'draft',
  IN_UNDERWRITING = 'in_underwriting',
  MANUAL_INTERVENTION_REQUIRED = 'manual_intervention_required',
  REJECTED = 'rejected',
  TERMINATED = 'terminated',
}

export enum SDSProductStatusDisplayName {
  'active' = 'Active',
  'awaiting_signatures' = 'Awaiting your signature',
  'draft' = 'Draft',
  'in_underwriting' = 'In underwriting review',
  'manual_intervention_required' = 'Our support team is reviewing your application',
  'rejected' = 'Rejected by underwriters',
  'terminated' = 'Terminated',
}

export enum SDSSubscriptionOptionCode {
  ALL_UPFRONT = 'all_upfront',
  HYBRID_ZERO_DOWN = 'hybrid_zero_down',
  PURE_INSURANCE = 'pure_insurance',
}

export enum SDSSubscriptionOptionCodeDisplayName {
  'all_upfront' = 'Security Deposit Replacement',
  'hybrid_zero_down' = 'Hybrid SDA/SDR',
  'pure_insurance' = 'Security Deposit Alternative',
}

export enum SDSSubscriptionStatus {
  ACTIVE = 'active',
  AWAITING_SIGNATURES = 'awaiting_signatures',
  IN_UNDERWRITING = 'in_underwriting',
  INVITATION = 'invitation',
  INVITATION_ACCEPTED = 'invitation_accepted',
  INVITATION_EXPIRED = 'invitation_expired',
  MANUAL_INTERVENTION_REQUIRED = 'manual_intervention_required',
  MOVEOUT_REQUESTED = 'moveout_requested',
  OVERDUE_PAYMENT = 'overdue_payment',
  PENDING = 'pending',
  QUOTES_AVAILABLE = 'quotes_available',
  QUOTE_SELECTED = 'quote_selected',
  TERMINATED = 'terminated',
}

export enum SDSSubscriptionStatusDisplayName {
  'active' = 'Active',
  'awaiting_signatures' = 'We will email you documents to sign, after which this subscription will activate.',
  'in_underwriting' = 'In underwriting review',
  'invitation' = 'Tenant invitation',
  'invitation_accepted' = 'Tenant has accepted invitation',
  'invitation_expired' = 'Tenant invitation expired',
  'manual_intervention_required' = 'Our support team is reviewing this subscription',
  'moveout_requested' = 'Moveout requested',
  'overdue_payment' = 'Overdue payment',
  'pending' = 'Pending',
  'quotes_available' = 'Quotes available',
  'quote_selected' = 'Quote selected',
  'terminated' = 'Terminated',
}

export enum Sex {
  MALE = 'male',
  FEMALE = 'female',
}

export enum Switch {
  SDS_ALLOW_TENANT_INVITES = 'sds_allow_tenant_invites',
  TEST = 'test_switch',
}

export enum UnderwritingLevels {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  MORE = '6+',
}

export enum UserRole {
  BROKER = 'broker',
  GROUP_ADMIN = 'group_admin',
  GUEST = 'guest',
  LANDLORD = 'landlord',
  LIFERAFT_ADMIN = 'liferaft_admin',
  MEMBER = 'member',
  TENANT = 'tenant',
  LEVEL_FUNDED_MEC_MEMBER = 'level_funded_mec_member',
}

export enum YesNo {
  YES = 'yes',
  NO = 'no',
}

export enum ServicerName {
  VITABLE = 'VitableHealth',
  MISHE = 'Mishe',
}
