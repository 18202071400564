import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Redirect } from '@liferaft/core/components';

import { DJANGO_API_URL } from '@/environment';
import { AmeritasContentPreview } from '@/pages/product-matrix/ameritas-content-preview';

export function PublicEntry() {
  return (
    <Routes>
      <Route element={<Redirect href={DJANGO_API_URL as string} />} path="/" />
      <Route element={<AmeritasContentPreview />} path="amr-states" />
    </Routes>
  );
}
