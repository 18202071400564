import React from 'react';
import { Button } from 'react-bootstrap';

import './ach-form.scss';
import type { VGSForm } from './vgs-collect-form';
import { VGSCollectForm, VGSCollectInput } from './vgs-collect-form';

type Props = {
  onSubmit: (form: VGSForm) => (status: any, response: any) => Promise<any>;
  setIsSubmitting?: (value: boolean) => void;
  submitLabel?: React.ReactNode;
  vgsRoute: string;
  vgsVaultId: string;
  vgsSatellitePort?: number;
  vgsEnvironment: string;
} & React.PropsWithChildren<Record<string, unknown>>;

export const ACHForm = React.forwardRef<HTMLFormElement, Props>(
  (
    {
      onSubmit,
      setIsSubmitting,
      submitLabel,
      vgsRoute,
      vgsEnvironment,
      vgsSatellitePort,
      vgsVaultId,
      ...passProps
    }: Props,
    ref
  ) => {
    const [errors, setErrors] = React.useState<Record<string, string>>({});

    const handleStateChange = (state: any) => {
      if (state) {
        const newErrors: Record<string, string> = {};

        for (const field in state) {
          if (
            state[field].errorMessages &&
            !state[field].isValid &&
            !state[field].isFocused &&
            state[field].isDirty
          ) {
            newErrors[field] = state[field].errorMessages[0];
          } else if (state[field].isValid || state[field].isFocused) {
            newErrors[field] = '';
          }
        }
        if (
          state['ach_routing_number'] &&
          state['ach_routing_number'].isDirty &&
          !state['ach_routing_number'].isFocused &&
          !state['ach_routing_number'].isValid
        ) {
          newErrors['ach_routing_number'] =
            'Routing numbers are 9 digits long.';
          state['ach_routing_number'].errorMessages = [
            'Invalid Routing Number',
          ];
        }

        setErrors(newErrors);
      }
    };

    return (
      <VGSCollectForm
        environment={vgsEnvironment}
        onStateChange={handleStateChange}
        onSubmit={onSubmit}
        ref={ref}
        route={vgsRoute}
        satellitePort={vgsSatellitePort}
        setIsSubmitting={setIsSubmitting}
        vaultId={vgsVaultId}
        {...passProps}>
        {(isSubmitting: boolean, form: VGSForm) => (
          <>
            <VGSCollectInput
              dataName="ach_bank_name"
              error={errors['ach_bank_name']}
              fieldId="ach-bank-name"
              form={form}
              label="Financial institution name"
              register={(f, dataName) => {
                f.field('#ach-bank-name', {
                  type: 'text',
                  name: dataName,
                  placeholder: 'Financial institution',
                  validations: ['required'],
                });
              }}
            />
            <VGSCollectInput
              dataName="ach_routing_number"
              error={errors['ach_routing_number']}
              fieldId="ach-routing-number"
              form={form}
              label="Routing Number"
              register={(f, dataName) => {
                f.field('#ach-routing-number', {
                  type: 'number',
                  name: dataName,
                  successColor: '#4F8A10',
                  errorColor: '#D8000C',
                  placeholder: '000000000',
                  validations: ['required', '/^[0-9]{9}$/'],
                });
              }}
            />
            <VGSCollectInput
              dataName="ach_account_number"
              error={errors['ach_account_number']}
              fieldId="ach-account-number"
              form={form}
              label="Account Number"
              register={(f, dataName) => {
                f.field('#ach-account-number', {
                  type: 'number',
                  name: dataName,
                  successColor: '#4F8A10',
                  errorColor: '#D8000C',
                  placeholder: '0000000000000000000',
                  validations: ['required'],
                });
              }}
            />
            {submitLabel && (
              <div className="d-flex mt-8">
                <Button
                  className="ml-auto btn-block"
                  disabled={isSubmitting}
                  type="submit"
                  variant="primary">
                  {isSubmitting ? (
                    <span className="spinner-border" />
                  ) : (
                    submitLabel
                  )}
                </Button>
              </div>
            )}
          </>
        )}
      </VGSCollectForm>
    );
  }
);

ACHForm.displayName = 'ACHForm';
