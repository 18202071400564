import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    fraudNotice: (
      <p>
        A person who files a claim with intent to defraud or helps commit a
        fraud against an insurer is guilty of a crime.
      </p>
    ),
  },
};

export default stateModule;
