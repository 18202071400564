import React from 'react';
import { Button, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { Edit3 } from 'react-feather';

import type { OOCParams } from '@liferaft/api/resources/application';
import { createOOC, hasOOC } from '@liferaft/api/resources/application';
import { ApplicationFlowKey } from '@liferaft/api/types';
import type { Offering } from '@liferaft/api/types';
import {
  CarrierKey,
  CoverageDisplay,
  CoverageType,
  InsuranceProductKey,
} from '@liferaft/api/types';
import type { NetworkResult } from '@liferaft/api/utils/network';
import { NetworkController } from '@liferaft/api/utils/network';

import { Card } from '@liferaft/core/components';
import { ameritasSummaryOfBenefitsLink } from '@liferaft/core/content/products/ameritas';
import { activeAmeritasRevision } from '@liferaft/core/content/products/ameritas/current';
import { useDebounce } from '@liferaft/core/hooks';
import { openFromBlob } from '@liferaft/core/utils/file';
import { usDollarFormat } from '@liferaft/core/utils/money';

import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';
import { useProductMatrixContext } from '@/contexts';
import { Path } from '@/flows/paths';
import {
  getQuoteGridSections,
  getSummaryBenefitsList,
  getSummaryFootnote,
  getSummaryHospitalServicesFootnote,
} from '@/pages/product-matrix/content';

export function SelectedPlansSummary() {
  const {
    data: { egress, flow_key, referrer_details },
    goTo,
    next,
  } = useApplicationFlow();
  const context = useProductMatrixContext(egress);

  const selectedInsuranceOfferings = context.selectedInsuranceOfferings;

  const [handleSubmit, isSubmitting] = useDebounce(
    async (event: React.SyntheticEvent<EventTarget>) => {
      event.preventDefault();
      return next(false);
    }
  );

  const editOfferings = () => {
    goTo(Path.PRODUCT_MATRIX);
  };

  const applicationFee = referrer_details?.application_fee_amount;
  const shouldApplyApplicationFee =
    flow_key === ApplicationFlowKey.APPLY &&
    applicationFee &&
    applicationFee > 0;

  let total = usDollarFormat.format(Number(context.totalPremium));
  if (shouldApplyApplicationFee) {
    total = usDollarFormat.format(
      Number(applicationFee) + Number(context.totalPremium)
    );
  }

  return (
    <ApplyWrapper
      footer={<ApplyWrapperFooter rightDisabled={isSubmitting} />}
      heading="Customize Your Liferaft">
      <Form id={APPLY_FORM_ID} onSubmit={handleSubmit}>
        <Col className="p-0" xs={12}>
          <Card cardClasses="card-bleed shadow-light mb-5 text-secondary">
            <div>
              <h2 className="font-weight-bold">Plan and Product Summary</h2>
              <p className="mb-4">
                Please review and confirm your selection below.
              </p>
              <ListGroup className="list-group-flush">
                {selectedInsuranceOfferings.map(([productKey, offering], i) => (
                  <SummaryItem
                    key={i}
                    offering={offering}
                    onEdit={editOfferings}
                    productKey={productKey}
                    state={context.residentState as string}
                    zipCode={egress?.zip_code as string}
                  />
                ))}
                <ListGroup.Item className="px-0 py-2">
                  <div className="d-flex justify-content-between mt-2 mb-3">
                    <Col className="px-0" xs={12}>
                      {shouldApplyApplicationFee && (
                        <Row className="flex-grow-1 no-gutters">
                          <p>Application Fee</p>
                          <div className="ml-auto">{`${usDollarFormat.format(
                            Number(applicationFee)
                          )}`}</div>
                        </Row>
                      )}
                      <Row className="flex-grow-1 no-gutters">
                        <p>Monthly total</p>
                        <div className="ml-auto">{`${usDollarFormat.format(
                          Number(context.totalPremium)
                        )}`}</div>
                      </Row>
                    </Col>
                  </div>
                  <div className="d-flex justify-content-between mt-2 mb-3">
                    <Col className="px-0" xs={12}>
                      <Row className="flex-grow-1 no-gutters">
                        <p>
                          <span className="font-weight-bold mr-1">
                            Your total
                          </span>
                          <span>(due today)</span>
                        </p>
                        <div className="font-weight-bold ml-auto">{total}</div>
                      </Row>
                    </Col>
                  </div>
                </ListGroup.Item>
              </ListGroup>
              <div className="pt-6">
                <p>
                  Coverage under these policies do not constitute comprehensive
                  health insurance coverage (a/k/a/ 'major medical insurance').
                  It therefore does not satisfy the 'minimum essential coverage'
                  requirements of the Patient Protection and Affordable Care
                  Act. Coverage will not satisfy the individual responsibility
                  requirements of section 5000A of the Internal Revenue Code.
                </p>
              </div>
            </div>
          </Card>
        </Col>
      </Form>
    </ApplyWrapper>
  );
}

type SummaryItemProps = {
  onEdit: () => void;
  productKey: InsuranceProductKey;
  offering: Offering;
  state: string;
  zipCode: string;
};

export function SummaryItem({
  onEdit,
  productKey,
  offering,
  state,
  zipCode,
}: SummaryItemProps) {
  const [hasAmeritasOOC, setHasAmeritasOOC] = React.useState<boolean>();

  const productBenefits = getSummaryBenefitsList(productKey, offering, state);

  const ameritasRevision = activeAmeritasRevision();
  let ameritasSummaryBenefits = '';
  if (productKey === InsuranceProductKey.DENTAL) {
    ameritasSummaryBenefits = ameritasSummaryOfBenefitsLink(
      state,
      ameritasRevision,
      InsuranceProductKey.DENTAL
    );
  } else if (productKey === InsuranceProductKey.VISION) {
    ameritasSummaryBenefits = ameritasSummaryOfBenefitsLink(
      state,
      ameritasRevision,
      InsuranceProductKey.VISION
    );
  }

  let selectedPlan = '';

  if (productKey === InsuranceProductKey.CRITICAL) {
    const selectedCancerType = offering.details.cancer_only
      ? getQuoteGridSections(InsuranceProductKey.CRITICAL, offering.carrier)[0]
      : getQuoteGridSections(InsuranceProductKey.CRITICAL, offering.carrier)[1];
    selectedPlan = `${CoverageDisplay[offering.coverage_type]} ${
      offering.display_label
    } ${selectedCancerType} Plan`;
  } else if (
    offering.coverage_type === CoverageType.INDIVIDUAL_CHILDREN &&
    offering.carrier === CarrierKey.AMR
  ) {
    selectedPlan = `Individual + Child ${offering.display_label} Plan`;
  } else {
    selectedPlan = `${CoverageDisplay[offering.coverage_type]} ${
      offering.display_label
    } Plan`;
  }

  React.useEffect(() => {
    if (
      productKey === InsuranceProductKey.DENTAL ||
      productKey === InsuranceProductKey.VISION
    ) {
      const network = new NetworkController();
      network.request(
        hasOOC(state, productKey),
        (result: NetworkResult<boolean>) => {
          if (result.error) {
            return;
          }
          setHasAmeritasOOC(result.data);
        }
      );
      return () => void network.cancel();
    }
  }, [productKey]);

  const downloadOOC = async () => {
    const network = new NetworkController();
    const planCode = offering.plan_code;

    const result = await network.request<OOCParams, Blob>(
      createOOC({
        coverageType: offering.coverage_type,
        planCode,
        product: productKey,
        zipCode,
      })
    );

    if (result.error) return;
    openFromBlob(
      result.data.slice(0, result.data.size, 'application/pdf'),
      `Ameritas${productKey}OOC_${planCode}_${zipCode}.pdf`,
      true
    );
  };

  const footnote = getSummaryFootnote(
    productKey,
    offering.carrier,
    state,
    ameritasRevision,
    productKey === InsuranceProductKey.DENTAL && offering
      ? offering.display_label
      : ''
  );

  return (
    <ListGroup.Item className="list-group-item px-0 py-2">
      <div className="d-flex justify-content-between mt-2 mb-3">
        <Col className="px-0" xs={12}>
          <Row className="mb-md-2 mb-3">
            <Col xs={6}>
              <h3 className="font-weight-bold mb-0">
                {offering.product_display_name}
              </h3>
            </Col>
            <Col className="text-right" xs={6}>
              <Button
                className="btn-link ml-5 mb-2 p-0"
                onClick={onEdit}
                variant="empty">
                <Edit3 viewBox="0 0 30 30" />
                Edit Plan
              </Button>
            </Col>
          </Row>

          <div className="d-flex flex-column flex-md-row">
            <p className="font-weight-bold mb-0">Selected Plan:</p>
            <p className="ml-0 ml-md-3 mb-2 mb-md-0">{selectedPlan}</p>
          </div>

          <div className="d-flex flex-column flex-md-row">
            <p className="font-weight-bold mb-0">Benefit:</p>
            <p className="ml-0 ml-md-3 mb-2 mb-md-0">
              {productBenefits.length > 0 &&
                productBenefits.map((benefit: string, i: number) => {
                  return (
                    <span key={i}>
                      {benefit}
                      <br />
                    </span>
                  );
                })}
            </p>
          </div>

          <div className="text-right">{`$${offering.monthly_premium}/mo`}</div>
          <div className="font-size-sm mt-2">{footnote}</div>
          {offering.carrier === 'AMR' && (
            <div className="font-size-sm mt-2">
              <a
                href={ameritasSummaryBenefits}
                rel="noreferrer"
                target="_blank">
                Summary of Benefits
              </a>
              {hasAmeritasOOC && (
                <>
                  {' | '}
                  <Button
                    className="font-size-sm btn-link m-0 p-0"
                    onClick={downloadOOC}
                    variant="empty">
                    Outline of Coverage
                  </Button>
                </>
              )}
            </div>
          )}
          {productKey === InsuranceProductKey.HOSPITAL &&
            offering.has_non_insurance && (
              <>
                <Row className="flex-grow-1 no-gutters mt-4">
                  <div>Non-Insurance Services</div>
                  <div className="ml-auto">{`$${offering.monthly_non_insurance_premium}/mo`}</div>
                </Row>
                <Row className="flex-grow-1 no-gutters">
                  {getSummaryHospitalServicesFootnote(offering.carrier)}
                </Row>
              </>
            )}
        </Col>
      </div>
    </ListGroup.Item>
  );
}
