import React from 'react';
import { Form } from 'react-bootstrap';

import { formatPhoneNumber } from '../../utils/phone-number';

type Props = {
  disabled?: boolean;
  required?: boolean;
  error?: string;
  label?: string;
  groupClasses?: string;
  labelClasses?: string;
  value?: string;
  onChange: (value: string) => void;
};

export function PhoneNumberInput({
  disabled = false,
  required = true,
  error,
  label,
  groupClasses = 'form-group',
  labelClasses = '',
  value,
  onChange,
}: Props) {
  React.useLayoutEffect(() => {
    if (value) {
      onChange(formatPhoneNumber(value));
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(formatPhoneNumber(event.target.value));
  };

  return (
    <Form.Group className={groupClasses} data-testid="phone-form-group">
      {label && <Form.Label className={labelClasses}>{label}</Form.Label>}
      <Form.Control
        data-testid="phone-number-input"
        disabled={disabled}
        id="phone-number"
        isInvalid={!!error}
        name="phone-number"
        onChange={handleChange}
        pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
        placeholder="(XXX) XXX-XXXX"
        required={required}
        type="tel"
        value={value}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
}
