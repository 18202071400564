import Cookies from 'js-cookie';
import React from 'react';
import { Form } from 'react-bootstrap';

import { updateApplication } from '@liferaft/api/resources/application';
import type {
  Application,
  Egress,
  InsuranceProductKey,
  Offering,
  ProductKey,
  ProductSelection,
} from '@liferaft/api/types';
import { NetworkController } from '@liferaft/api/utils/network';

import { useDebounce } from '@liferaft/core/hooks';

import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';
import { useProductMatrixContext } from '@/contexts';
import { Product } from '@/pages/product-matrix';
import { productSort } from '@/utils/data-shaping';

export function ProductMatrix() {
  const {
    data: { egress, id },
    next,
  } = useApplicationFlow();

  const context = useProductMatrixContext(egress);
  const canGoNext = context.selectedInsuranceOfferings.length > 0;

  const preferredProduct = Cookies.get('preferred_product') || '';

  let products = Object.keys(context.matrix?.products || {}) as ProductKey[];
  products = products.sort((p1, p2) => productSort(p1, p2, preferredProduct));

  const [activeProduct, setActiveProduct] = React.useState<ProductKey>();

  const [handleSubmit, isSubmitting] = useDebounce(
    async (event: React.SyntheticEvent<EventTarget>) => {
      event.preventDefault();

      const network = new NetworkController();

      const productSelections: ProductSelection[] =
        context.selectedInsuranceOfferings.map(
          ([productKey, offering]: [InsuranceProductKey, Offering]) => ({
            name: productKey,
            carrier: offering.carrier,
            code: offering.plan_code,
            offering_id: offering.offering_id,
            coverage_type: offering.coverage_type,
          })
        );

      const result = await network.request<Partial<Egress>, Application>(
        updateApplication(id as string, {
          product_selections: productSelections,
        })
      );

      if (result.error) {
        return;
      }

      return next();
    }
  );

  return (
    <ApplyWrapper
      footer={
        <ApplyWrapperFooter
          rightDisabled={!canGoNext || isSubmitting}
          submitLabel="Submit Selections"
        />
      }
      fullWidth
      heading="Customize Your Liferaft"
      subheading="Select the plans and products that are right for you.">
      <Form id={APPLY_FORM_ID} onSubmit={handleSubmit}>
        {products &&
          products.length > 0 &&
          products.map((productKey, i) => {
            const displayName =
              context.matrix?.products[productKey]?.display_name;

            let isActive = i === 0;
            if (activeProduct) {
              isActive = activeProduct === productKey;
            }

            if (displayName) {
              return (
                <Product
                  isActive={isActive}
                  key={productKey}
                  onToggle={setActiveProduct}
                  productKey={productKey}
                  productName={displayName}
                />
              );
            }
          })}
        <div className="d-flex mt-6">
          <div className="ml-auto">
            <h5 className="font-weight-bold mb-0">Total</h5>
            <div>
              <span className="price display-4 mb-0 font-weight-bold">
                {`$${context?.totalPremium}`}
              </span>
              <span className="h3 align-self-end mb-1"> /mo</span>
            </div>
          </div>
        </div>
      </Form>
    </ApplyWrapper>
  );
}
