import React from 'react';

import type { StateModule } from '../types';

function getPlanHelperRevision(planDisplayLabel: string): string {
  switch (planDisplayLabel) {
    case 'Advantage Plus Network 1000':
      return '(Indiv. 9000 WA Rev. 07-16-1)';
    case 'Advantage Plus Network 2000':
      return '(Indiv. 9000 WA Rev. 07-16-4)';
    case 'Advantage Plus 1000':
      return '(Indiv. 9000 WA Rev. 07-16-2)';
    case 'Advantage Plus 2000':
      return '(Indiv. 9000 WA Rev. 07-16-5)';
    case 'Basic Vision':
      return '(Indiv. 9000 WA Rev. 07-16 EC-1)';
    default:
      return '(Indiv. 9000 WA Rev. 07-16 et al)';
  }
}

export function getWashingtonDentalSummaryFootnote(
  selectedDentalDisplayLabel: string
): React.ReactNode {
  switch (selectedDentalDisplayLabel) {
    case 'Advantage Plus Network 1000':
      return (
        <small>
          Advantage Plus Network 1000 is designed for those who will visit an
          Ameritas Classic (PPO) network provider.
        </small>
      );
    case 'Advantage Plus Network 2000':
      return (
        <small>
          Advantage Plus Network 2000 is designed for those who will visit an
          Ameritas Classic (PPO) network provider.
        </small>
      );
    case 'Advantage Plus 1000':
      return (
        <small>
          Advantage Plus 1000 provides freedom to use any dentist with the
          opportunity to visit an Ameritas Classic (PPO) network provider for
          additional savings.
        </small>
      );
    case 'Advantage Plus 2000':
      return (
        <small>
          Advantage Plus 2000 provides freedom to use any dentist with the
          opportunity to visit an Ameritas Classic(PPO) network provider for
          additional savings.
        </small>
      );
    default:
      return null;
  }
}

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
      vision: false,
    },
    planDescriptions: {
      dental: (
        <p>
          These PrimeStar® Dental Plans help you get the dental coverage you
          need at affordable rates. Your coverage begins next-day with no
          waiting periods on most dental insurance plans. There are no
          enrollment fees with any plan. Preventive visits, such as exams and
          cleanings, are covered up to 100%. Dental plans may include additional
          benefits such as LASIK, orthodontia or hearing care. A deductible may
          apply for coverage; please see Coverage Quick Facts for more details.
        </p>
      ),
      vision: (
        <p>
          This PrimeStar® Vision Plan helps protect your eye health and ensure
          that you have access to the eyeglasses or contacts you rely on. Your
          coverage begins next-day with no waiting periods and no enrollment
          fees. A deductible may apply for coverage; please see Coverage Quick
          Facts for more details.
        </p>
      ),
    },
    planFacts: {
      dental: new Map([
        ['Access Plan In- and Out-of-Network Coinsurance', null],
        ['Lite Plan In- and Out-of-Network Coinsurance', null],
        ['Boost Plan In- and Out-of-Network Coinsurance', null],
        ['Complete Plan In- and Out-of-Network Coinsurance', null],
        ['Value Plan', null],
        ['Access Plan', null],
        ['Total Plan', null],
        ['Lite Plan', null],
        ['Boost Plan', null],
        ['Complete Plan', null],
        ['Preventive Plus', null],
        [
          'Deductible',
          <p key="description">
            $50 deductible per person for Preventive, Basic, and Major services
            combined. Family plans have a max $150 deductible.
          </p>,
        ],
        [
          'Waiting Periods',
          <p key="description">
            No waiting period applies to Preventive (Type 1) and Basic (Type 2)
            Services. A 6-month waiting period applies to Major (Type 3)
            Services.
          </p>,
        ],
        [
          'Max Benefit',
          <p key="description">
            Maximum benefit is per person for Preventive, Basic, and Major
            services combined.
          </p>,
        ],

        [
          'Preventive Services (Type 1)',
          <p key="description">
            Exams, Cleanings, Fluoride (age 16), Sealants, Space Maintainers,
            X-rays (Bitewing)
          </p>,
        ],
        [
          'Basic Services (Type 2)',
          <p key="description">Fillings, Simple Extractions</p>,
        ],
        [
          'Major Services (Type 3)',
          <p key="description">
            X-Rays (Panoramic), Oral Surgery, Endodontics, Periodontics, Crowns,
            Bridges, Dentures, Implants
          </p>,
        ],
        [
          'Network',
          <>
            <p>
              Advantage Plus 1000 and Advantage Plus 2000 plans provide freedom
              to use any dentist with the opportunity to visit an Ameritas
              Classic (PPO) network provider for additional savings.
            </p>
            <p>
              Advantage Plus Network 1000 and Advantage Plus Network 2000 plans
              are designed for those who will visit an Ameritas Classic (PPO)
              network provider.
            </p>
          </>,
        ],
      ]),
      vision: new Map([
        ['Premium Contacts Deductible', null],
        ['Medically Necessary Contacts', null],
        ['Select Vision Plan Partner', null],
        ['Choice Vision Plan Partner', null],
        ['Select Vision Plan Out-of-Network Maximum', null],
        ['Choice Vision Plan Out-of-Network Maximum', null],
        [
          'Deductibles',
          <p key="description">
            $50 eyeglass lenses or frames deductible per person per lifetime. A
            maximum of 3 individual deductibles per family will apply.
          </p>,
        ],
        [
          'Waiting Periods',
          <p key="description">
            No waiting periods apply to the eye exam, glasses lenses, glasses
            frames, or contact lenses benefits.
          </p>,
        ],
        [
          'Glasses Lenses Benefit',
          <>
            <p>
              Max benefit amount for additional glasses lenses options after $50
              deductible:
            </p>
            <ul>
              <li>$40 single vision</li>
              <li>$60 lined bifocal</li>
              <li>$70 lined trifocal</li>
              <li>$100 no line bifocal</li>
              <li>$100 standard progressive</li>
              <li>$100 lenticular</li>
            </ul>
          </>,
        ],
      ]),
    },
    planFactsFootnote: {
      dental: (
        <>
          Advantage Plus Network plans claim allowance (MAC): The Maximum
          Allowable Charge (MAC) claim allowance is the maximum amount a network
          provider may charge. If you select a network provider, you may have
          lower out-of-pocket costs. If you visit an out-of-network dentist, the
          claim allowance is considered at the Maximum Allowable Benefit (MAB),
          which is equal to the lowest contracted fee in your ZIP Code area. You
          pay the difference between what the plan pays and the dentist's actual
          charge.
          <br />
          Advantage Plus plans claim allowance (PPO): If you visit a network
          provider, payments are based on the dentist's contracted fees
          (MAC/maximum allowable charge), which may result in lower
          out-of-pocket costs. If you visit an out-of-network dentist, covered
          benefits are paid at the 80th percentile of usual and customary (80th
          U&C) charges. This means we expect 8 out of 10 charges from dental
          providers to be within the amount we'll pay for a covered procedure.
          You pay the difference between what the plan pays and the dentist's
          actual charge.
          <br />
          <a
            href="https://dentalnetwork.ameritas.com/classic"
            rel="noreferrer"
            target="_blank">
            Find a Classic (PPO) network provider near you.
          </a>
        </>
      ),
    },
    planHelper: {
      dental: (label: string) => (
        <>
          <small>
            Underwritten by Ameritas Life Insurance Corp. | 5900 O Street
            Lincoln, NE 68510
          </small>
          <br />
          <small>
            This highlight is not a certificate of insurance or guarantee of
            coverage. Premium rates may change upon renewal. This policy is
            renewable at the option of the insured.
          </small>
          <br />
          <small>
            This information is provided by Ameritas Life Insurance Corp.
            (Ameritas Life). Individual dental and vision products{' '}
            {getPlanHelperRevision(label)} are issued by Ameritas Life. Some
            plan designs are not available in all areas. The state of Washington
            requires that producers be appointed with us before soliciting
            products.
          </small>
          <br />
          <small>
            Ameritas, the bison design and "fulfilling life" are service marks
            or registered service marks of Ameritas Life, affiliate Ameritas
            Holding Company or Ameritas Mutual Holding Company. © 2023 Ameritas
            Mutual Holding Company.
          </small>
        </>
      ),
      vision: (label: string) => (
        <>
          <small>
            Underwritten by Ameritas Life Insurance Corp. | 5900 O Street
            Lincoln, NE 68510
          </small>
          <br />
          <small>
            This highlight is not a certificate of insurance or guarantee of
            coverage. Premium rates may change upon renewal. This policy is
            renewable at the option of the insured.
          </small>
          <br />
          <small>
            This information is provided by Ameritas Life Insurance Corp.
            (Ameritas Life). Individual dental and vision products{' '}
            {getPlanHelperRevision(label)} are issued by Ameritas Life. Some
            plan designs are not available in all areas. The state of Washington
            requires that producers be appointed with us before soliciting
            products.
          </small>{' '}
          <br />
          <small>
            Ameritas, the bison design and "fulfilling life" are service marks
            or registered service marks of Ameritas Life, affiliate Ameritas
            Holding Company or Ameritas Mutual Holding Company. © 2023 Ameritas
            Mutual Holding Company.
          </small>
        </>
      ),
    },
    summaryFootnote: {
      dental: getWashingtonDentalSummaryFootnote,
    },
    fraudNotice: (
      <p>
        It is a crime to knowingly provide false, incomplete, or misleading
        information to an insurance company for the purpose of defrauding the
        company. Penalties include imprisonment, fines, and denial of insurance
        benefits.
      </p>
    ),
    dentalLimitations: (
      <>
        <p>
          Dental Expenses will not include, and benefits will not be payable,
          for any of the following.
        </p>
        <ul>
          <li>
            Covered Dental Expenses in the first 12 months that a person is
            insured if the person is a Late Entrant; except for evaluations,
            prophylaxis (cleanings), and fluoride application.
          </li>
          <li>
            Covered Dental Expenses for initial placement of any prosthetic
            crown, appliance, or fixed partial denture unless such placement is
            needed because of the extraction of one or more teeth while the
            insured person is covered under this contract. But the extraction of
            a third molar (wisdom tooth) will not qualify under the above. Any
            such prosthetic crown, appliance, or fixed partial denture must
            include the replacement of the extracted tooth or teeth.
          </li>
          <li>
            Covered Dental Expenses for appliances, restorations, or procedures
            to do any of the following:
            <ul>
              <li>Alter vertical dimension.</li>
              <li>Restore or maintain occlusion.</li>
              <li>
                Splint or replace tooth structure lost as a result of abrasion
                or attrition.
              </li>
            </ul>
          </li>
          <li>
            Covered Dental Expenses for any procedure begun after the insured
            person's insurance under this contract terminates; or for any
            prosthetic dental appliances installed or delivered more than 90
            days after the Insured's insurance under this contract terminates.
          </li>
          <li>Covered Dental Expenses to replace lost or stolen appliances.</li>
          <li>
            Covered Dental Expenses for any treatment which is for cosmetic
            purposes.
          </li>
          <li>
            Covered Dental Expenses for any procedure not shown in the Table of
            Dental Procedures, including procedures deemed experimental or
            investigational by the American Dental Association. (Frequency and
            other limitations may apply. Please see the Table of Dental
            Procedures for details.)
          </li>
          <li>
            Covered Dental Expenses for which the Insured person is entitled to
            benefits under any workers' compensation or similar law, or charges
            for services or supplies received as a result of any dental
            condition caused or contributed to by an injury or sickness arising
            out of or in the course of employment.
          </li>
          <li>
            Covered Dental Expenses for charges which the Insured person is not
            liable or which would not have been made had no insurance been in
            force, except for those benefits paid under Medicaid.
          </li>
          <li>
            Covered Dental Expenses for services that are not required for
            necessary care and treatment or are not within the generally
            accepted parameters of care.
          </li>
          <li>
            Covered Dental Expenses because of war or any act of war, declared
            or not.
          </li>
        </ul>
      </>
    ),
  },
  revisions: {
    '2024Q2': {
      planHelper: {
        dental: (label: string) => (
          <>
            <small>
              Underwritten by Ameritas Life Insurance Corp. | 5900 O Street
              Lincoln, NE 68510
            </small>
            <br />
            <small>
              This highlight is not a certificate of insurance or guarantee of
              coverage. Premium rates may change upon renewal. This policy is
              renewable at the option of the insured.
            </small>
            <br />
            <small>
              This information is provided by Ameritas Life Insurance Corp.
              (Ameritas Life). Individual dental and vision products{' '}
              {getPlanHelperRevision(label)} are issued by Ameritas Life. Some
              plan designs are not available in all areas. The state of
              Washington requires that producers be appointed with us before
              soliciting products. Ameritas, the bison design and “fulfilling
              life” are service marks or registered service marks of Ameritas
              Life, affiliate Ameritas Holding Company or Ameritas Mutual
              Holding Company. © 2024 Ameritas Mutual Holding Company.
            </small>
          </>
        ),
        vision: (label: string) => (
          <>
            <small>
              Underwritten by Ameritas Life Insurance Corp. | 5900 O Street
              Lincoln, NE 68510
            </small>
            <br />
            <small>
              This highlight is not a certificate of insurance or guarantee of
              coverage. Premium rates may change upon renewal. This policy is
              renewable at the option of the insured.
            </small>
            <br />
            <small>
              This information is provided by Ameritas Life Insurance Corp.
              (Ameritas Life). Individual dental and vision products{' '}
              {getPlanHelperRevision(label)} are issued by Ameritas Life. Some
              plan designs are not available in all areas. The state of
              Washington requires that producers be appointed with us before
              soliciting products. Ameritas, the bison design and “fulfilling
              life” are service marks or registered service marks of Ameritas
              Life, affiliate Ameritas Holding Company or Ameritas Mutual
              Holding Company. © 2024 Ameritas Mutual Holding Company.
            </small>
          </>
        ),
      },
    },
  },
};

export default stateModule;
