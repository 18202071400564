import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
    },
    dentalLimitations: (
      <>
        <p>
          Covered expenses will not include and benefits will not be payable for
          expenses incurred:
        </p>
        <ul>
          <li>
            for any treatment which is for cosmetic purposes, except as
            specifically listed in the Table of Dental Procedures.
          </li>
          <li>
            to replace any crowns, inlays, onlays, veneers, complete or partial
            dentures within five years of the date of the last placement of
            these items. But if a replacement is required because of an
            accidental bodily injury sustained while the Insured person is
            covered under this contract, it will be a covered expense.
          </li>
          <li>
            for any procedure begun before the insured person was covered under
            the policy.
          </li>
          <li>
            for any procedure begun after the insured person's insurance under
            the policy terminates; or for any prosthetic dental appliances
            installed or delivered more than 90 days after the insured's
            insurance under the policy terminates.
          </li>
          <li>to replace lost or stolen appliances.</li>
          <li>
            for appliances, restorations, or procedures to:
            <ul>
              <li>alter vertical dimension;</li>
              <li>restore or maintain occlusion; or</li>
              <li>
                splint or replace tooth structure lost as a result of abrasion
                or attrition.
              </li>
            </ul>
          </li>
          <li>
            for any procedure which is not shown on the Table of Dental
            Procedures. (There may be additional frequencies and limitations
            that apply, please see the Table of Dental Procedures in the
            policy.)
          </li>
          <li>
            for which the insured person is entitled to benefits under any
            workmen's compensation or similar law, or charges for services or
            supplies received as a result of any dental condition caused or
            contributed to by an injury or sickness arising out of or in the
            course of any employment for wage or profit.
          </li>
          <li>
            for charges for which the insured person is not liable or which
            would not have been made had no insurance been in force.
          </li>
          <li>
            for services which are not required for necessary care and treatment
            or are not within the generally accepted parameters of care.
          </li>
          <li>because of war or any act of war, declared or not.</li>
          <li>
            if two or more procedures are considered adequate and appropriate
            treatment to correct a certain condition under generally accepted
            standards of dental care, the amount of the covered expense will be
            equal to the charge for the least expensive procedure.
          </li>
        </ul>
      </>
    ),
    stateNotice: (
      <>
        <p>
          THIS POLICY DOES NOT INCLUDE COVERAGE OF PEDIATRIC DENTAL SERVICES AS
          REQUIRED UNDER THE AFFORDABLE CARE ACT. COVERAGE OF PEDIATRIC DENTAL
          SERVICES IS AVAILABLE FOR PURCHASE IN THE STATE OF COLORADO AND CAN BE
          PURCHASED AS A STAND-ALONE PLAN. PLEASE CONTACT YOUR INSURANCE
          CARRIER, AGENT, OR CONNECT FOR HEALTH COLORADO TO PURCHASE EITHER A
          PLAN THAT INCLUDES PEDIATRIC DENTAL COVERAGE OR AN EXCHANGE-QUALIFIED
          STAND-ALONE DENTAL PLAN THAT INCLUDES PEDIATRIC DENTAL COVERAGE.
        </p>
        <p>
          THIS IS A LIMITED BENEFIT HEALTH COVERAGE POLICY AND IS NOT A
          SUBSTITUTE FOR MAJOR MEDICAL COVERAGE. LACK OF MAJOR MEDICAL COVERAGE
          (OR OTHER MINIMUM ESSENTIAL COVERAGE) MAY RESULT IN AN ADDITIONAL
          PAYMENT WITH YOUR TAXES.
        </p>
      </>
    ),
    fraudNotice: (
      <p>
        It is unlawful to knowingly provide false, incomplete, or misleading
        facts or information to an insurance company for the purpose of
        defrauding or attempting to defraud the company. Penalties may include
        imprisonment, fines, denial of insurance and civil damages. Any
        insurance company or agent of an insurance company who knowingly
        provides false, incomplete, or misleading facts or information to a
        policyholder or claimant for the purpose of defrauding or attempting to
        defraud the policyholder or claimant with regard to a settlement or
        award payable from insurance proceeds shall be reported to the Colorado
        division of insurance within the department of regulatory agencies.
      </p>
    ),
    planFacts: {
      vision: new Map([
        [
          'Select Vision Plan Partner',
          <p key="description">
            Select Vision Plan features the EyeMed vision network. Find EyeMed
            Access network providers at{' '}
            <a href="https://eyemed.com" rel="noreferrer" target="_blank">
              eyemed.com
            </a>
            . Retail chains accepting EyeMed insurance include LensCrafters,
            Pearle Vision and Target Optical. Online options in the EyeMed
            network are{' '}
            <a href="https://www.glasses.com" rel="noreferrer" target="_blank">
              Glasses.com
            </a>{' '}
            and{' '}
            <a
              href="https://www.contactsdirect.com"
              rel="noreferrer"
              target="_blank">
              ContactsDirect
            </a>
            . Based on applicable laws, reduced cost may vary by doctor
            location.
            <br />
            <a
              href="https://eyemed.com/en-us/member-bill-of-rights/ameritas-colorado-access-plan"
              rel="noreferrer"
              target="_blank">
              EyeMed's Colorado Network Access Plan
            </a>
          </p>,
        ],
      ]),
    },
  },
};

export default stateModule;
