import React from 'react';

export function createContext<A extends Record<string, any> | null>(
  defaultValue?: A
) {
  const context = React.createContext<A | undefined>(defaultValue);

  function useContext() {
    const ctx = React.useContext(context);
    if (ctx === undefined)
      throw new Error('useContext must be inside a Provider with a value');
    return ctx;
  }

  return [useContext, context.Provider] as const;
}
