import type {
  InProgressApplication,
  Offering,
  ProductSelection,
} from '@liferaft/api/types';
import { CarrierKey, CoverageType } from '@liferaft/api/types';

import type { NextParams } from '@liferaft/core/utils/flow';
import { Step } from '@liferaft/core/utils/flow';

import { Path } from '@/flows/paths';

class ApplicationStep extends Step<InProgressApplication> {
  public readonly dataKey = 'egress';
}

export const Alias = new ApplicationStep(Path.ALIAS, {
  collectables: ['alias'],
});

export const RatingData = new ApplicationStep(Path.RATING_DATA, {
  collectables: [
    'date_of_birth',
    'doctor_visits',
    'exercise',
    'is_tobacco_user',
    'selected_sex',
    'state',
    'zip_code',
  ],
});

export const ProductMatrix = new ApplicationStep(Path.PRODUCT_MATRIX, {
  collectables: ['product_selections'],
});

export const PlanSummary = new ApplicationStep(Path.PLAN_SUMMARY);

export const CreateAccount = new ApplicationStep(Path.CREATE_ACCOUNT, {
  collectables: ['first_name', 'last_name', 'email'],
});

export const ContactInfo = new ApplicationStep(Path.CONTACT_INFO, {
  collectables: ['phone_number', 'address'],
  next: ({ state: application }: NextParams<InProgressApplication>): string => {
    const egress = application.egress;
    const insuranceOfferings = egress?.product_selections?.map(
      (selection: ProductSelection) => {
        if ('carrier' in selection) {
          const products = egress?.product_matrix?.products;
          if (products) {
            const carriers = products[selection.name]?.carriers;
            if (carriers) {
              return carriers[selection.carrier]?.offerings?.find(
                (o: Offering) => o.offering_id === selection.offering_id
              );
            }
          }
        }
      }
    ) as Offering[];

    const selectedCoverages = insuranceOfferings
      .filter((o: Offering) => o !== undefined)
      .map((o: Offering) => o.coverage_type);

    if (
      selectedCoverages.includes(CoverageType.INDIVIDUAL_CHILDREN) ||
      selectedCoverages.includes(CoverageType.INDIVIDUAL_SPOUSE) ||
      selectedCoverages.includes(CoverageType.FAMILY)
    ) {
      return Dependents.path;
    }

    return PaymentInfo.path;
  },
});

export const Dependents = new ApplicationStep(Path.DEPENDENTS, {
  collectables: ['children', 'spouse'],
  isRequired: (application: InProgressApplication): boolean => {
    const egress = application.egress;

    const insuranceOfferings = egress?.product_selections?.map(
      (selection: ProductSelection) => {
        if ('carrier' in selection) {
          const products = egress?.product_matrix?.products;
          if (products) {
            const carriers = products[selection.name]?.carriers;
            if (carriers) {
              return carriers[selection.carrier]?.offerings?.find(
                (o: Offering) => o.offering_id === selection.offering_id
              );
            }
          }
        }
      }
    ) as Offering[];

    return insuranceOfferings
      .filter((o: Offering) => o !== undefined)
      .map((o: Offering) => o.coverage_type)
      .some(
        (coverageType) =>
          coverageType === CoverageType.INDIVIDUAL_SPOUSE ||
          coverageType === CoverageType.INDIVIDUAL_CHILDREN ||
          coverageType === CoverageType.FAMILY
      );
  },
  isFulfilled: (application: InProgressApplication): boolean => {
    const egress = application.egress;

    const insuranceOfferings = egress?.product_selections?.map(
      (selection: ProductSelection) => {
        if ('carrier' in selection) {
          const products = egress?.product_matrix?.products;
          if (products) {
            const carriers = products[selection.name]?.carriers;
            if (carriers) {
              return carriers[selection.carrier]?.offerings?.find(
                (o: Offering) => o.offering_id === selection.offering_id
              );
            }
          }
        }
      }
    ) as Offering[];

    const selectedCoverages = insuranceOfferings
      .filter((o: Offering) => o !== undefined)
      .map((o: Offering) => o.coverage_type);

    const requiresSpouse = selectedCoverages.includes(
      CoverageType.INDIVIDUAL_SPOUSE
    );
    const requiresChildren = selectedCoverages.includes(
      CoverageType.INDIVIDUAL_CHILDREN
    );

    const hasSpouse = egress?.spouse;
    const hasChildren = egress?.children && egress?.children.length > 0;

    if (requiresSpouse && !hasSpouse) {
      return false;
    }

    if (requiresChildren && !hasChildren) {
      return false;
    }

    if (
      selectedCoverages.includes(CoverageType.FAMILY) &&
      !hasSpouse &&
      !hasChildren
    ) {
      return false;
    }

    return true;
  },
});

export const PaymentInfo = new ApplicationStep(Path.PAYMENT_INFO, {
  collectables: ['payment_via', 'ach_info', 'credit_card'],
  isFulfilled: (application: InProgressApplication) =>
    !!(application?.egress?.ach_info || application?.egress?.credit_card),
});

export const Confirm = new ApplicationStep(Path.CONFIRM, {
  collectables: ['confirmed_info'],
  next: ({ state: application }: NextParams<InProgressApplication>): string => {
    const egress = application.egress;
    const insuranceOfferings = egress?.product_selections?.map(
      (selection: ProductSelection) => {
        if ('carrier' in selection) {
          const products = egress?.product_matrix?.products;
          if (products) {
            const carriers = products[selection.name]?.carriers;
            if (carriers) {
              return carriers[selection.carrier]?.offerings?.find(
                (o: Offering) => o.offering_id === selection.offering_id
              );
            }
          }
        }
      }
    ) as Offering[];

    const selectedCarriers = insuranceOfferings
      .filter((o: Offering) => o !== undefined)
      .map((o: Offering) => o.carrier);

    if (selectedCarriers.includes(CarrierKey.AIG)) {
      return AIGDisclosures.path;
    }

    if (selectedCarriers.includes(CarrierKey.LIB)) {
      return LibertyDisclaimer.path;
    }

    if (selectedCarriers.includes(CarrierKey.AMR)) {
      return AmeritasDisclosures.path;
    }

    return Submit.path;
  },
});

export const AIGDisclosures = new ApplicationStep(Path.AIG_DISCLOSURES, {
  collectables: ['aig_document_delivery', 'aig_electronic_consent'],
});

export const AIGDisclaimer = new ApplicationStep(Path.AIG_DISCLAIMER, {
  next: ({ state: application }: NextParams<InProgressApplication>): string => {
    const egress = application.egress;
    const insuranceOfferings = egress?.product_selections?.map(
      (selection: ProductSelection) => {
        if ('carrier' in selection) {
          const products = egress?.product_matrix?.products;
          if (products) {
            const carriers = products[selection.name]?.carriers;
            if (carriers) {
              return carriers[selection.carrier]?.offerings?.find(
                (o: Offering) => o.offering_id === selection.offering_id
              );
            }
          }
        }
      }
    ) as Offering[];

    const selectedCarriers = insuranceOfferings
      .filter((o: Offering) => o !== undefined)
      .map((o: Offering) => o.carrier);

    if (
      selectedCarriers.includes(CarrierKey.LIB) &&
      !LibertyDisclaimer.isFulfilled(application)
    ) {
      return LibertyDisclaimer.path;
    }

    if (
      selectedCarriers.includes(CarrierKey.AMR) &&
      !AmeritasDisclosures.isFulfilled(application)
    ) {
      return AmeritasDisclosures.path;
    }

    return Submit.path;
  },
});

export const LibertyDisclaimer = new ApplicationStep(Path.LIBERTY_DISCLAIMER, {
  collectables: ['lib_document_delivery', 'lib_electronic_consent'],
  next: ({ state: application }: NextParams<InProgressApplication>): string => {
    const egress = application.egress;
    const insuranceOfferings = egress?.product_selections?.map(
      (selection: ProductSelection) => {
        if ('carrier' in selection) {
          const products = egress?.product_matrix?.products;
          if (products) {
            const carriers = products[selection.name]?.carriers;
            if (carriers) {
              return carriers[selection.carrier]?.offerings?.find(
                (o: Offering) => o.offering_id === selection.offering_id
              );
            }
          }
        }
      }
    ) as Offering[];

    const selectedCarriers = insuranceOfferings
      .filter((o: Offering) => o !== undefined)
      .map((o: Offering) => o.carrier);

    if (
      selectedCarriers.includes(CarrierKey.AMR) &&
      !AmeritasDisclosures.isFulfilled(application)
    ) {
      return AmeritasDisclosures.path;
    }

    return Submit.path;
  },
});

export const AmeritasDisclosures = new ApplicationStep(
  Path.AMERITAS_DISCLOSURES,
  {
    collectables: ['amr_has_current_coverage', 'amr_document_delivery'],
  }
);

export const AmeritasDisclaimer = new ApplicationStep(Path.AMERITAS_DISCLAIMER);

export const Submit = new ApplicationStep(Path.SUBMIT);
