import React from 'react';
import { Col, Row } from 'react-bootstrap';

import type { CarrierKey, ProductKey } from '@liferaft/api/types';
import { InsuranceProductKey } from '@liferaft/api/types';

import { Card } from '@liferaft/core/components';
import type { AmeritasRevision } from '@liferaft/core/content/products/ameritas';

import { useProductMatrixContext } from '@/contexts';
import { CoverageToggle, PlanInfo, QuotesGrid } from '@/pages/product-matrix';

type Props = {
  productKey: ProductKey;
  ameritasRevision: AmeritasRevision;
};

export function PlanCard({ productKey, ameritasRevision }: Props) {
  const context = useProductMatrixContext();

  const selectedCarrier = context.config[productKey]
    .carrierSelected as CarrierKey;

  let partialPlanName;
  let planName;

  switch (productKey) {
    case InsuranceProductKey.ACCIDENT:
      planName = 'AME Plans';
      break;
    case InsuranceProductKey.CRITICAL:
      if (selectedCarrier === 'LIB') {
        planName = 'Critical Illness';
      } else if (selectedCarrier === 'AIG') {
        partialPlanName = 'Cancer';
        planName = 'CancerPlus';
      }
      break;
    case InsuranceProductKey.HOSPITAL:
      planName = 'Hospital Plans';
      break;
    case InsuranceProductKey.DENTAL:
      planName = 'Primestar® Dental Plans';
      break;
    case InsuranceProductKey.VISION:
      planName = 'Primestar® Vision Plans';
      break;
  }

  return (
    <Card cardClasses="mb-5">
      {planName && partialPlanName && (
        <Row>
          <Col lg={6} xs={12}>
            <PlanInfo planName={partialPlanName} productKey={productKey} />
          </Col>
          <Col lg={6} xs={12}>
            <PlanInfo planName={planName} productKey={productKey} />
          </Col>
        </Row>
      )}
      {planName && !partialPlanName && (
        <PlanInfo planName={planName} productKey={productKey} />
      )}
      <>
        <CoverageToggle productKey={productKey} />
        <QuotesGrid
          ameritasRevision={ameritasRevision}
          productKey={productKey}
        />
      </>
    </Card>
  );
}
