import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import infoCircle from '@liferaft/core/assets/images/icons/duotone-icons/Code/Info-circle.svg';

type InputHTMLAttributes = {
  accept?: string | undefined;
  alt?: string | undefined;
  autoComplete?: string | undefined;
  capture?: boolean | 'user' | 'environment' | undefined;
  crossOrigin?: string | undefined;
  disabled?: boolean | undefined;
  enterKeyHint?:
    | 'enter'
    | 'done'
    | 'go'
    | 'next'
    | 'previous'
    | 'search'
    | 'send'
    | undefined;
  height?: number | string | undefined;
  list?: string | undefined;
  max?: number | string | undefined;
  maxLength?: number | undefined;
  min?: number | string | undefined;
  minLength?: number | undefined;
  multiple?: boolean | undefined;
  name?: string | undefined;
  pattern?: string | undefined;
  placeholder?: string | undefined;
  readOnly?: boolean | undefined;
  required?: boolean | undefined;
  size?: 'sm' | 'lg' | undefined;
  src?: string | undefined;
  step?: number | string | undefined;
  tooltip?: string;
  type?: HTMLInputTypeAttribute | undefined;
  value?: string | number | undefined;
  width?: number | string | undefined;
} & (
  | {
      onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    }
  | {
      onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    }
);

type InputProps = {
  as?: React.ElementType<any> | undefined;
  error?: string;
  help?: string;
  id: string;
  label?: string;
  labelClasses?: string;
};

type Props = InputHTMLAttributes & InputProps;

type HTMLInputTypeAttribute =
  | 'button'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

export function Input({
  disabled = false,
  multiple = false,
  readOnly = false,
  required = false,
  accept,
  alt,
  as,
  autoComplete,
  capture,
  error,
  enterKeyHint,
  help,
  height,
  id,
  label,
  labelClasses,
  list,
  max,
  maxLength,
  min,
  minLength,
  name,
  onChange,
  pattern,
  placeholder,
  size,
  src,
  step,
  tooltip,
  type,
  value,
  width,
}: Props) {
  return (
    <Form.Group className="form-group" data-testid={`${id}-input-group`}>
      <Form.Label
        className={labelClasses}
        data-testid={`${id}-input-label`}
        htmlFor={id}>
        {tooltip && (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}
            placement="top">
            <span>
              <img
                alt="more info on hover"
                className="icon icon-xxs mr-2"
                src={infoCircle}
              />
            </span>
          </OverlayTrigger>
        )}
        {label}
      </Form.Label>
      <Form.Control
        accept={accept}
        alt={alt}
        as={as}
        autoComplete={autoComplete}
        capture={capture}
        data-testid={`${id}-input-field`}
        disabled={disabled}
        enterKeyHint={enterKeyHint}
        height={height}
        id={id}
        isInvalid={!!error}
        list={list}
        max={max}
        maxLength={maxLength}
        min={min}
        minLength={minLength}
        multiple={multiple}
        name={name}
        onChange={onChange}
        pattern={pattern}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        size={size}
        src={src}
        step={step}
        type={type}
        value={value}
        width={width}
      />
      {help && (
        <Form.Text id={`${id}-help`} muted>
          {help}
        </Form.Text>
      )}
      {error && (
        <Form.Control.Feedback data-testid={`${id}-error`} type="invalid">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}
