import React from 'react';
import { Form } from 'react-bootstrap';

import { createDisclosureAcknowledgement } from '@liferaft/api/resources/application';
import {
  CarrierKey,
  DisclosureAcknowledgementResponse,
  DisclosureType,
} from '@liferaft/api/types';
import { NetworkController } from '@liferaft/api/utils/network';

import { Card } from '@liferaft/core/components';
import { Input } from '@liferaft/core/components/forms';
import { useUserContext } from '@liferaft/core/contexts';
import { useDebounce } from '@liferaft/core/hooks';

import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';
import { DJANGO_API_URL, SUPPORT_EMAIL } from '@/environment';

export function LibertyDisclaimer() {
  const { user } = useUserContext();
  const {
    data: { egress },
    next,
  } = useApplicationFlow();

  const productNames = egress?.product_selections
    ?.filter((s) => {
      if ('carrier' in s) {
        return s.carrier === CarrierKey.LIB;
      }
      return false;
    })
    ?.map((s) => s.name);

  const [signature, setSignature] = React.useState<string>('');
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);

  const fullName = `${egress?.first_name || ''} ${egress?.last_name || ''}`;

  function handleSignatureChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    value.toLowerCase() === fullName.toLowerCase()
      ? setIsDisabled(false)
      : setIsDisabled(true);

    setSignature(value);
  }

  const [handleSubmit, isSubmitting] = useDebounce(
    async (event: React.SyntheticEvent<EventTarget>) => {
      event.preventDefault();

      if (signature.toLowerCase() === fullName.toLowerCase()) {
        const network = new NetworkController();

        network.request(
          createDisclosureAcknowledgement(user.id, {
            content: signature,
            disclosure_type: DisclosureType.LIBERTY_DISCLAIMER,
            response: DisclosureAcknowledgementResponse.ACCEPTED,
          })
        );

        network.request(
          createDisclosureAcknowledgement(user.id, {
            disclosure_type: DisclosureType.LIBERTY_E_DELIVERY,
            response: DisclosureAcknowledgementResponse.ACCEPTED,
          })
        );

        return next();
      }
    }
  );

  return (
    <ApplyWrapper
      footer={<ApplyWrapperFooter rightDisabled={isDisabled || isSubmitting} />}
      heading="Agreement to Terms">
      <Card cardClasses="card-bleed shadow-light">
        {productNames && (
          <div className="mb-4 mt-2">
            <div className="d-flex flex-column flex-lg-row w-100 mb-4">
              {productNames.map((name, index) => {
                return (
                  <div className="font-size-lg mr-2" key={index}>
                    {name.toUpperCase().replaceAll('-', ' ')}
                    {index === productNames.length - 1 ? '' : ','}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="strike-line ml-0 mb-4" />
        <Form id={APPLY_FORM_ID} onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Check
              id="has-agreed-terms"
              label={
                <>
                  I have read and agree to Liferaft's{' '}
                  <a
                    href={`${DJANGO_API_URL}/company/tos/`}
                    rel="noreferrer"
                    target="_blank">
                    Terms and Conditions.
                  </a>{' '}
                  I also confirm that
                </>
              }
              name="has-agreed-terms"
              required
              type="checkbox"
            />
          </Form.Group>
          <ul>
            <li>
              I authorize{' '}
              <b>Calstar Financial and Insurance Services, Inc., </b>on behalf
              of Liferaft Risk Technologies and the Emergency Management
              Alliance, Inc., to charge the credit card or ACH debit indicated
              in this authorization. If any payment dates fall on a weekend or
              holiday, I understand that the payments may be executed on the
              prior business day.
            </li>

            <li>
              I understand that this authorization will remain in effect until I
              cancel it in writing, and I agree to notify Liferaft Risk
              Technologies (email address{' '}
              <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>) in
              writing of any changes in my account information or termination of
              this authorization at least 15 days prior to the next billing
              date. This payment authorization is for EMA membership and
              services billing.
            </li>

            <li>
              I understand that "<b>Calstar Financial</b>" will appear on my
              billing statement.
            </li>
            <li>
              I certify that I am an authorized user of this credit card or bank
              account and that I will not dispute the scheduled payments with my
              Credit Card Company or Bank, provided transactions correspond to
              the terms indicated in this authorization.
            </li>

            <li>
              I agree to receive text messages to my mobile number regarding my
              account. Message and data rates may apply. I may reply STOP to
              disable texts about my account.
            </li>

            <li>
              I understand that I have the option of downloading my program
              materials (including but not limited to my program certificate)
              electronically. By providing my electronic signature and
              submitting my enrollment I am consenting to accept electronic
              delivery of these materials. I also have the right at any time to
              receive a hard copy of these materials. If I choose this option, I
              can email Liferaft at{' '}
              <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
            </li>

            <li>The information I have provided is accurate.</li>
          </ul>
          <p>
            <b>IMPORTANT NOTICE</b>
          </p>
          <p>
            <b>
              <u>Consent to Electronic Communication</u>
            </b>
          </p>

          <p>
            I agree to the receipt of electronic documents using the electronic
            signature consent form and confirm that all documents may be
            provided to me in electronic form.
          </p>
          <hr className="my-6 my-md-6" />

          <p>
            <b>BY ENTERING MY NAME HERE</b>, I acknowledge that I have read and
            agree to the terms and conditions set forth in this agreement. I
            also agree that my electronic signature is the legally binding
            equivalent to my handwritten signature. Whenever I execute an
            electronic signature, it has the same validity and meaning as my
            handwritten signature.
          </p>
          <Input
            id="electronic-signature"
            label="Enter Your Name"
            labelClasses="mt-2 text-gray-700"
            onChange={handleSignatureChange}
            placeholder={fullName}
            required
            value={signature}
          />
        </Form>
      </Card>
    </ApplyWrapper>
  );
}
