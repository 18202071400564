import React from 'react';
import { Form } from 'react-bootstrap';

import { createDisclosureAcknowledgement } from '@liferaft/api/resources/application';
import type { Offering } from '@liferaft/api/types';
import {
  CarrierKey,
  DisclosureAcknowledgementResponse,
  DisclosureType,
  InsuranceProductKey,
} from '@liferaft/api/types';
import { NetworkController } from '@liferaft/api/utils/network';

import { Card } from '@liferaft/core/components';
import { useUserContext } from '@liferaft/core/contexts';
import { useDebounce } from '@liferaft/core/hooks';

import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';
import { useProductMatrixContext } from '@/contexts';
import { DJANGO_API_URL, TOLL_FREE_NUMBER } from '@/environment';

export function AIGDisclaimer() {
  const { user } = useUserContext();
  const {
    data: { egress },
    next,
  } = useApplicationFlow();

  const context = useProductMatrixContext(egress);

  const productNames = egress?.product_selections
    ?.filter((s) => {
      if ('carrier' in s) {
        return s.carrier === CarrierKey.AIG;
      }
      return false;
    })
    ?.map((s) => s.name);

  const state = egress?.state as string;
  const insuranceSelections = context.selectedInsuranceOfferings;
  const hasNonInsurance = insuranceSelections.some(
    ([, offering]) => offering.has_non_insurance
  );

  const hiTermsLink = `${DJANGO_API_URL}/static/aig/tnc/hospital_indemnity/${state}.pdf`;
  const ciTermsLink = `${DJANGO_API_URL}/static/aig/tnc/critical_illness/${state}.pdf`;

  const hasHI = insuranceSelections.some(
    ([productKey, offering]) =>
      productKey === InsuranceProductKey.HOSPITAL &&
      offering.carrier === CarrierKey.AIG
  );
  const hasCI = insuranceSelections.some(
    ([productKey, offering]) =>
      productKey === InsuranceProductKey.CRITICAL &&
      offering.carrier === CarrierKey.AIG
  );

  let label;

  if (hasHI && hasCI) {
    label = (
      <>
        I have read and agree to the Critical Supplement{' '}
        <a href={ciTermsLink} rel="noreferrer" target="_blank">
          Terms and Conditions
        </a>{' '}
        and the Hospital Supplement{' '}
        <a href={hiTermsLink} rel="noreferrer" target="_blank">
          Terms and Conditions
        </a>
        . I also confirm that
      </>
    );
  } else if (hasCI) {
    label = (
      <>
        I have read and agree to the{' '}
        <a href={ciTermsLink} rel="noreferrer" target="_blank">
          Terms and Conditions
        </a>
        . I also confirm that
      </>
    );
  } else if (hasHI) {
    label = (
      <>
        I have read and agree to the{' '}
        <a href={hiTermsLink} rel="noreferrer" target="_blank">
          Terms and Conditions
        </a>
        . I also confirm that
      </>
    );
  }

  const [handleSubmit, isSubmitting] = useDebounce(
    async (event: React.SyntheticEvent<EventTarget>) => {
      event.preventDefault();

      const network = new NetworkController();

      network.request(
        createDisclosureAcknowledgement(user.id, {
          disclosure_type: DisclosureType.AIG_STATE_DISCLAIMER,
          response: DisclosureAcknowledgementResponse.ACCEPTED,
        })
      );

      return next();
    }
  );

  return (
    <ApplyWrapper
      footer={<ApplyWrapperFooter rightDisabled={isSubmitting} />}
      heading="Agreement to Terms">
      <Card bodyClasses="" cardClasses="card-bleed shadow-light">
        {productNames && (
          <div className="d-flex w-100 align-items-center mb-4 mt-2">
            {productNames.map((name, index) => {
              return (
                <div className="font-size-lg" key={index}>
                  {name.toUpperCase().replaceAll('-', ' ')}
                  {index === productNames.length - 1 ? '' : ','}
                </div>
              );
            })}
          </div>
        )}
        <div className="strike-line ml-0 mb-4" />
        <Form id={APPLY_FORM_ID} onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Check
              id="has-agreed-terms"
              label={label}
              name="has-agreed-terms"
              required
              type="checkbox"
            />
          </Form.Group>
        </Form>
        {getDisclaimerIntro(state, hasNonInsurance)}
        {getDisclaimerPreex(state, insuranceSelections)}
        {getDisclaimerFraud(state)}
        {getDisclaimerLimit(state)}
        {getDisclaimerSupplement(state)}
        {getDisclaimerAddress(state)}
        {getDisclaimerNotice(state)}
        {getDisclaimerMoreFar(state, hasNonInsurance)}
      </Card>
    </ApplyWrapper>
  );
}

enum DisclaimerSections {
  NOTICE = 'notice',
  MORE_FAR = 'more-far',
  ADDRESS = 'base-address',
  SUPPLEMENT = 'base-supplement',
  LIMIT = 'limit',
  FRAUD = 'fraud',
  PREEX = 'preex',
  INTRO = 'intro',
}

const CONTENT_LOOKUP: Record<
  string,
  Partial<Record<DisclaimerSections, any>>
> = {
  AK: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek diagnosis, care or treatment during the
                12 months immediately preceding the effective date of the
                Insured Person’s coverage under this Policy; or (b) for which
                medical advice or treatment was recommended by a Physician or
                received from a Physician during the 12 months immediately
                preceding the effective date of the Insured Person’s coverage
                under this Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 24
                consecutive months following the Insured Person's effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a sickness suffered by an Insured
                Person for which he or she sought or received medical advice,
                consultation, investigation, or diagnosis, or for which
                treatment was required or recommended by a Physician during the
                24 months immediately prior to the Insured Person's effective
                date of coverage under the Benefit, that directly or indirectly
                causes the condition to occur within the first 24 months from
                the Insured Person's effective date of coverage under the
                Benefit.
              </p>
            </>
          )}
        </>
      );
    },
  },
  AL: {
    [DisclaimerSections.FRAUD]: () => (
      <p>
        Fraud Statement: Any person who knowingly presents a false or fraudulent
        claim for payment of a loss or benefit or who knowingly presents false
        information in an application for insurance is guilty of a crime and may
        be subject to restitution fines or confinement in prison, or any
        combination thereof.{' '}
      </p>
    ),
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek diagnosis, care or treatment during the
                12 months immediately preceding the effective date of the
                Insured Person’s coverage under the Policy; or (b) for which
                medical advice or treatment was recommended by a Physician or
                received from a Physician during the 12 months immediately
                preceding the effective date of the Insured Person’s coverage
                under the Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 24
                consecutive months following the Insured Person's effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a sickness suffered by an Insured
                Person for which he or she sought or received medical advice,
                consultation, investigation, or diagnosis, or for which
                treatment was required or recommended by a Physician during the
                24 months immediately prior to the Insured Person's effective
                date of coverage under the Benefit, that directly or indirectly
                causes the condition to occur within the first 24 months from
                the Insured Person's effective date of coverage under the
                Benefit.
              </p>
            </>
          )}
        </>
      );
    },
  },
  AR: {},
  AZ: {
    [DisclaimerSections.INTRO]: (hasNonInsurance: boolean) => (
      <>
        <p>
          I apply for individual insurance in accordance with my selection(s)
          indicated. The information on this form is complete and true to the
          best of my knowledge and belief. I acknowledge that I have received,
          read, understand, and agree to the Terms and Conditions provided and
          the insurance pre-existing conditions disclosure on the bottom of this
          page. I authorize Morefar** to automatically collect my payment from
          my account based on the Plan selected. I understand that insurance
          coverage will be effective on the Effective Date indicated in the
          issued policy, provided the full first payment has been received and
          Plan documents have been issued.
        </p>
        {hasNonInsurance && (
          <p>
            I apply for non-insurance services in accordance with my
            selection(s) indicated. I understand that the non-insurance services
            will be available on that same date provided the full first payment
            has been received and Plan documents have been issued.
          </p>
        )}
      </>
    ),
  },
  CA: {
    [DisclaimerSections.INTRO]: (hasNonInsurance: boolean) => (
      <div>
        <ul>
          <li>
            I acknowledge that I have applied for individual insurance{' '}
            {hasNonInsurance ? 'and non-insurance services' : ''} in accordance
            with my selection(s) indicated.
          </li>
          <li>
            The information on this form is complete and true to the best of my
            knowledge and belief;
          </li>
          <li>
            I authorize Liferaft<sup>*</sup> to collect payment based on my
            preferred payment method indicated. I understand that I am not
            obligated to continue coverage and can cancel at any time; and
          </li>
          <li>
            I understand that insurance coverage will be effective on the
            Effective Date indicated in the issued policy
            {hasNonInsurance &&
              ', and non-insurance services will be available on that same date,'}
            provided the full first payment has been received and Plan documents
            have been issued.
          </li>
          <li>
            I understand that the policy applied for will not pay benefits for
            any loss incurred during the first 12 months after the issue date on
            account of disease or physical condition that I now have or have had
            in the past.
          </li>
          <li>
            I hereby attest that I currently have other health coverage that is
            minimum essential coverage within the meaning of section 5000A(f) of
            the Internal Revenue Code, or, if not, that I am treated as having
            minimum essential coverage due to my status as a bona fide resident
            of any possession of the United States pursuant to Internal Revenue
            Code section 5000A(f)(4)(B).
          </li>
        </ul>
      </div>
    ),
    [DisclaimerSections.PREEX]: () => null,
    [DisclaimerSections.LIMIT]: () => null,
    [DisclaimerSections.FRAUD]: () => null,
    [DisclaimerSections.SUPPLEMENT]: () => (
      <>
        <p>
          THIS IS A SUPPLEMENT TO HEALTH INSURANCE AND IS NOT A SUBSTITUTE FOR
          MAJOR MEDICAL COVERAGE. LACK OF MAJOR MEDICAL COVERAGE (OR OTHER
          MINIMUM ESSENTIAL COVERAGE) MAY RESULT IN AN ADDITIONAL PAYMENT WITH
          YOUR TAXES.
        </p>
        <p className="font-weight-bold mt-3">
          The policy provides limited benefits. Please read your policy
          carefully.
        </p>
      </>
    ),
  },
  DC: {
    [DisclaimerSections.SUPPLEMENT]: () => (
      <p>
        NOTICE TO CONSUMER: THIS IS A SUPPLEMENT TO HEALTH INSURANCE AND IS NOT
        A SUBSTITUTE FOR MAJOR MEDICAL COVERAGE. LACK OF MAJOR MEDICAL COVERAGE
        (OR OTHER MINIMUM ESSENTIAL COVERAGE) MAY RESULT IN AN ADDITIONAL
        PAYMENT WITH YOUR TAXES. ALSO, THE BENEFITS PROVIDED BY THIS POLICY
        CANNOT BE COORDINATED WITH THE BENEFITS PROVIDED BY OTHER COVERAGE.
        PLEASE REVIEW THE BENEFITS PROVIDED BY THIS POLICY CAREFULLY TO AVOID A
        DUPLICATION OF COVERAGE
      </p>
    ),
  },
  DE: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek diagnosis, care or treatment during the
                12 months immediately preceding the effective date of the
                Insured Person’s coverage under this Policy; or (b) for which
                medical advice or treatment was recommended by a Physician or
                received from a Physician during the 12 months immediately
                preceding the effective date of the Insured Person’s coverage
                under this Policy.
              </p>
            </>
          )}

          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 12
                consecutive months following the Insured Person’s effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means the existence of symptoms which
                would cause an ordinarily prudent person to seek diagnosis, care
                or treatment within the 24 month period immediately preceding
                the Insured Person’s effective date of coverage under the
                Benefit, or for which medical advice or treatment was
                recommended by or received from a Physician within a 24 month
                period preceding the Insured Person’s effective date of coverage
                under the Benefit.
              </p>
            </>
          )}
        </>
      );
    },
  },
  FL: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                Benefits are not payable in connection with a Pre-Existing
                Condition within the initial 12 consecutive months from the
                Insured Person’s effective date of coverage under this Policy.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek medical advice, diagnosis, care or
                treatment during the 12 months immediately preceding the
                effective date of the Insured Person’s coverage under this
                Policy; (b) for which medical advice, diagnosis, care, or
                treatment was recommended by a Physician or received from a
                Physician during the 12 months immediately preceding the
                effective date of the Insured Person’s coverage under this
                Policy; or (c) a pregnancy existing on the effective date of the
                insured person’s coverage under the policy.
              </p>
            </>
          )}

          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 24
                consecutive months following the Insured Person’s effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means any sickness or other condition:
                (a) that would cause an ordinarily prudent person to seek
                medical advice, diagnosis, care or treatment during the 24
                months immediately preceding the effective date of the Insured
                Person’s coverage under the Benefit, or (b) for which medical
                advice, diagnosis, care, or treatment was recommended by a
                Physician or received from a Physician during the 24 months
                immediately preceding the effective date of the Insured Person’s
                coverage under the Benefit.
              </p>
            </>
          )}
        </>
      );
    },
    [DisclaimerSections.FRAUD]: () => (
      <p>
        Fraud Statement: Any person who knowingly and with intent to injure,
        defraud, or deceive any insurer files a statement of claim or an
        application containing any false, incomplete, or misleading information
        is guilty of a felony of the third degree.{' '}
      </p>
    ),
  },
  GA: {
    [DisclaimerSections.PREEX]: () => null,
    [DisclaimerSections.FRAUD]: () => (
      <p>
        Fraud Statement: Any person who knowingly presents a false or fraudulent
        claim for payment of a loss or benefit or knowingly presents false
        information in an application for insurance is guilty of a crime and may
        be subject to fines and confinement in prison.
      </p>
    ),
    [DisclaimerSections.LIMIT]: () => (
      <p className="font-weight-bold">
        The policy provides limited benefits. Review your policy carefully.
      </p>
    ),
    [DisclaimerSections.SUPPLEMENT]: () => (
      <p>
        THIS IS A SUPPLEMENT TO HEALTH INSURANCE AND IS NOT A SUBSTITUTE FOR
        MAJOR MEDICAL COVERAGE. LACK OF MAJOR MEDICAL COVERAGE (OR OTHER MINIMUM
        ESSENTIAL COVERAGE) MAY RESULT IN AN ADDITIONAL PAYMENT WITH YOUR TAXES.
      </p>
    ),
    [DisclaimerSections.ADDRESS]: () => (
      <>
        <div>
          <p>
            <u>Pre-existing Condition</u> - mean any sickness or other
            condition: (a) that would cause an ordinarily prudent person to seek
            diagnosis, care or treatment during the 12 months immediately
            preceing the effective date of coverage under the policy; or (b) for
            which medical advice or treatment was recommended by a physician or
            received from a physician during the 12 months immediately preceding
            the effective date of coverage under the policy.
          </p>
        </div>

        <p>
          Program Administrative Office
          <br />
          PO Box 3102
          <br />
          FRISCO, TX 75034
          <br />
        </p>
      </>
    ),
  },
  HI: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek diagnosis, care or treatment during the
                12 months immediately preceding the effective date of the
                Insured Person’s coverage under this Policy; or (b) for which
                medical advice or treatment was recommended by a Physician or
                received from a Physician during the 12 months immediately
                preceding the effective date of the Insured Person’s coverage
                under this Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 24
                consecutive months following the Insured Person's effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a sickness suffered by an Insured
                Person for which he or she sought or received medical advice,
                consultation, investigation, or diagnosis, or for which
                treatment was required or recommended by a Physician during the
                24 months immediately prior to the Insured Person's effective
                date of coverage under the Benefit, that directly or indirectly
                causes the condition to occur within the first 24 months from
                the Insured Person's effective date of coverage under the
                Benefit.
              </p>
            </>
          )}
        </>
      );
    },
  },
  IL: {},
  IN: {
    [DisclaimerSections.INTRO]: (hasNonInsurance: boolean) => (
      <>
        <p>
          I apply for individual insurance in accordance with my selection(s)
          indicated. The information on this form is complete and true to the
          best of my knowledge and belief. I acknowledge that I have received,
          read, understand, and agree to the Terms and Conditions provided and
          the insurance pre-existing conditions disclosure below. I authorize
          Liferaft<sup>*</sup> to automatically collect my payment from my
          account based on the Plan selected. I understand that insurance
          coverage will be effective on the Effective Date indicated in the
          issued policy, provided the full first payment has been received and
          Plan documents have been issued.
        </p>
        {hasNonInsurance && (
          <p>
            I apply for non-insurance services in accordance with my
            selection(s) indicated. I understand that the non-insurance services
            will be available on that same date provided the full first payment
            has been received and Plan documents have been issued.
          </p>
        )}
      </>
    ),
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek diagnosis, care or treatment during the
                12 months immediately preceding the effective date of the
                Insured Person’s coverage under this Policy; or (b) for which
                medical advice or treatment was recommended by a Physician or
                received from a Physician during the 12 months immediately
                preceding the effective date of the Insured Person’s coverage
                under this Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 24
                consecutive months following the Insured Person's effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a sickness suffered by an Insured
                Person for which he or she sought or received medical advice,
                consultation, investigation, or diagnosis, or for which
                treatment was required or recommended by a Physician during the
                24 months immediately prior to the Insured Person's effective
                date of coverage under the Benefit, that directly or indirectly
                causes the condition to occur within the first 24 months from
                the Insured Person's effective date of coverage under the
                Benefit.
              </p>
            </>
          )}
        </>
      );
    },
  },
  KY: {
    [DisclaimerSections.INTRO]: () => (
      <p>
        <ul>
          <li>
            The information on this Application form is complete and true to the
            best of my knowledge and belief;
          </li>
          <li>
            I authorize Liferaft<sup>*</sup> to collect payment for the plan
            selected and based on my preferred method selected on the payments
            page; and
          </li>
          <li>
            I understand that coverage is effective upon receipt of payment and
            issuance of plan documents.
          </li>
        </ul>
      </p>
    ),
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek diagnosis, care or treatment during the
                12 months immediately preceding the effective date of the
                Insured Person’s coverage under this Policy; or (b) for which
                medical advice or treatment was recommended by a Physician or
                received from a Physician during the 12 months immediately
                preceding the effective date of the Insured Person’s coverage
                under this Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 24
                consecutive months following the Insured Person's effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a sickness suffered by an Insured
                Person for which he or she sought or received medical advice,
                consultation, investigation, or diagnosis, or for which
                treatment was required or recommended by a Physician during the
                24 months immediately prior to the Insured Person's effective
                date of coverage under the Benefit, that directly or indirectly
                causes the condition to occur within the first 24 months from
                the Insured Person's effective date of coverage under the
                Benefit.
              </p>
            </>
          )}
        </>
      );
    },
    [DisclaimerSections.FRAUD]: () => (
      <p>
        Fraud Statement: Any person who knowingly and with intent to defraud any
        insurance company or other person files an application for insurance
        containing any materially false information or conceals, for the purpose
        of misleading, information concerning any fact material thereto commits
        a fraudulent insurance act, which is a crime.
      </p>
    ),
  },
  LA: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek diagnosis, care or treatment during the
                12 months immediately preceding the effective date of the
                Insured Person’s coverage under this Policy; or (b) for which
                medical advice or treatment was recommended by a Physician or
                received from a Physician during the 12 months immediately
                preceding the effective date of the Insured Person’s coverage
                under this Policy. Genetic information will not be treated as a
                Pre-Existing Condition. We will not impose any pre-existing
                condition exclusion on the basis of genetic information of an
                Insured Person or family member of an Insured Person.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 24
                consecutive months following the Insured Person's effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a sickness suffered by an Insured
                Person for which he or she sought or received medical advice,
                consultation, investigation, or diagnosis, or for which
                treatment was required or recommended by a Physician during the
                24 months immediately prior to the Insured Person's effective
                date of coverage under the Benefit, that directly or indirectly
                causes the condition to occur within the first 24 months from
                the Insured Person's effective date of coverage under the
                Benefit.
              </p>
            </>
          )}
        </>
      );
    },
  },
  MS: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek diagnosis, care or treatment during the
                12 months immediately preceding the effective date of the
                Insured Person’s coverage under this Policy; or (b) for which
                medical advice or treatment was recommended by a Physician or
                received from a Physician during the 12 months immediately
                preceding the effective date of the Insured Person’s coverage
                under this Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 24
                consecutive months following the Insured Person's effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a sickness suffered by an Insured
                Person for which he or she sought or received medical advice,
                consultation, investigation, or diagnosis, or for which
                treatment was required or recommended by a Physician during the
                24 months immediately prior to the Insured Person's effective
                date of coverage under the Benefit, that directly or indirectly
                causes the condition to occur within the first 24 months from
                the Insured Person's effective date of coverage under the
                Benefit.
              </p>
            </>
          )}
        </>
      );
    },
  },
  NC: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition for which medical advice, diagnosis,
                care or treatment was recommended by a Physician or received
                from a Physician during the 12 months immediately preceding the
                effective date of the Insured Person’s coverage under the
                Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 12
                consecutive months following the Insured Person's effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a condition for which medical
                advice, diagnosis, care, or treatment was received or
                recommended within the 12 month period immediately preceding the
                effective date of the Insured Person's effective date of
                coverage under the Benefit, that directly or indirectly causes
                the condition to occur within the first 12 months from the
                Insured Person's effective date of coverage under the Benefit.
              </p>
            </>
          )}
        </>
      );
    },
    [DisclaimerSections.FRAUD]: () => (
      <p>
        Fraud Statement: Any person with the intent to injure, defraud, or
        deceive an insurer or insurance claimant who knowingly presents a false
        or fraudulent claim for payment of a loss or benefit or knowingly
        presents false information in an application for insurance is guilty of
        a crime (Class H felony) and may subject the person to criminal and
        civil penalties, pursuant to NCGS 58-2-161(b).
      </p>
    ),
  },
  ND: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I UNDERSTAND THAT THE POLICY APPLIED FOR WILL NOT PAY BENEFITS
                FOR ANY LOSS INCURRED DURING THE FIRST 12 MONTHS AFTER THE ISSUE
                DATE ON ACCOUNT OF DISEASE OR PHYSICAL CONDITION THAT I NOW HAVE
                OR HAVE HAD IN THE PAST.
              </p>

              <p>
                “PRE-EXISTING CONDITION” UNDER THE SICKNESS BENEFITS MEANS ANY
                SICKNESS OR OTHER CONDITION WHICH WAS DIAGNOSED OR TREATED
                DURING THE 6 MONTHS IMMEDIATELY PRECEDING THE EFFECTIVE DATE OF
                THE INSURED PERSON’S COVERAGE UNDER THIS POLICY.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I UNDERSTAND NO ACCIDENTAL DEATH PLUS (CANCER OR CANCERPLUS)
                BENEFIT IS PAYABLE FOR ANY PRE-EXISTING CONDITIONS, EXCEPT WHERE
                COVERAGE UNDER THE BENEFIT HAS BEEN IN EFFECT FOR A PERIOD OF 24
                CONSECUTIVE MONTHS FOLLOWING THE INSURED PERSON’S EFFECTIVE DATE
                OF COVERAGE UNDER THE BENEFIT.
              </p>

              <p>
                “PRE-EXISTING CONDITION” UNDER THE ACCIDENTAL DEATH PLUS (CANCER
                OR CANCERPLUS) BENEFITS MEANS A SICKNESS SUFFERED BY AN INSURED
                PERSON FOR WHICH MEDICAL ADVICE OR TREATMENT WAS RECEIVED DURING
                THE 24 MONTHS IMMEDIATELY PRIOR TO THE INSURED PERSON’S
                EFFECTIVE DATE OF COVERAGE UNDER THE BENEFIT, THAT DIRECTLY OR
                INDIRECTLY CAUSES THE CONDITION TO OCCUR WITHIN THE FIRST 24
                MONTHS FROM THE INSURED PERSON’S EFFECTIVE DATE OF COVERAGE
                UNDER THE BENEFIT.
              </p>
            </>
          )}
        </>
      );
    },
  },
  NE: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek diagnosis, care or treatment during the
                12 months immediately preceding the effective date of the
                Insured Person’s coverage under this Policy; or (b) for which
                medical advice or treatment was recommended by a Physician or
                received from a Physician during the 12 months immediately
                preceding the effective date of the Insured Person’s coverage
                under this Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 24
                consecutive months following the Insured Person's effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a sickness suffered by an Insured
                Person for which he or she sought or received medical advice,
                consultation, investigation, or diagnosis, or for which
                treatment was required or recommended by a Physician during the
                24 months immediately prior to the Insured Person's effective
                date of coverage under the Benefit, that directly or indirectly
                causes the condition to occur within the first 24 months from
                the Insured Person's effective date of coverage under the
                Benefit.
              </p>
            </>
          )}
        </>
      );
    },
  },
  NM: {
    [DisclaimerSections.PREEX]: () => (
      <p>
        <u>Pre-existing Condition</u> - I understand that the policy applied for
        will not pay benefits for any loss incurred during the first 6 months
        after the issue date on account of disease or physical condition that I
        now have or have had in the past.
      </p>
    ),
    [DisclaimerSections.FRAUD]: () => (
      <p>
        Fraud Statement: Any person who knowingly presents a false or fraudulent
        claim for payment of a loss or benefit or knowingly presents false
        information in an application for insurance is guilty of a crime and may
        be subject to civil fines and criminal penalties.
      </p>
    ),
    [DisclaimerSections.SUPPLEMENT]: () => (
      <>
        <p>
          THIS IS A SUPPLEMENT TO HEALTH INSURANCE AND IS NOT A SUBSTITUTE FOR
          MAJOR MEDICAL COVERAGE. LACK OF MAJOR MEDICAL COVERAGE (OR OTHER
          MINIMUM ESSENTIAL COVERAGE) MAY RESULT IN AN ADDITIONAL PAYMENT WITH
          YOUR TAXES.
        </p>
        <p>
          NOTICE TO CONSUMER: This is a limited benefits health plan. The
          benefits provided are supplemental to, and not a substitute for, major
          medical coverage, even in combination with other limited benefits
          plans. To apply for an individual or small group major medical plan,
          please visit the website of the New Mexico Health Insurance Exchange
          at www.bewellnm.com or call 1-833-862-3935 (TTY: 711).
        </p>
      </>
    ),
  },
  NV: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition, regardless of the cause of the
                condition, for which medical advice, diagnosis, care or
                treatment was recommended or received during the 6 months
                immediately preceding the effective date of the Insured Person’s
                coverage under the Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 24
                consecutive months following the Insured Person's effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a sickness suffered by an Insured
                Person for which he or she sought or received medical advice,
                consultation, investigation, or diagnosis, or for which
                treatment was required or recommended by a Physician during the
                24 months immediately prior to the Insured Person's effective
                date of coverage under the Benefit, that directly or indirectly
                causes the condition to occur within the first 24 months from
                the Insured Person's effective date of coverage under the
                Benefit.
              </p>
            </>
          )}
        </>
      );
    },
    [DisclaimerSections.FRAUD]: () => (
      <p>
        Fraud Statement: Any person who knowingly presents a false or fraudulent
        claim for payment of a loss or benefit or who knowingly presents false
        information in an application for insurance is guilty of a crime and may
        be subject to restitution fines or confinement in prison, or any
        combination thereof.{' '}
      </p>
    ),
  },
  OK: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek diagnosis, care or treatment during the
                12 months immediately preceding the effective date of the
                Insured Person’s coverage under this Policy; or (b) for which
                medical advice or treatment was recommended by a Physician or
                received from a Physician during the 12 months immediately
                preceding the effective date of the Insured Person’s coverage
                under this Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 12
                consecutive months following the Insured Person’s effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a sickness suffered by an Insured
                Person for which he or she sought or received medical advice,
                consultation, investigation, or diagnosis, or for which
                treatment was required or recommended by a Physician during the
                24 months immediately prior to the Insured Person’s effective
                date of coverage under the Benefit, that directly or indirectly
                causes the condition to occur within the first 12 months from
                the Insured Person’s effective date of coverage under the
                Benefit.
              </p>
            </>
          )}
        </>
      );
    },
    [DisclaimerSections.FRAUD]: () => (
      <p>
        Fraud Statement: Any person who knowingly, and with intent to injure,
        defraud or deceive any insurer, makes any claim for the proceeds of an
        insurance policy containing any false, incomplete or misleading
        information is guilty of a felony.
      </p>
    ),
  },
  OR: {
    [DisclaimerSections.FRAUD]: () => (
      <p>
        Fraud Statement: Any person who knowingly presents a false or fraudulent
        claim for payment of a loss or benefit or knowingly presents false
        information in an application for insurance may be guilty of a crime and
        may be subject to fines and confinement in prison.
      </p>
    ),
  },
  RI: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek diagnosis, care or treatment during the
                12 months immediately preceding the effective date of the
                Insured Person’s coverage under this Policy; or (b) for which
                medical advice or treatment was recommended by a Physician or
                received from a Physician during the 12 months immediately
                preceding the effective date of the Insured Person’s coverage
                under this Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 12
                consecutive months following the Insured Person's effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a existence of symptoms which
                would cause an ordinarily prudent person to seek diagnosis, care
                or treatment, or for which medical advice or treatment was
                recommended by or received from a Physician during the 24 months
                immediately prior to the Insured Person’s effective date of
                coverage under this Benefit, that directly or indirectly causes
                the condition to occur within the first 12 months from the
                Insured Person’s effective date of coverage under this Benefit.
              </p>
            </>
          )}
        </>
      );
    },
  },
  SC: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek diagnosis, care or treatment during the
                12 months immediately preceding the effective date of the
                Insured Person’s coverage under this Policy; or (b) for which
                medical advice or treatment was recommended by a Physician or
                received from a Physician during the 12 months immediately
                preceding the effective date of the Insured Person’s coverage
                under this Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>
              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 12
                consecutive months following the Insured Person’s effective date
                of coverage under the Benefit.
              </p>
              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a sickness suffered by an Insured
                Person for which he or she sought or received medical advice,
                consultation, investigation, or diagnosis, or for which
                treatment was required or recommended by a Physician during the
                12 months immediately prior to the Insured Person’s effective
                date of coverage under the Benefit, that directly or indirectly
                causes the condition to occur within the first 12 months from
                the Insured Person’s effective date of coverage under the
                Benefit.
              </p>
            </>
          )}
        </>
      );
    },
  },
  SD: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek medical advice, diagnosis, care or
                treatment during the 12 months immediately preceding the
                effective date of the Insured Person’s coverage under this
                Policy; or (b) for which medical advice, diagnosis, care or
                treatment was recommended by a Physician or received from a
                Physician during the 12 months immediately preceding the
                effective date of the Insured Person’s coverage under this
                Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions during the 12
                months after the effective date of the Insured Person’s coverage
                under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a condition that would have caused
                an ordinarily prudent person to seek medical advice, diagnosis,
                care or treatment, or for which medical advice, diagnosis, care
                or treatment was recommended or received during the 12 months
                immediately prior to the Insured Person’s effective date of
                coverage under the Benefit, or a pregnancy that existed on the
                Insured Person’s effective date of coverage under the Benefit.
              </p>
            </>
          )}
        </>
      );
    },
  },
  TX: {},
  WI: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition for which medical advice or
                treatment was recommended by a Physician or received from a
                Physician during the 12 months immediately preceding the
                effective date of the Insured Person’s coverage under this
                Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 24
                consecutive months following the Insured Person's effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a sickness suffered by an Insured
                Person for which he or she sought or received medical advice,
                consultation, investigation, or diagnosis, or for which
                treatment was required or recommended by a Physician during the
                24 months immediately prior to the Insured Person's effective
                date of coverage under the Benefit, that directly or indirectly
                causes the condition to occur within the first 24 months from
                the Insured Person's effective date of coverage under the
                Benefit.
              </p>
            </>
          )}
        </>
      );
    },
  },
  WV: {
    [DisclaimerSections.PREEX]: (
      insuranceOfferings: [InsuranceProductKey, Offering][]
    ) => {
      const includeHospital = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.HOSPITAL
      );
      const includeCritical = insuranceOfferings.some(
        ([product, offering]) =>
          offering.carrier === CarrierKey.AIG &&
          product === InsuranceProductKey.CRITICAL
      );

      return (
        <>
          {includeHospital && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for Sickness Benefits (not
                including the Accidental Death Plus (Cancer or CancerPlus)
                Benefit)
              </p>

              <p>
                I understand that the policy applied for will not pay benefits
                for any loss incurred during the first 12 months after the issue
                date on account of disease or physical condition that I now have
                or have had in the past.
              </p>

              <p>
                “Pre-Existing Condition” under the Sickness benefits means any
                Sickness or other condition: (a) that would cause an ordinarily
                prudent person to seek diagnosis, care or treatment during the
                12 months immediately preceding the effective date of the
                Insured Person’s coverage under this Policy; or (b) for which
                medical advice or treatment was recommended by a Physician or
                received from a Physician during the 12 months immediately
                preceding the effective date of the Insured Person’s coverage
                under this Policy.
              </p>
            </>
          )}
          {includeCritical && (
            <>
              <p className="font-weight-bold">
                Pre-Existing Conditions Exclusion for the Accidental Death Plus
                (Cancer or CancerPlus) Benefit
              </p>

              <p>
                I understand no Accidental Death Plus (Cancer or CancerPlus)
                Benefit is payable for any Pre-Existing Conditions, except where
                coverage under the Benefit has been in effect for a period of 12
                consecutive months following the Insured Person’s effective date
                of coverage under the Benefit.
              </p>

              <p>
                “Pre-Existing Condition” under the Accidental Death Plus (Cancer
                or CancerPlus) Benefits means a sickness suffered by an Insured
                Person for which he or she sought or received medical advice,
                consultation, investigation, or diagnosis, or for which
                treatment was required or recommended by a Physician during the
                24 months immediately prior to the Insured Person’s effective
                date of coverage under the Benefit, that directly or indirectly
                causes the condition to occur within the first 12 months from
                the Insured Person’s effective date of coverage under the
                Benefit.
              </p>
            </>
          )}
        </>
      );
    },
  },
  WY: {
    [DisclaimerSections.PREEX]: () => (
      <p>
        <u>Pre-existing Condition</u> - I understand that the policy applied for
        will not pay benefits in connection with a Pre-Existing Condition within
        the initial 12 consecutive months from the effective date of coverage
        under the policy. A Pre-Existing Condition means any sickness or other
        condition for which medical advice, diagnosis, care or treatment was
        recommended or received during the 6 months immediately preceding the
        effective date of coverage under the policy.
      </p>
    ),
  },
};

function getDisclaimerNotice(state: string) {
  return (
    CONTENT_LOOKUP[state][DisclaimerSections.NOTICE]?.() || (
      <>
        <hr className="my-6 my-md-6" />
        <p className="text-uppercase font-weight-bold font-size-sm">
          {' '}
          Important Notice
        </p>
        <p className="font-weight-bold">
          Coverage under the policy does not constitute comprehensive health
          insurance coverage (a/k/a/ "major medical insurance"). It therefore
          does not satisfy the "minimum essential coverage" requirements of the
          Patient Protection and Affordable Care Act. Coverage will not satisfy
          the individual responsibility requirements of section 5000A of the
          Internal Revenue Code.
        </p>
      </>
    )
  );
}

function getDisclaimerMoreFar(state: string, hasNonInsurance: boolean) {
  const defaultContent = hasNonInsurance ? (
    <>
      <hr className="my-6 my-md-6" />
      <h6 className="text-gray-700">
        <sup>*</sup>Payment may be processed by a third party processor.
      </h6>
      <h6 className="text-gray-700">
        Insurance underwritten by National Union Fire Insurance Company of
        Pittsburgh, Pa. (National Union), a Pennsylvania insurance company, with
        its principal place of business at 1271 Ave of the Americas Fl 41, New
        York, NY 10020-1304. It is currently authorized to transact business in
        all states and the District of Columbia. NAIC No. 19445. The
        non-insurance services are made available through Morefar Marketing,
        Inc. (Agency name is shown as Morefar Insurance Agency in CA, under
        license number OC91962) and are provided through Careington
        International Corporation. Insurance coverage and non-insurance services
        may not be available in all states. Non-insurance services may be
        subject to sales and use tax. National Union and Morefar are AIG
        companies.
      </h6>
      <h6 className="text-gray-700">
        Non-insurance services are made available through Morefar Marketing, Inc
        (Agency name is shown as Morefar Insurance Agency in CA, under license
        number 0C91962.). Non-insurance services are provided through Careington
        International Corporation. Click{' '}
        <a href={`${DJANGO_API_URL}/static/aig/docs/non-insurance.pdf`}>
          this link
        </a>{' '}
        for details. You may opt to apply for just the non-insurance services
        shown. To learn more, please call {TOLL_FREE_NUMBER}.
      </h6>
    </>
  ) : (
    <>
      <hr className="my-6 my-md-6" />
      <h6 className="text-gray-700">
        <sup>*</sup>Payment may be processed by a third party processor.
      </h6>
      <h6 className="text-gray-700">
        Insurance underwritten by National Union Fire Insurance Company of
        Pittsburgh, Pa. (National Union), a Pennsylvania insurance company, with
        its principal place of business at 1271 Ave of the Americas Fl 41, New
        York, NY 10020-1304. It is currently authorized to transact business in
        all states and the District of Columbia. NAIC No. 19445. National Union
        is an AIG company.
      </h6>
    </>
  );

  return (
    CONTENT_LOOKUP[state][DisclaimerSections.MORE_FAR]?.(hasNonInsurance) ||
    defaultContent
  );
}

function getDisclaimerAddress(state: string) {
  return (
    CONTENT_LOOKUP[state][DisclaimerSections.SUPPLEMENT]?.() || (
      <p>
        Program Administrative Office
        <br />
        PO Box 3102
        <br />
        FRISCO, TX 75034
        <br />
      </p>
    )
  );
}

function getDisclaimerSupplement(state: string) {
  return (
    CONTENT_LOOKUP[state][DisclaimerSections.SUPPLEMENT]?.() || (
      <p>
        THIS IS A SUPPLEMENT TO HEALTH INSURANCE AND IS NOT A SUBSTITUTE FOR
        MAJOR MEDICAL COVERAGE. LACK OF MAJOR MEDICAL COVERAGE (OR OTHER MINIMUM
        ESSENTIAL COVERAGE) MAY RESULT IN AN ADDITIONAL PAYMENT WITH YOUR TAXES.
      </p>
    )
  );
}

function getDisclaimerLimit(state: string) {
  return (
    CONTENT_LOOKUP[state][DisclaimerSections.LIMIT]?.() || (
      <p className="font-weight-bold">
        The policy provides limited benefits. Review your policy carefully.
      </p>
    )
  );
}

function getDisclaimerFraud(state: string) {
  return (
    CONTENT_LOOKUP[state][DisclaimerSections.FRAUD]?.() || (
      <p>
        Fraud Statement: Any person who knowingly presents a false or fraudulent
        claim for payment of a loss or benefit or knowingly presents false
        information in an application for insurance is guilty of a crime and may
        be subject to fines and confinement in prison.
      </p>
    )
  );
}

function getDisclaimerPreex(
  state: string,
  insuranceOfferings: [InsuranceProductKey, Offering][]
) {
  const includeHospital = insuranceOfferings.some(
    ([product, offering]) =>
      offering.carrier === CarrierKey.AIG &&
      product === InsuranceProductKey.HOSPITAL
  );
  const includeCritical = insuranceOfferings.some(
    ([product, offering]) =>
      offering.carrier === CarrierKey.AIG &&
      product === InsuranceProductKey.CRITICAL
  );

  return (
    CONTENT_LOOKUP[state][DisclaimerSections.PREEX]?.(insuranceOfferings) || (
      <>
        {includeHospital && (
          <>
            <p className="font-weight-bold">
              Pre-Existing Conditions Exclusion for Sickness Benefits (not
              including the Accidental Death Plus (Cancer or CancerPlus)
              Benefit)
            </p>

            <p>
              Benefits are not payable in connection with a Pre-Existing
              Condition within the initial 12 consecutive months from the
              Insured Person’s effective date of coverage under this Policy.
            </p>

            <p>
              “Pre-Existing Condition” under the Sickness benefits means any
              sickness or other condition: (a) that would cause an ordinarily
              prudent person to seek medical advice, diagnosis, care or
              treatment during the 12 months immediately preceding the effective
              date of the Insured Person’s coverage under this Policy; (b) for
              which medical advice, diagnosis, care, or treatment was
              recommended by a Physician or received from a Physician during the
              12 months immediately preceding the effective date of the Insured
              Person’s coverage under this Policy; or (c) a pregnancy existing
              on the effective date of the insured person’s coverage under the
              policy.
            </p>
          </>
        )}
        {includeCritical && (
          <>
            <p className="font-weight-bold">
              Pre-Existing Conditions Exclusion for the Accidental Death Plus
              (Cancer or CancerPlus) Benefit
            </p>

            <p>
              I understand no Accidental Death Plus (Cancer or CancerPlus)
              Benefit is payable for any Pre-Existing Conditions, except where
              coverage under the Benefit has been in effect for a period of 24
              consecutive months following the Insured Person's effective date
              of coverage under the Benefit.
            </p>

            <p>
              “Pre-Existing Condition” under the Accidental Death Plus (Cancer
              or CancerPlus) Benefits means a sickness suffered by an Insured
              Person for which he or she sought or received medical advice,
              consultation, investigation, or diagnosis, or for which treatment
              was required or recommended by a Physician during the 24 months
              immediately prior to the Insured Person's effective date of
              coverage under the Benefit, that directly or indirectly causes the
              condition to occur within the first 24 months from the Insured
              Person's effective date of coverage under the Benefit.
            </p>
          </>
        )}
      </>
    )
  );
}

function getDisclaimerIntro(state: string, hasNonInsurance: boolean) {
  return (
    CONTENT_LOOKUP[state][DisclaimerSections.INTRO]?.(hasNonInsurance) || (
      <p>
        I apply for individual insurance
        {hasNonInsurance && ' and non-insurance services '}in accordance with my
        selection(s) indicated. The information on this form is complete and
        true to the best of my knowledge and belief. I acknowledge that I have
        received, read, understand, and agree to the Terms and Conditions
        provided and the insurance pre-existing conditions disclosure below. I
        authorize Liferaft<sup>*</sup> to automatically collect my payment from
        my account based on the Plan selected. I understand that insurance
        coverage will be effective on the Effective Date indicated in the issued
        policy,
        {hasNonInsurance
          ? 'and non-insurance services will be available on that same date, '
          : 'provided the full first payment has been received and Plan documents have been issued.'}
      </p>
    )
  );
}
