import React from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';

import { finishApplication } from '@liferaft/api/resources/application';
import type { Application } from '@liferaft/api/types';
import type { NoBody } from '@liferaft/api/utils/network';
import { NetworkController } from '@liferaft/api/utils/network';

import { ReactComponent as NavigationRightIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Navigation/Arrow-right.svg';
import { Card } from '@liferaft/core/components';
import { useDebounce } from '@liferaft/core/hooks';

import {
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';
import '@/pages/submit.scss';

export function Submit() {
  const {
    data: { id },
    next,
  } = useApplicationFlow();

  const [error, setError] = React.useState<string>();

  const [handleSubmit] = useDebounce(async () => {
    setError(undefined);

    const network = new NetworkController();

    const result = await network.request<NoBody, Application>(
      finishApplication(id as string)
    );

    if (result.error) {
      setError(
        'There was an error submitting your application, please contact support.'
      );
      return;
    }

    return next();
  });

  return (
    <ApplyWrapper
      footer={<ApplyWrapperFooter rightAction={null} />}
      heading="Submit Application"
      subheading="Click below to submit your application and process payment.">
      <Form>
        {error && (
          <Alert className="text-center mb-3" variant="danger">
            {error}
          </Alert>
        )}
        <Row className="justify-content-center">
          <Form.Check className="hide-input" />
          <Col md={4} onClick={handleSubmit} xs={6}>
            <Form.Label className="block">
              <Card
                bodyClasses="text-center p-3"
                cardClasses="card-border-bottom border-primary shadow-light-lg lift mb-5 cursor-pointer">
                <div className="icon icon-lg text-primary my-3">
                  <NavigationRightIcon viewBox="0 0 24 24" />
                  <p className="text-secondary my-3">Submit</p>
                </div>
              </Card>
            </Form.Label>
          </Col>
        </Row>
      </Form>
    </ApplyWrapper>
  );
}
