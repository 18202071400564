import React from 'react';

export function useDebounce(
  fn: (...args: any[]) => any
): [(...args: any[]) => any, boolean] {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const submittingRef = React.useRef<boolean>(false);

  const callback = async (...args: any[]) => {
    if (submittingRef.current) return;

    submittingRef.current = true;
    setSubmitting(true);

    await fn(...args);

    submittingRef.current = false;
    setSubmitting(false);
  };

  return [callback, submitting];
}

export function useLeadingDebounce(
  fn: (...args: any[]) => Promise<any> | any,
  waitMs = 1_000
): [(...args: any[]) => void, boolean, boolean] {
  const timer = React.useRef<ReturnType<typeof setTimeout>>();

  const submittingRef = React.useRef<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const waitingRef = React.useRef<boolean>(false);
  const [waiting, setWaiting] = React.useState<boolean>(false);

  const callback = (...args: any[]) => {
    if (submittingRef.current) return;

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(async () => {
      waitingRef.current = false;
      setWaiting(false);

      submittingRef.current = true;
      setSubmitting(true);

      await fn(...args);

      submittingRef.current = false;
      setSubmitting(false);
    }, waitMs);

    waitingRef.current = true;
    setWaiting(true);
  };

  return [callback, submitting, waiting];
}
