import React from 'react';

import { NetworkController } from '@liferaft/api/utils/network';

import { useAuthenticationContext } from '../contexts';

type Props = {
  handleAuthentication: ({
    onSuccess,
    onFailure,
    network,
  }: {
    onSuccess: () => void;
    onFailure: () => void;
    network: NetworkController;
  }) => void;
  loadingApp: React.ReactNode;
  pollingIntervalMs?: number;
  protectedApp: React.ReactNode;
  publicApp: React.ReactNode;
};

export function AuthenticationBoundary({
  handleAuthentication,
  loadingApp,
  protectedApp,
  publicApp,
  pollingIntervalMs,
}: Props) {
  const {
    state: { isAuthenticated, shouldAuthenticate },
    actions: { setAuthenticated, setUnauthenticated, setAuthenticating },
  } = useAuthenticationContext();

  const pollAuthentication = (network: NetworkController) => {
    setAuthenticating();

    handleAuthentication({
      onSuccess: () => !network.canceled && setAuthenticated(),
      onFailure: () => !network.canceled && setUnauthenticated(),
      network,
    });
  };

  React.useEffect(() => {
    const network = new NetworkController();

    pollAuthentication(network);

    let timer: ReturnType<typeof setInterval>;
    if (pollingIntervalMs) {
      timer = setInterval(() => pollAuthentication(network), pollingIntervalMs);
    }

    () => {
      if (timer) clearTimeout(timer);
      network.cancel();
    };
  }, []);

  React.useEffect(() => {
    const network = new NetworkController();

    if (shouldAuthenticate) {
      pollAuthentication(network);
    }

    () => void network.cancel();
  }, [shouldAuthenticate]);

  if (isAuthenticated === undefined) {
    return <>{loadingApp}</>;
  }

  const App = isAuthenticated ? protectedApp : publicApp;

  return <>{App}</>;
}
