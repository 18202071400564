import React from 'react';

import { getWaffle } from '@liferaft/api/resources/feature-flags';
import type { Flag, Sample, Switch, Waffle } from '@liferaft/api/types';
import type { NetworkResult, NoBody } from '@liferaft/api/utils/network';
import { NetworkController } from '@liferaft/api/utils/network';

import { createContext } from '.';

export type WaffleUtility = {
  isFlagActive: (flagName: Flag) => boolean;
  isSwitchActive: (switchName: Switch) => boolean;
  isSampleActive: (sampleName: Sample) => boolean;
  statuses: Waffle | undefined;
};

const [useContext, Provider] = createContext<WaffleUtility>();
export const useWaffleContext = useContext;

type Props = {
  children?: React.ReactNode;
};

const waffleDefault: Waffle = {
  flags: {},
  switches: {},
  samples: {},
};

export function WaffleContextProvider({ children }: Props) {
  const [waffle, setWaffle] = React.useState<Waffle>(waffleDefault);

  React.useEffect(() => {
    const network = new NetworkController();

    network.request<NoBody, Waffle>(
      getWaffle(),
      (result: NetworkResult<Waffle>) => {
        if (result.data) setWaffle(result.data);
      }
    );

    return () => void network.cancel();
  }, []);

  const isFlagActive = (flagName: Flag): boolean => {
    return waffle?.flags[flagName]?.is_active ? true : false;
  };

  const isSwitchActive = (switchName: Switch): boolean => {
    return waffle?.switches[switchName]?.is_active ? true : false;
  };

  const isSampleActive = (sampleName: Sample): boolean => {
    return waffle?.samples[sampleName]?.is_active ? true : false;
  };

  const utility: WaffleUtility = {
    isFlagActive: isFlagActive,
    isSwitchActive: isSwitchActive,
    isSampleActive: isSampleActive,
    statuses: waffle,
  };

  return <Provider value={utility}>{children}</Provider>;
}
