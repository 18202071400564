import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Card } from '@liferaft/core/components';

import { DJANGO_API_URL } from '@/environment';

export function FinishedNotice() {
  return (
    <Container fluid="md">
      <Row className="my-8">
        <Col xs={12}>
          <Card>
            <h2>Your application has been completed</h2>
            <hr />
            <p className="text-muted">
              Please visit your <a href={DJANGO_API_URL}>member dashboard</a> to
              check the status of your policies.
            </p>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
