import { InsuranceProductKey } from '@liferaft/api/types';
import type { ProductKey } from '@liferaft/api/types';

const PRODUCT_SORT = [
  InsuranceProductKey.ACCIDENT,
  InsuranceProductKey.HOSPITAL,
  InsuranceProductKey.CRITICAL,
  InsuranceProductKey.DENTAL,
  InsuranceProductKey.VISION,
];

// sort available products by specified order or with broker's preferred product first
export function productSort(
  p1: ProductKey,
  p2: ProductKey,
  preferredProduct: string
): number {
  if (p1 === preferredProduct) {
    return PRODUCT_SORT.length * -1;
  } else if (p2 === preferredProduct) {
    return PRODUCT_SORT.length;
  } else {
    return PRODUCT_SORT.indexOf(p1) - PRODUCT_SORT.indexOf(p2);
  }
}

// for AIG spouse terminology
export function SpouseTerminology(
  carrier: string,
  state: string,
  capitalize = false
): string {
  if (carrier === 'AIG') {
    if (['CA', 'NV', 'OR'].includes(state) && capitalize) {
      return 'Spouse or Domestic Partner';
    } else if (['CA', 'NV', 'OR'].includes(state) && !capitalize) {
      return 'spouse or domestic partner';
    } else if (state === 'DC' && capitalize) {
      return 'Spouse, Domestic Partner, or Civil Union Partner';
    } else if (state === 'DC' && !capitalize) {
      return 'spouse, domestic partner, or civil union partner';
    }
  }

  return capitalize ? 'Spouse' : 'spouse';
}

// Strip UUID from apply URLs
export function stripApplyPathUuid(url: string): string {
  return url.replace(
    /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/,
    '[UUID]'
  );
}
