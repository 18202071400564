import React from 'react';
import { Card as BootstrapCard } from 'react-bootstrap';

type CardProps = {
  cardClasses?: string;
  bodyClasses?: string;
  cornered?: boolean;
  fullHeight?: boolean;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
} & React.PropsWithChildren<Record<string, unknown>>;

export function Card({
  cardClasses,
  bodyClasses,
  children,
  cornered = false,
  footer,
  fullHeight = false,
  header,
  ...passProps
}: CardProps) {
  let classes = cardClasses ? cardClasses : 'shadow-lg';
  let body = bodyClasses ? bodyClasses : '';

  if (!cardClasses?.includes('shadow-')) {
    classes = classes.concat(' ', 'shadow-lg');
  }

  if (cornered) {
    classes = classes.concat(' ', 'corner-card');
  }

  if (fullHeight) {
    classes = classes.concat(' ', 'h-100');
    body = body.concat(' ', 'h-100');
  }

  return (
    <BootstrapCard className={classes} {...passProps}>
      {header}
      <BootstrapCard.Body className={body}>{children}</BootstrapCard.Body>
      {footer}
    </BootstrapCard>
  );
}

type CardHeaderProps = {
  cardHeaderClasses?: string;
  iconComponent?: React.ReactNode;
  title?: React.ReactNode;
  subTitle?: string;
  accentColor?: string;
  iconLink?: string;
  largeIcon?: boolean;
  smallCard?: boolean;
  leftAction?: React.ReactNode;
  rightAction?: React.ReactNode;
};

export function CardHeader({
  cardHeaderClasses,
  iconComponent,
  title,
  subTitle,
  accentColor,
  iconLink,
  largeIcon,
  smallCard = false,
  leftAction,
  rightAction,
}: CardHeaderProps) {
  let iconElement: React.ReactNode;

  let classes = cardHeaderClasses
    ? cardHeaderClasses.concat(' card-header')
    : 'card-header border-bottom-0 d-flex justify-content-between';

  if (!cardHeaderClasses?.includes('border-bottom-')) {
    classes = classes.concat(' ', 'border-bottom-0');
  }

  if (!cardHeaderClasses?.includes('d-')) {
    classes = classes.concat(' ', 'd-flex');
  }

  if (!cardHeaderClasses?.includes('justify-')) {
    classes = classes.concat(' ', 'justify-content-between');
  }

  if (!iconLink && !subTitle && !largeIcon) {
    iconElement = (
      <a data-testid="card-header-icon" href={iconLink}>
        <span className="icon icon-xs text-primary mr-2">{iconComponent}</span>
      </a>
    );
  } else if (!smallCard && !iconLink) {
    iconElement = (
      <div className="mr-3">
        <span
          className="icon icon-md text-primary"
          data-testid="card-header-icon">
          {iconComponent}
        </span>
      </div>
    );
  } else if (iconLink) {
    iconElement = (
      <a data-testid="card-header-icon" href={iconLink}>
        <span
          className="icon icon-sm ml-n3"
          style={{ width: 'auto', color: accentColor }}>
          {iconComponent}
        </span>
      </a>
    );
  } else if (smallCard) {
    iconElement = (
      <span
        className="icon icon-sm ml-n3"
        data-testid="card-header-icon"
        style={{ width: 'auto', color: accentColor }}>
        {iconComponent}
      </span>
    );
  }

  let titleElement: React.ReactNode;
  if (smallCard && subTitle) {
    titleElement = (
      <div style={{ lineHeight: '1em' }}>
        <div className="m-0 font-weight-bold">{title}</div>
        <small style={{ color: accentColor }}>{subTitle}</small>
      </div>
    );
  } else if (smallCard && !subTitle) {
    titleElement = (
      <div>
        <div className="m-0 font-weight-bold">{title}</div>
      </div>
    );
  } else if (!smallCard && subTitle) {
    titleElement = (
      <div>
        <h3 className="m-0 font-weight-bold">{title}</h3>
        <small className="text-muted">{subTitle}</small>
      </div>
    );
  } else {
    titleElement = (
      <div className="d-flex align-items-center">
        <h3 className="m-0 font-weight-bold">{title}</h3>
      </div>
    );
  }

  return (
    <div className={classes} data-testid="card-header">
      <div className="d-flex align-items-center">
        {iconElement}
        {titleElement}
        {leftAction && (
          <div className="d-flex align-items-center">{leftAction}</div>
        )}
      </div>
      {rightAction && (
        <div className="d-flex align-items-center">{rightAction}</div>
      )}
    </div>
  );
}
