export abstract class NetworkResult<T = undefined> {
  data: T | undefined;
  error: unknown | undefined;
  response: Response | undefined;

  constructor({
    data,
    error,
    response,
  }: {
    data?: T;
    error?: unknown;
    response?: Response;
  }) {
    this.data = data;
    this.error = error;
    this.response = response;
  }
}

export class NetworkSuccess<T> extends NetworkResult<T> {
  constructor(response: Response, data?: T) {
    super({ data, response });
  }
}

export class NetworkFailure<T> extends NetworkResult<T> {
  constructor(error: unknown, data?: T, response?: Response) {
    super({ data, error, response });
  }
}

export class NetworkController {
  private onError?: (error: unknown) => void;
  private abortController: AbortController;

  constructor({ onError }: { onError?: (error: unknown) => void } = {}) {
    this.onError = onError;
    this.abortController = new AbortController();
  }

  cancel(): void {
    this.abortController.abort();
  }

  get canceled(): boolean {
    return this.abortController.signal.aborted;
  }

  async request<Body, Return = undefined>(
    requestConfiguration: RequestConfiguration<Body>,
    callbackFn?: (result: NetworkResult<Return>) => void
  ): Promise<NetworkSuccess<Return> | NetworkFailure<any>> {
    const request = this.buildRequest(
      requestConfiguration.signal(this.abortController.signal)
    );

    let response;
    let parsedResponse;
    let result;

    try {
      response = await fetch(request);
      parsedResponse = (await this.parseResponse(response)) as Return;
    } catch (error) {
      result = new NetworkFailure(error, parsedResponse, response);

      if (error instanceof Error) {
        this.onError && this.onError(error);

        if (error.name !== 'AbortError') {
          if (!this.canceled) {
            callbackFn && callbackFn(result);
          }
        }
      }

      return result;
    }

    const isSuccess = this.isSuccess(response);

    if (requestConfiguration.responseParser) {
      parsedResponse = requestConfiguration.responseParser.parse(
        parsedResponse,
        isSuccess
      );
    }

    if (isSuccess) {
      result = new NetworkSuccess(response, parsedResponse);
    } else {
      const error = Error(
        `Returned a response with a non-ok status: ${response.status}; Included details: ${parsedResponse}`
      );

      result = new NetworkFailure(error, parsedResponse, response);
    }

    if (!this.canceled) {
      callbackFn && callbackFn(result);
    }

    return result;
  }

  async requestAll<T extends [...RequestConfiguration<any>[]]>(
    ...configurations: [...T]
  ): Promise<NetworkResult<any>[]> {
    return await Promise.all(configurations.map((c) => this.request(c)));
  }

  private buildRequest<Body>(
    configuration: RequestConfiguration<Body>
  ): Request {
    const { credentials, body, query, url, method, headers } =
      configuration.options;

    if (!url || !method) {
      throw new Error(
        `Missing required request parameters; url: "${url}"; method: "${method}"`
      );
    }

    let fullUrl = url;

    if (query) {
      const searchParams = new URLSearchParams();
      Object.entries(query).forEach(([key, value]) =>
        searchParams.append(key, value.toString())
      );
      fullUrl = `${url}?${searchParams.toString()}`;
    }

    let preparedBody;

    switch (headers?.['Content-Type']) {
      case 'application/json': {
        preparedBody = JSON.stringify(body);
        break;
      }
      case 'multipart/form-data': {
        if (!(body instanceof FormData)) {
          throw new Error(
            'Body must be an instance of FormData to in order to post data with a content type of multipart/form-data'
          );
        }

        preparedBody = body;
        delete headers['Content-Type'];
      }
    }

    return new Request(fullUrl, {
      body: preparedBody,
      credentials,
      headers: new Headers(headers),
      method,
    });
  }

  private async parseResponse(response: Response): Promise<any> {
    const contentType = response.headers.get('Content-Type');

    if (contentType === null || contentType === 'application/json') {
      const body = await response.text();

      if (body === '') {
        return undefined;
      }

      return await JSON.parse(body);
    } else if (['application/pdf', 'text/csv'].includes(contentType)) {
      return new Blob([await response.blob()], {
        type: contentType,
      });
    }

    const body = await response.text();

    if (body === '') {
      return undefined;
    }

    return await JSON.parse(body);
  }

  private isSuccess(rawResponse: Response): boolean {
    return rawResponse.status.toString().startsWith('2') ? true : false;
  }
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type NoBody = undefined;

export type QueryParams = Record<string, string | boolean | number>;

export interface RequestParams<T> {
  url: string;
  method: HttpMethod;
  credentials: RequestCredentials;
  signal?: AbortSignal;
  headers: Record<string, string>;
  body?: T;
  query?: Record<string, string | boolean | number>;
}

export interface ResponseParser {
  parse: (responseData: any, isSuccess: boolean) => any;
}

export class RequestConfiguration<Body> {
  public options: Partial<RequestParams<Body>>;
  public responseParser?: ResponseParser;

  constructor(
    options?: Partial<RequestParams<Body>>,
    responseParser?: ResponseParser
  ) {
    this.responseParser = responseParser;

    this.options = {
      credentials: 'include',
      ...options,
      headers: {
        Accept: 'application/json',
        Origin: window.location.origin,
        'Content-Type': 'application/json',
        ...options?.headers,
      },
    };
  }

  url(url: string): this {
    this.options.url = url;
    return this;
  }

  query(query: QueryParams): this {
    this.options.query = query;
    return this;
  }

  body(body: Body): this {
    this.options.body = body;
    return this;
  }

  method(method: HttpMethod): this {
    this.options.method = method;
    return this;
  }

  get(): this {
    return this.method('GET');
  }

  post(): this {
    return this.method('POST');
  }

  put(): this {
    return this.method('PUT');
  }

  patch(): this {
    return this.method('PATCH');
  }

  delete(): this {
    return this.method('DELETE');
  }

  signal(signal: AbortSignal): this {
    this.options.signal = signal;
    return this;
  }
}
