import React from 'react';

import type { Dependent } from '@liferaft/api/types';

import type {
  DateOfBirthData,
  NameData,
  NameErrors,
  Sex,
} from '@liferaft/core/components/forms';
import {
  DateOfBirthInput,
  NameInput,
  SexInput,
} from '@liferaft/core/components/forms';
import * as dates from '@liferaft/core/utils/dates';

export function makeBlankPersonalInfo(): PersonalInfoData {
  return {
    name: {
      firstName: '',
      middleName: '',
      lastName: '',
    },
    dateOfBirth: {},
    sex: undefined,
  };
}

export function fromDependent(dependent: Dependent): PersonalInfoData {
  return {
    name: {
      firstName: dependent.first_name,
      lastName: dependent.last_name,
      middleName: '',
    },
    dateOfBirth: dates.fromString(dependent.date_of_birth),
    sex: dependent.sex,
  };
}

export function isDirty(data: Partial<PersonalInfoData>): boolean {
  if (data.sex) {
    return true;
  }

  for (const k in data.name) {
    if (
      data.name[k as keyof NameData] !== undefined &&
      data.name[k as keyof NameData] !== null &&
      data.name[k as keyof NameData] !== ''
    )
      return true;
  }

  for (const k in data.dateOfBirth) {
    if (
      data.dateOfBirth[k as keyof DateOfBirthData] !== undefined &&
      data.dateOfBirth[k as keyof DateOfBirthData] !== null
    ) {
      return true;
    }
  }

  return false;
}

export type PersonalInfoData = {
  name: Partial<NameData>;
  dateOfBirth: DateOfBirthData;
  sex?: Sex;
};

export type PersonalInfoDataErrors = {
  name?: NameErrors;
  dateOfBirth?: string;
  sex?: string;
};

type Props = {
  errors?: PersonalInfoDataErrors;
  showNameLabels?: boolean;
  label?: string;
  fieldsetClasses?: string;
  nameLabel?: string;
  sexRadioPrefix?: string;
  sexHelper?: string;
  required?: boolean;
  value: Partial<PersonalInfoData>;
  onChange: (value: Partial<PersonalInfoData>) => void;
};

export function PersonalInfoInput({
  errors,
  showNameLabels = false,
  label,
  fieldsetClasses,
  nameLabel,
  sexRadioPrefix,
  sexHelper,
  required = true,
  value,
  onChange,
}: Props) {
  const handleNameChange = (newName: Partial<NameData>) => {
    onChange({ ...value, name: newName });
  };

  const handleDateOfBirthChange = (newDateOfBirth: DateOfBirthData) => {
    onChange({ ...value, dateOfBirth: newDateOfBirth });
  };

  const handleSexChange = (newSex: Sex) => {
    onChange({ ...value, sex: newSex });
  };

  return (
    <fieldset className={fieldsetClasses} data-testid="personal-info-fieldset">
      {label && (
        <legend className="col-form-label font-weight-bold">{label}</legend>
      )}
      <NameInput
        errors={errors?.name}
        fieldsetClasses="form-group"
        label={nameLabel}
        onChange={handleNameChange}
        required={required}
        showFieldLabels={showNameLabels}
        value={value?.name}
      />
      <DateOfBirthInput
        error={errors?.dateOfBirth}
        groupClasses="form-group"
        label="Date of Birth"
        onChange={handleDateOfBirthChange}
        required={required}
        value={value?.dateOfBirth}
      />
      <SexInput
        error={errors?.sex}
        groupClasses="form-group"
        help={sexHelper}
        label="Sex"
        onChange={handleSexChange}
        radioPrefix={sexRadioPrefix}
        required={required}
        value={value?.sex}
      />
    </fieldset>
  );
}
