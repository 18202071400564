import React from 'react';
import { Form } from 'react-bootstrap';
import type * as yup from 'yup';

import type { NonFieldErrorKey } from '@liferaft/api/utils/django-utils';

export type FormErrors<Data extends Record<string, any>> = Partial<
  Record<keyof Data | NonFieldErrorKey, any>
>;

export type FormErrorsSetter<Data extends Record<string, any>> = React.Dispatch<
  React.SetStateAction<FormErrors<Data>>
>;

type SubmitHandler<Data extends Record<string, any>> = (
  data: Data,
  setErrors: FormErrorsSetter<Data>
) => void;

type Props<ValidatedData extends Record<string, any>> = {
  controlledData: Record<string, any>;
  validationSchema: any;
  onSubmit: SubmitHandler<ValidatedData>;
  children: ({
    errors,
  }: {
    errors: FormErrors<ValidatedData>;
  }) => React.ReactNode;
} & React.PropsWithChildren<Record<string, unknown>>;

export function ValidatedForm<ValidatedData extends Record<string, any>>({
  controlledData,
  validationSchema,
  onSubmit,
  children,
  ...passProps
}: Props<ValidatedData>) {
  const [errors, setErrors] = React.useState<FormErrors<ValidatedData>>({});

  const handleSubmit = (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();

    const presentData = Object.fromEntries(
      Object.entries(controlledData).filter(
        ([, v]) => v !== '' && v !== undefined && v !== null
      )
    );

    validationSchema
      .validate(presentData, { abortEarly: false })
      .then(
        (validatedData: ValidatedData) =>
          void onSubmit(validatedData, setErrors)
      )
      .catch((err: yup.ValidationError) => {
        const errors: FormErrors<ValidatedData> = {};

        err.inner.forEach((error: yup.ValidationError) => {
          if (error.path) {
            if (error.path.includes('[')) {
              const [path, field] = error.path.split('.');
              const [key, [index]] = path.split('[');

              if (errors[key as keyof ValidatedData]) {
                errors[key as keyof ValidatedData][Number(index)][field] =
                  error.message;
              } else {
                errors[key as keyof ValidatedData] = [
                  { [field]: error.message },
                ];
              }
            } else if (error.path.includes('.')) {
              const [key, field] = error.path.split('.');

              if (errors[key as keyof ValidatedData]) {
                errors[key as keyof ValidatedData][field] = error.message;
              } else {
                errors[key as keyof ValidatedData] = { [field]: error.message };
              }
            } else {
              errors[error.path as keyof ValidatedData] = error.message;
            }
          }
        });

        setErrors(errors);
      });
  };

  return (
    <Form onSubmit={handleSubmit} {...passProps}>
      {children({ errors })}
    </Form>
  );
}
