import React from 'react';

import type {
  AmeritasRevision,
  StateContent,
  StateContentOverride,
} from './types';

type BaseModule = {
  default: StateContent;
  revisions: Record<AmeritasRevision, StateContentOverride>;
};

const state: BaseModule = {
  default: {
    offersProduct: {
      dental: true,
      vision: true,
      'accidental-medical-expense': false,
      'critical-illness': false,
      'hospital-indemnity': false,
    },
    isBaseState: {
      dental: true,
      vision: true,
      'accidental-medical-expense': true,
      'critical-illness': true,
      'hospital-indemnity': true,
    },
    stateNotice: null,
    fraudNotice: (
      <p>
        Any person who knowingly, and with intent to injure, defraud or deceive
        any insurer or insurance company, files a statement of claim containing
        any materially false, incomplete, or misleading information or conceals
        any fact material thereto, may be guilty of a fraudulent act, may be
        prosecuted under state law and may be subject to civil and criminal
        penalties. In addition, any insurer or insurance company may deny
        benefits if false information materially related to a claim is provided
        by the claimant.
      </p>
    ),
    dentalLimitations: (
      <>
        <p>
          Covered expenses will not include and benefits will not be payable for
          expenses incurred:
        </p>
        <ul>
          <li>
            for any treatment which is for cosmetic purposes, except as
            specifically listed in the Table of Dental Procedures.
          </li>
          <li>
            to replace any crowns, inlays, onlays, veneers, complete or partial
            dentures within five years of the date of the last placement of
            these items.But if a replacement is required because of an
            accidental bodily injury sustained while the Insured person is
            covered under this contract, it will be a covered expense.
          </li>
          <li>
            for initial placement of any dental prosthesis or prosthetic crown
            unless such placement is needed because of the extraction of one or
            more teeth while the insured person is covered under this
            contract.But the extraction of a third molar(wisdom tooth) will not
            qualify under the above.Any such appliance or fixed partial denture
            must include the replacement of the extracted tooth or teeth.
          </li>
          <li>
            for any procedure begun before the insured person was covered under
            the policy.
          </li>
          <li>
            for any procedure begun after the insured person's insurance under
            the policy terminates; or for any prosthetic dental appliances
            installed or delivered more than 90 days after the insured's
            insurance under the policy terminates.
          </li>
          <li>to replace lost or stolen appliances.</li>
          <li>for appliances, restorations, or procedures to:</li>
          <ul>
            <li>alter vertical dimension;</li>
            <li>restore or maintain occlusion; or</li>
            <li>
              splint or replace tooth structure lost as a result of abrasion or
              attrition.
            </li>
          </ul>
          <li>
            for any procedure which is not shown on the Table of Dental
            Procedures. (There may be additional frequencies and limitations
            that apply, please see the Table of Dental Procedures in the
            policy.)
          </li>
          <li>
            for which the insured person is entitled to benefits under any
            workmen's compensation or similar law, or charges for services or
            supplies received as a result of any dental condition caused or
            contributed to by an injury or sickness arising out of or in the
            course of any employment for wage or profit(except in CA & KY).
          </li>
          <li>
            for charges for which the insured person is not liable or which
            would not have been made had no insurance been in force.
          </li>
          <li>
            for services which are not required for necessary care and treatment
            or are not within the generally accepted parameters of care.
          </li>
          <li>because of war or any act of war, declared or not.</li>
          <li>
            if two or more procedures are considered adequate and appropriate
            treatment to correct a certain condition under generally accepted
            standards of dental care, the amount of the covered expense will be
            equal to the charge for the least expensive procedure.
          </li>
        </ul>
      </>
    ),
    planDescriptions: {
      'accidental-medical-expense': null,
      'critical-illness': null,
      'hospital-indemnity': null,
      dental: (
        <p>
          These Primestar Dental Plans help you get the dental coverage you need
          at affordable rates. Your coverage begins next-day with no waiting
          periods on most dental insurance plans. There are no enrollment fees
          with any plan. Preventive visits, such as exams and cleanings, are
          covered up to 100%. Dental plans may include additional benefits such
          as orthodontia or hearing care. A deductible may apply for coverage;
          please see Coverage Quick Facts for more details.
        </p>
      ),
      vision: (
        <p>
          These Primestar® Vision Plans help protect your eye health and ensure
          that you have access to the eyeglasses or contacts you rely on. Your
          coverage begins next-day with no waiting periods and no enrollment
          fees on all vision insurance plans. A deductible may apply for
          coverage; please see Coverage Quick Facts for more details.
        </p>
      ),
    },
    authorizationAgreement: (
      <>
        <p>
          I authorize Ameritas Life Insurance Corp. (“Ameritas”) to initiate
          electronic debit entries to my account chosen above for payment of my
          insurance premium. I certify that I am an authorized user on the above
          listed account. I acknowledge that debits to my account for premium
          due will occur on a regular recurring basis based on the payment
          frequency indicated above until such time as coverage terminates or
          until I notify Ameritas to terminate these transactions. I understand
          that it may take up to two weeks to process a request to discontinue
          recurring payments. In order to make changes to this authorization
          (such as change in bank account, method of payment, or termination of
          payment) I must provide Ameritas at least two weeks' notice in advance
          of the next scheduled payment date.
        </p>
        <p> Payment Information: </p>
        <ol>
          <li>
            Initial premium will be withdrawn within 3 days of your policy
            effective date, subsequent premiums are due on the day of the month
            in which the policy was effective.
          </li>
          <li>
            For initial payments I acknowledge that Ameritas may debit my
            account upon acceptance and approval of my application. Based upon
            my authorization, Ameritas will process recurring payments on or
            within three business days of the date of the month in which my
            policy was first effective.
          </li>
        </ol>
        <p>
          If any authorized payment is returned or dishonored by my bank, I
          acknowledge that I am responsible for any fees my bank may charge. I
          understand also that I may incur a return payment fee of $25 charged
          by Ameritas if the return is due to insufficient funds. I acknowledge
          that such a fee, if charged, may be automatically debited from my
          authorized account on the next payment date. I am responsible for
          remitting payment within the policy grace period. If payment is not
          received by Ameritas within the defined grace period I acknowledge
          that my coverage may be cancelled in accordance with the terms of the
          insurance contract.
        </p>
        <p>
          I also acknowledge that I have read the following information from
          Ameritas regarding this electronic signature.
        </p>
        <ul>
          <li>
            I may return my policy within the right-to-cancel period as
            described in my policy;
          </li>
          <li>
            I acknowledge receipt of the Outline of Coverage (in states where
            required by law);
          </li>
          <li>
            I understand the policy I am applying for provides dental and (if
            chosen) vision benefits only and is not a Medicare supplement;
          </li>
          <li>
            I acknowledge that the agent of record, if applicable, is my
            insurance agent for purposes of the Ameritas Privacy Policy; and
          </li>
          <li>
            I understand that it is my responsibility to give notice to Ameritas
            of changes in my email address or any information above, as well as
            my status and my family's status that affect coverage, such as
            marriage, births, or death of someone covered under the policy. I
            will provide notice via email at{' '}
            <a href="mailto:cs@ameritas.com">cs@ameritas.com</a>, fax at
            402-309-2598 or in writing to Ameritas or its designee: PO Box
            82607, Lincoln, NE 68501-2607.
          </li>
        </ul>
        <p>
          You are encouraged to print a copy of your electronic forms to retain
          for your own records.
        </p>
      </>
    ),
    planFacts: {
      'accidental-medical-expense': new Map(),
      'critical-illness': new Map(),
      'hospital-indemnity': new Map(),
      dental: new Map([
        [
          'Enrollment Start Date',
          <p key="description">
            Coverage begins next-day after application is submitted.
          </p>,
        ],
        [
          'Deductible',
          <p key="description">
            $50 deductible per person for Basic and Major services combined.
            Family plans have a max $150 deductible.
          </p>,
        ],
        ['Waiting Periods', null],
        [
          'Max Benefit',
          <p key="description">
            Maximum benefit is per person for Basic and Major services combined.
          </p>,
        ],
        ['Value Plan Coinsurance', null],
        ['Value Plan In- and Out-of-Network Coinsurance', null],
        ['Value Plan', null],
        ['Lite Plan Coinsurance', null],
        [
          'Lite Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/50/10 INN & 70/25/5 OON
            <br />
            Year 2 - 100/80/20 INN & 70/40/10 OON
          </>,
        ],
        [
          'Lite Plan',
          <p key="description">
            Type 1: Exams, Cleanings
            <br />
            Type 2: Fluoride (Up to age 16), Sealants, Space Maintainers,
            Bitewing X-Rays, Fillings
            <br />
            Type 3: All Other X-rays, Oral Surgery, Simple Extractions,
            Endodontics, Periodontics, Crowns, Bridges, Dentures
          </p>,
        ],
        ['Access Plan Coinsurance', null],
        ['Access Plan In- and Out-of-Network Coinsurance', null],
        ['Access Plan', null],
        ['Boost Plan Coinsurance', null],
        [
          'Boost Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/65/20 INN & 80/45/10 OON
            <br />
            Year 2 - 100/80/50 INN & 80/60/30 OON
          </>,
        ],
        [
          'Boost Plan',
          <p key="description">
            Type 1: Exams, Cleanings, Fluoride (Up to age 16), Sealants, Space
            Maintainers, Bitewing X-rays
            <br />
            Type 2: Fillings, Simple Extractions
            <br />
            Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
            Crowns, Bridges, Dentures, Implants, Teeth Whitening
          </p>,
        ],
        ['Total Plan Coinsurance', null],
        ['Total Plan In- and Out-of-Network Coinsurance', null],
        ['Total Plan', null],
        ['Complete Plan Coinsurance', null],
        [
          'Complete Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/80/20 INN & 90/70/15 OON
            <br />
            Year 2 - 100/90/50 INN & 90/80/40 OON
          </>,
        ],
        [
          'Complete Plan',
          <p key="description">
            Type 1: Exams, Cleanings, Bitewing X-rays
            <br />
            Type 2: Fillings, Simple Extractions
            <br />
            Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
            Crowns, Bridges, Dentures, Implants
          </p>,
        ],
        [
          'Preventive Plus',
          <p key="description">
            Type 1 Preventive procedures are not deducted from the plan's annual
            maximum benefit. This saves all of the annual benefit to help pay
            for more expensive Type 2 and 3 procedures.
          </p>,
        ],
      ]),
      vision: new Map([
        [
          'Enrollment Start Date',
          <p key="description">
            Coverage begins next-day after application is submitted.
          </p>,
        ],
        ['Deductibles', null],
        [
          'Premium Contacts Deductible',
          <p key="description">
            Select Vision Plan: 90% of retail charge minus $55 allowance for
            premium contact lens fit and exam
            <br />
            Choice Vision Plan: Up to $60
          </p>,
        ],
        [
          'Medically Necessary Contacts',
          <p key="description">
            Select Vision Plan: Paid in Full minus $25 Materials Deductible
            <br />
            Choice Vision Plan: Paid in Full minus $20 Materials Deductible
          </p>,
        ],
        [
          'Select Vision Plan Partner',
          <p key="description">
            Select Vision Plan features the EyeMed vision network. Find EyeMed
            Access network providers at{' '}
            <a href="https://eyemed.com" rel="noreferrer" target="_blank">
              eyemed.com
            </a>
            . Retail chains accepting EyeMed insurance include LensCrafters,
            Pearle Vision and Target Optical. Online options in the EyeMed
            network are{' '}
            <a href="https://www.glasses.com" rel="noreferrer" target="_blank">
              Glasses.com
            </a>{' '}
            and{' '}
            <a
              href="https://www.contactsdirect.com"
              rel="noreferrer"
              target="_blank">
              ContactsDirect
            </a>
            . Based on applicable laws, reduced cost may vary by doctor
            location.
          </p>,
        ],
        [
          'Select Vision Plan Out-of-Network Maximum',
          <p key="description">
            $50 exams, $70 glasses frames, glasses lenses - $50 single vision,
            $75 bifocal, $75 progressive, $100 trifocal, $75 lenticular, $105
            elective contacts, $250 medically-necessary contacts, $40 contacts
            standard & premium fit and follow-up
          </p>,
        ],
        [
          'Choice Vision Plan Partner',
          <p key="description">
            Choice Vision Plan features the VSP vision network. Find VSP Choice
            network providers at{' '}
            <a href="https://www.vsp.com" rel="noreferrer" target="_blank">
              vsp.com
            </a>
            . Retail chains accepting VSP insurance include Costco Optical*,
            Sam's Club, Visionworks and Walmart. An online option in the VSP
            network is{' '}
            <a
              href=" https://www.eyeconic.com"
              rel="noreferrer"
              target="_blank">
              eyeconic.com
            </a>
            . Based on applicable laws, reduced cost may vary by doctor
            location.
            <br />
            <small>
              * Not all providers at Costco locations are VSP network providers
              - verify before seeking services.
            </small>
          </p>,
        ],
        [
          'Choice Vision Plan Out-of-Network Maximum',
          <p key="description">
            $45 exams, $70 glasses frames, glasses lenses - $30 single vision,
            $50 bifocal, $50 progressive, $65 trifocal, $100 lenticular, $105
            elective contacts, $210 medically-necessary contacts
          </p>,
        ],
      ]),
    },
    planFactsFootnote: {
      'accidental-medical-expense': null,
      'critical-illness': null,
      'hospital-indemnity': null,
      dental: (
        <>
          Claim allowance (MAC): The Maximum Allowable Charge (MAC) claim
          allowance is the maximum amount a network provider may charge. If you
          select a network provider, you may have lower out-of-pocket costs. If
          you visit an out-of-network dentist, the claim allowance is considered
          at the Maximum Allowable Benefit (MAB), which is equal to the lowest
          contracted fee in your ZIP Code area. You pay the difference between
          what the plan pays and the dentist's actual charge.{' '}
          <a
            href="https://dentalnetwork.ameritas.com/classic"
            rel="noreferrer"
            target="_blank">
            Find a Classic (PPO) network provider near you.
          </a>
        </>
      ),
      vision: null,
    },
    planHelper: {
      'accidental-medical-expense': () => null,
      'critical-illness': () => null,
      'hospital-indemnity': () => null,
      dental: () => (
        <>
          <small>
            Underwritten by Ameritas Life Insurance Corp. | 5900 O Street
            Lincoln, NE 68510
          </small>
          <br />
          <small>
            This is not a certificate of insurance or guarantee of coverage.
            Plan designs may not be available in all areas and are subject to
            individual state regulations. This piece is not for use in New
            Mexico. This information is provided by Ameritas Life Insurance
            Corp. (Ameritas Life). Dental, vision and hearing care products
            (9000 Rev. 03-16 for Group and 9000 Rev. 10-22 for Individual, dates
            may vary by state) are issued by Ameritas Life. The Dental and
            Vision Networks are not available in RI. In Texas, our dental
            network and plans are referred to as the Ameritas Dental Network.
          </small>
          <br />
          <small>
            Ameritas, the bison design and "fulfilling life" are service marks
            or registered service marks of Ameritas Life, affiliate Ameritas
            Holding Company or Ameritas Mutual Holding Company. © 2023 Ameritas
            Mutual Holding Company.
          </small>
        </>
      ),
      vision: () => (
        <>
          <small>
            Underwritten by Ameritas Life Insurance Corp. | 5900 O Street
            Lincoln, NE 68510
          </small>
          <br />
          <small>
            This is not a certificate of insurance or guarantee of coverage.
            Plan designs may not be available in all areas and are subject to
            individual state regulations. This piece is not for use in New
            Mexico. This information is provided by Ameritas Life Insurance
            Corp. (Ameritas Life). Dental, vision and hearing care products
            (9000 Rev. 03-16 for Group and 9000 Rev. 10-22 for Individual, dates
            may vary by state) are issued by Ameritas Life. The Dental and
            Vision Networks are not available in RI. In Texas, our dental
            network and plans are referred to as the Ameritas Dental Network.
          </small>
          <br />
          <small>
            Ameritas, the bison design and "fulfilling life" are service marks
            or registered service marks of Ameritas Life, affiliate Ameritas
            Holding Company or Ameritas Mutual Holding Company. © 2023 Ameritas
            Mutual Holding Company.
          </small>
        </>
      ),
    },
    summaryFootnote: {
      'accidental-medical-expense': () => null,
      'critical-illness': () => null,
      'hospital-indemnity': () => null,
      dental: () => null,
      vision: () => null,
    },
  },
  revisions: {
    '2023Q3': {},
    '2024Q2': {
      planHelper: {
        dental: () => (
          <>
            <small>
              Underwritten by Ameritas Life Insurance Corp. | 5900 O Street
              Lincoln, NE 68510
            </small>
            <br />
            <small>
              This is not a certificate of insurance or guarantee of coverage.
              Plan designs may not be available in all areas and are subject to
              individual state regulations. This piece is not for use in New
              Mexico. This information is provided by Ameritas Life Insurance
              Corp. (Ameritas Life). Dental, vision and hearing care products
              (9000 Rev. 03-16 for Group and 9000 Rev. 10-22 for Individual,
              dates may vary by state) are issued by Ameritas Life. The Dental
              and Vision Networks are not available in RI. In Texas, our dental
              network and plans are referred to as the Ameritas Dental Network.
              Ameritas, the bison design and “fulfilling life” are service marks
              or registered service marks of Ameritas Life, affiliate Ameritas
              Holding Company or Ameritas Mutual Holding Company. © 2024
              Ameritas Mutual Holding Company.
            </small>
          </>
        ),
        vision: () => (
          <>
            <small>
              Underwritten by Ameritas Life Insurance Corp. | 5900 O Street
              Lincoln, NE 68510
            </small>
            <br />
            <small>
              This is not a certificate of insurance or guarantee of coverage.
              Plan designs may not be available in all areas and are subject to
              individual state regulations. This piece is not for use in New
              Mexico. This information is provided by Ameritas Life Insurance
              Corp. (Ameritas Life). Dental, vision and hearing care products
              (9000 Rev. 03-16 for Group and 9000 Rev. 10-22 for Individual,
              dates may vary by state) are issued by Ameritas Life. The Dental
              and Vision Networks are not available in RI. In Texas, our dental
              network and plans are referred to as the Ameritas Dental Network.
              Ameritas, the bison design and “fulfilling life” are service marks
              or registered service marks of Ameritas Life, affiliate Ameritas
              Holding Company or Ameritas Mutual Holding Company. © 2024
              Ameritas Mutual Holding Company.
            </small>
          </>
        ),
      },
    },
  },
};

export default state;
