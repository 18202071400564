import type { Moov } from '@moovio/moov-js';
import { loadMoov } from '@moovio/moov-js';
import React from 'react';

import type { MoovAccessTokenParams } from '@liferaft/api/resources/moov';
import { createMoovAccessToken } from '@liferaft/api/resources/moov';
import type { MoovAccessToken } from '@liferaft/api/types';
import type { NetworkResult } from '@liferaft/api/utils/network';
import { NetworkController } from '@liferaft/api/utils/network';

export type MoovUtility = {
  accessToken?: string;
  moov: Moov | null;
  refreshAccessToken: () => Promise<void>;
};

export function useMoov(moovAccountId?: string): MoovUtility {
  const [accessToken, setAccessToken] = React.useState<string>();
  const [moov, setMoov] = React.useState<Moov | null>(null);

  const fetchAccessToken = async (
    accountId?: string,
    network?: NetworkController
  ) => {
    network = network || new NetworkController();

    network.request<MoovAccessTokenParams, MoovAccessToken>(
      createMoovAccessToken(accountId),
      (result: NetworkResult<MoovAccessToken>) => {
        if (result.error) return;
        setAccessToken(result.data?.access_token);
      }
    );
  };

  React.useEffect(() => {
    const network = new NetworkController();
    fetchAccessToken(moovAccountId);
    return () => void network.cancel();
  }, [moovAccountId]);

  React.useEffect(() => {
    if (accessToken && moov !== undefined) {
      (async () => {
        setMoov(await loadMoov(accessToken));
      })();
    }
  }, [accessToken]);

  return {
    accessToken,
    moov,
    refreshAccessToken: async () => {
      await fetchAccessToken(moovAccountId);
    },
  };
}
