import React from 'react';
import { Alert } from 'react-bootstrap';
import * as yup from 'yup';

import { updateApplication } from '@liferaft/api/resources/application';
import type {
  Application,
  ApplicationAddress,
  Egress,
} from '@liferaft/api/types';
import type { ParsedDjangoErrorResponse } from '@liferaft/api/utils/django-utils';
import { NON_FIELD_ERROR_LABEL } from '@liferaft/api/utils/django-utils';
import { NetworkController } from '@liferaft/api/utils/network';

import { Card } from '@liferaft/core/components';
import type {
  AddressData,
  FormErrorsSetter,
} from '@liferaft/core/components/forms';
import {
  AddressInput,
  PhoneNumberInput,
  ValidatedForm,
} from '@liferaft/core/components/forms';
import { useDebounce } from '@liferaft/core/hooks';

import {
  APPLY_FORM_ID,
  ApplyWrapper,
  ApplyWrapperFooter,
  useApplicationFlow,
} from '@/components';

const schema = yup.object({
  raw_phone_number: yup.string().required().label('Phone number'),
  address: yup.mixed<ApplicationAddress>().required().label('Address'),
});

type FormData = yup.InferType<typeof schema>;

export function ContactInformation() {
  const {
    data: { egress, id },
    next,
  } = useApplicationFlow();

  const [phoneNumber, setPhoneNumber] = React.useState<string>(
    egress?.phone_number || ''
  );

  const [address, setAddress] = React.useState<Partial<AddressData>>({
    primary: egress?.address?.primary || '',
    secondary: egress?.address?.secondary || '',
    city: egress?.address?.city || '',
    state: egress?.state || egress?.address?.state || '',
    zipCode: egress?.zip_code || egress?.address?.zip_code || '',
  });

  const [handleSubmit, isSubmitting] = useDebounce(
    async (validatedData: FormData, setErrors: FormErrorsSetter<FormData>) => {
      const network = new NetworkController();

      const result = await network.request<FormData, Application>(
        updateApplication(id as string, {
          ...validatedData,
          phone_number: validatedData.raw_phone_number.replace(/[^\d]/g, ''),
        })
      );

      if (result.error) {
        setErrors(result.data as ParsedDjangoErrorResponse<Egress>);
        return;
      }

      return next();
    }
  );

  return (
    <ApplyWrapper
      footer={<ApplyWrapperFooter rightDisabled={isSubmitting} />}
      heading="Phone Number and Address">
      <ValidatedForm<FormData>
        controlledData={{
          raw_phone_number: phoneNumber,
          address: {
            primary: address.primary,
            secondary: address.secondary,
            city: address.city,
            state: address.state,
            zip_code: address.zipCode,
          },
        }}
        id={APPLY_FORM_ID}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        {({ errors }) => (
          <Card cardClasses="card-bleed shadow-light">
            {errors[NON_FIELD_ERROR_LABEL] && (
              <Alert className="mb-3" variant="danger">
                <ul>
                  {errors[NON_FIELD_ERROR_LABEL].map(
                    (err: string, i: number) => (
                      <li key={i}>{err}</li>
                    )
                  )}
                </ul>
              </Alert>
            )}
            <PhoneNumberInput
              error={errors.raw_phone_number}
              groupClasses="form-group"
              label="Phone number"
              onChange={setPhoneNumber}
              value={phoneNumber}
            />
            <AddressInput
              disableState
              disableZipCode
              errors={{
                ...errors?.address,
                zipCode: errors?.address?.zip_code,
              }}
              fieldsetClasses="form-group"
              label="Residential Address"
              onChange={setAddress}
              showFieldLabels={true}
              value={address}
            />
          </Card>
        )}
      </ValidatedForm>
    </ApplyWrapper>
  );
}
