import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '../components';
import type { WrappedActions } from '../contexts';

export type FlowState<FlowData> = {
  routeStack: string[];
  currentPath: string | null;
  returnPath: string | null;
  goToPath: string | null;
  data: Partial<FlowData>;
  entered: boolean;
  entering: boolean;
  exiting: boolean;
  navigating: boolean;
};

type FlowAction<Data> =
  | { type: 'BACK'; path: string; routeStack: string[] }
  | { type: 'ENTERING'; data: Partial<Data> }
  | { type: 'ENTERED' }
  | { type: 'EXITING' }
  | { type: 'GO'; path: string }
  | { type: 'NAVIGATING' }
  | { type: 'NAVIGATED' }
  | {
      type: 'NEXT';
      path: string;
      returnPath: string | null;
      goToPath: string | null;
      routeStack: string[];
    }
  | { type: 'UPDATE'; data: Partial<Data> };

type FlowContext<Data> = {
  dispatch: React.Dispatch<FlowAction<Data>>;
  state: FlowState<Data>;
  wrappedActions: WrappedActions<FlowAction<Data>>;
};

type Flow<Data> = {
  Context: React.Context<FlowContext<Data>>;
};

type FlowProps<State, Data> = {
  children?: React.ReactNode;
  determineNextPath: (flowState: State) => string | null;
  determinePreviousPath: (flowState: State) => string;
  exitPath: string | (() => void);
  fetchState?: (previousState?: Partial<Data>) => Promise<Partial<Data>>;
  onEntry?: () => void;
  onExit?: () => void;
  onStepTransition?: (currentPath: string) => void;
  pathPrefix?: string;
  searchParams?: URLSearchParams;
};

export function useFlow<State>(Flow: Flow<State>) {
  const {
    wrappedActions,
    dispatch,
    state: { data },
  } = React.useContext(Flow.Context);

  return {
    back: wrappedActions.back(dispatch),
    data,
    goTo: wrappedActions.goTo(dispatch),
    next: wrappedActions.next(dispatch),
  };
}

export function createFlow<Data>() {
  const initialState = {
    routeStack: [],
    returnPath: null,
    currentPath: null,
    goToPath: null,
    exiting: false,
    entering: false,
    entered: false,
    navigating: false,
    data: {},
  };
  const defaultDispatch: React.Dispatch<FlowAction<Data>> = () => initialState;
  const defaultWrappedActions = {};

  const Context = React.createContext<{
    state: FlowState<Data>;
    dispatch: React.Dispatch<FlowAction<Data>>;
    wrappedActions: WrappedActions<FlowAction<Data>>;
  }>({
    state: initialState,
    dispatch: defaultDispatch,
    wrappedActions: defaultWrappedActions,
  });

  function reducer(state: FlowState<Data>, action: FlowAction<Data>) {
    switch (action.type) {
      case 'BACK': {
        return {
          ...state,
          currentPath: action.path,
          routeStack: action.routeStack,
        };
      }
      case 'ENTERED': {
        return {
          ...state,
          entered: true,
          entering: false,
        };
      }
      case 'ENTERING': {
        return {
          ...state,
          data: {
            ...action.data,
          },
          entering: true,
        };
      }
      case 'EXITING': {
        return {
          ...state,
          exiting: true,
        };
      }
      case 'GO': {
        return {
          ...state,
          returnPath: state.currentPath,
          goToPath: action.path,
          currentPath: action.path,
        };
      }
      case 'NEXT': {
        return {
          ...state,
          currentPath: action.path,
          returnPath: action.returnPath,
          goToPath: action.goToPath,
          routeStack: action.routeStack,
        };
      }
      case 'NAVIGATING': {
        return {
          ...state,
          navigating: true,
        };
      }
      case 'NAVIGATED': {
        return {
          ...state,
          navigating: false,
        };
      }
      case 'UPDATE': {
        return {
          ...state,
          data: {
            ...action.data,
          },
        };
      }
    }
  }

  function Flow({
    children,
    determineNextPath,
    determinePreviousPath,
    exitPath,
    fetchState,
    onEntry,
    onExit,
    onStepTransition,
    pathPrefix,
    searchParams,
  }: FlowProps<FlowState<Data>, Data>) {
    const navigate = useNavigate();
    const location = useLocation();

    const [state, dispatch] = React.useReducer<
      React.Reducer<FlowState<Data>, FlowAction<Data>>
    >(reducer, initialState);

    const {
      routeStack,
      currentPath,
      exiting,
      entering,
      entered,
      navigating,
      returnPath,
      goToPath,
    } = state;

    const wrappedActions = {
      next: React.useCallback(
        (dispatchFn: React.Dispatch<FlowAction<Data>>) => {
          return async function (
            update = true,
            newData: null | Partial<Data> = null
          ) {
            if (update && fetchState) {
              const data = await fetchState(state.data);
              dispatchFn({ type: 'UPDATE', data });
            } else if (newData) {
              const data = { ...state.data, ...newData };
              dispatchFn({ type: 'UPDATE', data });
            } else {
              const path = determineNextPath(state);

              const updatedStack = [...routeStack];

              if (path && !returnPath && !goToPath) {
                updatedStack.push(path);
              }

              if (path) {
                dispatchFn({
                  type: 'NEXT',
                  path,
                  returnPath: path === returnPath ? null : returnPath,
                  goToPath: path === returnPath ? null : goToPath,
                  routeStack: updatedStack,
                });
              } else {
                dispatchFn({ type: 'EXITING' });
              }
            }
          };
        },
        [state, dispatch]
      ),
      goTo: React.useCallback(
        (dispatchFn: React.Dispatch<FlowAction<Data>>) => {
          return async function (path: string) {
            dispatchFn({
              type: 'GO',
              path,
            });
          };
        },
        [state, dispatch]
      ),
      back: React.useCallback(
        (dispatchFn: React.Dispatch<FlowAction<Data>>) => {
          return async function () {
            if (!currentPath) {
              return;
            }

            let path;
            let updatedStack: string[] = [...routeStack];

            if (returnPath && goToPath) {
              if (routeStack.length < 2 || !routeStack.includes(goToPath)) {
                path = determinePreviousPath(state);
              } else {
                const goToPathIndex = routeStack.indexOf(goToPath);
                path =
                  goToPathIndex === 0
                    ? (path = determinePreviousPath(state))
                    : (path = routeStack[goToPathIndex - 1]);
              }
            } else {
              if (routeStack.length < 2 && determinePreviousPath) {
                path = determinePreviousPath(state);
                updatedStack = [path];
              } else {
                updatedStack.pop();
                path = updatedStack[updatedStack.length - 1];
              }
            }

            dispatchFn({
              type: 'BACK',
              routeStack: updatedStack,
              path,
            });
          };
        },
        [state, dispatch]
      ),
    };

    React.useEffect(() => {
      if (fetchState) {
        (async () => {
          const data = await fetchState();
          dispatch({ type: 'ENTERING', data });
        })();
      } else {
        dispatch({ type: 'ENTERING', data: {} });
      }
    }, []);

    React.useEffect(() => {
      if (entered && !navigating) {
        wrappedActions.next(dispatch)(false);
      }
    }, [state.data]);

    React.useEffect(() => {
      if (entering) {
        if (onEntry) {
          onEntry();
        }

        wrappedActions.next(dispatch)(false);
      }
    }, [entering]);

    React.useEffect(() => {
      if (exiting) {
        if (onExit) {
          onExit();
        }

        if (typeof exitPath === 'function') {
          exitPath();
        } else {
          navigate(exitPath);
        }
      }
    }, [exiting]);

    React.useEffect(() => {
      if (entering && currentPath) {
        dispatch({ type: 'ENTERED' });
      }
    }, [currentPath, entering]);

    React.useEffect(() => {
      if (currentPath) {
        let target = currentPath;

        if (pathPrefix) {
          target = `${pathPrefix}${currentPath}/`;
          if (searchParams) {
            target += `?${encodeURI(searchParams.toString())}`;
          }
        }

        navigate(target, { replace: entering });

        setTimeout(() => void window.scrollTo(0, 0), 100);

        dispatch({ type: 'NAVIGATING' });
      }
    }, [currentPath]);

    React.useEffect(() => {
      if (navigating) {
        if (currentPath && location.pathname.includes(currentPath)) {
          dispatch({ type: 'NAVIGATED' });
          if (onStepTransition) onStepTransition(currentPath);
        }
      }
    }, [currentPath, navigating]);

    React.useEffect(() => {
      if (!navigating && currentPath) {
        if (returnPath && goToPath) {
          if (location.pathname.includes(returnPath)) {
            dispatch({
              type: 'NEXT',
              path: returnPath,
              returnPath: null,
              goToPath: null,
              routeStack,
            });
            return;
          } else if (location.pathname.includes(goToPath)) {
            dispatch({
              type: 'NEXT',
              path: goToPath,
              returnPath,
              goToPath,
              routeStack,
            });
            return;
          }
        }

        const currentPath = routeStack[routeStack.length - 1];
        const previousPath = routeStack[routeStack.length - 2];
        const updatedStack = [...routeStack];
        updatedStack.pop();

        if (
          currentPath &&
          previousPath &&
          location.pathname.includes(previousPath)
        ) {
          dispatch({
            type: 'NEXT',
            path: previousPath,
            returnPath,
            goToPath,
            routeStack: updatedStack,
          });
        } else {
          const path = location.pathname.replace(pathPrefix || '', '');

          dispatch({
            type: 'NEXT',
            path,
            returnPath: currentPath,
            goToPath: path,
            routeStack,
          });
        }
      }
    }, [location.pathname]);

    return (
      <Context.Provider value={{ wrappedActions, dispatch, state }}>
        {entered ? children : <Loader />}
      </Context.Provider>
    );
  }

  Flow.Context = Context;

  return [() => useFlow(Flow), Flow] as const;
}
