import React from 'react';

import { getTenants } from '@liferaft/api/resources';
import { getSDSSubscriptionsForTenant } from '@liferaft/api/resources';
import type { SDSSubscription, Tenant } from '@liferaft/api/types';
import type { DjangoListResponse } from '@liferaft/api/utils/django-utils';
import { NetworkController } from '@liferaft/api/utils/network';
import type { NoBody } from '@liferaft/api/utils/network';

import { createContext } from '.';
import { useUserContext } from '.';

type TenantContextValue = {
  fetchSDSSubscriptions: (tenantId: string) => Promise<void>;
  fetchAllSDSSubscriptions: ({
    forceFetch,
  }?: {
    forceFetch?: boolean;
  }) => Promise<void>;
  getSubscription: (subscriptionId: string) => SDSSubscription | undefined;
  getSubsciptions: (tenantId: string) => SDSSubscription[];
  loadingAllSubs: boolean;
  loadingSubs: boolean;
  subscriptions?: SDSSubscription[];
  tenants?: Tenant[];
};

const [useContext, Provider] = createContext<TenantContextValue>();

export const useTenantContext = useContext;

type Props = {
  children?: React.ReactNode;
};

export function TenantContextProvider({ children }: Props) {
  const { user } = useUserContext();
  const [tenants, setTenants] = React.useState<Tenant[]>();
  const [subscriptions, setSubscriptions] = React.useState<SDSSubscription[]>();
  const [loadingAllSubs, setLoadingAllSubs] = React.useState<boolean>(false);
  const [loadingSubs, setLoadingSubs] = React.useState<boolean>(false);
  const [lastFetchedAll, setLastFetchedAll] = React.useState<Date>();

  React.useEffect(() => {
    const network = new NetworkController();

    (async () => {
      const result = await network.request<NoBody, DjangoListResponse<Tenant>>(
        getTenants(user.id)
      );

      if (result.error || network.canceled) return;
      else if (result.data) setTenants(result.data.results);
    })();

    return () => void network.cancel();
  }, []);

  const fetchSDSSubscriptions = React.useCallback(
    async (tenantId: string, withLoadingState = true) => {
      const network = new NetworkController();

      if (withLoadingState) setLoadingSubs(true);

      const result = await network.request<
        NoBody,
        DjangoListResponse<SDSSubscription>
      >(getSDSSubscriptionsForTenant(tenantId));

      if (result.error) return;

      setSubscriptions((prevSubs) => {
        if (prevSubs) {
          const newSubIds = result.data.results.map(
            (s: SDSSubscription) => s.id
          );
          const otherSubs = prevSubs.filter((s) => !newSubIds.includes(s.id));
          return [...otherSubs, ...result.data.results];
        }

        return result.data.results;
      });

      if (withLoadingState) setLoadingSubs(false);
    },
    [tenants]
  );

  const fetchAllSDSSubscriptions = React.useCallback(
    async ({ forceFetch = false } = {}) => {
      if (tenants) {
        if (
          !lastFetchedAll ||
          forceFetch ||
          new Date().getTime() - lastFetchedAll.getTime() > 10 * 60 * 1_000
        ) {
          setLoadingAllSubs(true);

          await Promise.all(
            tenants.map((t) => fetchSDSSubscriptions(t.id, false))
          );

          setLastFetchedAll(new Date());
          setLoadingAllSubs(false);
        }
      }
    },
    [tenants, lastFetchedAll]
  );

  const getSubsciptions = React.useCallback(
    (tenantId: string): SDSSubscription[] => {
      return subscriptions?.filter((s) => s.tenant_id === tenantId) || [];
    },
    [subscriptions]
  );

  const getSubscription = React.useCallback(
    (subscriptionId: string): SDSSubscription | undefined => {
      return subscriptions?.find((s) => s.id === subscriptionId);
    },
    [subscriptions]
  );

  const value: TenantContextValue = {
    fetchAllSDSSubscriptions,
    fetchSDSSubscriptions,
    getSubscription,
    getSubsciptions,
    loadingAllSubs,
    loadingSubs,
    subscriptions,
    tenants,
  };

  return <Provider value={value}>{children}</Provider>;
}
