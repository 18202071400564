import React from 'react';
import type { PlaidLinkOnExit, PlaidLinkOnSuccess } from 'react-plaid-link';
import { usePlaidLink } from 'react-plaid-link';

type Props = {
  onExit?: PlaidLinkOnExit;
  onSuccess: PlaidLinkOnSuccess;
  receivedRedirectUri?: string;
  token: string;
};

export type PlaidHandler = {
  exit: any;
};

export const PlaidLink = React.forwardRef<PlaidHandler, Props>(
  ({ onExit, onSuccess, receivedRedirectUri, token }: Props, ref) => {
    const { exit, open, ready } = usePlaidLink({
      onExit,
      onSuccess,
      token,
      receivedRedirectUri,
    });

    React.useEffect(() => {
      if (ready) {
        open();
      }
    }, [ready, open]);

    React.useImperativeHandle(ref, () => ({
      exit,
    }));

    return null;
  }
);

PlaidLink.displayName = 'PlaidLink';
