import React from 'react';

import type { ProductKey } from '@liferaft/api/types';
import {
  CarrierKey,
  CoverageDisplay,
  CoverageType,
  InsuranceProductKey,
} from '@liferaft/api/types';

import { ReactComponent as HideToggleIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Code/Square-circle-minus.svg';
import { ReactComponent as ShowToggleIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Code/Square-circle-plus.svg';
import { activeAmeritasRevision } from '@liferaft/core/content/products/ameritas/current';

import { useProductMatrixContext } from '@/contexts';
import { PlanCard, ServicesCard } from '@/pages/product-matrix';
import type { Service } from '@/pages/product-matrix/content';
import {
  getPlanHelper,
  getProductDescription,
  getQuoteGridSections,
  getServices,
  getServicesHelper,
} from '@/pages/product-matrix/content';

type Props = {
  productName: string;
  productKey: ProductKey;
  isActive: boolean;
  onToggle: (productKey: ProductKey) => void;
};

export function Product({
  isActive,
  productKey,
  productName,
  onToggle,
}: Props) {
  const context = useProductMatrixContext();
  const ameritasRevision = activeAmeritasRevision();

  const state = context.residentState;
  const selectedCarrier = context.config[productKey].carrierSelected;

  const selectedCoverage = context.config[productKey].coverageSelected;
  let selectedCoverageDisplay = CoverageDisplay[selectedCoverage];
  if (
    selectedCoverage === CoverageType.INDIVIDUAL_CHILDREN &&
    selectedCarrier === CarrierKey.AMR
  ) {
    selectedCoverageDisplay = CoverageDisplay.AMR_EC;
  }

  const selectedOffering =
    context.getSelectedInsuranceOfferingByProduct(productKey);

  const level = selectedOffering?.display_label
    ? selectedOffering?.display_label
    : '';

  const cancerType = selectedOffering?.details?.cancer_only
    ? getQuoteGridSections(productKey, selectedCarrier)[0]
    : '';
  const selectionCallout = `${selectedCoverageDisplay} ${level} ${cancerType}`;

  const selectedDentalDisplayLabel =
    context.getSelectedInsuranceOfferingByProduct(InsuranceProductKey.DENTAL)
      ?.display_label || '';
  const selectedVisionDisplayLabel =
    context.getSelectedInsuranceOfferingByProduct(InsuranceProductKey.VISION)
      ?.display_label || '';

  let servicesList: Service[] = [];
  let hasOfferingsWithServices = false;

  if (selectedCarrier && selectedCarrier !== CarrierKey.LR) {
    servicesList = getServices(productKey, selectedCarrier);

    hasOfferingsWithServices =
      !!context.matrix?.products?.[productKey]?.carriers?.[selectedCarrier]
        ?.has_non_insurance;
  }

  const handleToggle = () => void onToggle(productKey);

  return (
    <>
      <div
        className="hover-pointer mt-6 text-secondary step-header d-flex align-items-center"
        onClick={handleToggle}
        onKeyDown={handleToggle}
        role="button"
        tabIndex={0}>
        <div className="d-flex align-items-center flex-wrap flex-lg-nowrap">
          <h2 className="m-0 font-weight-bold text-nowrap">{productName}</h2>
          {selectedOffering && selectionCallout && (
            <div className="text-nowrap">
              <span className="mx-4 d-none d-lg-inline">—</span>
              {selectionCallout}
            </div>
          )}
        </div>
        <div className="d-flex align-items-center w-100 mx-4">
          <div className="border-bottom border-dark w-100" />
        </div>

        <div className="ml-auto d-flex align-items-center">
          <div>
            <span>{isActive ? <HideToggleIcon /> : <ShowToggleIcon />}</span>
          </div>
        </div>
      </div>

      {selectedCarrier && state && isActive && (
        <div className="mb-6 mt-3">
          <div className="w-max-800">
            {getProductDescription(productKey, selectedCarrier, state)}
          </div>

          <PlanCard
            ameritasRevision={ameritasRevision}
            productKey={productKey}
          />

          <div>
            {getPlanHelper(
              productKey,
              selectedCarrier,
              state,
              ameritasRevision,
              selectedDentalDisplayLabel,
              selectedVisionDisplayLabel
            )}
          </div>

          {hasOfferingsWithServices && servicesList.length > 0 && (
            <ServicesCard
              productKey={productKey}
              services={servicesList}
              servicesName="Non-Insurance Services"
            />
          )}
          <div>{getServicesHelper(productKey, selectedCarrier)}</div>
        </div>
      )}
    </>
  );
}
