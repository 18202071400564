import type {
  Application,
  ApplicationFlowKey,
  ApplicationOrigin,
  CoverageType,
  DisclosureAcknowledgement,
  Egress,
  FlowProgress,
  InsuranceProductKey,
} from '../types';
import type {
  DjangoAPIResponse,
  DjangoErrorResponse,
  DjangoListResponse,
  DjangoNonFieldError,
  DjangoRetrieveResponse,
  DjangoSuccessResponse,
  ParsedDjangoErrorResponse,
} from '../utils/django-utils';
import {
  DjangoRequestConfiguration,
  NON_FIELD_ERROR_LABEL,
} from '../utils/django-utils';
import type { NoBody, QueryParams } from '../utils/network';
import type { RequestConfiguration } from '../utils/network';

/*
----- Requests -----
*/

type ApplicationCreationRequestData = {
  origin: ApplicationOrigin;
  flow_key: ApplicationFlowKey;
  organization_id?: string;
  quote_set_id?: string;
};

export function createApplication(
  body: ApplicationCreationRequestData
): DjangoRequestConfiguration<ApplicationCreationRequestData> {
  return new DjangoRequestConfiguration<ApplicationCreationRequestData>()
    .post()
    .body(body)
    .url('/api/applications/');
}

export function createDisclosureAcknowledgement(
  userId: string,
  body: Partial<DisclosureAcknowledgement>
): DjangoRequestConfiguration<Partial<DisclosureAcknowledgement>> {
  return new DjangoRequestConfiguration<Partial<DisclosureAcknowledgement>>()
    .post()
    .body(body)
    .url(`/api/users/${userId}/disclosure-acknowledgements/`);
}

export type OOCParams = {
  coverage_type: string;
  plan_code: string;
  zip_code: string;
};

export function createOOC({
  coverageType,
  planCode,
  product,
  zipCode,
}: {
  coverageType: CoverageType;
  planCode: string;
  product: InsuranceProductKey;
  zipCode: string;
}): DjangoRequestConfiguration<OOCParams, Blob> {
  return new DjangoRequestConfiguration<OOCParams, Blob>()
    .post()
    .body({
      coverage_type: coverageType,
      plan_code: planCode,
      zip_code: zipCode,
    })
    .url(`/api/carriers/ameritas/${product}/ooc/`);
}

export function deleteApplication(
  applicationId: string
): RequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .delete()
    .url(`/api/applications/${applicationId}/`);
}

export function finishApplication(
  applicationId: string
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .post()
    .url(`/api/applications/${applicationId}/finish/`);
}

export function getApplication(
  applicationId: string,
  query?: QueryParams
): ApplicationRequestConfiguration<NoBody, Application> {
  const configuration = new ApplicationRequestConfiguration<
    NoBody,
    Application
  >()
    .get()
    .url(`/api/applications/${applicationId}/`);

  if (query) {
    configuration.query(query);
  }

  return configuration;
}

export function getApplications(
  query?: QueryParams
): RequestConfiguration<NoBody> {
  const configuration = new DjangoRequestConfiguration<NoBody>()
    .get()
    .url('/api/applications/');

  if (query) {
    configuration.query(query);
  }

  return configuration;
}

export function getApplicationStats(): RequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url('/api/application-stats/');
}

export function getDisclosureAcknowledgements(
  userId: string,
  query?: QueryParams
): DjangoRequestConfiguration<NoBody> {
  const configuration = new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/users/${userId}/disclosure-acknowledgements/`);

  if (query) {
    configuration.query(query);
  }

  return configuration;
}

export function hasOOC(
  state: string,
  product: InsuranceProductKey
): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url(`/api/carriers/ameritas/${product}/ooc/${state}/check/`);
}

type Exactly<T, U> = T & Record<Exclude<keyof U, keyof T>, never>;

export function updateApplication<D extends Exactly<Partial<Egress>, D>>(
  applicationId: string,
  body: D
): ApplicationRequestConfiguration<D, Application> {
  return new ApplicationRequestConfiguration<D, Application>()
    .patch()
    .body(body)
    .url(`/api/applications/${applicationId}/`);
}

export function updateApplicationFlowProgress(
  applicationId: string,
  progress: Partial<FlowProgress>
): DjangoRequestConfiguration<Partial<FlowProgress>> {
  return new DjangoRequestConfiguration<Partial<FlowProgress>>()
    .patch()
    .body(progress)
    .url(`/api/applications/${applicationId}/progress/`);
}

/*
----- Configurations -----
*/

type EgressError = {
  field_name: 'egress';
  detail: Record<keyof Egress, string[] | Record<string, string[]>>;
};

export class ApplicationResponseParser {
  parse(
    response: DjangoAPIResponse<Application>,
    isSuccess: boolean
  ):
    | DjangoListResponse<Application>
    | DjangoRetrieveResponse<Application>
    | ParsedDjangoErrorResponse<Egress> {
    if (isSuccess) {
      return response as DjangoSuccessResponse<Application>;
    }

    const errorResponse = response as DjangoErrorResponse<Application>;

    const egressError = errorResponse.errors.find(
      (error) => error.field_name === 'egress'
    ) as EgressError;

    const nonFieldErrors = errorResponse.errors.filter(
      (error) => error.field_name === NON_FIELD_ERROR_LABEL
    ) as DjangoNonFieldError[];

    let errors: ParsedDjangoErrorResponse<Egress> = {};

    function serializeErrors(errs: Record<string, any>) {
      const entries = Object.entries(errs).map(([key, e]) => {
        let value = e;

        if (typeof e === 'object') {
          value = serializeErrors(e);
        }

        if (Array.isArray(e)) {
          value = e.join('\n');
        }

        return [key, value];
      });

      return Object.fromEntries(entries);
    }

    if (egressError) {
      errors = serializeErrors(egressError.detail);
    }

    if (nonFieldErrors.length > 0) {
      errors = {
        ...errors,
        [NON_FIELD_ERROR_LABEL]: nonFieldErrors.map((err) => err.detail),
      };
    }

    return errors;
  }
}

export class ApplicationRequestConfiguration<
  Body,
  Return = undefined
> extends DjangoRequestConfiguration<Body, Return> {
  public responseParser: ApplicationResponseParser =
    new ApplicationResponseParser();
}
