export function formatPhoneNumber(raw: string): string {
  const matches = raw
    .replace(/[^\d+]+/g, '')
    .match(/(\+\d{0,1})?(\d{0,3})(\d{0,3})(\d{0,4})/);

  if (!matches) {
    return raw;
  }

  const prefix = !matches[1] ? '' : !matches[2] ? matches[1] : `${matches[1]} `;
  const num = !matches[3]
    ? matches[2]
    : '(' +
      matches[2] +
      ') ' +
      matches[3] +
      (matches[4] ? '-' + matches[4] : '');

  return prefix + num;
}
