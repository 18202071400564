import React from 'react';
import type { ReactNode } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';

import { InsuranceProductKey } from '@liferaft/api/types';

import type { AmeritasRevision } from '@liferaft/core/content/products/ameritas';
import {
  AllAmeritasRevisions,
  ameritasContentSummaryOfBenefitsLink,
  getAmeritasStateContent,
} from '@liferaft/core/content/products/ameritas';
import { activeAmeritasRevision } from '@liferaft/core/content/products/ameritas/current';
import type { StateAbbr } from '@liferaft/core/content/states';
import { AllStates, stateName } from '@liferaft/core/content/states';

import {
  getPlanDescription,
  getPlanFacts,
  getPlanFactsFootnote,
  getPlanHelper,
  getProductDescription,
} from '@/pages/product-matrix/content';

function storedState<T>(key: string, defaultValue: T) {
  const [value, setValue] = React.useState<T>(defaultValue);
  React.useEffect(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      setValue(JSON.parse(storedValue) as T);
    }
  }, []);
  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value]);
  return [value, setValue] as const;
}

export function AmeritasContentPreview() {
  const [ameritasRevision, setAmeritasRevision] = storedState<AmeritasRevision>(
    'ameritasRevision',
    activeAmeritasRevision()
  );
  const [state, setState] = storedState<StateAbbr>('state', AllStates[0]);

  return (
    <Container>
      <h1 className="my-6">
        Dental and Vision Supplemental Insurance By State
      </h1>
      <h2>Select Content</h2>
      <label htmlFor="revision-selection">Select revision:</label>
      <select
        id="revision-select"
        onChange={(e) =>
          setAmeritasRevision(e.target.value as AmeritasRevision)
        }
        value={ameritasRevision}>
        {AllAmeritasRevisions.map((revision) => (
          <option key={revision} value={revision}>
            {revision}
          </option>
        ))}
      </select>

      <label htmlFor="state-selection">Select state:</label>
      <select
        id="state-select"
        onChange={(e) => setState(e.target.value as StateAbbr)}
        value={state}>
        {AllStates.map((state) => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </select>

      <hr />
      <StateDetails
        ameritasRevision={ameritasRevision}
        key={state}
        state={state}
      />
      {state === 'PA' && (
        <>
          <hr />
          <StateDetails
            ameritasRevision={ameritasRevision}
            state={state}
            sublabel="Indemnity"
            zipCode="15828"
          />
        </>
      )}
    </Container>
  );
}

type Props = {
  state: StateAbbr;
  ameritasRevision: AmeritasRevision;
  zipCode?: string;
  sublabel?: string;
};

export function StateDetails({
  state,
  ameritasRevision,
  zipCode,
  sublabel,
}: Props) {
  const ameritasContent = getAmeritasStateContent(state, ameritasRevision);

  const columns: ReactNode[] = [];
  for (const product of [
    InsuranceProductKey.DENTAL,
    InsuranceProductKey.VISION,
  ]) {
    if (!ameritasContent.offersProduct[product]) {
      columns.push(
        <Col>
          <h3>{product.toUpperCase()}</h3>
          NOT OFFERED
        </Col>
      );
      continue;
    }
    const planFacts = getPlanFacts(
      product,
      'AMR',
      state,
      zipCode || '00001',
      ameritasRevision
    );
    const benefitsLink = ameritasContentSummaryOfBenefitsLink(
      ameritasContent,
      product
    );
    columns.push(
      <Col>
        <h3>{product.toUpperCase()}</h3>
        {getProductDescription(product, 'AMR', state)}
        {getPlanDescription(product, 'AMR', state)}
        <Table className="table-sm table-bordered">
          <tbody className="font-size-sm">
            {planFacts.length > 0 &&
              planFacts.map((element, i) => {
                return (
                  <tr key={i}>
                    <td>{element.topic}</td>
                    <td>{element.description}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <p className="font-size-sm mb-0">
          {getPlanFactsFootnote(
            product,
            'AMR',
            state,
            zipCode || '00001',
            ameritasRevision
          )}
        </p>
        {product === InsuranceProductKey.DENTAL && (
          <>
            <div>
              <h4>Dental Limitations</h4>
              {ameritasContent.dentalLimitations}
            </div>
            <hr />
          </>
        )}
        {/* TODO: washington display labels */}
        <p className="font-size-sm mb-0">
          {getPlanHelper(product, 'AMR', state, ameritasRevision, '', '')}
        </p>
        <p className="pt-2">
          <a href={benefitsLink} rel="noreferrer" target="_blank">
            {benefitsLink.replace(/\/(?!\/)/g, '/ ')}
          </a>
        </p>
      </Col>
    );
  }
  let header = `${stateName(state)} - ${state}`;
  if (zipCode) {
    header += `:${zipCode}`;
  }
  if (sublabel) {
    header += ` (${sublabel})`;
  }
  return (
    <>
      <h2>{header}</h2>
      <Row>{columns}</Row>
    </>
  );
}
