import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

export const NAME_ANTIPATTERN = /[^A-Za-z-' ]/g;

export type NameData = {
  firstName: string;
  middleName?: string;
  lastName: string;
};

export type NameErrors = {
  firstName?: string;
  lastName?: string;
  middleName?: string;
};

type Props = {
  errors?: NameErrors;
  required?: boolean;
  rowDisplay?: boolean;
  showFieldLabels?: boolean;
  showMiddle?: boolean;
  label?: string;
  labelClasses?: string;
  fieldLabelsPrefix?: string;
  fieldsetClasses?: string;
  value?: Partial<NameData>;
  onChange: (value: Partial<NameData>) => void;
};

export function NameInput({
  errors,
  required = true,
  rowDisplay = false,
  showFieldLabels = false,
  showMiddle = false,
  label,
  labelClasses = '',
  fieldLabelsPrefix,
  fieldsetClasses,
  value,
  onChange,
}: Props) {
  const handleFirstChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validInput = event.target.value.replace(NAME_ANTIPATTERN, '');
    onChange({ ...value, firstName: validInput });
  };

  const handleMiddleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validInput = event.target.value.replace(NAME_ANTIPATTERN, '');
    onChange({ ...value, middleName: validInput });
  };

  const handleLastChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validInput = event.target.value.replace(NAME_ANTIPATTERN, '');
    onChange({ ...value, lastName: validInput });
  };

  return rowDisplay ? (
    <fieldset className={fieldsetClasses} data-testid="name-fieldset">
      {label && (
        <legend className="col-form-label font-weight-bold">{label}</legend>
      )}
      <Row>
        <Col md={showMiddle ? 4 : 6}>
          <Form.Group className="form-group">
            {showFieldLabels && (
              <Form.Label className={labelClasses}>
                {fieldLabelsPrefix} First Name
              </Form.Label>
            )}
            <Form.Control
              data-testid="first-name-input"
              id="first-name"
              isInvalid={!!errors?.firstName}
              name="firstName"
              onChange={handleFirstChange}
              pattern={"[a-zA-Z-' ]+"}
              placeholder="First Name"
              required={required}
              type="text"
              value={value?.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.firstName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {showMiddle && (
          <Col md={showMiddle ? 4 : 6}>
            <Form.Group className="form-group">
              {showFieldLabels && (
                <Form.Label className={labelClasses}>
                  {fieldLabelsPrefix} Middle Name (optional)
                </Form.Label>
              )}
              <Form.Control
                data-testid="middle-name-input"
                id="middle-name"
                isInvalid={!!errors?.middleName}
                name="middleName"
                onChange={handleMiddleChange}
                pattern={"[a-zA-Z-' ]+"}
                placeholder="Middle Name (optional)"
                type="text"
                value={value?.middleName}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.middleName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        )}
        <Col md={showMiddle ? 4 : 6}>
          <Form.Group>
            {showFieldLabels && (
              <Form.Label className={labelClasses}>
                {fieldLabelsPrefix} Last Name
              </Form.Label>
            )}
            <Form.Control
              data-testid="last-name-input"
              id="last-name"
              isInvalid={!!errors?.lastName}
              name="lastName"
              onChange={handleLastChange}
              pattern={"[a-zA-Z-' ]+"}
              placeholder="Last Name"
              required={required}
              type="text"
              value={value?.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.lastName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </fieldset>
  ) : (
    <fieldset className={fieldsetClasses} data-testid="name-fieldset">
      {label && (
        <legend className="col-form-label font-weight-bold">{label}</legend>
      )}
      <Form.Group className="form-group">
        {showFieldLabels && (
          <Form.Label className={labelClasses}>
            {fieldLabelsPrefix} First Name
          </Form.Label>
        )}
        <Form.Control
          data-testid="first-name-input"
          id="first-name"
          isInvalid={!!errors?.firstName}
          name="firstName"
          onChange={handleFirstChange}
          pattern={"[a-zA-Z-' ]+"}
          placeholder="First Name"
          required={required}
          type="text"
          value={value?.firstName}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.firstName}
        </Form.Control.Feedback>
      </Form.Group>
      {showMiddle && (
        <Form.Group className="form-group">
          {showFieldLabels && (
            <Form.Label className={labelClasses}>
              {fieldLabelsPrefix} Middle Name (optional)
            </Form.Label>
          )}
          <Form.Control
            data-testid="middle-name-input"
            id="middle-name"
            isInvalid={!!errors?.middleName}
            name="middleName"
            onChange={handleMiddleChange}
            pattern={"[a-zA-Z-' ]+"}
            placeholder="Middle Name (optional)"
            type="text"
            value={value?.middleName}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.middleName}
          </Form.Control.Feedback>
        </Form.Group>
      )}
      <Form.Group>
        {showFieldLabels && (
          <Form.Label className={labelClasses}>
            {fieldLabelsPrefix} Last Name
          </Form.Label>
        )}
        <Form.Control
          data-testid="last-name-input"
          id="last-name"
          isInvalid={!!errors?.lastName}
          name="lastName"
          onChange={handleLastChange}
          pattern={"[a-zA-Z-' ]+"}
          placeholder="Last Name"
          required={required}
          type="text"
          value={value?.lastName}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.lastName}
        </Form.Control.Feedback>
      </Form.Group>
    </fieldset>
  );
}
