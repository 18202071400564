import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    fraudNotice: (
      <p>
        It is a crime to knowingly provide false, incomplete or misleading
        information to an insurance company for the purpose of defrauding the
        company. Penalties may include imprisonment, fines, or a denial of
        insurance benefits.
      </p>
    ),
    planFacts: {
      dental: new Map([
        ['Value Plan', null],
        ['Lite Plan', null],
        ['Access Plan', null],
        [
          'Boost Plan',
          <p key="description">
            Type 1: Exams, Cleanings, Fluoride (under age 16), Sealants, Space
            Maintainers, Bitewing X-rays
            <br />
            Type 2: Fillings, Simple Extractions
            <br />
            Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
            Crowns, Bridges, Dentures, Implants, Teeth Whitening
          </p>,
        ],
        ['Total Plan', null],
        [
          'Complete Plan',
          <p key="description">
            Type 1: Exams, Cleanings, Bitewing X-rays
            <br />
            Type 2: Fillings, Simple Extractions
            <br />
            Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
            Crowns, Bridges, Dentures, Implants
          </p>,
        ],
      ]),
    },
  },
};

export default stateModule;
