import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
    },
    fraudNotice: (
      <p>
        Any person who knowingly files a statement of claim containing any false
        or misleading information is subject to criminal and civil penalties.
      </p>
    ),
    planFacts: {
      dental: new Map([
        ['Access Plan In- and Out-of-Network Coinsurance', null],
        [
          'Lite Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/50/50 INN & 70/50/50 OON
            <br />
            Year 2 - 100/80/50 INN & 70/50/50 OON
          </>,
        ],
        [
          'Boost Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/65/50 INN & 80/50/50 OON
            <br />
            Year 2 - 100/80/50 INN & 80/60/50 OON
          </>,
        ],
        [
          'Complete Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/80/50 INN & 80/70/50 OON
            <br />
            Year 2 - 100/90/50 INN & 80/80/50 OON
          </>,
        ],
      ]),
    },
    planFactsFootnote: {
      dental: (
        <>
          Claim allowance (PPO): If you visit a network provider, payments are
          based on the dentist's contracted fees (MAC/maximum allowable charge),
          which may result in lower out-of-pocket costs. If you visit an
          out-of-network dentist, covered benefits are paid at the 80th
          percentile of usual and customary (80th U&C) charges. This means we
          expect 8 out of 10 charges from dental providers to be within the
          amount we'll pay for a covered procedure. You pay the difference
          between what the plan pays and the dentist's actual charge.{' '}
          <a
            href="https://dentalnetwork.ameritas.com/classic"
            rel="noreferrer"
            target="_blank">
            Find a Classic (PPO) network provider near you.
          </a>
        </>
      ),
    },
  },
};

export default stateModule;
