import type { InProgressApplication } from '@liferaft/api/types';

import type { NextParams } from '@liferaft/core/utils/flow';
import { Flow } from '@liferaft/core/utils/flow';

import {
  AIGDisclaimer,
  AIGDisclosures,
  Alias,
  AmeritasDisclaimer,
  AmeritasDisclosures,
  Confirm,
  ContactInfo,
  CreateAccount,
  Dependents,
  LibertyDisclaimer,
  PaymentInfo,
  PlanSummary,
  ProductMatrix,
  RatingData,
  Submit,
} from '@/flows/steps';

export const BrokerFlow = new Flow<InProgressApplication>([
  Alias,
  RatingData,
  ProductMatrix,
  PlanSummary,
  CreateAccount,
  ContactInfo,
  Dependents,
  PaymentInfo,
  Confirm,
  AIGDisclosures,
  AIGDisclaimer,
  LibertyDisclaimer,
  AmeritasDisclosures,
  AmeritasDisclaimer,
  Submit,
]);

export const ConsumerFlow = new Flow<InProgressApplication>([
  RatingData,
  ProductMatrix,
  PlanSummary,
  CreateAccount,
  ContactInfo,
  Dependents,
  PaymentInfo,
  Confirm,
  AIGDisclosures,
  AIGDisclaimer,
  LibertyDisclaimer,
  AmeritasDisclosures,
  AmeritasDisclaimer,
  Submit,
]);

export const UpsellFlow = new Flow<InProgressApplication>([
  ProductMatrix,
  PlanSummary.setNext(({ state }: NextParams<InProgressApplication>) => {
    if (Dependents.isRequired(state)) {
      return Dependents.isFulfilled(state) ? Confirm.path : Dependents.path;
    } else {
      return Confirm.path;
    }
  }),
  Dependents,
  Confirm,
  AIGDisclosures,
  AIGDisclaimer,
  LibertyDisclaimer,
  AmeritasDisclosures,
  AmeritasDisclaimer,
  Submit,
]);

export const QuotingToolFlow = new Flow<InProgressApplication>([
  RatingData,
  ProductMatrix,
  PlanSummary,
  CreateAccount,
  ContactInfo,
  Dependents,
  PaymentInfo,
  Confirm,
  AIGDisclosures,
  AIGDisclaimer,
  LibertyDisclaimer,
  AmeritasDisclosures,
  AmeritasDisclaimer,
  Submit,
]);

export const GroupBenefitFlow = new Flow<InProgressApplication>([
  RatingData,
  ProductMatrix,
  PlanSummary,
  CreateAccount,
  ContactInfo,
  Dependents,
  PaymentInfo,
  Confirm,
  AIGDisclosures,
  AIGDisclaimer,
  LibertyDisclaimer,
  AmeritasDisclosures,
  AmeritasDisclaimer,
  Submit,
]);
