import React from 'react';

import type { Offering } from '@liferaft/api/types';
import { InsuranceProductKey } from '@liferaft/api/types';

import { ReactComponent as CommunicationCallIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Communication/Call#1.svg';
import { ReactComponent as CustomDoctorIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Custom/doctor.svg';
import { getAmeritasStateContent } from '@liferaft/core/content/products/ameritas';
import type { AmeritasRevision } from '@liferaft/core/content/products/ameritas';
import { activeAmeritasRevision } from '@liferaft/core/content/products/ameritas/current';

import { DJANGO_API_URL, TOLL_FREE_NUMBER } from '@/environment';
import { SpouseTerminology } from '@/utils/data-shaping';

export function getProductDescription(
  product: string,
  carrier: string,
  residentialState: string
): React.ReactNode {
  const productDescription: Record<string, Record<string, React.ReactNode>> = {
    'accidental-medical-expense': {
      LIB: (
        <p>
          Accident medical expense (AME) insurance provides cash reimbursements
          for medical expenses you or a family member may incur due to an
          accident. Ideal for paying the costs that your health insurance does
          not fully cover, example expenses from accidents include hospital and
          ICU room costs, ER visits, imaging services such as X-rays and MRIs,
          physician services including a second opinion, laboratory tests,
          ambulance services, outpatient nursing care, and prescription
          medications.
        </p>
      ),
      AIG: null,
    },
    'critical-illness': {
      LIB: (
        <p>
          Be prepared for a life-changing diagnosis with Critical Supplement
          Insurance. Critical illness insurance pays a cash benefit based on the
          diagnosis you, your covered spouse or covered children receive. The
          flexibility of cash benefits means you can control how to use the
          benefits, including covering medical expenses, seeking a second
          opinion, traveling for care, or just managing day-to-day expenses.
          These guaranteed-issue plans provide coverage for critical illness
          diagnoses and can remain in force up to the insured's 70th birthday.
        </p>
      ),
      AIG: (
        <>
          <p>
            Be prepared for a life-changing diagnosis with Critical Supplement.
            The Cancer and CancerPlus options below are offered through an
            Accidental Death Plus Plan, providing cash benefits in the event you
            are diagnosed with a serious illness like cancer, heart attack or
            stroke, depending on your coverage selection.
          </p>
          <p>
            These guaranteed-issue plans provide coverage up to your 70th
            birthday and automatically include an accidental death and
            dismemberment (AD&D) benefit as part of the coverage. Accidental
            Death Plus Cancer and Cancer Plus plans do not require smoker
            status.
          </p>
        </>
      ),
    },
    'hospital-indemnity': {
      LIB: (
        <p>
          Be prepared with hospital indemnity insurance that helps cover your
          out-of-pocket expenses due to hospital stays. This insurance pays cash
          benefits that you can use to help pay for medical bills, childcare, or
          any other expenses.
        </p>
      ),
      AIG: (
        <p>
          Be prepared with accident and sickness insurance that helps cover your
          out-of-pocket expenses due to covered hospital stays. This insurance
          pays cash benefits that you can use to help pay for medical bills,
          childcare, or any other expenses you may have.{' '}
          <a
            href={`${DJANGO_API_URL}/static/aig/tnc/hospital_indemnity/${residentialState}.pdf`}
            rel="noreferrer"
            target="_blank">
            (Terms and Conditions)
          </a>
        </p>
      ),
    },
    dental: {
      AMR: (
        <p>
          Dental plans provide coverage for dental expenses you or a family
          member may have, including preventive services such as dental exams
          and cleanings, basic services such as fillings, and major services
          such as oral surgery.
        </p>
      ),
    },
    vision: {
      AMR: (
        <p>
          Vision plans provide coverage for eye health expenses you or a family
          member may have, including eye exams and fit and follow-up exams for
          contacts.
        </p>
      ),
    },
  };

  return productDescription[product][carrier];
}

export function getPlanDescription(
  product: string,
  carrier: string,
  residentialState: string
): React.ReactNode {
  const ameritasRevision = activeAmeritasRevision();
  const ameritasContent = getAmeritasStateContent(
    residentialState,
    ameritasRevision
  );

  const coverageDescription: Record<string, Record<string, React.ReactNode>> = {
    'accidental-medical-expense': {
      LIB: (
        <p>
          These AME plans pay out cash benefits for any leftover expenses you
          may have after using your health insurance for an accident. A
          deductible may apply before reimbursements can be paid. These
          guaranteed-issue plans have no termination age and automatically
          include accidental death and dismemberment (AD&D) coverage for you and
          any covered family members. AD&D covers the loss of life, limbs,
          speech, sight or hearing.
        </p>
      ),
      AIG: null,
    },
    'critical-illness': {
      LIB: (
        <>
          <p>
            Critical illness plans cover a wider set of conditions, including
            cancer, heart attack, stroke, benign brain tumor, and major organ
            failure, paying 100% of the benefit face amount. Non-invasive cancer
            and coronary bypass surgery pay 25% of the benefit face amount.
          </p>
          <p>
            A health screening benefit is also included in a critical illness
            plan, where you can be reimbursed up to $50 per policy year for a
            health screening. Certain childhood conditions are also covered. The{' '}
            <a
              href={`${DJANGO_API_URL}/static/apl/docs/benefits/apl_CI_benefits.pdf`}
              rel="noreferrer"
              target="_blank">
              Summary of Benefits
            </a>{' '}
            provides more details on the diagnoses, conditions and benefits that
            are offered.
          </p>
        </>
      ),
      AIG: (
        <p>
          CancerPlus plans add additional illnesses to your coverage benefit. In
          addition to the cancers covered in the Cancer option, CancerPlus also
          includes cardiovascular diagnoses such as heart attack, stroke, and
          coronary bypass surgery. Once a CancerPlus plan benefit has become
          payable to an Insured Person for any covered cancer or illness, no
          CancerPlus plan benefit is payable to that Insured Person for any
          other cancer or covered illness. More details can be found in the{' '}
          <a
            href={`${DJANGO_API_URL}/static/aig/tnc/critical_illness/${residentialState}.pdf`}
            rel="noreferrer"
            target="_blank">
            Terms and Conditions
          </a>
          .
        </p>
      ),
    },
    'critical-illness-cancer': {
      LIB: null,
      AIG: (
        <p>
          Cancer plans pay benefits if you are diagnosed with a covered cancer.
          100% of benefits are paid for malignant tumors while partial benefits
          are paid for earlier stages of cancer. Once a Cancer plan benefit has
          become payable to an Insured Person for any covered cancer, no Cancer
          plan benefit is payable to that Insured Person for any other cancer.
          See the{' '}
          <a
            href={`${DJANGO_API_URL}/static/aig/tnc/critical_illness/${residentialState}.pdf`}
            rel="noreferrer"
            target="_blank">
            Terms and Conditions
          </a>{' '}
          for more details.
        </p>
      ),
    },
    'hospital-indemnity': {
      LIB: (
        <>
          <p>
            This plan provides coverage for individuals who are confined to a
            hospital, ICU or ER due to an injury or illness. Coverage is
            effective immediately for hospital stays due to an injury suffered
            after your coverage effective date. Hospital stays as a result of an
            illness have a 30-day waiting period. Coverage for a hospital stay
            due to childbirth is subject to pre-existing condition limitations,
            however complications of pregnancy will be covered as any other
            illness.
          </p>
          <p>
            Benefits are paid for admission to the hospital or ICU. For hospital
            stays, a 31-day maximum applies per policy year. For ICU stays, a
            15-day maximum applies per policy year. Benefits for ER confinement
            are limited to 1 day per policy year, with no admission benefit.
          </p>
        </>
      ),
      AIG: (
        <p>
          These guaranteed-issue plans provide coverage up to your 65th birthday
          and apply to unexpected hospital and ICU stays due to a covered
          accident or sickness. Approved claims will pay a daily benefit amount
          that is based on the plan type you choose below. This insurance
          product does not require smoker status.
        </p>
      ),
    },
    dental: {
      AMR: ameritasContent.planDescriptions.dental,
    },
    vision: {
      AMR: ameritasContent.planDescriptions.vision,
    },
  };

  return coverageDescription[product][carrier];
}

export type PlanFact = {
  topic: string;
  description: React.ReactNode;
};

export function getPlanFacts(
  product: string,
  carrier: string,
  residentialState: string,
  zipCode: string,
  ameritasRevision: AmeritasRevision
): PlanFact[] {
  const ameritasContent = getAmeritasStateContent(
    residentialState,
    ameritasRevision,
    zipCode
  );
  const visionFacts: PlanFact[] = Array.from(ameritasContent.planFacts.vision)
    .filter((entry) => entry[1] !== null)
    .map(([topic, description]) => ({
      topic,
      description,
    }));

  const dentalFacts = Array.from(ameritasContent.planFacts.dental)
    .filter((entry) => entry[1] !== null)
    .map(([topic, description]) => ({ topic, description }));

  const planFacts: Record<string, Record<string, PlanFact[]>> = {
    'accidental-medical-expense': {
      LIB: [
        {
          topic: 'Coverage Ages',
          description: (
            <>
              Insurance can be issued to individuals 18 years old and older.
              There is no termination age for benefits.
            </>
          ),
        },
        {
          topic: 'AME Coverage',
          description: (
            <>
              A benefit will be paid if a covered individual suffers an injury
              and requires a covered medical treatment. AME benefits are paid
              after any deductible has been satisfied and only when expenses
              incurred are in excess of amounts payable by any Other Insurance
              whether or not a claim as been made. Benefit amounts are based on
              the <b>usual and customary</b> charges up to a benefit maximum.
              See{' '}
              <a
                href={`${DJANGO_API_URL}/static/nfc/docs/benefits/nfc_AME_benefits.pdf`}
                rel="noreferrer"
                target="_blank">
                Summary of Benefits
              </a>{' '}
              for details.
            </>
          ),
        },
        {
          topic: 'AME Deductible',
          description: (
            <>A deductible of $250 applies to each covered injury.</>
          ),
        },
        {
          topic: 'AD&D Family Payout',
          description: (
            <>
              Payout for a spouse is 100% of the primary insured's face amount.
              Payout for a dependent is 50% of the primary insured face amount.
            </>
          ),
        },
        {
          topic: '24-Hour Coverage',
          description: (
            <>
              There is 24-hour business and pleasure coverage. You will be
              covered if you suffer a loss that occurs at any time while insured
              by this Policy, subject to applicable conditions and exclusions.
            </>
          ),
        },
      ],
      AIG: [],
    },
    'critical-illness': {
      LIB: [
        {
          topic: 'Coverage Ages',
          description: (
            <>
              Insurance can be issued to individuals 18-69 years old. Benefits
              terminate on your 70th birthday.
            </>
          ),
        },
        {
          topic: 'Critical Illness Coverage',
          description: (
            <>
              You must be covered under the plan you select for at least 30 days
              to receive benefits for a critical illness diagnosis.
            </>
          ),
        },
        {
          topic: 'Covered Conditions and Procedures',
          description: (
            <>
              Cancer (invasive, non-invasive and recurrence benefit levels),
              heart attack, stroke, benign brain tumor, major organ failure and
              coronary artery bypass surgery are covered. Also included are
              benefits for health screenings and certain childhood conditions.
              See the{' '}
              <a
                href={`${DJANGO_API_URL}/static/apl/docs/benefits/apl_CI_benefits.pdf`}
                rel="noreferrer"
                target="_blank">
                Summary of Benefits
              </a>{' '}
              for details.
            </>
          ),
        },
        {
          topic: 'Pre-Existing Conditions',
          description: (
            <>
              Pre-Existing Condition means: Any disease or physical condition
              for which a Covered Person received medical advice, Diagnosis, or
              Treatment in the 6 months prior to: <br />
              <ol>
                <li>
                  the date the Covered Person's coverage was effective under the
                  Policy; or
                </li>
                <li>
                  the date of any increase in benefit amounts or the addition of
                  any benefit under the Policy.
                </li>
              </ol>
              Benefits will not be paid for any Critical Illness that results
              from, or is caused or contributed to by, a Pre-Existing Condition
              until 6 months after a Covered Person is continuously covered
              under the Policy. A Pre-Existing Condition Limitation of 6 months
              will also apply to any benefit amount increase or the addition of
              any benefit under the Policy. This limitation does not apply to
              newborn children. In the event coverage provided by the Policy
              replaces an existing critical illness policy issued by Us, credit
              will be given for the time the Covered Person was covered under
              the replaced coverage.
            </>
          ),
        },
      ],
      AIG: [
        {
          topic: 'Coverage Ages',
          description: (
            <>
              Insurance can be issued to individuals 18-60 years old. Benefits
              terminate at age 70.
            </>
          ),
        },
        {
          topic: 'Covered Conditions and Procedures',
          description: (
            <>
              Cancer (full and partial), heart attack, stroke, and coronary
              artery bypass surgery are covered. See the{' '}
              <a
                href={`${DJANGO_API_URL}/static/aig/tnc/critical_illness/${residentialState}.pdf`}>
                Terms and Conditions
              </a>{' '}
              for details.
            </>
          ),
        },
        {
          topic: 'Coverage Start',
          description: (
            <>
              You must be covered under the plan you select for at least 30 days
              to receive benefits for a cardiovascular diagnosis, and for at
              least 60 days to receive benefits for a cancer diagnosis.
            </>
          ),
        },
        {
          topic: 'Benefit Details',
          description: (
            <>
              Once a CancerPlus plan benefit has become payable to an Insured
              Person for any covered cancer or illness, no CancerPlus plan
              benefit is payable to that Insured Person for any other cancer or
              covered illness.
            </>
          ),
        },
        {
          topic: 'Pre-Existing Conditions',
          description: (
            <>
              No Benefit is payable for any Pre-Existing Conditions, except
              where coverage under the Benefit has been in effect for a period
              of{' '}
              {['NC', 'SC', 'OK', 'DE', 'RI', 'SD', 'WV'].includes(
                residentialState
              )
                ? 12
                : 24}{' '}
              consecutive months following the Insured Person's effective date
              of coverage under the Benefit.
              <br />
              {residentialState === 'FL' && (
                <div>
                  "Pre-Existing Condition" means any sickness or other
                  condition: (a) that would cause an ordinarily prudent person
                  to seek medical advice, diagnosis, care or treatment during
                  the 24 months immediately preceding the effective date of the
                  Insured Person's coverage under the Benefit, or (b) for which
                  medical advice, diagnosis, care, or treatment was recommended
                  by a Physician or received from a Physician during the 24
                  months immediately preceding the effective date of the Insured
                  Person's coverage under the Benefit.
                </div>
              )}
              {residentialState === 'NC' && (
                <div>
                  "Pre-Existing Condition" means a condition for which medical
                  advice, diagnosis, care, or treatment was received or
                  recommended within the 12 month period immediately preceding
                  the effective date of the Insured Person's effective date of
                  coverage under the Benefit, that directly or indirectly causes
                  the condition to occur within the first 12 months from the
                  Insured Person's effective date of coverage under the Benefit.
                </div>
              )}
              {residentialState === 'SC' && (
                <div>
                  "Pre-Existing Condition" means a sickness suffered by an
                  Insured Person for which he or she sought or received medical
                  advice, consultation, investigation, or diagnosis, or for
                  which treatment was required or recommended by a Physician
                  during the 12 months immediately prior to the Insured Person's
                  effective date of coverage under the Benefit, that directly or
                  indirectly causes the condition to occur within the first 12
                  months from the Insured Person's effective date of coverage
                  under the Benefit.
                </div>
              )}
              {residentialState === 'DE' && (
                <div>
                  "Pre-Existing Condition" means the existence of symptoms which
                  would cause an ordinarily prudent person to seek diagnosis,
                  care or treatment within the 24 month period immediately
                  preceding the Insured Person's effective date of coverage
                  under the Benefit, or for which medical advice or treatment
                  was recommended by or received from a Physician within a 24
                  month period preceding the Insured Person's effective date of
                  coverage under the Benefit.
                </div>
              )}
              {residentialState === 'ND' && (
                <div>
                  "PRE-EXISTING CONDITION" MEANS A SICKNESS SUFFERED BY AN
                  INSURED PERSON FOR WHICH MEDICAL ADVICE OR TREATMENT WAS
                  RECEIVED DURING THE 24 MONTHS IMMEDIATELY PRIOR TO THE INSURED
                  PERSON'S EFFECTIVE DATE OF COVERAGE UNDER THE BENEFIT, THAT
                  DIRECTLY OR INDIRECTLY CAUSES THE CONDITION TO OCCUR WITHIN
                  THE FIRST 24 MONTHS FROM THE INSURED PERSON'S EFFECTIVE DATE
                  OF COVERAGE UNDER THE BENEFIT.
                </div>
              )}
              {residentialState === 'RI' && (
                <div>
                  "Pre-Existing Condition" means a existence of symptoms which
                  would cause an ordinarily prudent person to seek diagnosis,
                  care or treatment, or for which medical advice or treatment
                  was recommended by or received from a Physician during the 24
                  months immediately prior to the Insured Person's effective
                  date of coverage under this Benefit, that directly or
                  indirectly causes the condition to occur within the first 12
                  months from the Insured Person's effective date of coverage
                  under this Benefit.
                </div>
              )}
              {residentialState === 'SD' && (
                <div>
                  "Pre-Existing Condition" means a condition that would have
                  caused an ordinarily prudent person to seek medical advice,
                  diagnosis, care or treatment, or for which medical advice,
                  diagnosis, care or treatment was recommended or received
                  during the 12 months immediately prior to the Insured Person's
                  effective date of coverage under the Benefit, or a pregnancy
                  that existed on the Insured Person's effective date of
                  coverage under the Benefit.
                </div>
              )}
              {(residentialState === 'OK' || residentialState === 'WV') && (
                <div>
                  "Pre-Existing Condition" means a sickness suffered by an
                  Insured Person for which he or she sought or received medical
                  advice, consultation, investigation, or diagnosis, or for
                  which treatment was required or recommended by a Physician
                  during the 24 months immediately prior to the Insured Person's
                  effective date of coverage under the Benefit, that directly or
                  indirectly causes the condition to occur within the first 12
                  months from the Insured Person's effective date of coverage
                  under the Benefit.
                </div>
              )}
              {!['FL', 'NC', 'SC', 'DE', 'ND', 'RI', 'SD', 'OK', 'WV'].includes(
                residentialState
              ) && (
                <div>
                  "Pre-Existing Condition" means a sickness suffered by an
                  Insured Person for which he or she sought or received medical
                  advice, consultation, investigation, or diagnosis, or for
                  which treatment was required or recommended by a Physician
                  during the 24 months immediately prior to the Insured Person's
                  effective date of coverage under the Benefit, that directly or
                  indirectly causes the condition to occur within the first 24
                  months from the Insured Person's effective date of coverage
                  under the Benefit.
                </div>
              )}
            </>
          ),
        },
      ],
    },
    'critical-illness-cancer': {
      AIG: [
        {
          topic: 'Coverage Ages',
          description: (
            <>
              Insurance can be issued to individuals 18-60 years old. Benefits
              terminate at age 70.
            </>
          ),
        },
        {
          topic: 'Covered Conditions',
          description: (
            <>
              Cancer (full and partial) is covered. See the{' '}
              <a
                href={`${DJANGO_API_URL}/static/aig/tnc/critical_illness/${residentialState}.pdf`}>
                Terms and Conditions
              </a>{' '}
              for details.
            </>
          ),
        },
        {
          topic: 'Coverage Start',
          description: (
            <>
              You must be covered under the plan you select for at least 60 days
              to receive benefits for a cancer diagnosis.
            </>
          ),
        },
        {
          topic: 'Benefit Details',
          description: (
            <>
              Once a Cancer plan benefit has become payable to an Insured Person
              for any covered cancer, no Cancer plan benefit is payable to that
              Insured Person for any other cancer.
            </>
          ),
        },
        {
          topic: 'Pre-Existing Conditions',
          description: (
            <>
              No Benefit is payable for any Pre-Existing Conditions, except
              where coverage under the Benefit has been in effect for a period
              of{' '}
              {['NC', 'SC', 'OK', 'DE', 'RI', 'SD', 'WV'].includes(
                residentialState
              )
                ? 12
                : 24}{' '}
              consecutive months following the Insured Person's effective date
              of coverage under the Benefit.
              <br />
              {residentialState === 'FL' && (
                <div>
                  "Pre-Existing Condition" means any sickness or other
                  condition: (a) that would cause an ordinarily prudent person
                  to seek medical advice, diagnosis, care or treatment during
                  the 24 months immediately preceding the effective date of the
                  Insured Person's coverage under the Benefit, or (b) for which
                  medical advice, diagnosis, care, or treatment was recommended
                  by a Physician or received from a Physician during the 24
                  months immediately preceding the effective date of the Insured
                  Person's coverage under the Benefit.
                </div>
              )}
              {residentialState === 'NC' && (
                <div>
                  "Pre-Existing Condition" means a condition for which medical
                  advice, diagnosis, care, or treatment was received or
                  recommended within the 12 month period immediately preceding
                  the effective date of the Insured Person's effective date of
                  coverage under the Benefit, that directly or indirectly causes
                  the condition to occur within the first 12 months from the
                  Insured Person's effective date of coverage under the Benefit.
                </div>
              )}
              {residentialState === 'SC' && (
                <div>
                  "Pre-Existing Condition" means a sickness suffered by an
                  Insured Person for which he or she sought or received medical
                  advice, consultation, investigation, or diagnosis, or for
                  which treatment was required or recommended by a Physician
                  during the 12 months immediately prior to the Insured Person's
                  effective date of coverage under the Benefit, that directly or
                  indirectly causes the condition to occur within the first 12
                  months from the Insured Person's effective date of coverage
                  under the Benefit.
                </div>
              )}
              {residentialState === 'DE' && (
                <div>
                  "Pre-Existing Condition" means the existence of symptoms which
                  would cause an ordinarily prudent person to seek diagnosis,
                  care or treatment within the 24 month period immediately
                  preceding the Insured Person's effective date of coverage
                  under the Benefit, or for which medical advice or treatment
                  was recommended by or received from a Physician within a 24
                  month period preceding the Insured Person's effective date of
                  coverage under the Benefit.
                </div>
              )}
              {residentialState === 'ND' && (
                <div>
                  "PRE-EXISTING CONDITION" MEANS A SICKNESS SUFFERED BY AN
                  INSURED PERSON FOR WHICH MEDICAL ADVICE OR TREATMENT WAS
                  RECEIVED DURING THE 24 MONTHS IMMEDIATELY PRIOR TO THE INSURED
                  PERSON'S EFFECTIVE DATE OF COVERAGE UNDER THE BENEFIT, THAT
                  DIRECTLY OR INDIRECTLY CAUSES THE CONDITION TO OCCUR WITHIN
                  THE FIRST 24 MONTHS FROM THE INSURED PERSON'S EFFECTIVE DATE
                  OF COVERAGE UNDER THE BENEFIT.
                </div>
              )}
              {residentialState === 'RI' && (
                <div>
                  "Pre-Existing Condition" means a existence of symptoms which
                  would cause an ordinarily prudent person to seek diagnosis,
                  care or treatment, or for which medical advice or treatment
                  was recommended by or received from a Physician during the 24
                  months immediately prior to the Insured Person's effective
                  date of coverage under this Benefit, that directly or
                  indirectly causes the condition to occur within the first 12
                  months from the Insured Person's effective date of coverage
                  under this Benefit.
                </div>
              )}
              {residentialState === 'SD' && (
                <div>
                  "Pre-Existing Condition" means a condition that would have
                  caused an ordinarily prudent person to seek medical advice,
                  diagnosis, care or treatment, or for which medical advice,
                  diagnosis, care or treatment was recommended or received
                  during the 12 months immediately prior to the Insured Person's
                  effective date of coverage under the Benefit, or a pregnancy
                  that existed on the Insured Person's effective date of
                  coverage under the Benefit.
                </div>
              )}
              {(residentialState === 'OK' || residentialState === 'WV') && (
                <div>
                  "Pre-Existing Condition" means a sickness suffered by an
                  Insured Person for which he or she sought or received medical
                  advice, consultation, investigation, or diagnosis, or for
                  which treatment was required or recommended by a Physician
                  during the 24 months immediately prior to the Insured Person's
                  effective date of coverage under the Benefit, that directly or
                  indirectly causes the condition to occur within the first 12
                  months from the Insured Person's effective date of coverage
                  under the Benefit.
                </div>
              )}
              {!['FL', 'NC', 'SC', 'DE', 'ND', 'RI', 'SD', 'OK', 'WV'].includes(
                residentialState
              ) && (
                <div>
                  "Pre-Existing Condition" means a sickness suffered by an
                  Insured Person for which he or she sought or received medical
                  advice, consultation, investigation, or diagnosis, or for
                  which treatment was required or recommended by a Physician
                  during the 24 months immediately prior to the Insured Person's
                  effective date of coverage under the Benefit, that directly or
                  indirectly causes the condition to occur within the first 24
                  months from the Insured Person's effective date of coverage
                  under the Benefit.
                </div>
              )}
            </>
          ),
        },
      ],
      LIB: [],
    },
    'hospital-indemnity': {
      LIB: [
        {
          topic: 'Coverage Ages',
          description: (
            <>
              Insurance can be issued to individuals 18-64 years old. Benefits
              terminate at age 70.
            </>
          ),
        },
        {
          topic: 'Illness Coverage',
          description: (
            <>
              You must be covered under the plan you select for at least 30 days
              to receive benefits for an unexpected hospitalization due to an
              illness.
            </>
          ),
        },
        {
          topic: 'Hospital or ICU Admission',
          description: (
            <>
              You must seek care within 180 days of a covered accident. One
              benefit is payable per policy year.
            </>
          ),
        },
        {
          topic: 'Hospital or ICU Confinement',
          description: (
            <>
              You must seek care within 180 days of a covered accident. Benefits
              will be paid up to 31 days per policy year.
            </>
          ),
        },
        {
          topic: 'Emergency Room Coverage',
          description: <>Benefits will be paid up to 1 day per policy year.</>,
        },
        {
          topic: 'Childbirth Coverage',
          description: (
            <>
              Routine childbirth and complications of pregnancy will be covered
              as any other illness, subject to pre-existing condition
              limitations.
            </>
          ),
        },
        {
          topic: 'Pre-Existing Conditions',
          description: (
            <>
              Preexisting Condition means a Sickness for which, in the 12 months
              before a Covered Person becomes insured under this Certificate, or
              in the 12 months before the effective date of an increase in
              benefit amounts, medical advice, treatment or care was sought by
              the Covered Person, or, was recommended by, prescribed by or
              received from a Physician. <br />
              We will not pay any benefits under this Certificate for treatment
              of a Covered Person for a Preexisting Condition during the first
              12 months that such Covered Person is insured under this
              Certificate. We will not pay any increase in a benefit amount
              under this Certificate for treatment of a Covered Person for a
              Preexisting Condition during the first 12 months that such Covered
              Person is insured for the increase under this Certificate.
            </>
          ),
        },
      ],
      AIG: [
        {
          topic: 'Coverage Ages',
          description: (
            <>
              Insurance can be issued to individuals 18-60 years old. Benefits
              terminate at age 65.
            </>
          ),
        },
        {
          topic: 'Accident Coverage',
          description: (
            <>
              You must seek care within 90 days of a covered accident. Benefits
              paid for the 1st day of confinement, up to 365-days of required
              in-patient hospital stays for a covered accident.
            </>
          ),
        },
        {
          topic: 'Sickness Coverage',
          description: (
            <>
              You must be covered under the plan you choose for at least 30
              days. Benefits paid for the 1st day of confinement, up to 365-days
              of required in-patient hospital stays due to a covered sickness.
              Only one benefit is payable over your lifetime due to a covered
              sickness.
            </>
          ),
        },
        {
          topic: 'ICU Accident Coverage',
          description: (
            <>
              You must require ICU care within 48 hours of a covered accident.
              Benefits paid for the 1st day of confinement, up to a lifetime
              maximum of 365-days of required ICU stays due to a covered
              accident.
            </>
          ),
        },
        {
          topic: 'ICU Sickness Coverage',
          description: (
            <>
              You must be covered under the plan you choose for at least 30
              days. Benefits paid for the 1st day of confinement, up to a
              lifetime maximum of 365-days of required ICU stays due to a
              covered sickness.
            </>
          ),
        },
      ],
    },
    dental: {
      AMR: dentalFacts,
    },
    vision: {
      AMR: visionFacts,
    },
  };

  return planFacts[product][carrier];
}

export function getPlanFactsFootnote(
  product: string,
  carrier: string,
  residentialState: string,
  zipCode: string,
  ameritasRevision: AmeritasRevision
): React.ReactNode {
  const ameritasContent = getAmeritasStateContent(
    residentialState,
    ameritasRevision,
    zipCode
  );
  const planFactsFootnote: Record<string, Record<string, React.ReactNode>> = {
    'accidental-medical-expense': {
      LIB: null,
      AIG: null,
    },
    'critical-illness': {
      LIB: null,
      AIG: null,
    },
    'critical-illness-cancer': {
      LIB: null,
      AIG: null,
    },
    'hospital-indemnity': {
      LIB: null,
      AIG: null,
    },
    dental: {
      AMR: ameritasContent.planFactsFootnote.dental,
    },
    vision: {
      AMR: ameritasContent.planFactsFootnote.vision,
    },
  };

  return planFactsFootnote[product][carrier];
}

export function getPlanHelper(
  product: string,
  carrier: string,
  residentialState: string,
  ameritasRevision: AmeritasRevision,
  selectedDentalDisplayLabel: string,
  selectedVisionDisplayLabel: string
): React.ReactNode {
  const ameritasContent = getAmeritasStateContent(
    residentialState,
    ameritasRevision
  );
  const planHelper: Record<string, Record<string, React.ReactNode>> = {
    'accidental-medical-expense': {
      LIB: (
        <small>
          These plans are brought to you in partnership with EMA (Emergency
          Management Alliance), a Liferaft partner with a proven track record of
          supporting its members. More details can be found in the{' '}
          <a
            href={`${DJANGO_API_URL}/static/ema/docs/tnc/EMA_TNC2021.pdf`}
            rel="noreferrer"
            target="_blank">
            EMA Terms and Conditions
          </a>
          . The{' '}
          <a
            href={`${DJANGO_API_URL}/static/nfc/docs/benefits/nfc_AME_benefits.pdf`}
            rel="noreferrer"
            target="_blank">
            Summary of Benefits
          </a>{' '}
          will provide more details about this insurance.
        </small>
      ),
      AIG: null,
    },
    'critical-illness': {
      LIB: (
        <small>
          Critical Illness plans are brought to you in partnership with EMA
          (Emergency Management Alliance), a Liferaft partner with a proven
          track record of supporting its members. More details can be found in
          the{' '}
          <a
            href={`${DJANGO_API_URL}/static/ema/docs/tnc/EMA_TNC2021.pdf`}
            rel="noreferrer"
            target="_blank">
            EMA Terms and Conditions
          </a>
          {' and the '}
          <a
            href={`${DJANGO_API_URL}/static/apl/docs/benefits/apl_CI_benefits.pdf`}
            rel="noreferrer"
            target="_blank">
            Critical Illness Summary of Benefits
          </a>
          .
        </small>
      ),
      AIG: (
        <>
          <small>
            * Guaranteed issue only applies to customers ages 18-60 who reside
            in an eligible state.
          </small>
          <br />
          <small>
            Cancer and CancerPlus options are offered through an Accidental
            Death Plus Plan under an accident and sickness insurance policy
            which includes a benefit for an accidental death or dismemberment
            (loss of vital limbs or functions) occurring within 90 days of a
            covered accident. More details can be found in the{' '}
            <a
              href={`${DJANGO_API_URL}/static/aig/tnc/critical_illness/${residentialState}.pdf`}
              rel="noreferrer"
              target="_blank">
              Terms and Conditions
            </a>
            .
          </small>
          <br />
          <small>
            This is only a brief description of the coverage(s) available under
            policy series A30421NUFIC in AK, AL, DE, HI, IN, KY, LA, MS, NE, NV,
            NC, ND, OK, RI, SC, SD, WI, and WV, A30421NUFIC (Rev. 07/14) in FL.
            The Policy will contain reductions, limitations, exclusions, and
            termination provisions. Full details of the coverage are contained
            in the Policy. If there are any conflicts between this document and
            the Policy, the Policy shall govern. Insurance underwritten by
            National Union Fire Insurance Company of Pittsburgh, Pa. (National
            Union), a Pennsylvania insurance company, with its principal place
            of business at 1271 Ave of the Americas, 41st Floor, New York, NY
            10020. It is currently authorized to transact business in all states
            and the District of Columbia. NAIC No. 19445. National Union is an
            AIG company.
          </small>
        </>
      ),
    },
    'hospital-indemnity': {
      LIB: (
        <small>
          These plans are brought to you in partnership with EMA (Emergency
          Management Alliance), a Liferaft partner with a proven track record of
          supporting its members. More details can be found in the{' '}
          <a
            href={`${DJANGO_API_URL}/static/ema/docs/tnc/EMA_TNC2021.pdf`}
            rel="noreferrer"
            target="_blank">
            EMA Terms and Conditions
          </a>
          {' and the '}
          <a
            href={`${DJANGO_API_URL}/static/metlife/docs/benefits/metlife_HI_benefits.pdf`}
            rel="noreferrer"
            target="_blank">
            Hospital Indemnity Summary of Benefits
          </a>
          .
        </small>
      ),
      AIG: (
        <>
          <small>
            * Guaranteed issue only applies to customers ages 18-60 who reside
            in an eligible state.
          </small>
          <br />
          <small>
            These plans apply to unexpected hospital stays due to a covered
            accident or sickness. Please review the plan's{' '}
            <a
              href={`${DJANGO_API_URL}/static/aig/tnc/hospital_indemnity/${residentialState}.pdf`}
              rel="noreferrer"
              target="_blank">
              Terms and Conditions
            </a>{' '}
            for eligibility and full benefit details.
          </small>
          <br />
          <small>
            This is only a brief description of the coverage(s) available under
            policy series A30421NUFIC in AL, AK, DE, HI, IL, IN, KY, LA, MS, NE,
            NC, NV, ND, OK, RI, SD, SC, TX, WV, WI and WY, A30421NUFIC (Rev.
            07/14) in FL, A30421NUFIC (Rev. 10/14) in CA. The Policy will
            contain reductions, limitations, exclusions, and termination
            provisions. Full details of the coverage are contained in the
            Policy. If there are any conflicts between this document and the
            Policy, the Policy shall govern. Insurance underwritten by National
            Union Fire Insurance Company of Pittsburgh, Pa. (National Union), a
            Pennsylvania insurance company, with its principal place of business
            at 1271 Ave of the Americas, 41st Floor, New York, NY 10020. It is
            currently authorized to transact business in all states and the
            District of Columbia. NAIC No. 19445. National Union is an AIG
            company.
          </small>
        </>
      ),
    },
    dental: {
      AMR: ameritasContent.planHelper.dental(selectedDentalDisplayLabel),
    },
    vision: {
      AMR: ameritasContent.planHelper.vision(selectedVisionDisplayLabel),
    },
  };

  return planHelper[product][carrier];
}

export type Service = {
  icon: React.ReactNode;
  title: string;
  description: React.ReactNode;
};

export function getServices(product: string, carrier: string): Service[] {
  const services: Record<string, Record<string, Service[]>> = {
    'accidental-medical-expense': {
      LIB: [],
      AIG: [],
    },
    'critical-illness': {
      LIB: [],
      AIG: [],
    },
    'hospital-indemnity': {
      LIB: [],
      AIG: [
        {
          icon: <CommunicationCallIcon viewBox="0 0 24 24" />,
          title: '24/7 Nurse Hotline',
          description: (
            <>
              Access registered nurses anytime to answer questions, assess
              symptoms and provide recommendations.
            </>
          ),
        },
        {
          icon: <CustomDoctorIcon viewBox="0 0 32 32" />,
          title: 'Expert Medical Opinion',
          description: (
            <>
              A Personal Advisor is available to help you in confirming
              diagnoses, arranging expert second opinions and choosing treatment
              options. They can also assist you in managing medical records and
              securing appointments with top specialists.
            </>
          ),
        },
      ],
    },
    dental: {
      AMR: [],
    },
    vision: {
      AMR: [],
    },
  };

  return services[product][carrier];
}

export function getServicesHelper(
  product: string,
  carrier: string
): React.ReactNode {
  const servicesHelper: Record<string, Record<string, React.ReactNode>> = {
    'accidental-medical-expense': {
      LIB: null,
      AIG: null,
    },
    'critical-illness': {
      LIB: null,
      AIG: null,
    },
    'hospital-indemnity': {
      LIB: null,
      AIG: (
        <small>
          Non-insurance services are made available through Morefar Marketing,
          Inc.® (Agency name is shown as Morefar Insurance Agency in CA, under
          license number 0C91962 together with Morefar Marketing, Inc.,
          "Morefar") national producer number: 654243, with its principal place
          of business at 1271 Ave of the Americas FL 41, New York, NY
          10020-1304. Non-insurance services are provided through Careington
          International Corporation. Click{' '}
          <a href={`${DJANGO_API_URL}/static/aig/docs/non-insurance.pdf`}>
            this link
          </a>{' '}
          for details. You may opt to apply for just the non-insurance services
          shown. To learn more, please call {TOLL_FREE_NUMBER}.
        </small>
      ),
    },
    dental: {
      AMR: null,
    },
    vision: {
      AMR: null,
    },
  };

  return servicesHelper[product][carrier];
}

export function getQuoteGridSections(
  product: string,
  carrier: string
): string[] {
  const quoteGridSections: Record<string, Record<string, string[]>> = {
    'accidental-medical-expense': {
      LIB: [],
      AIG: [],
    },
    'critical-illness': {
      LIB: ['Cancer-Only', 'Critical Illness'],
      AIG: ['Cancer', 'CancerPlus'],
    },
    'hospital-indemnity': {
      LIB: [],
      AIG: [],
    },
    dental: {
      AMR: [],
    },
    vision: {
      AMR: [],
    },
  };

  return quoteGridSections[product][carrier];
}

function getVisionQuoteTableRowKeys(residentialState: string) {
  if (['MA', 'MT', 'RI', 'WA'].includes(residentialState)) {
    return {
      EE: ['exam', 'lenses', 'frames', 'contacts'],
      ES: ['exam', 'lenses', 'frames', 'contacts'],
      EC: ['exam', 'lenses', 'frames', 'contacts'],
      F: ['exam', 'lenses', 'frames', 'contacts'],
    };
  } else {
    return {
      EE: ['exam', 'materials'],
      ES: ['exam', 'materials'],
      EC: ['exam', 'materials'],
      F: ['exam', 'materials'],
    };
  }
}

export function getQuoteTableRowKeys(
  product: string,
  carrier: string,
  coverage: string,
  residentialState: string
): string[] {
  const quoteTableRows: Record<
    string,
    Record<string, Record<string, string[]>>
  > = {
    'accidental-medical-expense': {
      LIB: {
        EE: ['ame', 'add'],
        F: ['ame', 'add', 'add_child'],
      },
      AIG: {},
    },
    'critical-illness': {
      LIB: {
        EE: ['self'],
        ES: ['self', 'spouse'],
        EC: ['self', 'child'],
        F: ['self', 'spouse', 'child'],
      },
      AIG: {
        EE: ['self', 'add'],
        F: ['self', 'add'],
      },
    },
    'hospital-indemnity': {
      LIB: {
        EE: [
          'hospital_admission',
          'hospital_daily',
          'icu_admission',
          'icu_daily',
          'er',
        ],
        ES: [
          'hospital_admission',
          'hospital_daily',
          'icu_admission',
          'icu_daily',
          'er',
        ],
        EC: [
          'hospital_admission',
          'hospital_daily',
          'icu_admission',
          'icu_daily',
          'er',
        ],
        F: [
          'hospital_admission',
          'hospital_daily',
          'icu_admission',
          'icu_daily',
          'er',
        ],
      },
      AIG: {
        EE: ['hospital', 'icu'],
        F: ['hospital', 'icu'],
      },
    },
    dental: {
      AMR: {
        EE: [
          'max',
          'preventive',
          'basic',
          'major',
          'hearing',
          'lasik',
          'ortho',
        ],
        ES: [
          'max',
          'preventive',
          'basic',
          'major',
          'hearing',
          'lasik',
          'ortho',
        ],
        EC: [
          'max',
          'preventive',
          'basic',
          'major',
          'hearing',
          'lasik',
          'ortho',
        ],
        F: ['max', 'preventive', 'basic', 'major', 'hearing', 'lasik', 'ortho'],
      },
    },
    vision: {
      AMR: getVisionQuoteTableRowKeys(residentialState),
    },
  };

  return quoteTableRows[product][carrier][coverage];
}

export function getQuoteTableRowLabels(
  product: string,
  carrier: string,
  coverage: string,
  residentialState: string
): Record<string, string> {
  const spouseTerm = SpouseTerminology(carrier, residentialState, false);
  const quoteTableRowLabels: Record<
    string,
    Record<string, Record<string, Record<string, string>>>
  > = {
    'accidental-medical-expense': {
      LIB: {
        EE: {
          ame: 'Max AME for you',
          add: 'AD&D for you',
        },
        F: {
          ame: 'Max AME for each insured person',
          add: 'AD&D for you and your spouse',
          add_child: 'AD&D for each dependent child',
        },
      },
      AIG: {},
    },
    'critical-illness': {
      LIB: {
        EE: { self: 'For you' },
        ES: { self: 'For you', spouse: 'For your spouse' },
        EC: { self: 'For you', child: 'For each dependent child' },
        F: {
          self: 'For you',
          spouse: 'For your spouse',
          child: 'For each dependent child',
        },
      },
      AIG: {
        EE: { self: 'Cancer or CancerPlus Benefit', add: 'AD&D Benefit' },
        F: {
          self: `Cancer or CancerPlus Benefit for you, your ${spouseTerm}, and each dependent child`,
          add: `AD&D Benefit for you, your ${spouseTerm}, and each dependent child`,
        },
      },
    },
    'hospital-indemnity': {
      LIB: {
        EE: {
          hospital_admission: 'Per hospital admission',
          hospital_daily: 'Per day confined in hospital',
          icu_admission: 'Per ICU admission',
          icu_daily: 'Per day confined in ICU',
          er: 'Per day confined in ER',
        },
        ES: {
          hospital_admission: 'Per hospital admission',
          hospital_daily: 'Per day confined in hospital',
          icu_admission: 'Per ICU admission',
          icu_daily: 'Per day confined in ICU',
          er: 'Per day confined in ER',
        },
        EC: {
          hospital_admission: 'Per hospital admission',
          hospital_daily: 'Per day confined in hospital',
          icu_admission: 'Per ICU admission',
          icu_daily: 'Per day confined in ICU',
          er: 'Per day confined in ER',
        },
        F: {
          hospital_admission: 'Per hospital admission',
          hospital_daily: 'Per day confined in hospital',
          icu_admission: 'Per ICU admission',
          icu_daily: 'Per day confined in ICU',
          er: 'Per day confined in ER',
        },
      },
      AIG: {
        EE: { hospital: 'Per day in hospital', icu: 'Per day in ICU' },
        F: { hospital: 'Per day in hospital', icu: 'Per day in ICU' },
      },
    },
    dental: {
      AMR: {
        EE: {
          max: 'Max Benefit per Year',
          preventive: 'Preventive Services Benefit (Type 1)',
          basic: 'Basic Services Benefit (Type 2)',
          major: 'Major Services Benefit (Type 3)',
          hearing: 'Hearing',
          lasik: 'LASIK',
          ortho: 'Child Orthodontia',
        },
        ES: {
          max: 'Max Benefit per Year',
          preventive: 'Preventive Services Benefit (Type 1)',
          basic: 'Basic Services Benefit (Type 2)',
          major: 'Major Services Benefit (Type 3)',
          hearing: 'Hearing',
          lasik: 'LASIK',
          ortho: 'Child Orthodontia',
        },
        EC: {
          max: 'Max Benefit per Year',
          preventive: 'Preventive Services Benefit (Type 1)',
          basic: 'Basic Services Benefit (Type 2)',
          major: 'Major Services Benefit (Type 3)',
          hearing: 'Hearing',
          lasik: 'LASIK',
          ortho: 'Child Orthodontia',
        },
        F: {
          max: 'Max Benefit per Year',
          preventive: 'Preventive Services Benefit (Type 1)',
          basic: 'Basic Services Benefit (Type 2)',
          major: 'Major Services Benefit (Type 3)',
          hearing: 'Hearing',
          lasik: 'LASIK',
          ortho: 'Child Orthodontia',
        },
      },
    },
    vision: {
      AMR: {
        EE: {
          exam: 'Eye Exam Benefit',
          materials: 'Glasses OR Contacts Benefit',
          lenses: 'Glasses Lenses Benefit',
          frames: 'Glasses Frames Benefit',
          contacts: 'Contact Lenses Benefit (in lieu of glasses)',
        },
        ES: {
          exam: 'Eye Exam Benefit',
          materials: 'Glasses OR Contacts Benefit',
          lenses: 'Glasses Lenses Benefit',
          frames: 'Glasses Frames Benefit',
          contacts: 'Contact Lenses Benefit (in lieu of glasses)',
        },
        EC: {
          exam: 'Eye Exam Benefit',
          materials: 'Glasses OR Contacts Benefit',
          lenses: 'Glasses Lenses Benefit',
          frames: 'Glasses Frames Benefit',
          contacts: 'Contact Lenses Benefit (in lieu of glasses)',
        },
        F: {
          exam: 'Eye Exam Benefit',
          materials: 'Glasses OR Contacts Benefit',
          lenses: 'Glasses Lenses Benefit',
          frames: 'Glasses Frames Benefit',
          contacts: 'Contact Lenses Benefit (in lieu of glasses)',
        },
      },
    },
  };

  return quoteTableRowLabels[product][carrier][coverage];
}

export function getSummaryBenefitsList(
  product: string,
  quote: Offering,
  residentialState: string
): string[] {
  if (product === InsuranceProductKey.ACCIDENT) {
    return [
      `$${quote.details.benefits.ame.toLocaleString()} AME benefit maximum`,
      `$${quote.details.benefits.add.toLocaleString()} AD&D`,
    ];
  } else if (product === InsuranceProductKey.CRITICAL) {
    return [
      `$${quote.details.benefits.self.toLocaleString()} benefit face value`,
    ];
  } else if (product === InsuranceProductKey.HOSPITAL) {
    const hospitalBenefitsList: Record<string, string[]> = {
      LIB: [
        `$${quote.details.benefits.hospital_daily}/day for hospital confinement`,
        `$${quote.details.benefits.icu_daily}/day for ICU confinement`,
        `$${quote.details.benefits.er}/day for ER confinement`,
      ],
      AIG: [
        `$${quote.details.benefits.hospital}/day for hospital stays`,
        `$${quote.details.benefits.icu}/day for ICU stays`,
      ],
    };
    return hospitalBenefitsList[quote.carrier];
  } else if (product === InsuranceProductKey.DENTAL) {
    const dentalBenefits: string[] = [];
    const dentalBenefitKeys = getQuoteTableRowKeys(
      InsuranceProductKey.DENTAL,
      'AMR',
      'EE',
      residentialState
    );
    const dentalBenefitLabels = getQuoteTableRowLabels(
      InsuranceProductKey.DENTAL,
      'AMR',
      'EE',
      residentialState
    );
    dentalBenefitKeys.map((key) => {
      const benefit = quote.details.benefits[key] as string[];
      if (benefit.length) {
        const label = dentalBenefitLabels[key];
        dentalBenefits.push(label + ': ' + benefit.join(', '));
      }
    });
    return dentalBenefits;
  } else if (product === InsuranceProductKey.VISION) {
    const visionBenefits: string[] = [];
    const visionBenefitKeys = getQuoteTableRowKeys(
      InsuranceProductKey.VISION,
      'AMR',
      'EE',
      residentialState
    );
    const visionBenefitLabels = getQuoteTableRowLabels(
      InsuranceProductKey.VISION,
      'AMR',
      'EE',
      residentialState
    );
    visionBenefitKeys.map((key) => {
      const benefit = quote.details.benefits[key] as string[];
      if (benefit.length) {
        const label = visionBenefitLabels[key];
        visionBenefits.push(label + ': ' + benefit.join('; '));
      }
    });
    return visionBenefits;
  } else {
    return [];
  }
}

export function getSummaryFootnote(
  product: string,
  carrier: string,
  residentialState: string,
  ameritasRevision: AmeritasRevision,
  selectedDentalDisplayLabel: string
): React.ReactNode {
  const ameritasContent = getAmeritasStateContent(
    residentialState,
    ameritasRevision
  );
  const summaryBenefitsFootnote: Record<
    string,
    Record<string, React.ReactNode>
  > = {
    'accidental-medical-expense': {
      LIB: (
        <>
          <a
            href={`${DJANGO_API_URL}/static/ema/docs/tnc/EMA_TNC2021.pdf`}
            rel="noreferrer"
            target="_blank">
            EMA Terms and Conditions
          </a>
          {' and '}
          <a
            href={`${DJANGO_API_URL}/static/nfc/docs/benefits/nfc_AME_benefits.pdf`}
            rel="noreferrer"
            target="_blank">
            Summary of Benefits
          </a>
        </>
      ),
      AIG: null,
    },
    'critical-illness': {
      LIB: (
        <>
          <a
            href={`${DJANGO_API_URL}/static/ema/docs/tnc/EMA_TNC2021.pdf`}
            rel="noreferrer"
            target="_blank">
            EMA Terms and Conditions
          </a>
          {' and '}
          <a
            href={`${DJANGO_API_URL}/static/apl/docs/benefits/apl_CI_benefits.pdf`}
            rel="noreferrer"
            target="_blank">
            Summary of Benefits
          </a>
        </>
      ),
      AIG: (
        <a
          href={`${DJANGO_API_URL}/static/aig/tnc/critical_illness/${residentialState}.pdf`}
          rel="noreferrer"
          target="_blank">
          Terms and Conditions
        </a>
      ),
    },
    'hospital-indemnity': {
      LIB: (
        <>
          <a
            href={`${DJANGO_API_URL}/static/ema/docs/tnc/EMA_TNC2021.pdf`}
            rel="noreferrer"
            target="_blank">
            EMA Terms and Conditions
          </a>
          {' and '}
          <a
            href={`${DJANGO_API_URL}/static/metlife/docs/benefits/metlife_HI_benefits.pdf`}
            rel="noreferrer"
            target="_blank">
            Summary of Benefits
          </a>
        </>
      ),
      AIG: (
        <a
          className="font-size-sm"
          href={`${DJANGO_API_URL}/static/aig/tnc/hospital_indemnity/${residentialState}.pdf`}
          rel="noreferrer"
          target="_blank">
          Terms and Conditions
        </a>
      ),
    },
    dental: {
      AMR: ameritasContent.summaryFootnote.dental(selectedDentalDisplayLabel),
    },
    vision: {
      AMR: null,
    },
  };

  return summaryBenefitsFootnote[product][carrier];
}

export function getSummaryHospitalServicesFootnote(
  carrier: string
): React.ReactNode {
  const summaryServicesFootnote: Record<string, React.ReactNode> = {
    LIB: null,
    AIG: (
      <a
        className="font-size-sm"
        href={`${DJANGO_API_URL}/static/aig/docs/non-insurance.pdf`}
        rel="noreferrer"
        target="_blank">
        Description of Services
      </a>
    ),
  };

  return summaryServicesFootnote[carrier];
}
