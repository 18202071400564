import { DjangoRequestConfiguration } from '../utils/django-utils';
import type { NoBody } from '../utils/network';

export function getMoovAccessToken(): DjangoRequestConfiguration<NoBody> {
  return new DjangoRequestConfiguration<NoBody>()
    .get()
    .url('/api/transfer/moov/access-token/');
}

export type MoovAccessTokenParams = {
  account_id?: string;
};

export function createMoovAccessToken(
  accountId?: string
): DjangoRequestConfiguration<MoovAccessTokenParams> {
  return new DjangoRequestConfiguration<MoovAccessTokenParams>()
    .post()
    .body({ account_id: accountId })
    .url('/api/transfer/moov/access-token/');
}
