import { loadVGSCollect } from '@vgs/collect-js';
import React from 'react';
import { Form } from 'react-bootstrap';

import { useDebounce } from '../../hooks';
import './vgs-collect-form.scss';

export interface VGSForm {
  state: Record<string, any>;
  submit: (
    route: string,
    options?: Record<string, any>,
    callbackFn?: (status: any, response: any) => void,
    errorCallbackFn?: () => void
  ) => void;
  field: (id: string, options: any) => void;
  connectSatellite: (port: number) => void;
}

interface VGSCollect {
  init: (callback?: (state: any) => void) => VGSForm;
}

type FormProps = {
  children: (isSubmitting: boolean, form?: VGSForm) => React.ReactNode;
  environment: string;
  onStateChange?: (state: any) => void;
  onSubmit: (form: VGSForm) => (status: any, response: any) => Promise<any>;
  route: string;
  satellitePort?: number;
  setIsSubmitting?: (value: boolean) => void;
  vaultId: string;
} & React.PropsWithChildren<Record<string, unknown>>;

export const VGSCollectForm = React.forwardRef<HTMLFormElement, FormProps>(
  (
    {
      children,
      environment,
      onStateChange,
      onSubmit,
      route,
      satellitePort,
      setIsSubmitting,
      vaultId,
      ...passProps
    }: FormProps,
    ref
  ) => {
    const [collect, setCollect] = React.useState<VGSCollect>();
    const [form, setForm] = React.useState<VGSForm>();

    React.useEffect(() => {
      loadVGSCollect({
        environment: environment,
        vaultId: vaultId,
        version: '2.12.0',
      })
        .then((c) => setCollect(c as VGSCollect))
        .catch((e) => void console.log(e));
    }, []);

    React.useEffect(() => {
      if (collect) {
        const form = collect.init(onStateChange);
        setForm(form as VGSForm);
      }
    }, [collect]);

    React.useEffect(() => {
      if (form && satellitePort) {
        form.connectSatellite(satellitePort);
      }
    }, [form]);

    const [handleSubmit, isSubmitting] = useDebounce(
      (event: React.SyntheticEvent<EventTarget>) => {
        event.preventDefault();

        if (!form) {
          return;
        }

        const submit = onSubmit(form);

        let resolve: (value?: unknown) => void;
        const vgsPromise = new Promise((res) => (resolve = res));

        const onSuccess = async (status: any, response: any) => {
          await submit(status, response);
          resolve();
        };
        const onFailure = () => void resolve();

        form.submit(route, {}, onSuccess, onFailure);

        return vgsPromise;
      }
    );

    React.useEffect(() => {
      setIsSubmitting?.(isSubmitting);
    }, [isSubmitting]);

    return (
      <Form onSubmit={handleSubmit} ref={ref} {...passProps}>
        {children(isSubmitting, form)}
      </Form>
    );
  }
);

VGSCollectForm.displayName = 'VGSCollectForm';

type VGSCollectInputProps = {
  dataName: string;
  error?: string;
  fieldId: string;
  form: VGSForm | undefined;
  label: string;
  register: (form: VGSForm, dataName: string) => void;
};

export function VGSCollectInput({
  dataName,
  error,
  fieldId,
  form,
  label,
  register,
}: VGSCollectInputProps) {
  React.useEffect(() => {
    if (form) {
      register(form, dataName);
    }
  }, [form]);

  return (
    <Form.Group>
      <Form.Label htmlFor={fieldId}>{label}</Form.Label>
      <span className="form-field" id={fieldId} />
      <span className="vgs-error-message" data-name={dataName}>
        {error}
      </span>
    </Form.Group>
  );
}
