import React from 'react';

import type { StateModule } from '../types';

const stateModule: StateModule = {
  default: {
    isBaseState: {
      dental: false,
      vision: false,
    },
    stateNotice: (
      <p>
        NOTICE TO CONSUMERS: This is a limited benefits health plan. The
        benefits provided are supplemental to, and not a substitute for, major
        medical coverage, even in combination with other limited benefit plans.
        To apply for an individual or small-group major medical plan, please
        visit the website of the New Mexico Health Insurance Exchange at{' '}
        <a href="https://www.bewellnm.com" rel="noreferrer" target="_blank">
          www.bewellnm.com
        </a>{' '}
        or call 1-833-862-3935 (TTY: 711).
      </p>
    ),
    fraudNotice: (
      <p>
        Any person who knowingly presents a false or fraudulent claim for
        payment of a loss or benefit or knowingly presents false information in
        an application for insurance is guilty of a crime and may be subject to
        civil fines and criminal penalties. This is a limited benefits health
        plan. The benefits provided are supplemental to, and not a substitute
        for, major medical coverage, even in combination with other limited
        benefit plans. To apply for an individual or small-group major medical
        plan, please visit the website of the New Mexico Health Insurance
        Exchange at www.bewellnm.com or call 1-833-862-3935 (TTY: 711). This
        plan may contain limitations for replacement of missing teeth. Please
        see LIMITATIONS for details.
      </p>
    ),
    planDescriptions: {
      vision: (
        <p>
          This PrimeStar® Vision Plan helps protect your eye health and ensure
          that you have access to the eyeglasses or contacts you rely on. Your
          coverage begins next-day with no waiting periods and no enrollment
          fees. A deductible may apply for coverage; please see Coverage Quick
          Facts for more details.
        </p>
      ),
    },
    planHelper: {
      dental: () => (
        <>
          <small>
            Underwritten by Ameritas Life Insurance Corp. | 5900 O Street
            Lincoln, NE 68510
          </small>
          <br />
          <small>
            This is not a certificate of insurance or guarantee of coverage.
            Plan designs may not be available in all areas and are subject to
            individual state regulations. This information is provided by
            Ameritas Life Insurance Corp. (Ameritas Life). Dental and vision
            products (9000 NM Rev. 09-21 for Group and Indiv. 9000 NM Rev. 09-21
            for Individual) are issued by Ameritas Life.
          </small>
          <br />
          <small>
            Ameritas, the bison design and "fulfilling life" are service marks
            or registered service marks of Ameritas Life, affiliate Ameritas
            Holding Company or Ameritas Mutual Holding Company. © 2023 Ameritas
            Mutual Holding Company.
          </small>
        </>
      ),
      vision: () => (
        <>
          <small>
            Underwritten by Ameritas Life Insurance Corp. | 5900 O Street
            Lincoln, NE 68510
          </small>
          <br />
          <small>
            This is not a certificate of insurance or guarantee of coverage.
            Plan designs may not be available in all areas and are subject to
            individual state regulations. This information is provided by
            Ameritas Life Insurance Corp. (Ameritas Life). Dental and vision
            products (9000 NM Rev. 09-21 for Group and Indiv. 9000 NM Rev. 09-21
            for Individual) are issued by Ameritas Life.
          </small>
          <br />
          <small>
            Ameritas, the bison design and "fulfilling life" are service marks
            or registered service marks of Ameritas Life, affiliate Ameritas
            Holding Company or Ameritas Mutual Holding Company. © 2023 Ameritas
            Mutual Holding Company.
          </small>
        </>
      ),
    },
    dentalLimitations: (
      <>
        <p>
          Covered expenses will not include and benefits will not be payable for
          expenses incurred:
        </p>
        <ul>
          <li>
            for any treatment which is for cosmetic purposes, except as
            specifically listed in the Table of Dental Procedures.
          </li>
          <li>
            to replace any crowns, inlays, onlays, veneers, complete or partial
            dentures within five years of the date of the last placement of
            these items. But if a replacement is required because of an
            accidental bodily injury sustained while the Insured person is
            covered under this contract, it will be a covered expense.
          </li>
          <li>
            for any procedure begun before the insured person was covered under
            the policy.
          </li>
          <li>
            for any procedure begun after the insured person’s insurance under
            the policy terminates; or for any prosthetic dental appliances
            installed or delivered more than 90 days after the insured’s
            insurance under the policy terminates.
          </li>
          <li>to replace lost or stolen appliances.</li>
          <li>
            for appliances, restorations, or procedures to:
            <ul>
              <li>alter vertical dimension;</li>
              <li>restore or maintain occlusion; or</li>
              <li>
                splint or replace tooth structure lost as a result of abrasion
                or attrition.
              </li>
            </ul>
          </li>
          <li>
            for any procedure which is not shown on the Table of Dental
            Procedures. (There may be additional frequencies and limitations
            that apply, please see the Table of Dental Procedures in the
            policy.)
          </li>
          <li>
            for which the insured person is entitled to benefits under any
            workmen’s compensation or similar law, or charges for services or
            supplies received as a result of any dental condition caused or
            contributed to by an injury or sickness arising out of or in the
            course of any employment for wage or profit.
          </li>
          <li>
            for charges for which the insured person is not liable or which
            would not have been made had no insurance been in force.
          </li>
          <li>
            for services which are not required for necessary care and treatment
            or are not within the generally accepted parameters of care.
          </li>
          <li>because of war or any act of war, declared or not.</li>
          <li>
            if two or more procedures are considered adequate and appropriate
            treatment to correct a certain condition under generally accepted
            standards of dental care, the amount of the covered expense will be
            equal to the charge for the least expensive procedure.
          </li>
          <li>
            This policy provides coverage for surgical and nonsurgical treatment
            of temporomandibular joint disorders and craniomandibular disorders,
            subject to the same conditions, limitations, prior review and
            referral procedures as are applicable to treatment of any other
            joint in the body.
          </li>
        </ul>
      </>
    ),
    planFacts: {
      dental: new Map([
        ['Waiting Periods', null],
        ['Value Plan', null],
        [
          'Lite Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/50/10 INN & 70/25/10 OON
            <br />
            Year 2 - 100/80/20 INN & 70/40/10 OON
          </>,
        ],
        [
          'Lite Plan',
          <p key="description">
            Type 1: Exams, Cleanings, Sealants (All Ages), Fluoride (Up to age
            16), Bitewing X-Rays
            <br />
            Type 2: Space Maintainers, Fillings
            <br />
            Type 3: All Other X-rays, Oral Surgery, Simple Extractions,
            Endodontics, Periodontics, Crowns, Bridges, Dentures
          </p>,
        ],
        ['Access Plan In- and Out-of-Network Coinsurance', null],
        ['Access Plan', null],
        [
          'Boost Plan In- and Out-of-Network Coinsurance',
          <>
            Year 1 - 100/65/20 INN & 80/45/10 OON
            <br />
            Year 2 - 100/80/50 INN & 80/60/30 OON
          </>,
        ],
        [
          'Boost Plan',
          <p key="description">
            Type 1: Exams, Cleanings, Sealants (all ages), Fluoride (under age
            16), Space Maintainers, Bitewing X-rays
            <br />
            Type 2: Fillings, Simple Extractions
            <br />
            Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
            Crowns, Bridges, Dentures, Implants, Teeth Whitening
          </p>,
        ],
        ['Total Plan', null],
        [
          'Complete Plan',
          <p key="description">
            Type 1: Exams, Cleanings, Bitewing X-rays, Sealants (all ages),
            Fluoride (under age 16)
            <br />
            Type 2: Fillings, Simple Extractions
            <br />
            Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
            Crowns, Bridges, Dentures, Implants
          </p>,
        ],
      ]),
      vision: new Map([
        ['Select Vision Plan Partner', null],
        ['Select Vision Plan Out-of-Network Maximum', null],
        [
          'Premium Contacts Deductible',
          <p key="description">Choice Vision Plan: Up to $60</p>,
        ],
        [
          'Medically Necessary Contacts',
          <p key="description">
            Choice Vision Plan: Paid in Full minus $20 Materials Deductible
          </p>,
        ],
        [
          'Choice Vision Plan Partner',
          <p key="description">
            Choice Vision Plan features the VSP vision network. Find VSP Choice
            network providers at{' '}
            <a href="https://www.vsp.com" rel="noreferrer" target="_blank">
              vsp.com
            </a>
            . Retail chains accepting VSP insurance include Costco Optical*,
            Sam's Club, Visionworks and Walmart. An online option in the VSP
            network is{' '}
            <a
              href=" https://www.eyeconic.com"
              rel="noreferrer"
              target="_blank">
              eyeconic.com
            </a>
            . Based on applicable laws, reduced cost may vary by doctor
            location.
            <br />
            <small>
              * Not all providers at Costco locations are VSP network providers
              - verify before seeking services.
            </small>
          </p>,
        ],
        [
          'Choice Vision Plan Out-of-Network Maximum',
          <p key="description">
            $45 exams, $70 glasses frames, glasses lenses - $30 single vision,
            $50 bifocal, $50 progressive, $65 trifocal, $100 lenticular, $105
            elective contacts, $210 medically-necessary contacts
          </p>,
        ],
      ]),
    },
  },
  revisions: {
    '2024Q2': {
      stateNotice: (
        <>
          <p>
            NOTICE TO CONSUMERS: This is a limited benefits health plan. The
            benefits provided are supplemental to, and not a substitute for,
            major medical coverage, even in combination with other limited
            benefit plans. To apply for an individual or small-group major
            medical plan, please visit the website of the New Mexico Health
            Insurance Exchange at{' '}
            <a href="https://www.bewellnm.com" rel="noreferrer" target="_blank">
              www.bewellnm.com
            </a>{' '}
            or call 1-833-862-3935 (TTY: 711).
          </p>
          <p>
            This plan may contain limitations for replacement of missing teeth.
            Please see LIMITATIONS for details.
          </p>
        </>
      ),
      fraudNotice: (
        <p>
          Any person who knowingly presents a false or fraudulent claim for
          payment of a loss or benefit or knowingly presents false information
          in an application for insurance is guilty of a crime and may be
          subject to civil fines and criminal penalties.
        </p>
      ),
      planFacts: {
        dental: new Map([
          [
            'Boost Plan',
            <p key="description">
              Type 1: Exams, Cleanings, Fluoride (Up to age 16), Sealants (All
              Ages), Space Maintainers, Bitewing X-rays
              <br />
              Type 2: Fillings, Simple Extractions
              <br />
              Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
              Crowns, Bridges, Dentures, Implants, Teeth Whitening
            </p>,
          ],
          [
            'Complete Plan',
            <p key="description">
              Type 1: Exams, Cleanings, Bitewing X-rays, Sealants (All Ages),
              Fluoride (Up to age 16)
              <br />
              Type 2: Fillings, Simple Extractions
              <br />
              Type 3: All Other X-rays, Oral Surgery, Endodontics, Periodontics,
              Crowns, Bridges, Dentures, Implants
            </p>,
          ],
        ]),
      },
      planHelper: {
        dental: () => (
          <>
            <small>
              Underwritten by Ameritas Life Insurance Corp. | 5900 O Street
              Lincoln, NE 68510
            </small>
            <br />
            <small>
              This is not a certificate of insurance or guarantee of coverage.
              Plan designs may not be available in all areas and are subject to
              individual state regulations. This information is provided by
              Ameritas Life Insurance Corp. (Ameritas Life). Dental and vision
              products (9000 NM Rev. 09-21 for Group and Indiv. 9000 NM Rev.
              09-21 for Individual) are issued by Ameritas Life. Ameritas, the
              bison design and “fulfilling life” are service marks or registered
              service marks of Ameritas Life, affiliate Ameritas Holding Company
              or Ameritas Mutual Holding Company. © 2024 Ameritas Mutual Holding
              Company.
            </small>
          </>
        ),
        vision: () => (
          <>
            <small>
              Underwritten by Ameritas Life Insurance Corp. | 5900 O Street
              Lincoln, NE 68510
            </small>
            <br />
            <small>
              This is not a certificate of insurance or guarantee of coverage.
              Plan designs may not be available in all areas and are subject to
              individual state regulations. This information is provided by
              Ameritas Life Insurance Corp. (Ameritas Life). Dental and vision
              products (9000 NM Rev. 09-21 for Group and Indiv. 9000 NM Rev.
              09-21 for Individual) are issued by Ameritas Life. Ameritas, the
              bison design and “fulfilling life” are service marks or registered
              service marks of Ameritas Life, affiliate Ameritas Holding Company
              or Ameritas Mutual Holding Company. © 2024 Ameritas Mutual Holding
              Company.
            </small>
          </>
        ),
      },
    },
  },
};

export default stateModule;
