import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { ReactComponent as NavigationLeftIcon } from '@liferaft/core/assets/images/icons/duotone-icons/Navigation/Arrow-left.svg';
import { useUserContext } from '@liferaft/core/contexts';

import { useApplicationFlow } from '@/components';

type Props = {
  fullWidth?: boolean;
  largeWidth?: boolean;
  hint?: string;
  heading: string;
  subheading?: string | React.ReactNode;
  helper?: string | React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
};

export function ApplyWrapper({
  fullWidth,
  largeWidth,
  hint,
  heading,
  subheading,
  helper,
  children,
  footer = <ApplyWrapperFooter />,
}: Props) {
  const {
    data: { label, egress },
  } = useApplicationFlow();
  const { user } = useUserContext();

  let hintText;
  if (!hint && user.organization_id) {
    hintText = `Applying on behalf of ${label ? label : ''} (${
      egress?.email || 'No customer email'
    })`;
  } else if (hint) {
    hintText = hint;
  }

  let mainBody;
  if (fullWidth) {
    mainBody = (
      <Container fluid="md">
        <Row className="mt-n10 mb-10 text-gray-800 justify-content-center align-items-center">
          <Col xs={12}>{children}</Col>
        </Row>
        {footer}
      </Container>
    );
  } else if (largeWidth) {
    mainBody = (
      <Container fluid="md">
        <Row className="mt-n10 mb-10 text-gray-800 justify-content-center align-items-center">
          <div className="col-lg-10">
            <Row>{children}</Row>
          </div>
        </Row>
        {footer}
      </Container>
    );
  } else {
    mainBody = (
      <Container fluid="md">
        <Row className="mt-n10 mb-10 text-gray-800 justify-content-center align-items-center">
          <Col lg={8} xs={12}>
            {children}
            {footer}
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <section className="pt-6 pb-10 bg-primary-extra-light">
        <Container>
          <Row className="justify-content-center">
            <Col className="text-center" xs={12}>
              {hintText && (
                <p>
                  <i>{hintText}</i>
                </p>
              )}
              <h1 className="display-2 text-black mb-7" id="apply-heading">
                {heading}
              </h1>
              {subheading && (
                <p
                  className="lead text-black-80 mb-4 mb-md-5"
                  id="apply-subheading">
                  {subheading}
                </p>
              )}
              {helper && (
                <div
                  className="font-size-sm text-black-70 mb-4 mb-md-5"
                  id="apply-helper">
                  {helper}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <main className="mt-md-n6">{mainBody}</main>
    </>
  );
}

type ApplyWrapperFooterProps = {
  footnote?: React.ReactNode;
  previousPage?: string;
  leftAction?: React.ReactNode;
  submitLabel?: string;
  rightDisabled?: boolean;
  rightAction?: React.ReactNode;
};

export const APPLY_FORM_ID = 'apply-form';

export function ApplyWrapperFooter({
  footnote,
  leftAction,
  submitLabel = 'Next',
  rightAction,
  rightDisabled = false,
}: ApplyWrapperFooterProps) {
  const { back } = useApplicationFlow();

  let left;
  if (leftAction) {
    left = leftAction;
  } else {
    left = (
      <Button
        className="icon text-primary"
        data-testid="previous-step-button"
        onClick={back}
        role="button"
        variant="empty">
        <NavigationLeftIcon viewBox="0 0 24 24" />
      </Button>
    );
  }

  let right;
  if (rightAction !== undefined) {
    right = rightAction;
  } else if (submitLabel) {
    right = (
      <Button
        data-testid="primary-form-submit"
        disabled={rightDisabled}
        form={APPLY_FORM_ID}
        id="primary-form-submit"
        role="button"
        type="submit"
        variant="primary">
        {submitLabel}
      </Button>
    );
  }

  return (
    <>
      {footnote && (
        <p className="mt-6 font-size-sm text-center text-muted">{footnote}</p>
      )}
      <div className="d-flex mt-6">
        {left}
        <div className="ml-auto">{right}</div>
      </div>
    </>
  );
}
