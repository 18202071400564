import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import {
  getApplication,
  updateApplicationFlowProgress,
} from '@liferaft/api/resources/application';
import type { Application } from '@liferaft/api/types';
import {
  ApplicationFlowKey,
  ApplicationOrigin,
  ApplicationStatus,
} from '@liferaft/api/types';
import type { NoBody } from '@liferaft/api/utils/network';
import { NetworkController } from '@liferaft/api/utils/network';

import type { FlowState } from '@liferaft/core/flow';
import { createFlow } from '@liferaft/core/flow';

import { ProductMatrixContextProvider } from '@/contexts';
import { DJANGO_API_URL } from '@/environment';
import {
  BrokerFlow,
  ConsumerFlow,
  GroupBenefitFlow,
  QuotingToolFlow,
  UpsellFlow,
} from '@/flows';
import { Path } from '@/flows/paths';
import {
  AIGDisclaimer,
  AIGDisclosures,
  AmeritasDisclaimer,
  AmeritasDisclosures,
  ApplicationAlias,
  Confirm,
  ContactInformation,
  CreateAccount,
  FamilyInformation,
  FinishedNotice,
  LibertyDisclaimer,
  PaymentInformation,
  ProductMatrix,
  RatingData,
  SelectedPlansSummary,
  Submit,
} from '@/pages';

export const [useApplicationFlow, ApplicationFlow] = createFlow<Application>();

export function ApplyEntry() {
  const urlParams = useParams();

  const applicationId = urlParams.id as string;

  const fetchState = async () => {
    const network = new NetworkController();
    const result = await network.request<NoBody, Application>(
      getApplication(applicationId, { 'filter[status]': 'in_progress' })
    );

    if (result.error) return;

    return result.data;
  };

  const determinePreviousPath = (state: FlowState<Application>): string => {
    const { currentPath, data } = state;

    if (!currentPath)
      throw Error('Cannot determine previous step without a currentPath.');

    switch (state.data.flow_key) {
      case ApplicationFlowKey.APPLY: {
        if (data.origin === ApplicationOrigin.GROUP_BENEFIT_FLOW) {
          return GroupBenefitFlow.determinePrevious({
            currentPath,
            state: data,
          });
        } else {
          return data.was_filled_out_by_broker
            ? BrokerFlow.determinePrevious({ currentPath, state: data })
            : ConsumerFlow.determinePrevious({ currentPath, state: data });
        }
      }
      case ApplicationFlowKey.REAPPLY: {
        return UpsellFlow.determinePrevious({ currentPath, state: data });
      }
      case ApplicationFlowKey.QUOTING_TOOL_ON_BEHALF_OF: {
        return QuotingToolFlow.determinePrevious({ currentPath, state: data });
      }
      default: {
        throw new Error('Cannot determine flow to render');
      }
    }
  };

  const handleStepTransition = (currentStep: string) => {
    const network = new NetworkController();
    network.request(
      updateApplicationFlowProgress(applicationId, {
        current_step: currentStep,
      })
    );
  };

  const determineNextPath = (state: FlowState<Application>): string | null => {
    const { currentPath, data, returnPath, goToPath } = state;

    if (data.status === ApplicationStatus.FINISHED) {
      return currentPath ? null : Path.FINISHED_NOTICE;
    }

    const flowParams = {
      currentPath,
      returnPath,
      goToPath,
      staleCollectables: data.flow_state?.stale_fields,
      state: data,
    };

    switch (state.data.flow_key) {
      case ApplicationFlowKey.APPLY: {
        if (data.origin === ApplicationOrigin.GROUP_BENEFIT_FLOW) {
          return GroupBenefitFlow.determineNext(flowParams);
        } else {
          return data.was_filled_out_by_broker
            ? BrokerFlow.determineNext(flowParams)
            : ConsumerFlow.determineNext(flowParams);
        }
      }
      case ApplicationFlowKey.REAPPLY: {
        return UpsellFlow.determineNext(flowParams);
      }
      case ApplicationFlowKey.QUOTING_TOOL_ON_BEHALF_OF: {
        return QuotingToolFlow.determineNext(flowParams);
      }
      default: {
        throw new Error('Cannot determine flow to render');
      }
    }
  };

  const exitPath = () => {
    window.location.href = DJANGO_API_URL as string;
  };

  return (
    <ApplicationFlow
      determineNextPath={determineNextPath}
      determinePreviousPath={determinePreviousPath}
      exitPath={exitPath}
      fetchState={fetchState}
      onStepTransition={handleStepTransition}
      pathPrefix={`/apply/${urlParams.id}/`}>
      <Routes>
        <Route element={<ApplicationAlias />} path={Path.ALIAS} />
        <Route element={<RatingData />} path={Path.RATING_DATA} />
        <Route
          element={
            <ProductMatrixContextProvider>
              <ProductMatrix />
            </ProductMatrixContextProvider>
          }
          path={Path.PRODUCT_MATRIX}
        />
        <Route
          element={
            <ProductMatrixContextProvider>
              <SelectedPlansSummary />
            </ProductMatrixContextProvider>
          }
          path={Path.PLAN_SUMMARY}
        />
        <Route element={<CreateAccount />} path={Path.CREATE_ACCOUNT} />
        <Route element={<ContactInformation />} path={Path.CONTACT_INFO} />
        <Route
          element={
            <ProductMatrixContextProvider>
              <FamilyInformation />
            </ProductMatrixContextProvider>
          }
          path={Path.DEPENDENTS}
        />
        <Route element={<PaymentInformation />} path={Path.PAYMENT_INFO} />
        <Route
          element={
            <ProductMatrixContextProvider>
              <Confirm />
            </ProductMatrixContextProvider>
          }
          path={Path.CONFIRM}
        />
        <Route element={<AIGDisclosures />} path={Path.AIG_DISCLOSURES} />
        <Route
          element={
            <ProductMatrixContextProvider>
              <AIGDisclaimer />
            </ProductMatrixContextProvider>
          }
          path={Path.AIG_DISCLAIMER}
        />
        <Route
          element={<AmeritasDisclosures />}
          path={Path.AMERITAS_DISCLOSURES}
        />
        <Route
          element={
            <ProductMatrixContextProvider>
              <AmeritasDisclaimer />
            </ProductMatrixContextProvider>
          }
          path={Path.AMERITAS_DISCLAIMER}
        />
        <Route element={<LibertyDisclaimer />} path={Path.LIBERTY_DISCLAIMER} />
        <Route element={<Submit />} path={Path.SUBMIT} />
        <Route element={<FinishedNotice />} path={Path.FINISHED_NOTICE} />
      </Routes>
    </ApplicationFlow>
  );
}
