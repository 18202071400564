import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { YesNo } from '@liferaft/api/types';

type Props = {
  idPrefix: string;
  trueLabel: string;
  falseLabel: string;
  value?: string;
  onChange: (value: YesNo) => void;
};

export function YesNoSelect({
  idPrefix,
  trueLabel,
  falseLabel,
  value,
  onChange,
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as YesNo);
  };

  return (
    <Form.Group data-testid={`${idPrefix}-opt-in-form-group`}>
      <Row className="mt-6">
        <Col md={6} xs={12}>
          <Form.Label
            className={
              value === YesNo.YES
                ? 'card mb-6 hover-pointer border border-primary'
                : 'card mb-6 hover-pointer border text-muted'
            }
            htmlFor={`${idPrefix}-opt-in-true`}>
            <div className="d-flex card-body py-3">
              <Form.Check
                checked={value == YesNo.YES}
                className="form-check mb-3 mt-3 d-flex align-items-center"
                data-testid="opt-in-true"
                id={`${idPrefix}-opt-in-true`}
                name={`${idPrefix}-opt-in`}
                onChange={handleChange}
                required
                type="radio"
                value={YesNo.YES}
              />
              {trueLabel}
            </div>
          </Form.Label>
        </Col>
        <Col md={6} xs={12}>
          <Form.Label
            className={
              value === YesNo.NO
                ? 'card mb-6 hover-pointer border border-primary'
                : 'card mb-6 hover-pointer border text-muted'
            }
            htmlFor={`${idPrefix}-opt-in-false`}>
            <div className="d-flex card-body py-3">
              <Form.Check
                checked={value == YesNo.NO}
                className="form-check mb-3 mt-3 d-flex align-items-center"
                data-testid="opt-in-false"
                id={`${idPrefix}-opt-in-false`}
                name={`${idPrefix}-opt-in`}
                onChange={handleChange}
                required
                type="radio"
                value={YesNo.NO}
              />
              {falseLabel}
            </div>
          </Form.Label>
        </Col>
      </Row>
    </Form.Group>
  );
}
