import type { StateAbbr } from '../../states';
import { AllStates, isStateAbbr, stateName } from '../../states';
import basestate from './basestate';
import AK from './perstate/AK';
import AL from './perstate/AL';
import AR from './perstate/AR';
import AZ from './perstate/AZ';
import CA from './perstate/CA';
import CO from './perstate/CO';
import CT from './perstate/CT';
import DC from './perstate/DC';
import DE from './perstate/DE';
import FL from './perstate/FL';
import GA from './perstate/GA';
import HI from './perstate/HI';
import IA from './perstate/IA';
import ID from './perstate/ID';
import IL from './perstate/IL';
import IN from './perstate/IN';
import KS from './perstate/KS';
import KY from './perstate/KY';
import LA from './perstate/LA';
import MA from './perstate/MA';
import MD from './perstate/MD';
import ME from './perstate/ME';
import MI from './perstate/MI';
import MN from './perstate/MN';
import MO from './perstate/MO';
import MS from './perstate/MS';
import MT from './perstate/MT';
import NC from './perstate/NC';
import ND from './perstate/ND';
import NE from './perstate/NE';
import NH from './perstate/NH';
import NJ from './perstate/NJ';
import NM from './perstate/NM';
import NV from './perstate/NV';
import NY from './perstate/NY';
import OH from './perstate/OH';
import OK from './perstate/OK';
import OR from './perstate/OR';
import PA from './perstate/PA';
import RI from './perstate/RI';
import SC from './perstate/SC';
import SD from './perstate/SD';
import TN from './perstate/TN';
import TX from './perstate/TX';
import UT from './perstate/UT';
import VA from './perstate/VA';
import VT from './perstate/VT';
import WA from './perstate/WA';
import WI from './perstate/WI';
import WV from './perstate/WV';
import WY from './perstate/WY';
import type { AmeritasContent, AmeritasRevision, StateModule } from './types';
import { mergeStateOverride, zipcodeInRange } from './types';

const AmeritasDentalSkippedStates: ReadonlyArray<StateAbbr> = ['MA'];
export const AmeritasDentalStates: ReadonlyArray<StateAbbr> = AllStates.filter(
  (el) => !AmeritasDentalSkippedStates.includes(el)
);
const AmeritasDentalNonBaseStates: Record<
  AmeritasRevision,
  ReadonlyArray<StateAbbr>
> = {
  '2023Q3': [
    'AR',
    'CO',
    'CT',
    'FL',
    'IL',
    'KS',
    'KY',
    'LA',
    'MD',
    'MO',
    'NC',
    'NH',
    'NJ',
    'NM',
    'NY',
    'OH',
    'OK',
    'TX',
    'VA',
    'WA',
    'WI',
  ],
  '2024Q2': [
    'AK',
    'AR',
    'CO',
    'CT',
    'FL',
    'GA',
    'IL',
    'KS',
    'LA',
    'MD',
    'MO',
    'MS',
    'MT',
    'NC',
    'NH',
    'NJ',
    'NM',
    'NY',
    'OH',
    'OK',
    'PA',
    'RI',
    'TX',
    'VA',
    'WA',
    'WI',
    'WY',
  ],
};
export function ameritasDentalNonBaseStates(
  revision: AmeritasRevision
): ReadonlyArray<StateAbbr> {
  return AmeritasDentalNonBaseStates[revision];
}
export function ameritasDentalBaseStates(
  revision: AmeritasRevision
): ReadonlyArray<StateAbbr> {
  return AmeritasDentalStates.filter(
    (el) => !AmeritasDentalNonBaseStates[revision].includes(el)
  );
}

const AmeritasVisionSkippedStates: ReadonlyArray<StateAbbr> = ['MD'];
export const AmeritasVisionStates: ReadonlyArray<StateAbbr> = AllStates.filter(
  (el) => !AmeritasVisionSkippedStates.includes(el)
);
const AmeritasVisionNonBaseStates: Record<
  AmeritasRevision,
  ReadonlyArray<StateAbbr>
> = {
  '2023Q3': ['MA', 'MT', 'NM', 'RI', 'TX', 'WA'],
  '2024Q2': ['MA', 'MT', 'NM', 'RI', 'TX', 'WA'],
};
export function ameritasVisionNonBaseStates(
  revision: AmeritasRevision
): ReadonlyArray<StateAbbr> {
  return AmeritasVisionNonBaseStates[revision];
}
export function ameritasVisionBaseStates(
  revision: AmeritasRevision
): ReadonlyArray<StateAbbr> {
  return AmeritasVisionStates.filter(
    (el) => !AmeritasVisionNonBaseStates[revision].includes(el)
  );
}

const StateDefinitions: Record<StateAbbr, StateModule> = {
  AK: AK,
  AL: AL,
  AR: AR,
  AZ: AZ,
  CA: CA,
  CO: CO,
  CT: CT,
  DC: DC,
  DE: DE,
  FL: FL,
  GA: GA,
  HI: HI,
  IA: IA,
  ID: ID,
  IL: IL,
  IN: IN,
  KS: KS,
  KY: KY,
  LA: LA,
  MA: MA,
  MD: MD,
  ME: ME,
  MI: MI,
  MN: MN,
  MO: MO,
  MS: MS,
  MT: MT,
  NC: NC,
  ND: ND,
  NE: NE,
  NH: NH,
  NJ: NJ,
  NM: NM,
  NV: NV,
  NY: NY,
  OH: OH,
  OK: OK,
  OR: OR,
  PA: PA,
  RI: RI,
  SC: SC,
  SD: SD,
  TN: TN,
  TX: TX,
  UT: UT,
  VA: VA,
  VT: VT,
  WA: WA,
  WI: WI,
  WV: WV,
  WY: WY,
};

/**
 * Get the Ameritas content for a given state and revision.
 *
 * Here we merge the base state content with the state and zipcode overrides.
 * We follow a specific order of precedence:
 * 1. base state content
 * 2. base state revision overrides
 * 3. state content overrides
 * 4. state revision overrides
 * 5. zipcode specific content overrides for each matching zipcode
 * 6. zipcode specific revision overrides for each matching zipcode
 *
 * @param state The state abbreviation.
 * @param revision The Ameritas revision.
 * @param zipcode The zipcode.
 * @returns The mergedAmeritas content with added state and revision information.
 */
export function getAmeritasStateContent(
  state: string | StateAbbr,
  revision: AmeritasRevision,
  zipcode?: string
): AmeritasContent {
  if (!isStateAbbr(state)) {
    throw new Error(`Invalid state abbreviation: ${state}`);
  }
  let content = basestate.default;
  content = mergeStateOverride(content, basestate.revisions[revision]);

  const stateOverrides = StateDefinitions[state];
  content = mergeStateOverride(content, stateOverrides.default);
  content = mergeStateOverride(content, stateOverrides.revisions?.[revision]);

  if (zipcode && stateOverrides.zipcodeOverrides) {
    for (const zipcodeOverride of stateOverrides.zipcodeOverrides) {
      if (
        zipcodeOverride.zipcodes.some((range) => zipcodeInRange(zipcode, range))
      ) {
        content = mergeStateOverride(
          content,
          zipcodeOverride.overrides.default
        );
        content = mergeStateOverride(
          content,
          zipcodeOverride.overrides.revisions?.[revision]
        );
      }
    }
  }
  return {
    ...content,
    name: stateName(state),
    abbr: state,
    revision: revision,
  };
}
