import React from 'react';
import { Form } from 'react-bootstrap';

const usStates: Record<string, string> = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

type Props = {
  error?: string;
  required?: boolean;
  disableState?: boolean;
  label?: string;
  groupClasses?: string;
  selectClasses?: string;
  stateCodes?: string[];
  value?: string;
  onChange: (value: string) => void;
};

export function StateInput({
  error,
  required = false,
  disableState = false,
  label,
  groupClasses,
  selectClasses = '',
  stateCodes,
  value,
  onChange,
}: Props) {
  let stateOptions: Record<string, string> = {};
  if (stateCodes) {
    const allowedStates = stateCodes.filter((code: string) =>
      Object.keys(usStates).includes(code)
    );
    stateOptions = Object.fromEntries(
      Object.entries(usStates).filter(([code]) => allowedStates.includes(code))
    );
  } else {
    stateOptions = usStates;
  }

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <Form.Group className={groupClasses} data-testid="state-form-group">
      {label && <Form.Label htmlFor="state-select">{label}</Form.Label>}
      <Form.Select
        className={'form-control' + selectClasses}
        data-testid="state-select"
        disabled={disableState}
        id="state-select"
        isInvalid={!!error}
        name="stateSelect"
        onChange={handleChange}
        required={required}
        value={value}>
        <option disabled value="">
          State
        </option>
        {Object.keys(stateOptions).map((state) => (
          <option data-testid="select-option" key={state} value={state}>
            {stateOptions[state]}
          </option>
        ))}
      </Form.Select>
      {error && (
        <div className="invalid-feedback d-block" data-testid="state-error">
          {error}
        </div>
      )}
    </Form.Group>
  );
}
